import Header from "../../components/Header";
import FindEamilStyle from "../../components/login&join/FindEmailStyle";

const FindEmail = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <FindEamilStyle />
    </>
  );
};

export default FindEmail;
