import styled from "@emotion/styled/macro";
import Check from "../../../../assets/img/findemail.png";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as DotLine } from "../../../../assets/img/PickupStepLine.svg";
import { ReactComponent as CheckStep } from "../../../../assets/img/PickupStepCheck2.svg";
import { ReactComponent as CheckDone } from "../../../../assets/icons/CheckDone.svg";
import { ReactComponent as DotLineDone } from "../../../../assets/icons/DotLineDone.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";

const Auction = ({ selfSellCarData, auctionResult }) => {
  console.log(selfSellCarData);
  const StepBarDot = styled(DotLine)`
    margin-top: 10px;
  `;

  const StepBarDoneDot = styled(DotLineDone)`
    margin-top: 10px;
  `;

  const DoneText = styled(SelfSellCarStepText)`
    color: #ffd0b8;
  `;

  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            회원님의 차량은 경매 단계에 있어요.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>
        {/* 경매 시작 / 종료 / 유찰 유무에 따라서 텍스트 달라짐 */}
        {selfSellCarData?.status === "경매신청" && (
          <SelfSellCarStepSubText>
            회원님의 차량의 경매가 시작되었습니다.
          </SelfSellCarStepSubText>
        )}
        {selfSellCarData?.status === "경매완료" && auctionResult === "낙찰" ? (
          <SelfSellCarStepSubText>
            차량이 낙찰되었습니다. 정산 신청을 해주세요.
          </SelfSellCarStepSubText>
        ) : null}
        {selfSellCarData?.status === "경매완료" && auctionResult === "유찰" ? (
          <SelfSellCarStepSubText>
            차량이 유찰되었습니다. 재경매 및 위탁 판매 신청을 선택해주세요!
          </SelfSellCarStepSubText>
        ) : null}
      </SelfSellCarApplyTextDiv>

      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>상품화</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer
          align
          style={{ marginLeft: 13, marginRight: 13 }}
        >
          <CheckActive />
          <SelfSellCarStepText active>경매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        {auctionResult === "낙찰" ? null : (
          <>
            <StepBarDot />
            <SelfSellCarInputContainer align>
              <CheckStep />
              <SelfSellCarStepText>위탁 판매</SelfSellCarStepText>
            </SelfSellCarInputContainer>{" "}
          </>
        )}
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};
export default Auction;
