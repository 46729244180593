import styled from "@emotion/styled/macro";
import moment from "moment";
import { Modal } from "antd";
import { ReactComponent as Default } from "../../assets/img/defaultDeliveryList.svg";

export const ModalWrapDiv = styled.div`
  width: 100%;
  height: 244px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const StepModalWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalText = styled.span`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
  color: #212529;
  line-height: 30px;
  letter-spacing: -0.6px;
`;

const StepModalText = styled.span`
  font-size: 16px;
  color: #868e96;
  line-height: 26px;
`;

const ModalText2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.6px;
`;

const ModalButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: #212529;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
`;

const StepModalContentDiv = styled.div`
  width: 580px;
  margin: 45px auto 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const StepNumberDiv = styled.div`
  width: 32px;
  height: 32px;
  font-family: SpoqaHanSansNeo;
  background-color: #ff6a13;
  z-index: 999;
  border-radius: 100%;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  display : flex;
  justify-content : center;
  align-items: center;
`;

const StepTextDiv = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const DotLine = styled.div`
  position: absolute;
  height: 450px;
  width: 0;
  top: 35px;
  left: 15px;
  border: 1px dashed rgba(134, 142, 150, 0.3);
  z-index: 0;
`;

const DeliveryListDiv = styled.div`
  width: 558px;
  height: 130px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  border: 1px solid #ededed;
  border-radius: 10px;
  align-items: center;
`;

const DeliveryTextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeliveryChoiceButton = styled.button`
  width: 120px;
  height: 60px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #333333;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  cursor: pointer;
`;

// 리뷰 모달
const ReviewWriterDiv = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReviewModalLine = styled.div`
  width: 720px;
  height: 2px;
  border: 1px solid #ededed;
  margin: 20px 0 24px 0;
`;

const ReviewImg = styled.img`
  width: 100%;
  height: 540px;
  margin-top: 30px;
  border-radius: 10px;
`;

const ReviewText = styled.p`
  font-size: 22px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.66px;
  color: #212529;
  margin-top: 14px;
`;

const ReviewText2 = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
`;

const ReviewText3 = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  margin-top: 24px;
  letter-spacing: -0.72px;
  color: #212529;
`;

const CheckNumberButton = styled.button`
  width: 100%;
  height: 95px;
  border-top: 1px solid #dee2e6 !important;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: #212529;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: SpoqaHanSansNeo;
`;

export const CertifiCationModal = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal
      footer={null}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      centered
      closable={false}
      cancelButtonProps={false}
      width={522}
    >
      <ModalWrapDiv>
        <ModalText2>인증번호가 발송되었습니다.</ModalText2>
      </ModalWrapDiv>
      <CheckNumberButton onClick={() => setModalOpen(false)}>
        확인
      </CheckNumberButton>
    </Modal>
  );
};

export const CertifiCationIdentyModal = ({
  certifiCationModalOpen,
  setCertificationModalOpen,
}) => {
  return (
    <Modal
      footer={null}
      visible={certifiCationModalOpen}
      onCancel={() => setCertificationModalOpen(false)}
      centered
      closable={false}
      cancelButtonProps={false}
      width={522}
    >
      <ModalWrapDiv>
        <ModalText2>인증이 완료되었습니다.</ModalText2>
      </ModalWrapDiv>
      <CheckNumberButton onClick={() => setCertificationModalOpen(false)}>
        확인
      </CheckNumberButton>
    </Modal>
  );
};

export const PrivacyPolicyModal = ({ setPolicyModal, policyModal }) => {
  return (
    <Modal
      footer={null}
      visible={policyModal}
      onCancel={() => setPolicyModal(false)}
      centered
      closable={false}
      cancelButtonProps={false}
      width={522}
      bodyStyle={{ height: 500, overflow: "scroll" }}
      title={
        <ModalWrapDiv style={{ height: 30 }}>
          <ModalText>개인정보 처리방침</ModalText>
        </ModalWrapDiv>
      }
    >
      <ModalText style={{ fontWeight: 500 }}>
        이상 눈이 때까지 우리의 없으면 봄바람을 일월과 이상을 되려니와, 말이다.
        동력은 물방아 청춘에서만 굳세게 할지니, 무엇을 청춘의 끓는 있는 보라.
        하여도 발휘하기 인류의 싹이 든 피다. 설산에서 긴지라 모래뿐일 없는 보는
        있다. 우리 싶이 앞이 생생하며, 작고 것이 이 오아이스도 긴지라 위하여서.
        두손을 우리 피는 크고 바로 힘있다. 바이며, 사라지지 우는 그들은 피가
        청춘의 것이다. 주며, 그것은 소담스러운 피고 사막이다. 설산에서 싹이
        주며, 트고, 이는 꽃 뿐이다. 이상 눈이 때까지 우리의 없으면 봄바람을
        일월과 이상을 되려니와, 말이다. 동력은 물방아 청춘에서만 굳세게 할지니,
        무엇을 청춘의 끓는 있는 보라. 하여도 발휘하기 인류의 싹이 든 피다.
        설산에서 긴지라 모래뿐일 없는 보는 있다. 우리 싶이 앞이 생생하며, 작고
        것이 이 오아이스도 긴지라 위하여서. 두손을 우리 피는 크고 바로 힘있다.
        바이며, 사라지지 우는 그들은 피가 청춘의 것이다. 주며, 그것은 소담스러운
        피고 사막이다. 설산에서 싹이 주며, 트고, 이는 꽃 뿐이다.
      </ModalText>
    </Modal>
  );
};

export const ReviewModal = ({
  reviewModalOpen,
  setReviewModalOpen,
  writeReview,
}) => {
  const closeModal = () => {
    setReviewModalOpen(false);
  };

  return (
    <Modal
      centered
      width={484}
      footer={null}
      cancelButtonProps={false}
      closable={false}
      visible={reviewModalOpen}
      bodyStyle={{
        height: "auto",
      }}
      onCancel={closeModal}
    >
      <ModalWrapDiv
        style={{
          borderBottom: " 1px solid #DEE2E6",
          height: 244,
          flexDirection: "column",
        }}
      >
        <ModalText>후기를 등록 하시겠습니까 ?</ModalText>
        <ModalText> (한번 등록한 후기는 수정이 불가능합니다.)</ModalText>
      </ModalWrapDiv>
      <ModalWrapDiv style={{ height: 80 }}>
        <ModalButton
          onClick={closeModal}
          style={{ borderRight: "1px solid #DEE2E6" }}
        >
          취소
        </ModalButton>
        <ModalButton onClick={writeReview}>확인</ModalButton>
      </ModalWrapDiv>
    </Modal>
  );
};

export const SelfSellCarReviewModal = ({
  reviewModalOpen,
  setReviewModalOpen,
  selfSellCarReview,
}) => {
  const closeModal = () => {
    setReviewModalOpen(false);
  };

  return (
    <Modal
      centered
      width={484}
      footer={null}
      cancelButtonProps={false}
      closable={false}
      visible={reviewModalOpen}
      bodyStyle={{
        height: "auto",
      }}
      onCancel={closeModal}
    >
      <ModalWrapDiv
        style={{
          borderBottom: " 1px solid #DEE2E6",
          height: 244,
          flexDirection: "column",
        }}
      >
        <ModalText>후기를 등록 하시겠습니까 ?</ModalText>
        <ModalText> (한번 등록한 후기는 수정이 불가능합니다.)</ModalText>
      </ModalWrapDiv>
      <ModalWrapDiv style={{ height: 80 }}>
        <ModalButton
          onClick={closeModal}
          style={{ borderRight: "1px solid #DEE2E6" }}
        >
          취소
        </ModalButton>
        <ModalButton onClick={selfSellCarReview}>확인</ModalButton>
      </ModalWrapDiv>
    </Modal>
  );
};

export const PickUpStepModal = ({ stepModalOpen, setStepModalOpen }) => {
  const handleCancel = () => {
    setStepModalOpen(false);
  };
  return (
    <Modal
      centered
      title="픽업정비 진행단계"
      footer={null}
      visible={stepModalOpen}
      onCancel={() => setStepModalOpen(false)}
      onOk={handleCancel}
      width={700}
      bodyStyle={{ paddingBottom : 30}}
    >
      <StepModalWrapDiv>
        <ModalText style={{ marginTop: 41, fontWeight: 500 }}>
          픽업정비는 어떻게 진행되나요 ?{" "}
        </ModalText>

        <StepModalContentDiv>
          <DotLine />
          <div style={{ display: "flex" }}>
            <StepNumberDiv>1</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 , marginTop : 3 }}>
                입고대기
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              고객님이 원하는 시간과 장소로 전문 탁송기사가 방문하여 안전하게 탁송합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex" , marginTop : 45}}>
            <StepNumberDiv>2</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500  , marginTop : 3 }}>
                정밀점검
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              입고 후 전문 차량평가사에 의하여 차량점검을 실시합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 }}>
            <StepNumberDiv>3</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500  , marginTop : 3 }}>
                점검결과 확인
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
                {" "}
                고객님이 원하는 정비부위를 선택하여 주세요.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 }}>
            <StepNumberDiv>4</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 , marginTop : 3  }}>
                정비 중
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              40년 전통의 기술력으로 완벽한 차량정비를 제공합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 }}>
            <StepNumberDiv>5</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 , marginTop : 3 }}>
                정비 완료
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
                {" "}
                정비 결과를 확인해 주세요. 고객님이 원하는 장소로 안전하게 딜리버리 해드립니다.
              </StepModalText>
            </StepTextDiv>
          </div>
       
        </StepModalContentDiv>
      </StepModalWrapDiv>
    </Modal>
  );
};

export const SelfSellCarStepModal = ({
  selfSellCarStepModal,
  setSelfSellCarStepModal,
}) => {
  const handleCancel = () => {
    setSelfSellCarStepModal(false);
  };
  return (
    <Modal
      centered
      title="셀프 셀카 진행단계"
      footer={null}
      visible={selfSellCarStepModal}
      onCancel={() => setSelfSellCarStepModal(false)}
      onOk={handleCancel}
      width={700}
      bodyStyle={{ height: 860, overflow: "scroll" }}
    >
      <StepModalWrapDiv style={{ marginBottom: 55  }}>
        <ModalText style={{ marginTop: 41, fontWeight: 500 }}>
          셀프 셀카는 어떻게 진행되나요 ?{" "}
        </ModalText>

        <StepModalContentDiv>
          <DotLine style={{ height: 670 }} />
          <div style={{ display: "flex" }}>
            <StepNumberDiv>1</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                입고대기
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              간편신청(이름, 전화번호, 차량번호)만 입력하면 접수 끝!<br/>
        고객님이 원하는 시간과 장소로 전문 탁송기사가 방문하여 안전하게 탁송합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 }}>
            <StepNumberDiv>2</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                정밀점검
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              입고 후 전문 차량평가사에 의하여 '탈것 정밀 점검'을 실시합니다. <br/>점검결과를 확인해 주세요.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 }}>
            <StepNumberDiv>3</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                상품화
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              고객님이 원하는 상품화(정비)부위를 선택하여 주세요. <br/>상품화 완료된 결과는 고객님과 공유합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45 , height : 94}}>
            <StepNumberDiv>4</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                경매
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              상품화된 상태를 바탕으로 전국 제휴딜러의 실시간 경쟁입찰이 진행됩니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45  , height : 94}}>
            <StepNumberDiv>5</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                위탁 판매
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              가장 합리적인 판매를 위해 판매 전문가가 처음부터 끝까지 진행해 드립니다.
              </StepModalText>
            </StepTextDiv>
          </div>
          <div style={{ display: "flex", marginTop: 45  , height : 94}}>
            <StepNumberDiv>5</StepNumberDiv>
            <StepTextDiv>
              <ModalText style={{ fontSize: 18, fontWeight: 500 }}>
                판매 완료
              </ModalText>
              <StepModalText style={{ marginTop: 12 }}>
              최종금액을 확인하고 거래를 완료하세요. 명의 이전까지 모든 서비스는 '탈것' 에서 제공합니다.
              </StepModalText>
            </StepTextDiv>
          </div>
        </StepModalContentDiv>
      </StepModalWrapDiv>
    </Modal>
  );
};

export const ImageModal = ({ imageModal, setImageModal }) => {
  return (
    <Modal
      centered
      width={522}
      visible={imageModal}
      footer={null}
      onCancel={() => setImageModal(false)}
      bodyStyle={{ height: 339 }}
    >
      <ModalWrapDiv style={{ borderBottom: "1px solid #DEE2E6" }}>
        <ModalText style={{ fontWeight: 500 }}>
          사진은 최대 16장 까지 첨부 가능 합니다.
        </ModalText>
      </ModalWrapDiv>
      <StepModalWrapDiv style={{ height: 95, justifyContent: "center" }}>
        <ModalText
          style={{ fontWeight: 400, cursor: "pointer" }}
          onClick={() => setImageModal(false)}
        >
          확인
        </ModalText>
      </StepModalWrapDiv>
    </Modal>
  );
};

export const AddressModal = ({ addressModal, setAddressModal }) => {
  return (
    <Modal
      centered
      width={522}
      visible={addressModal}
      footer={null}
      onCancel={() => setAddressModal(false)}
      bodyStyle={{ height: 339 }}
    >
      <ModalWrapDiv style={{ borderBottom: "1px solid #DEE2E6" }}>
        <ModalText style={{ fontWeight: 500 }}>
          등록된 배송지가 없습니다.
        </ModalText>
      </ModalWrapDiv>
      <StepModalWrapDiv style={{ height: 95, justifyContent: "center" }}>
        <ModalText
          style={{ fontWeight: 400, cursor: "pointer" }}
          onClick={() => setAddressModal(false)}
        >
          확인
        </ModalText>
      </StepModalWrapDiv>
    </Modal>
  );
};

export const DeliveryModal = ({ deliveryModalOpen, setDeliveryModalOpen }) => {
  return (
    <Modal
      centered
      width={522}
      visible={deliveryModalOpen}
      footer={null}
      onCancel={() => setDeliveryModalOpen(false)}
      bodyStyle={{ height: 339 }}
    >
      <ModalWrapDiv
        style={{ borderBottom: "1px solid #DEE2E6", textAlign: "center" }}
      >
        <ModalText style={{ fontWeight: 400 }}>
          배송 정보가 입력되었습니다.
          <br />
          상담원이 연락 드릴 예정입니다.
        </ModalText>
      </ModalWrapDiv>
      <StepModalWrapDiv style={{ height: 95, justifyContent: "center" }}>
        <ModalText
          style={{ fontWeight: 400, cursor: "pointer" }}
          onClick={() => setDeliveryModalOpen(false)}
        >
          확인
        </ModalText>
      </StepModalWrapDiv>
    </Modal>
  );
};

export const DeliveryListModal = ({
  openListModal,
  setOpenListModal,
  deliveryList,
  setZipCode,
  setMyAdress,
  setPickUpAddressDetail,
}) => {
  const cancelModal = () => {
    setOpenListModal(false);
  };

  const deliveryListOption = deliveryList?.map((delivery) => {
    return (
      <DeliveryListDiv key={delivery.id}>
        <DeliveryTextDiv>
          {delivery.default === true ? <Default /> : null}
          <ModalText
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#333",
              marginTop: 11,
            }}
          >
            {delivery.name}
          </ModalText>
          <ModalText style={{ fontSize: 18, fontWeight: 400, marginLeft: 6 }}>
            {delivery.address} ( {delivery.addressDetail ?? null} )
          </ModalText>
        </DeliveryTextDiv>
        <DeliveryChoiceButton
          onClick={() => {
            setMyAdress(delivery.address);
            setPickUpAddressDetail(delivery.addressDetail);
            setZipCode(delivery.zipcode);
            setOpenListModal(false);
          }}
        >
          선택
        </DeliveryChoiceButton>
      </DeliveryListDiv>
    );
  });

  return (
    <Modal
      centered
      visible={openListModal}
      onCancel={cancelModal}
      footer={null}
      title="기존 배송지 선택"
      width={700}
      bodyStyle={{ height: "auto", minHeight: 700 }}
    >
      <StepModalWrapDiv style={{ marginTop: 70 }}>
        {deliveryListOption}
      </StepModalWrapDiv>
    </Modal>
  );
};

// 리뷰 모달
export const MainUserReviewModal = ({
  setOpenReviewModal,
  openReviewModal,
  selectedReview,
}) => {
  return (
    <Modal
      footer={null}
      width={800}
      title="고객 후기"
      visible={openReviewModal}
      className="review_modal"
      onCancel={() => setOpenReviewModal(false)}
      bodyStyle={{
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40,
        overflowY : 'scroll',
      }}
    >
      <ReviewWriterDiv>
        <ReviewText2>
          {selectedReview
            ? `픽업정비 이용자 ${selectedReview[0]?.userName} 님`
            : null}
        </ReviewText2>
        <ReviewText2>
          {selectedReview
            ? moment(selectedReview[0]?.createdAt).format("YYYY.MM.DD")
            : null}
        </ReviewText2>
      </ReviewWriterDiv>
      <ReviewText>{selectedReview ? selectedReview[0]?.name : null}</ReviewText>
      <ReviewModalLine />
      <ReviewText3>
        {selectedReview ? selectedReview[0]?.content : null}
      </ReviewText3>

      <ReviewImg
        src={selectedReview ? selectedReview[0]?.image : null}
        alt="Car"
      />
    </Modal>
  );
};
