import axios from "axios";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Tabs, Table } from "antd";
import { useNavigate } from "react-router";
import tokenState from "../../states/TokenState";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import { MyPageImg, DeliveryListModal } from "./MyPageDeliveryListStyle";
import myPageImg from "../../assets/img/myPageImg.png";
import { MyPageSubText as LinkText } from "./MyPagePickUpStyle";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageText,
  MyPageActiveDiv,
  MyPageContentListDiv,
} from "./MyPageMenu";
import "../Common.css";
import "../PickUp.css";
import { BASIC_URL } from "../CommonStyle";
import { useUserType } from "../../hooks/useUserInfo";
import moment from "moment";
import { MyPageLine } from "./MyPageUpdateInfoStyle";

const MyPageSubText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => (props.color ? "#868e96" : "#212529")};
  font-weight: 400;
  ${(props) =>
    props.font &&
    css`
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ededed;
      height: 100%;
    `};
`;

const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: props.column && "column",
  width: props.width && "100%",
  alignItems: props.center && "center",
}));

const TableDiv = styled.div((props) => ({
  marginTop: props.margin && "32px",
  display: "flex",
  flexDirection: "row",
  width: "708px",
  height: "53px",
  borderBottom: "1px solid #ededed",
  borderTop: props.border && " 1px solid #ededed",
  alignItems: "center",
}));

const MyPagePickUpStyle = ({ userInfo }) => {
  const { TabPane } = Tabs;

  const navigate = useNavigate();

  const token = useRecoilValue(tokenState);

  const [userCarstorageList, setUserCarStorageList] = useState();

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    } else {
      axios({
        url: `${BASIC_URL}/user/mypage/car-storage`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.data) {
            setUserCarStorageList(res.data.carStorages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => setUserCarStorageList();
  }, [navigate, token]);

  const tableColumn = [
    {
      title: <MyPageSubText color="true">옵션</MyPageSubText>,
      dataIndex: "옵션",
      key: "옵션",
      align: "center",
      render: (text) => <MyPageSubText>{text}</MyPageSubText>,
    },
    {
      title: <MyPageSubText color="true">장수</MyPageSubText>,
      dataIndex: "장수",
      key: "장수",
      align: "center",
      render: (text) => <MyPageSubText>{text}</MyPageSubText>,
    },
    {
      title: <MyPageSubText color="true">날짜</MyPageSubText>,
      dataIndex: "날짜",
      key: "날짜",
      align: "center",
      render: (text) => <MyPageSubText>{text}</MyPageSubText>,
    },

    {
      title: <MyPageSubText color="true">총 결제 금액</MyPageSubText>,
      dataIndex: "총결제금액",
      key: "총 결제 금액",
      align: "center",
      render: (text) => <MyPageText>{text}</MyPageText>,
    },
  ];

  const userCarStorageData = userCarstorageList?.map((list) => {
    return (
      <TabPane tab={list?.carNumber} key={list?.id}>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container center>
            <MyPageText>진행 상태</MyPageText>
            <MyPageSubText style={{ marginLeft: 13 }} color="true">
              자세한 정보는 신청현황에서 확인 할 수 있습니다.
            </MyPageSubText>{" "}
          </Container>
          <MyPageText
            textdecoration
            style={{ fontWeight: 400, fontSize: 18 }}
            onClick={() => navigate("/carStorageApplyList")}
          >
            신청현황 바로가기
          </MyPageText>
        </MyPageContentListDiv>
        <TableDiv margin border>
          <MyPageSubText font color="true">
            {" "}
            차량 현황
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            {list?.status}
          </MyPageSubText>
        </TableDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true">
            <MyPageText>상품 정보 </MyPageText>
            <Table
              style={{ marginTop: 20 }}
              dataSource={[
                {
                  key: list?.id,
                  옵션: list?.option,
                  장수: `${list?.quantity}장`,
                  날짜: `${moment(list?.startDay).format(
                    "YYYY.MM.DD"
                  )} ~ ${moment(list?.endDay).format("YYYY.MM.DD")}`,
                  총결제금액: `${Number(list?.price).toLocaleString("en")}원`,
                },
              ]}
              columns={tableColumn}
              pagination={false}
            />
          </Container>
        </MyPageContentListDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true">
            <Container center>
              <MyPageText>결제 정보 </MyPageText>
            </Container>
            <TableDiv margin border>
              <MyPageSubText color="true" font>
                {" "}
                진행 상태
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {list?.paymentStatus}
              </MyPageSubText>
            </TableDiv>
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                결제 방법
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {list?.payment?.payMethod}
              </MyPageSubText>
            </TableDiv>
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                주문 접수 일자
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {moment(list?.payment?.orderedAt).format("YYYY-MM-DD")}
              </MyPageSubText>
            </TableDiv>
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                결제 금액
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {Number(list?.payment?.price).toLocaleString("en")}원
              </MyPageSubText>
            </TableDiv>
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                결제 수단
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {list?.payment?.payMethod === "계좌이체"
                  ? list?.payment?.account
                  : list?.card}
              </MyPageSubText>
            </TableDiv>
          </Container>
        </MyPageContentListDiv>
      </TabPane>
    );
  });

  const userType = useUserType();
  const vehicleText = useRef(false);
  useEffect(() => {
    vehicleText.current = true;
    return () => (vehicleText.current = false);
  }, [vehicleText]);
  return (
    <MyPageWrapDiv>
      <MyPageContentDiv>
        <MyPageMenu userType={userType} vehicleText={vehicleText} />
        <MyPageActiveDiv className="myPage_div">
          <MyPageText>나의 이용내역 &nbsp; &gt; &nbsp; 차량 보관</MyPageText>
          <MyPageLine style={{ marginBottom: 0 }} />
          {userCarstorageList?.length === 0 ? (
            <>
              <DeliveryListModal>
                <MyPageImg src={myPageImg} alt="myPage" />
                <LinkText color="true">
                  이용하신 차량보관 내역이 없습니다.
                </LinkText>
              </DeliveryListModal>
              <LinkText font2 onClick={() => navigate("/carStorageMain")}>
                차량보관 신청하기
              </LinkText>
            </>
          ) : (
            <Tabs
              defaultActiveKey="1"
              style={{ marginTop: 40, fontSize: 18 }}
              tabBarStyle={{ fontSize: 18 }}
              size="small"
              tabBarGutter={30}
            >
              {userCarStorageData}
            </Tabs>
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};
export default MyPagePickUpStyle;
