import styled from "@emotion/styled/macro";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import KaKaoLogin from "react-kakao-login";
import tokenState from "../../states/TokenState";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import snsToken from "../../states/snsToken";
import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../CommonStyle";
import { ReactComponent as KaKao } from "../../assets/img/kakao.svg";
import { ReactComponent as GoogleLogo } from "../../assets/img/google.svg";
import { ErrorText } from "./JoinInfoStyle";
import "./Social.css";
export const LoginWrapDiv = styled.div`
  width: 522px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 160px;
`;

export const Google = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fc5244;
  opacity : 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginText = styled.p`
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1.36px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  text-align: center;

  ${(props) =>
    props.subText &&
    css`
      font-size: 24px;
      letter-spacing: -0.96px;
    `};
`;

const InputDiv = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const TextInput = styled.input`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  outline: none;
  padding: 0 16px;
  border: 1px solid #dee2e6;
  color: #212529;
  letter-spacing: -0.72px;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 24px;
    `};

  ${(props) =>
    props.borderColor &&
    css`
      border-color: #ff383b;
    `};

  &:focus {
    border: 1px solid #212529;
  }
  &::placeholder {
    color: #dee2e6;
  }
`;

export const TextError = styled.span`
  color: #ff5252;
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  margin: 12px 0 19px 3px;
  display: block;
`;

const LoginButton = styled.button`
  margin-top: 32px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background: #fd6a21;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #fff;
`;

const SocialLoginDiv = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.margin &&
    css`
      margin-top: 0;
      width: auto;
    `};
`;

const SocialLogoDiv = styled.div`
  margin-top: 24px;
  width: 270px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LoginFooterDiv = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 20px;
    `};
`;
const LoginFooterText = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;

  ${(props) =>
    props.linkText &&
    css`
      font-weight: 700;
      cursor: pointer;
    `};
`;

const FooterDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoginContent = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const setToken = useSetRecoilState(tokenState);
  const setSnsToken = useSetRecoilState(snsToken);
  const [idError, setIdError] = useState(false);
  const [pwError, setPwError] = useState(false);
  const [idBorderColor, setIdBorderColor] = useState(false);
  const [pwdBorderColor, setPwdBorderColor] = useState(false);

  const kakaoToken = "8afe65a2788a3036ef1965d7a053fac4";

  const googleToken =
    "863420394793-nus1lrc027lcr08o70imoq8ceis83h7b.apps.googleusercontent.com";

  const { naver } = window;

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: "jAny6YPumptxESXef6CP",
      callbackUrl: "https://talgut.boundary.team/signUp/social",
      isPopup: false, // popup 형식으로 띄울것인지 설정
      loginButton: {
        type: 1,
        borderRadius: "100%",
        color: "green",
        height: "88",
        width: "88",
      }, //버튼의 스타일, 타입, 크기를 지정
    });

    naverLogin.init();
  };

  useEffect(() => {
    // initializeNaverLogin();
  });

  const idHandler = (event) => {
    setId(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  // 토큰(리코일 State)
  const LoginHandler = () => {
    axios({
      url: `${BASIC_URL}/login`,
      method: "POST",
      data: {
        id: id,
        password: password,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setToken(result.data.token);
          navigate('/');
        } else {
          if (result.data.id === false) {
            setIdError(true);
            setIdBorderColor(true);
          } else {
            setIdBorderColor(false);
          }

          if (result.data.password === false) {
            setPwError(true);
            setPwdBorderColor(true);
          } else {
            setPwdBorderColor(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LoginWrapDiv>
      <LoginText>로그인</LoginText>
      <InputDiv>
        <TextInput
          type="text"
          placeholder="아이디"
          value={id}
          borderColor={idBorderColor}
          onChange={idHandler}
          autoComplete="off"
        />

        <form>
          <TextInput
            margin
            autoComplete="password"
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={passwordHandler}
            borderColor={pwdBorderColor}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                LoginHandler();
              }
            }}
          />
          {pwError || idError ? (
            <ErrorText>
              입력하신 정보와 일치하는 계정이 없습니다. 로그인 정보를
              확인해주세요
            </ErrorText>
          ) : null}
        </form>
        <LoginButton onClick={LoginHandler}>로그인</LoginButton>
      </InputDiv>
      {/* <SocialLoginDiv>
        <LoginText subText>SNS 간편 로그인</LoginText>
        <SocialLogoDiv>
          <div id="naverIdLogin" />

          <KaKaoLogin
            style={{ border: "none", background: "#Fff" }}
            className="kakao_button"
            token={kakaoToken}
            onSuccess={(response) => {
              axios({
                url: `${BASIC_URL}/login/social`,
                method: "post",
                data: {
                  token: response?.profile?.id,
                  type: "kakao",
                },
              }).then((res) => {
                if (res.data.success) {
                  setToken(res.data.token);
                  navigate("/");
                } else {
                  setSnsToken({
                    id: response?.profile?.id,
                    type: "kakao",
                  });
                  setTimeout(() => {
                    navigate("/signUp/social");
                  }, 500);
                }
              });
            }}
            onFail={console.error}
            onLogout={console.info}
          >
            <KaKao
              style={{
                background: "#FEDE32",
                borderRadius: "100%",
                height : 70,
                width : 70,
                cursor: "pointer",
              }}
            />{" "}
          </KaKaoLogin>

          <GoogleLogin
            className="google_button"
            clientId={googleToken}
            onSuccess={(response) => {
              axios({
                url: `${BASIC_URL}/login/social`,
                method: "POST",
                data: {
                  token: response?.googleId,
                  type: "google",
                },
              }).then((res) => {
                if (res.data.success) {
                  navigate("/");
                  setToken(res.data.token);
                } else {
                  setSnsToken({
                    id: response?.googleId,
                    type: "google",
                  });
                  setTimeout(() => {
                    navigate("/signUp/social");
                  }, 500);
                }
              });
            }}
            onFailure={(res) => console.log(res)}
          >
            <Google style={{ padding : 0 }} className="google_logo">
              <GoogleLogo style={{ padding: 0 }} className="google_logo_button" />
            </Google>
          </GoogleLogin>
        </SocialLogoDiv>
      </SocialLoginDiv> */}
      <LoginFooterDiv>
        <LoginFooterText>아직 탈것 회원이 아니신가요 ?</LoginFooterText>
        <LoginFooterText linkText onClick={() => navigate("/join")}>
          회원가입
        </LoginFooterText>

        <LoginFooterDiv margin>
          <LoginFooterText>아이디 / 비밀번호를 잊으셨나요 ?</LoginFooterText>

          <FooterDiv>
            <LoginFooterText linkText onClick={() => navigate("/findEmail")}>
              아이디 찾기 &nbsp;| &nbsp;
            </LoginFooterText>
            <LoginFooterText linkText onClick={() => navigate("/findPassword")}>
              비밀번호 찾기
            </LoginFooterText>
          </FooterDiv>
        </LoginFooterDiv>
      </LoginFooterDiv>
    </LoginWrapDiv>
  );
};

export default LoginContent;
