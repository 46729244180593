import { useLocation } from "react-router";
import Header from "../../components/Header";
import PickUpApplyStyle from "../../components/pick-up/PickUpApplyStyle";


const PickUpApply = () => {
  const position = false;
  const location = useLocation();
  const textBold = true;
  return (
    <>
      <Header position={position} textBold={textBold} borderactive={location?.pathname?.includes('/pickUp')}/>
      <PickUpApplyStyle />
    </>
  );
};

export default PickUpApply;
