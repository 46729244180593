import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import tokenState from "../../states/TokenState";
import styled from "@emotion/styled/macro";
import { useState, useEffect } from "react";
import {
  FindPasswordWrapdiv,
  FindPasswordEmailInput,
  FindPasswordLabel,
  FindPasswordSubTitle,
  FindPasswordTitle,
  FinPasswordInputDiv,
  FindPasswordTextDiv,
  ErrorText,
} from "./FindPasswordStyle";
import grayCheck from "../../assets/img/passwordCorrectCheck.svg";
import { ErrorImg } from "./JoinInfoStyle";
import { BASIC_URL } from "../CommonStyle";
import InfoCheck2 from "../../assets/img/passwordCorrect.png";
import { FindPasswordButton as UpdateButton } from "../../view/login&join/FindEmailCompletion";

const CorrectText = styled(ErrorText)`
  color: #0081f5;
  letter-spacing: -0.48px;
`;

const CheckPasswordText = styled.p`
  color: #868e96;
  letter-spacing: -0.48px;
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  margin-top: 12px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  margin-top: 150px;
`;

const UpdatePasswordStyle = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const setToken = useSetRecoilState(tokenState);

  // 비밀번호 유효성 State
  const [pwLengthCheck, setPwLengthCheck] = useState(false);
  const [engCheck, setEngCheck] = useState(false);
  const [specialCharCheck, setSpecialCharCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [sameCheck, setSameCheck] = useState(false);
  const [passwordInput, setPasswordInput] = useState("#212529");

  useEffect(() => {
    const englishCheck = /[a-zA-Z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /^(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (password.length > 7 && password.length < 21) {
        setPwLengthCheck(true);
      } else {
        setPwLengthCheck(false);
        setPasswordInput("#FF383B");
      }

      if (englishCheck === true) {
        setEngCheck(true);
      } else {
        setEngCheck(false);
        setPasswordInput("#FF383B");
      }

      if (specialCharCheck === true) {
        setSpecialCharCheck(true);
      } else {
        setSpecialCharCheck(false);
        setPasswordInput("#FF383B");
      }

      if (numberCheck === true) {
        setNumberCheck(true);
      } else {
        setNumberCheck(false);
        setPasswordInput("#FF383B");
      }
    } else {
      setEngCheck(false);
      setSpecialCharCheck(false);
      setNumberCheck(false);
      setPwLengthCheck(false);
      setPasswordInput("#333");
    }

    if (
      englishCheck &&
      numberCheck &&
      specialCharCheck &&
      password.length > 7 &&
      password.length < 21
    ) {
      setPasswordInput("#333");
    }
  }, [password, pwLengthCheck, engCheck, specialCharCheck, numberCheck]);

  // 비밀번호 일치 여부
  const [checkPasswordInputBorder, setPasswordInputBorder] = useState("#333");
  useEffect(() => {
    if (checkPassword) {
      if (checkPassword === password) {
        setSameCheck(true);
        setPasswordInputBorder("#333");
      } else {
        setSameCheck(false);
        setPasswordInputBorder("#FF383B");
      }
    } else {
      setSameCheck(false);
      setPasswordInputBorder("#333");
    }
  }, [password, checkPassword, checkPasswordInputBorder]);

  const id = useLocation()?.state?.id;
  const phoneNumber = useLocation()?.state?.phoneNumber;

  // 비밀번호 변경
  const updatePassword = () => {
    axios({
      url: `${BASIC_URL}/login/reset/pw`,
      method: "POST",
      data: {
        pw: password,
        checkPw: checkPassword,
        phoneNumber: phoneNumber,
        id: id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          alert("변경 완료");
          setToken(result.data.token);
          navigate("/");
        } else {
          alert(result.data.message);
          navigate("/");
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        alert("알 수 없는 오류가 발생 하였습니다. 다시 시도해주세요.");
        return false;
      });
  };
  return (
    <FindPasswordWrapdiv>
      <FindPasswordTextDiv>
        <FindPasswordTitle>비밀번호 변경</FindPasswordTitle>
        <FindPasswordSubTitle>
          새로운 비밀번호를 입력해주세요.
        </FindPasswordSubTitle>
      </FindPasswordTextDiv>

      <FinPasswordInputDiv margin active={true}>
        <FindPasswordLabel htmlFor="new-password">
          새로운 비밀번호
        </FindPasswordLabel>
        <form>
          <FindPasswordEmailInput
            color={passwordInput}
            type="password"
            autoComplete="off"
            placeholder="영문 + 숫자 + 특수문자 포함 8 ~ 20자 내외"
            id="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
        <div style={{ display: "flex" }}>
          {engCheck ? (
            <>
              <ErrorImg src={InfoCheck2} alt="check" />
              <CorrectText>영문</CorrectText>
            </>
          ) : (
            <>
              <ErrorImg src={grayCheck} alt="check" />
              <CheckPasswordText>영문</CheckPasswordText>
            </>
          )}
          {numberCheck ? (
            <>
              <ErrorImg src={InfoCheck2} alt="check" />
              <CorrectText>숫자</CorrectText>
            </>
          ) : (
            <>
              <ErrorImg src={grayCheck} alt="check" />
              <CheckPasswordText>숫자</CheckPasswordText>
            </>
          )}
          {specialCharCheck ? (
            <>
              <ErrorImg src={InfoCheck2} alt="check" />
              <CorrectText>특수문자</CorrectText>
            </>
          ) : (
            <>
              <ErrorImg src={grayCheck} alt="check" />
              <CheckPasswordText>특수문자</CheckPasswordText>{" "}
            </>
          )}
          {pwLengthCheck ? (
            <>
              <ErrorImg src={InfoCheck2} alt="check" />
              <CorrectText>8~20자</CorrectText>
            </>
          ) : (
            <>
              <ErrorImg src={grayCheck} alt="check" />
              <CheckPasswordText>8~20자</CheckPasswordText>
            </>
          )}
        </div>
      </FinPasswordInputDiv>

      <FinPasswordInputDiv active={true} style={{ marginTop: 24 }}>
        <FindPasswordLabel htmlFor="new-password-check">
          비밀번호 확인
        </FindPasswordLabel>
        <form>
          <FindPasswordEmailInput
            type="password"
            autoComplete="off"
            color={checkPasswordInputBorder}
            placeholder="비밀번호 재 입력"
            value={checkPassword}
            onChange={(e) => setCheckPassword(e.target.value)}
            id="new-password-check"
          />
        </form>
        {sameCheck ? null : checkPassword.length === 0 ? null : (
          <ErrorText>비밀번호가 일치하지 않습니다.</ErrorText>
        )}
      </FinPasswordInputDiv>
      {sameCheck &&
      numberCheck &&
      engCheck &&
      pwLengthCheck &&
      specialCharCheck ? (
        <ButtonContainer>
          <UpdateButton oragneButton onClick={updatePassword}>
            비밀번호 변경
          </UpdateButton>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <UpdateButton disabled>비밀번호 변경</UpdateButton>
        </ButtonContainer>
      )}
    </FindPasswordWrapdiv>
  );
};

export default UpdatePasswordStyle;
