import Header from "../../components/Header";
import JoinInfoStyle from "../../components/login&join/JoinInfoStyle";
const JoinInfo = () => {
  const position = false;

  return (
    <>
      <Header position={position} />
      <JoinInfoStyle />
    </>
  );
};

export default JoinInfo;
