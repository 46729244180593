import styled from "@emotion/styled/macro";
import Check from "../../../../assets/img/findemail.png";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as DotLine } from "../../../../assets/img/PickupStepLine.svg";
import { ReactComponent as CheckStep } from "../../../../assets/img/PickupStepCheck2.svg";
import { ReactComponent as CheckDone } from "../../../../assets/icons/CheckDone.svg";
import { ReactComponent as DotLineDone } from "../../../../assets/icons/DotLineDone.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";

const DropShipping = () => {
  const StepBarDot = styled(DotLine)`
    margin-top: 10px;
  `;

  const StepBarDoneDot = styled(DotLineDone)`
    margin-top: 10px;
  `;

  const DoneText = styled(SelfSellCarStepText)`
    color: #ffd0b8;
  `;

  return (
    <>
      {/* 경매 유찰 / 출품 거부 상황에 따라 경매 단계가 사라 진다.  */}
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            회원님의 차량은 위탁 판매 단계에 있어요.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>

        <SelfSellCarStepSubText>
          판매 전략을 위해 전문가가 연락드릴 예정입니다.
        </SelfSellCarStepSubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>상품화</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer
          align
          style={{ marginLeft: 13, marginRight: 13 }}
        >
          <CheckDone />
          <DoneText active>경매</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>위탁 판매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};
export default DropShipping;
