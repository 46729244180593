import Header from "../../components/Header";
import UpdatePasswordStyle from "../../components/login&join/UpdatePasswordStyle";

const UpdatePassword = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <UpdatePasswordStyle />
    </>
  );
};

export default UpdatePassword;
