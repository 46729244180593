import styled from "@emotion/styled/macro";
import axios from "axios";
import { css } from "@emotion/react";
import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import tokenState from "../states/TokenState";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as Logo } from "../assets/img/MainLogo.svg";
import { ReactComponent as Alarm } from "../assets/img/alarm.svg";
import { ReactComponent as Alarm1 } from "../assets/icons/Alarm1.svg";
import { ReactComponent as User } from "../assets/img/user.svg";
import { BASIC_URL } from "./CommonStyle";
import "./Common.css";
import moment from "moment";

const HeaderDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 124px;
  display: flex;
  background: ${(props) => (props.active ? "#fff" : "transparent")};
  /* border-bottom : 1px solid  ${props => props.borderActive ? '#ededed' : 'transparent'}; */
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  z-index: 999;


  ${(props) =>
    props.borderactive &&
    css`
      border-bottom : 1px solid #ededed;
    `};

  &:hover {
    background-color: #fff;
    cursor: pointer;
    color: #212529;
  }
`;

const HeaderMenuWrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
`;

const HeaderMenuDiv = styled.div`
  margin: 0 0 0 40px;
  cursor: pointer;
`;

export const HeaderMenuText = styled.p`
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  letter-spacing: -0.64px;
  color: #212529;
  margin: 0;

  &:hover {
    font-weight: 700;
  }
`;

const HeaderMenuText2 = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.64px;
  color: #ff6a13;
  font-family: SpoqaHanSansNeo;
`;

const HeaderLoginJoinWrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 65px;
  align-items: center;
  margin-left: 110px;
`;

const HeaderLoginJoinDiv = styled.div`
  cursor: pointer;
  margin-left: 23px;
  position: relative;
`;

const HeaderLoginJoinText = styled.p`
  font-size: 14px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.56px;
  margin: 0;
  color: #212529;

  &:hover {
    font-weight: bold;
  }
`;

export const HeaderMenuBar = styled.div`
  width: 100%;
  height: 59px;
  background-color: #f8f9fa;
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const HeaderDropDownText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
`;

// 알람창
const AlarmContainer = styled.div`
  width: 400px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px #adb5bd66;
  position: absolute;
  background-color: #fff;
  top: 50px;
  right: -60px;
  padding-top: 30px;
  display: ${(props) => (props.display ? "flex" : "none")};
  flex-direction: column;
  overflow-y: scroll;
  z-index: 999;
`;

const AlarmContent = styled.div`
  width: 400px;
  height: 86px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  border-top: 1px solid #ededed;
  opacity: ${(props) => (props.seen ? 0.5 : 1)};
  padding: 20px;
  :hover {
    background-color: rgb(255, 208, 184, 0.8);
  }
`;

const AlarmContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const AlarmText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  margin-left: 20px;
  margin-bottom: 17px;
`;

const AlarmText2 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #212529;

  ${(props) =>
    props.font_weight &&
    css`
      font-weight: 400;
    `};
`;

const AlarmText3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.56px;
  color: #868e96;
`;

const Header = ({
  position,
  textBold,
  aboutUsText,
  boardText,
  carStoragetextBold,
  selfSellCarText,
  borderactive,
  autoOutletText,
  liveAuctionText,
}) => {
  const navigate = useNavigate();
  const headerRef = useRef();

  const [backgroundColor, setBackgroundColor] = useState(false);
  const [display, setDisplay] = useState(false);
  const [alarmData, setAlarmData] = useState([]);

  const handleScroll = () => {
    const { scrollTop } = document.body;

    if (scrollTop > 990) {
      setBackgroundColor(true);
    } else {
      setBackgroundColor(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (position === false) {
      headerRef.current.style = "position : static";
    }
  }, [position]);

  const [token, setToken] = useRecoilState(tokenState);

  const userLogout = () => {
    setToken(null);
    localStorage.removeItem("tokenState");
    navigate("/login");
  };

  // 알람 확인하기
  useEffect(() => {
    if (token) {
      axios({
        url: `${BASIC_URL}/user/notification`,
        method: "get",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.data) {
            setAlarmData(res.data.notifications);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  }, [token]);

  // 새로운 알람 여부
  const [newAlram, setNewAlarm] = useState(true);

  useEffect(() => {
    if (token) {
      if (alarmData) {
        axios({
          url: `${BASIC_URL}/user/notification/exist`,
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            setNewAlarm(res.data.exist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [token, alarmData]);

  const getAlarm = () => {
    axios({
      url: `${BASIC_URL}/user/notification`,
      method: "get",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setAlarmData(res.data.notifications);
          setDisplay((prev) => !prev);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userAlarm = alarmData?.map((list) => {
    const alramCheck = () => {
      axios({
        url: `${BASIC_URL}/user/notification/${list.id}`,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      }).catch((error) => {
        console.log(error);
      });

      if (list?.type === "픽업정비") {
        navigate(`/pickUpStep/${list.targetId}`);
        setDisplay(false);
      } else if (list?.type === "셀프셀카") {
        navigate(`/selfSellCarStep/${list.targetId}`);
        setDisplay(false);
      }
    };

    return (
      <AlarmContent key={list?.id} onClick={alramCheck} seen={list?.seen}>
        <AlarmText2>{list?.type}</AlarmText2>
        <AlarmContentBox>
          <AlarmText2 font_weight>{list?.content}</AlarmText2>
          <AlarmText3>
            {moment().diff(
              moment(list?.createdAt).subtract(9, "hours"),
              "days"
            ) < 6
              ? `${moment().diff(list?.createdAt, "days")}일전 `
              : moment().diff(
                  moment(list?.createdAt).subtract(9, "hours"),
                  "days"
                ) >= 6
              ? moment(list?.createdAt)
                  .subtract(9, "hours")
                  .format("YYYY.MM.DD")
              : moment().diff(list?.createdAt, "days") < 1
              ? `${moment().diff(list?.createdAt, "hours")}시간 전`
              : null}
          </AlarmText3>
        </AlarmContentBox>
      </AlarmContent>
    );
  });

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/myPage">
          <HeaderDropDownText>마이페이지</HeaderDropDownText>
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <HeaderDropDownText onClick={userLogout}>로그아웃</HeaderDropDownText>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderDiv ref={headerRef} active={backgroundColor} borderactive={borderactive}>
        <Logo
          style={{ width: 202, height: 64, cursor: "pointer" }}
          onClick={() => navigate("/")}
        />

        <HeaderMenuWrapDiv>
          <HeaderMenuDiv style={{ marginLeft: 0 }}>
            {aboutUsText >= 700 ? (
              <HeaderMenuText
                style={{ fontWeight: aboutUsText, color: "#ff6a13" }}
                onClick={() => navigate("/aboutUs")}
              >
                회사 소개
              </HeaderMenuText>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/aboutUs")}
                active={backgroundColor}
              >
                회사 소개
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {selfSellCarText ? (
              <HeaderMenuText2 onClick={() => navigate("/selfSellcarMain")}>
                셀프셀카
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/selfSellcarMain")}
                active={backgroundColor}
              >
                셀프셀카
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {autoOutletText === true ? (
              <HeaderMenuText2 onClick={() => navigate("/autoOutlet")}>
                오토아울렛
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/autoOutlet")}
                active={backgroundColor}
              >
                오토아울렛
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {liveAuctionText ? (
              <HeaderMenuText2 onClick={() => navigate("/liveAuctionMain")}>
                라이브 옥션
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/liveAuctionMain")}
                active={backgroundColor}
              >
                라이브 옥션
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {textBold === true ? (
              <HeaderMenuText2 onClick={() => navigate("/pickUpMain")}>
                픽업 정비
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/pickUpMain")}
                active={backgroundColor}
              >
                픽업 정비
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {carStoragetextBold === true ? (
              <HeaderMenuText2 onClick={() => navigate("/carStorageMain")}>
                차량 보관
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/carStorageMain")}
                active={backgroundColor}
              >
                차량 보관
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
          <HeaderMenuDiv>
            {boardText === true ? (
              <HeaderMenuText2 onClick={() => navigate("/board")}>
                게시판
              </HeaderMenuText2>
            ) : (
              <HeaderMenuText
                onClick={() => navigate("/board")}
                active={backgroundColor}
              >
                게시판
              </HeaderMenuText>
            )}
          </HeaderMenuDiv>
        </HeaderMenuWrapDiv>
        <HeaderLoginJoinWrapDiv>
          {token ? (
            <>
              <HeaderLoginJoinDiv style={{ marginLeft: 0 }}>
                {/* {newAlram && newAlram === true ? (
                  <>
                    <Alarm
                      style={{ width: 24, height: 24, cursor: "pointer" }}
                      onClick={getAlarm}
                    />
                    <AlarmContainer display={display}>
                      <AlarmText>알림</AlarmText>
                      {userAlarm}
                    </AlarmContainer>{" "}
                  </>
                ) : (
                  <>
                    <Alarm1
                      style={{ width: 24, height: 24, cursor: "pointer" }}
                      onClick={getAlarm}
                    />
                    <AlarmContainer display={display}>
                      <AlarmText>알림</AlarmText>
                      {userAlarm}
                    </AlarmContainer>{" "}
                  </>
                )} */}
              </HeaderLoginJoinDiv>

              <HeaderLoginJoinDiv style={{ marginLeft: 30 }}>
                <Dropdown
                  overlay={menu}
                  overlayClassName="header_menu"
                  placement="bottomRight"
                >
                  <User style={{ width: 24, height: 24, cursor: "pointer" }} />
                </Dropdown>
              </HeaderLoginJoinDiv>
            </>
          ) : (
            <>
              <HeaderLoginJoinDiv style={{ marginLeft: 0 }}>
                <HeaderLoginJoinText onClick={() => navigate("/login")}>
                  로그인
                </HeaderLoginJoinText>
              </HeaderLoginJoinDiv>
              <HeaderLoginJoinDiv>
                <HeaderLoginJoinText onClick={() => navigate("/join")}>
                  회원가입
                </HeaderLoginJoinText>
              </HeaderLoginJoinDiv>
            </>
          )}
        </HeaderLoginJoinWrapDiv>
      </HeaderDiv>
    </>
  );
};

export default Header;
