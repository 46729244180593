import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageSelfSellCarStyle from "../../components/myPage/MyPageSelfSellCarStyle";

const MyPageSelfSellCar = () => {
  const position = false;
  const location = useLocation();

  return (
    <>
      <Header position={position}  borderactive={location.pathname.includes('/myPage')} />
      <MyPageSelfSellCarStyle />
    </>
  );
};

export default MyPageSelfSellCar;
