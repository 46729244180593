import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageUpdateInfoStyle from "../../components/myPage/MyPageUpdateInfoStyle";
import useUserInfo from "../../hooks/useUserInfo";

const MyPageUpdateInfo = () => {
  const position = false;
  const location = useLocation();
  const userInfo = useUserInfo();
  return (
    <>
      <Header position={position} borderactive={location.pathname.includes('/myPageInfo')} />
      <MyPageUpdateInfoStyle userInfo={userInfo} />
    </>
  );
};

export default MyPageUpdateInfo;
