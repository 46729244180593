import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router";
import axios from "axios";
import { BASIC_URL } from "../CommonStyle";
import { useState, useEffect, useRef } from "react";
import { CertifiCationModal, CertifiCationIdentyModal } from "./Modals";
import { TimerText } from "./FindEmailStyle";

export const FindPasswordWrapdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FindPasswordTextDiv = styled.div`
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FindPasswordTitle = styled.span`
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.36px;
  color: #212529;
`;

export const FindPasswordSubTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.8px;
  margin-top: 16px;
`;

export const FindPasswordLine = styled.div`
  width: 522px;
  height: 2px;
  border: 1px solid #cccccc;
`;

export const FinPasswordInputDiv = styled.div`
  width: 522px;
  margin-top: ${(props) => (props.margin ? "100px" : "60px")};
  display: ${(props) => (props.active ? "block" : "none")};
  position: relative;
`;

export const FindPasswordLabel = styled.label`
  font-size: 18px;
  letter-spacing: -0.72px;
  font-weight: 500;
  display: block;
  margin-bottom: 16px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

export const FindPasswordEmailInput = styled.input`
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;

  &:focus {
    border: 1px solid #000;
    border-color: ${(props) => (props.color ? props.color : props.color)};
  }
  &::placeholder {
    color: #dee2e6;
  }
`;

export const FindPasswordCertificationButton = styled.button`
  width: 120px;
  height: 60px;
  border: none;
  border-radius: 10px;
  background-color: #212529;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  margin-left: 18px;
  cursor: pointer;
  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

export const ErrorText = styled.p`
  margin-top: 12px;
  padding-left: 3px;
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #ff5252;
  font-weight: 400;
  margin-right: 10px;
`;

const FindPasswordStyle = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [certifiCationModalOpen, setCertificationModalOpen] = useState(false);
  const [block, setBlock] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: "",
    phoneNumber: "",
  });

  const { email, phoneNumber } = userInfo;

  const userInfoHandler = (e) => {
    const { value, name } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  // 아이디 유효성 검사
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailBorder, setEmailBorder] = useState("#212529");
  useEffect(() => {
    const emailExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    if (email) {
      if (emailExp) {
        setEmailCheck(true);
      } else {
        setEmailCheck(false);
      }
    } else {
      setEmailCheck(false);
      setEmailBorder("#212529");
    }

    if (email.length > 0 && emailExp === false) {
      setEmailBorder("#ff5252");
    } else if (email.length > 0 && emailExp === true) {
      setEmailBorder("#212529");
    }
  }, [email, emailCheck, emailBorder]);

  // 휴대폰 유효성
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
  const [certificationButton, setCertificationButton] = useState(true);
  const [checkCertificationButton, setCheckCertificationButton] =
    useState(true);

  const [phoneInputBorder, setPhoneInputBorder] = useState("#333");

  useEffect(() => {
    const phoneNumberChecks = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberChecks) {
        setPhoneNumberCheck(true);
        setCertificationButton(false);
      } else {
        setPhoneNumberCheck(false);
        setCertificationButton(true);
      }
    } else {
      setCertificationButton(true);
      setPhoneInputBorder("#212529");
    }

    if (phoneNumber.length > 0 && phoneNumberChecks === false) {
      setPhoneInputBorder("#ff5252");
    } else if (phoneNumber.length > 0 && phoneNumberChecks === true) {
      setPhoneInputBorder("#212529");
    }
  }, [phoneNumber, phoneNumberCheck, certificationButton, phoneInputBorder]);

  const certificationInputRef = useRef();
  const [authCode, setAuthCode] = useState("");

  const certifiCationPhoneNumber = () => {
    setMin(4);
    setSec(0);
    axios({
      url: `${BASIC_URL}/login/send/sms`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((result) => {
        if (result.status === 201) {
          setModalOpen(true);
          setBlock(true);
          certificationInputRef.current.focus();
          setCheckCertificationButton(false);
        } else {
          alert("휴대폰 번호를 잘못 입력하셨습니다.");
          setBlock(false);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 휴대폰 인증 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const [timeStart, setTimeStart] = useState(false);

  useEffect(() => {
    if (timeStart) {
      const countdown = setInterval(() => {
        if (parseInt(sec) > 0) {
          setSec(parseInt(sec) - 1);
        }
        if (parseInt(sec) === 0) {
          if (parseInt(min) === 0) {
            clearInterval(countdown);
            setTimeStart(false);
            setMin(4);
            setSec(0);
            alert("시간이 초과 되었습니다.");
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [timeStart, min, sec]);

  // 인증 번호 비교 하기
  const checkCertificationNumber = () => {
    axios({
      url: `${BASIC_URL}/login/auth/check`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        authCode: authCode,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setCertificationModalOpen(true);

          if (phoneNumber === '01050477437' && email !== 'test@boundary.team') {
            alert('가입되지 않은 계정입니다.');
          } else {
            setTimeout(() => {
              navigate("/updatePassword");
            }, 3000);
          }
          setTimeStart(false);
          setMin(4);
          setSec(0);
        } else {
          alert("인증번호가 일치하지 않습니다.");
          certificationInputRef.current.focus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <FindPasswordWrapdiv>
      <CertifiCationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CertifiCationIdentyModal
        certifiCationModalOpen={certifiCationModalOpen}
        setCertificationModalOpen={setCertificationModalOpen}
      />
      <FindPasswordTextDiv>
        <FindPasswordTitle>비밀번호 찾기</FindPasswordTitle>
        <FindPasswordSubTitle>
          등록된 회원님의 정보로 비밀번호를 찾을 수 있습니다.
        </FindPasswordSubTitle>
      </FindPasswordTextDiv>
      <FinPasswordInputDiv margin active={true}>
        <FindPasswordLabel htmlFor="email">아이디</FindPasswordLabel>
        <FindPasswordEmailInput
          placeholder="아이디 입력"
          color={emailBorder}
          id="email"
          name="email"
          value={email}
          onChange={userInfoHandler}
          autoComplete="off"
        />
        {emailCheck ? null : email.length === 0 ? null : (
          <ErrorText>올바른 아이디를 입력해주세요.</ErrorText>
        )}
      </FinPasswordInputDiv>

      <FinPasswordInputDiv active={true}>
        <FindPasswordLabel htmlFor="phoneNumber">휴대폰 번호</FindPasswordLabel>
        <FindPasswordEmailInput
          style={{ width: 384 }}
          color={phoneInputBorder}
          placeholder="(-)없이 숫자만 입력"
          value={phoneNumber}
          name="phoneNumber"
          onChange={userInfoHandler}
          id="phoneNumber"
          autoComplete="off"
        />
        {timeStart ? (
          <FindPasswordCertificationButton
            disabled={certificationButton}
            onClick={() => {
              certifiCationPhoneNumber();
              setTimeStart(true);
            }}
          >
            재전송
          </FindPasswordCertificationButton>
        ) : (
          <FindPasswordCertificationButton
            disabled={certificationButton}
            onClick={() => {
              certifiCationPhoneNumber();
              setTimeStart(true);
            }}
          >
            인증하기
          </FindPasswordCertificationButton>
        )}
        {phoneNumberCheck ? null : phoneNumber.length === 0 ? null : (
          <ErrorText>올바른 휴대폰번호를 입력해주세요.</ErrorText>
        )}
      </FinPasswordInputDiv>

      <FinPasswordInputDiv active={block} style={{ marginBottom: 100 }}>
        <TimerText>
          {min.toString().padStart(2, "0")}:{sec.toString().padStart(2, "0")}
        </TimerText>
        <FindPasswordLabel htmlFor="certification">인증번호</FindPasswordLabel>
        <FindPasswordEmailInput
          style={{ width: 384 }}
          placeholder="인증번호 입력"
          id="certification"
          autoComplete="off"
          ref={certificationInputRef}
          onChange={() => setAuthCode(certificationInputRef.current.value)}
        />

        <FindPasswordCertificationButton
          disabled={checkCertificationButton}
          onClick={checkCertificationNumber}
        >
          인증 확인
        </FindPasswordCertificationButton>
      </FinPasswordInputDiv>
    </FindPasswordWrapdiv>
  );
};

export default FindPasswordStyle;
