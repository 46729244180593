import styled from "@emotion/styled/macro";
import DaumPostcode from "react-daum-postcode";
import axios from "axios";
import { css } from "@emotion/react/macro";
import { useNavigate } from "react-router";
import { BASIC_URL, WrapDiv } from "../CommonStyle";
import { useEffect, useRef, useState } from "react";
import { Modal, Select } from "antd";
import { useRecoilValue } from "recoil";
import tokenState from "../../states/TokenState";
import { ReactComponent as FileUpload } from "../../assets/icons/fileUploadIcon.svg";
import { ReactComponent as RemoveImage } from "../../assets/img/removeIcon.svg";
import { ConfirmDelearModal, ImageModal } from "./MyPageModal";

const TextInput = styled.input`
  height: 60px;
  width: ${(props) => (props.width ? props.width : "522px")};
  margin-top: ${(props) => (props.margin ? props.margin : 0)};
  outline: none;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-family: SpoqaHanSansNeo;
  border-color: ${(props) => (props.color ? props.color : "#dee2e6")};
  font-weight: 400;
  padding-left: 16px;
  font-size: 18px;
  color: #212529;
  &:focus {
    border-color: #212529;
  }
  padding-left: 16px;
  ::placeholder {
    color: #dee2e6;
  }
`;

const Actionbutton = styled.button`
  cursor: pointer;
  width: 240px;
  height: 70px;
  border-radius: 10px;
  background-color: #ff6a13;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  margin-top: 150px;

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  ${(props) =>
    props.postButton &&
    css`
      width: 120px;
      height: 60px;
      background-color: #212529;
      font-size: 18px;
      margin-top: 18px;
    `}
`;

const MainText = styled.span`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.36px;
`;

const ErrorText = styled.span`
  color: #ff383b;
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  margin-top: 12px;
`;

const Line = styled.div`
  width: 1080px;
  height: 1px;
  border: 1px solid #dee2e6;
`;

const TextDiv = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  margin-top: ${(props) => (props.margin ? props.margin : 0)};
  width: ${(props) => (props.width ? props.width : "1080px")};
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
`;

const InputDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${(props) => props.align};
  margin-top: ${(props) => (props.margin ? props.margin : 0)};
  position: relative;
`;

const FileImage = styled.img`
  width: 522px;
  height: 250px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  object-fit: cover;
  cursor: pointer;
`;

const FileLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
`;

const MyPageUserType = () => {
  const [officeName, setOfficeName] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [area, setArea] = useState("");
  const [salesArea, setSalesArea] = useState("");
  const [registNumber, setRegistNumber] = useState("");

  // 주소 , 우편 번호
  const [openPostModal, setOpenPostModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [myAdress, setMyAdress] = useState("");
  const [detailAddress, setDetailAdress] = useState("");
  const detailAddressRef = useRef();

  // 주소 모달
  const Post = () => {
    const handleComplete = (data) => {
      setZipCode(data.zonecode);
      setMyAdress(data.address);
      setOpenPostModal((prev) => !prev);
      detailAddressRef.current.focus();
    };

    return (
      <Modal
        centered
        visible={openPostModal}
        footer={null}
        width={613}
        closable={false}
        onCancel={() => setOpenPostModal(false)}
        bodyStyle={{ height: "auto" }}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  };

  const { Option } = Select;
  const navigate= useNavigate();
  const token = useRecoilValue(tokenState);

  // 지역 선택 불러오기 API
  const [city, setCity] = useState();
  const [salesComplex, setSalesComplex] = useState();

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/dealer/area`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCity(res.data.cities);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const cityOption = city?.map((item, index) => {
    return (
      <Option key={index} value={item.city}>
        {item.city}
      </Option>
    );
  });

  const salesComplexHandler = (value) => {
    setArea(value);
    axios({
      url: `${BASIC_URL}/user/dealer/area?city=${value}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setSalesComplex(res.data.areas);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const salesComplexOption = salesComplex?.map((item) => {
    return (
      <Option key={item.id} value={item.town}>
        {item.area}({item.town})
      </Option>
    );
  });

  // 사무실 번호 유효성 검사
  const [officeText, setOfficeText] = useState("");
  const [officeNumberBorder, setOfficeNumberBorder] = useState("#DEE2E6");
  useEffect(() => {
    const officeNumberCheck = /^0[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(
      officeNumber
    );
    if (officeNumber) {
      if (officeNumberCheck) {
        setOfficeText("");
        setOfficeNumberBorder("#DEE2E6");
      } else {
        setOfficeText("올바른 사무실 번호를 입력해주세요.");
        setOfficeNumberBorder("#FF383B");
      }
    } else {
      setOfficeText("");
      setOfficeNumberBorder("#DEE2E6");
    }
  }, [officeNumber]);

  // 사업자 번호 유효성 검사
  const [registrationNumberText, setRegistrationNumberText] = useState("");
  const [registNumberBorder, setRegistNumberBorder] = useState("");

  useEffect(() => {
    const registNumberCheck = /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/.test(
      registNumber
    );
    if (registNumber) {
      if (registNumberCheck) {
        setRegistrationNumberText("");
        setRegistNumberBorder("#DEE2E6");
      } else {
        setRegistrationNumberText("올바른 사업자 번호를 입력해주세요.");
        setRegistNumberBorder("#FF383B");
      }
    } else {
      setRegistrationNumberText("");
      setRegistNumberBorder("#DEE2E6");
    }
  }, [registNumber]);

  // 파일
  const [thumNail, setThumNail] = useState([
    {
      file: "",
      previewURL: "",
    },
  ]);

  const fileHanlder = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setThumNail([
        {
          file: file,
          previewURL: reader.result,
        },
      ]);
    };

    reader.readAsDataURL(file);
  };

  // 버튼 활성화
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (
      officeName &&
      area &&
      salesArea &&
      zipCode &&
      myAdress &&
      officeNumber &&
      registNumber &&
      thumNail.previewURL !== ""
    ) {
      if (registrationNumberText === "" && officeText === "") {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    } else {
      setDisabledButton(true);
    }
  }, [
    officeText,
    registrationNumberText,
    officeNumber,
    registNumber,
    officeName,
    area,
    zipCode,
    salesArea,
    myAdress,
    thumNail.previewURL,
  ]);

  const [delearModalOpen, setDelearModalOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);

  const changeUserType = async () => {
    const images = [];
    await Promise.all(
      thumNail?.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file.file);
        if(file.file === '') {
          alert("사업자등록증을 첨부 해주세요");
        } else {
          const result = await axios({
            url: `${BASIC_URL}/image`,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          });
  
          if (result.data.success) {
            images.push(result.data.url);
          } 
        }
     
      })
    );

    axios({
      url: `${BASIC_URL}/user/dealer`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name: officeName,
        address: myAdress,
        addressDetail: detailAddress,
        zipCode: zipCode,
        number: officeNumber,
        area,
        city: salesArea,
        registNumber,
        registImage: images[0],
      },
    }).then((res) => {
      if (res.data.success) {
        setDelearModalOpen(true);
      } else if (res.data.status === 413) {
        alert("파일 용량 초과");
      } else if(res.data.success === false) {
        alert("이미 신청 하셨습니다. 관리자 승인 후 연락 드리겠습니다.");
        navigate("/myPage");
      }
    });
  };

  return (
    <WrapDiv style={{ marginBottom: 50 }}>
      <Post />
      <ConfirmDelearModal
        delearModalOpen={delearModalOpen}
        setDelearModalOpen={setDelearModalOpen}
      />
      <TextDiv height="232px" column="true">
        <MainText fontSize="34px" color="#212529" weight={700}>
          딜러회원 신청
        </MainText>
        <MainText
          fontSize="20px"
          color="#868E96"
          weight={400}
          style={{ marginTop: 17, letterSpacing: -0.8 }}
        >
          상사 정보를 입력해주세요.
        </MainText>
      </TextDiv>
      <Line />
      <TextDiv margin="50px" align="flex-start" column="true">
        <MainText fontSize="24px" color="#212529" weight={700}>
          상사 정보 입력
        </MainText>
      </TextDiv>
      <InputDiv margin="60px">
        <TextDiv column="true" width="522px" align="flex-start">
          <MainText
            fontSize="18px"
            color="#212529"
            weight={400}
            align="flex-start"
          >
            상사명
          </MainText>
          <TextInput
            type="text"
            placeholder="상사명 입력"
            value={officeName}
            onChange={(event) => setOfficeName(event.target.value)}
            autoFocus={true}
            margin="18px"
          />
        </TextDiv>
        <TextDiv column="true" width="522px" align="flex-start">
          <MainText
            fontSize="18px"
            color="#212529"
            weight={400}
            align="flex-start"
          >
            지역 / 매매단지 선택
          </MainText>
          <div style={{ display: "flex", marginTop: 18 }}>
            <Select
              defaultValue="지역 선택"
              style={{ width: 170 }}
              onChange={salesComplexHandler}
            >
              {cityOption}
            </Select>
            <Select
              defaultValue="매매단지 선택"
              style={{ width: 334, marginLeft: 18 }}
              onChange={(value) => setSalesArea(value)}
            >
              {salesComplexOption}
            </Select>
          </div>
        </TextDiv>
      </InputDiv>
      <InputDiv margin="70px" align="flex-start">
        <TextDiv column="true" width="522px" align="flex-start">
          <MainText
            fontSize="18px"
            color="#212529"
            weight={400}
            align="flex-start"
          >
            상사 주소
          </MainText>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: " 100%",
              alignItems: " center",
            }}
          >
            <TextInput
              type="text"
              placeholder="우편번호"
              width="384px"
              value={zipCode}
              margin="18px"
              readOnly
            />
            <Actionbutton postButton onClick={() => setOpenPostModal(true)}>
              우편번호
            </Actionbutton>
          </div>
          <TextInput
            type="text"
            placeholder="주소"
            margin="12px"
            readOnly
            value={myAdress}
          />
          <TextInput
            type="text"
            placeholder="상세주소 입력"
            margin="12px"
            value={detailAddress}
            ref={detailAddressRef}
            onChange={(event) => setDetailAdress(event.target.value)}
          />
        </TextDiv>
        <TextDiv column="true" width="522px" align="flex-start">
          <MainText
            fontSize="18px"
            color="#212529"
            weight={400}
            align="flex-start"
          >
            사무실 번호
          </MainText>
          <TextInput
            type="text"
            placeholder="사무실 번호 입력 (-) 제외"
            margin="18px"
            value={officeNumber}
            color={officeNumberBorder}
            onChange={(event) => setOfficeNumber(event.target.value)}
          />
          <ErrorText>{officeText}</ErrorText>
        </TextDiv>
      </InputDiv>
      <InputDiv margin="60px" align="flex-start">
        <TextDiv column="true" width="522px" align="flex-start">
          <MainText
            fontSize="18px"
            color="#212529"
            weight={400}
            align="flex-start"
          >
            사업자 번호
          </MainText>
          <TextInput
            type="text"
            placeholder="(-)없이 숫자만 입력"
            value={registNumber}
            onChange={(event) => setRegistNumber(event.target.value)}
            margin="18px"
            color={registNumberBorder}
          />
          <ErrorText>{registrationNumberText}</ErrorText>
        </TextDiv>
        <TextDiv
          column="true"
          width="523px"
          align="flex-start"
          justify="flex-start"
        >
          <TextDiv justify="flex-start">
            <MainText fontSize="18px" color="#212529" weight={400}>
              사업자등록증 첨부
            </MainText>
            <MainText
              fontSize="16px"
              color="#868E96"
              weight={400}
              style={{ marginLeft: 13 }}
            >
              500mb 이하의 파일만 업로드 가능합니다.
            </MainText>
          </TextDiv>
          {thumNail[0]?.previewURL !== "" ? (
            <>
              <TextDiv margin="18px" align="center" justify="flex-start">
                <FileImage
                  alt="thumnail"
                  src={thumNail[0]?.previewURL}
                  onClick={() => setImageModalOpen(true)}
                />
                <ImageModal
                  src={thumNail[0]?.previewURL}
                  imageModalOpen={imageModalOpen}
                  setImageModalOpen={setImageModalOpen}
                />
                <RemoveImage
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: 48,
                    right: 16,
                  }}
                  onClick={() => setThumNail([{ file: "", previewURL: "" }])}
                />
              </TextDiv>
            </>
          ) : (
            <>
              <TextInput
                type="file"
                style={{ display: "none" }}
                id="file_upload"
                onChange={fileHanlder}
              />
              <TextDiv margin="38px" align="center" justify="flex-start">
                <FileLabel htmlFor="file_upload" style={{ cursor: "pointer" }}>
                  <FileUpload />
                </FileLabel>
              </TextDiv>
            </>
          )}
        </TextDiv>
      </InputDiv>

      <Actionbutton disabled={disabledButton} onClick={changeUserType}>
        완료
      </Actionbutton>
    </WrapDiv>
  );
};

export default MyPageUserType;
