import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageVehicleStorageStyle from "../../components/myPage/MyPageVehicleStorageStyle";

const MyPageVehicleStorage = () => {
  const position = false;
  const location = useLocation();

  return (
    <>
      <Header position={position}  borderactive={location.pathname.includes('/myPage')} />
      <MyPageVehicleStorageStyle />
    </>
  );
};

export default MyPageVehicleStorage;
