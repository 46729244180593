import Header from "../../components/Header";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import { Tabs, Table, DatePicker } from "antd";
import Loading from "react-loading";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ko_KR";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import {
  LiveAuctionContainer,
  LiveAuctionWrapDiv,
  LiveAuctionCateogryText,
  LiveAuctionCarInfoText,
  LiveAuctionCarTitle,
  LiveAutcionCarInfoContainer,
  LiveAuctionBoxContainer,
} from "../../components/liveAuction/LiveAuctionStyle";
import "../../components/liveAuction/LiveAuction.css";
import { useEffect, useState } from "react";
import { BidModal } from "../../components/liveAuction/LiveAuctionModal";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";

import axios from "axios";

const TableBox = styled(LiveAuctionBoxContainer)`
  margin-top: 0;
  align-items: center;
  margin-top: 50px;
`;

const BidResultText = styled(LiveAuctionCarTitle)`
  color: #ff6a13;
  ${(props) =>
    props.colorChange &&
    css`
      color: #868e96;
    `};
`;

const TableText = styled(LiveAuctionCateogryText)`
  font-weight: 700;
`;

const Subtext = styled(LiveAuctionCateogryText)`
  font-weight: 500;
`;

const BidUpdateText = styled(LiveAuctionCateogryText)`
  font-weight: 700;
  color: #ff6a13;
  text-decoration: underline;
  cursor: pointer;
`;

const LiveAuctionList = () => {
  const { TabPane } = Tabs;
  const position = false;
  const liveAuctionText = true;
  const hidden = false;
  const naviagte = useNavigate();
  const bold = true;
  const token = useToken();

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [bidModalInfo, setBidModalInfo] = useState();

  const [auctionState, setAuctionState] = useState("진행중");
  const [myAuctionList, setMyAuctionList] = useState({});

  // 날짜 옵션
  const [startDay, setStartDay] = useState();
  const [endDay, setEndDay] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // 나의 경매 현황
  useEffect(() => {
    setIsLoading(true);
    axios({
      url: `${BASIC_URL}/liveauction/me/status?status=${auctionState}`,
      method: "get",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data;

        data.bidders.sort((a, b) => b.id - a.id);

        setMyAuctionList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, auctionState]);

  // 경매 검색하기
  useEffect(() => {
    if (endDay && auctionState === "진행완료") {
      setIsLoading(true);
      axios({
        url: `${BASIC_URL}/liveauction/me/status?=${auctionState}&startDate=${moment(
          startDay
        ).format("YYYY-MM-DD")} &endDate=${moment(endDay).format(
          "YYYY-MM-DD"
        )}`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const data = res.data;

          data.bidders.sort((a, b) => b.id - a.id);

          setMyAuctionList(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token, auctionState, endDay, startDay]);

  const auctionListData = myAuctionList?.bidders?.map((list) => {
    if (list.length === 0) {
      return null;
    } else {
      return {
        key: list?.id,
        출품번호: `${list?.auctionId}번`,
        회차: `1001회 (${moment(list?.startsAt).format("YY년 MM월 DD일")})`,
        차종: list?.name,
        입찰가: `${Number(list?.price / 10000).toLocaleString("en")}만원`,
        부재자입찰: list?.editable ? (
          <BidUpdateText
            onClick={() => {
              setBidModalOpen(true);
              setBidModalInfo({
                id: list?.id,
                name: list?.userName,
                price: list?.price,
                auctionEntryNumber: list?.auctionEntryNumber,
                phoneNumber: list?.userPhoneNumber,
              });
            }}
          >
            변경
          </BidUpdateText>
        ) : (
          <BidUpdateText>변경 불가</BidUpdateText>
        ),
      };
    }
  });

  const auctionListData2 = myAuctionList?.bidders?.map((list) => {
    if (list.length === 0) {
      return null;
    } else {
      return {
        key: list?.id,
        출품번호: `${list?.auctionId}번`,
        회차: `1001회 (${moment(list?.startsAt).format("YY년 MM월 DD일")})`,
        차종: list?.name,
        입찰낙찰가: (
          <LiveAuctionCateogryText>
            입찰가 {Number(list?.price / 10000).toLocaleString("en")}만원
            <br />
            {`낙찰가 ${ list.highPrice === '-' ? '-' : `${Number(list?.highPrice / 10000).toLocaleString("en")}만원`}`}
          </LiveAuctionCateogryText>
        ),
        경매현황: list?.selected ? (
          <BidResultText>낙찰</BidResultText>
        ) : (
          <BidResultText colorChange>유찰</BidResultText>
        ),
      };
    }
  });

  const auctionTableColumn = [
    {
      title: <LiveAuctionCarInfoText>출품번호</LiveAuctionCarInfoText>,
      dataIndex: "출품번호",
      key: "출품번호",
      align: "center",
      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>회차</LiveAuctionCarInfoText>,
      dataIndex: "회차",
      key: "회차",
      align: "center",
      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>차종</LiveAuctionCarInfoText>,
      dataIndex: "차종",
      key: "차종",
      align: "center",

      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>입찰가</LiveAuctionCarInfoText>,
      dataIndex: "입찰가",
      key: "입찰가",
      align: "center",
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: <LiveAuctionCarInfoText>부재자 입찰</LiveAuctionCarInfoText>,
      dataIndex: "부재자입찰",
      key: "부재자입찰",
      align: "center",
    },
  ];

  const auctionTableColumn2 = [
    {
      title: <LiveAuctionCarInfoText>출품번호</LiveAuctionCarInfoText>,
      dataIndex: "출품번호",
      key: "출품번호",
      align: "center",
      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>회차</LiveAuctionCarInfoText>,
      dataIndex: "회차",
      key: "회차",
      align: "center",
      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>차종</LiveAuctionCarInfoText>,
      dataIndex: "차종",
      key: "차종",
      align: "center",

      render: (text) => (
        <LiveAuctionCateogryText>{text}</LiveAuctionCateogryText>
      ),
    },
    {
      title: <LiveAuctionCarInfoText>입찰/낙찰가</LiveAuctionCarInfoText>,
      dataIndex: "입찰낙찰가",
      key: "입찰낙찰가",
      align: "center",
    },
    {
      title: <LiveAuctionCarInfoText>경매 현황</LiveAuctionCarInfoText>,
      dataIndex: "경매현황",
      key: "경매현황",
      align: "center",
    },
  ];

  const dateHanlder = (datesStrings) => {
    setStartDay(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  const endDateHandler = (datesStrings) => {
    setEndDay(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  const endDayDisabledDate = (current) => {
    if (!startDay) {
      return false;
    } else {
      return current && current < moment(startDay).add(1, "days");
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(6, "months");
  };

  return (
    <>
      <BidModal
        bidModalOpen={bidModalOpen}
        setBidModalOpen={setBidModalOpen}
        bidModalInfo={bidModalInfo}
      />
      <Header position={position} liveAuctionText={liveAuctionText} />
      <HeaderMenuBar hidden={hidden}>
        <HeaderMenuText
          style={{ cursor: "pointer" }}
          onClick={() => naviagte("/liveAuctionMain")}
        >
          라이브 옥션
        </HeaderMenuText>
        <HeaderMenuText
          style={{ marginLeft: 28, cursor: "pointer" }}
          bold={bold}
        >
          나의 경매 현황
        </HeaderMenuText>
      </HeaderMenuBar>

      <LiveAuctionWrapDiv>
        <LiveAuctionContainer>
          <Tabs
            className="liveAuction_tab"
            onChange={(key) => setAuctionState(key)}
          >
            <TabPane key="진행중" tab="진행 중">
              <TableBox>
                <Subtext>이용 내역</Subtext>
              </TableBox>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: 1080,
                      height: "50vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loading
                      width={100}
                      height={100}
                      color="#ff6a13"
                      type="spin"
                    />
                  </div>
                </div>
              ) : (
                <Table
                  dataSource={auctionListData}
                  className="liveAuction_table"
                  columns={auctionTableColumn}
                  pagination={false}
                  rowClassName="auction_table_row"
                />
              )}
            </TabPane>
            <TabPane key="진행완료" tab="진행 종료">
              <TableBox>
                <Subtext>이용 내역</Subtext>
                <LiveAutcionCarInfoContainer>
                  <DatePicker
                    className="auction_list_picker"
                    placeholder="시작일"
                    showToday={false}
                    locale={locale}
                    allowClear={false}
                    onChange={dateHanlder}
                    disabledDate={disabledDate}
                  />

                  <DatePicker
                    style={{ marginLeft: 25 }}
                    className="auction_list_picker"
                    placeholder="종료일"
                    showToday={false}
                    locale={locale}
                    allowClear={false}
                    onChange={endDateHandler}
                    disabledDate={endDayDisabledDate}
                  />
                </LiveAutcionCarInfoContainer>
              </TableBox>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: 1080,
                      height: "50vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loading
                      width={100}
                      height={100}
                      color="#ff6a13"
                      type="spin"
                    />
                  </div>
                </div>
              ) : (
                <Table
                  dataSource={auctionListData2}
                  className="liveAuction_table"
                  columns={auctionTableColumn2}
                  pagination={false}
                  rowClassName="auction_table_row2"
                />
              )}
            </TabPane>
          </Tabs>
        </LiveAuctionContainer>
      </LiveAuctionWrapDiv>
    </>
  );
};

export default LiveAuctionList;
