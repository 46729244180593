import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { userEmailState } from "../../states/userInfoState";
import styled from "@emotion/styled/macro";
import Header from "../../components/Header";
import findImg from "../../assets/img/findemail.png";

const FindEmailCompleteWrapDiv = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FindEmailCompleteTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.2px;
  color: #212529;
`;

const FindEmailCompleteLine = styled.div`
  width: 600px;
  height: 2px;
  margin-top: 60px;
  border: 1px solid #dee2e6;
`;

const FindEmailInfoDiv = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  text-align: center;
`;

const FindEmailText = styled.span`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 30px;
  color: #212529;

  span {
    font-size: 20px;
    font-family: SpoqaHanSansNeo;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 30px;
    color: #212529;
  }
`;

const FindEmailImg = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 32px;
`;

const ButtonDiv = styled.div`
  width: 516px;
  height: 70px;
  margin-top: 150px;
  display: flex;
`;

export const FindPasswordButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  background-color: #212529;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.88px;
  font-size: 22px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;

  ${(props) =>
    props.oragneButton &&
    css`
      background-color: #ff6a13;
      margin-left: 20px;
    `};

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const FindEmailCompletion = () => {
  const position = false;
  const navigate = useNavigate();
  const userEmail = useRecoilValue(userEmailState);
  const { userName } = useParams();

  return (
    <>
      <Header position={position} />
      <FindEmailCompleteWrapDiv>
        <FindEmailCompleteTitle>아이디 찾기 완료</FindEmailCompleteTitle>
        <FindEmailCompleteLine />
        <FindEmailInfoDiv>
          <FindEmailImg src={findImg} alt="check" />
          <FindEmailText>
            {userName} 님의 아이디는 <br />
            <span>{userEmail}</span> 입니다.
          </FindEmailText>
        </FindEmailInfoDiv>
        <ButtonDiv>
          <FindPasswordButton onClick={() => navigate("/findPassword")}>
            비밀번호 찾기
          </FindPasswordButton>
          <FindPasswordButton oragneButton onClick={() => navigate("/login")}>
            로그인
          </FindPasswordButton>
        </ButtonDiv>
      </FindEmailCompleteWrapDiv>
    </>
  );
};

export default FindEmailCompletion;
