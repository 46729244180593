import styled from "@emotion/styled/macro";

const ImageButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #ff6a13;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  position: absolute;
`;

const SmallIamge = styled.div`
  width: 98px;
  height: 70px;
  border-radius: 5px;
  border: ${(props) =>
    props.active ? "1px solid #ff6a13" : "1px solid #f8f9fa"};
  background: #f8f9fa;
  object-fit: cover;
  margin-right: 10px;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &:hover {
    border: 1px solid #ff6a13;
  }

  ::after {
    content: "";
    margin-top: 30px;
    position: absolute;
    bottom: -30px;
    width: 108px;
    height: 2px;
    background: ${(props) => (props.active ? "#ff6a13" : "#E9ECEF")};
    display: block;
  }
`;

export { SmallIamge, ImageButton };
