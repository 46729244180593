import styled from "@emotion/styled/macro";
import Check from "../../../../assets/img/findemail.png";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as CheckDone } from "../../../../assets/icons/CheckDone.svg";
import { ReactComponent as DotLineDone } from "../../../../assets/icons/DotLineDone.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";

const SalesComplete = ({ selfSellCarData }) => {
  const StepBarDoneDot = styled(DotLineDone)`
    margin-top: 10px;
  `;

  const DoneText = styled(SelfSellCarStepText)`
    color: #ffd0b8;
  `;

  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            회원님의 차량 판매가 완료되었습니다.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>
        {/* 정산 신청 유무에 따라 텍스트 달라 짐  */}
        {selfSellCarData?.status === "정산완료" &&
        selfSellCarData?.paymentDone ? (
          <SelfSellCarStepSubText>
            서비스가 어떠셨나요? 후기를 남겨주세요.
          </SelfSellCarStepSubText>
        ) : (
          <SelfSellCarStepSubText>
            차량 판매가 완료되었습니다! 정산 신청을 해주세요.
          </SelfSellCarStepSubText>
        )}
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>상품화</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer
          align
          style={{ marginLeft: 13, marginRight: 13 }}
        >
          <CheckDone />
          <DoneText active>경매</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        {selfSellCarData?.saleDone ? (
          <>
            <SelfSellCarInputContainer align>
              <CheckDone />
              <DoneText active>위탁 판매</DoneText>
            </SelfSellCarInputContainer>
            <StepBarDoneDot />{" "}
          </>
        ) : null}
        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};
export default SalesComplete;
