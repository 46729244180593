import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPagePickUpStyle from "../../components/myPage/MyPagePickUpStyle";

const MyPagePickUp = () => {
  const position = false;
  const location = useLocation();

  return (
    <>
      <Header position={position}  borderactive={location.pathname.includes('/myPage')} />
      <MyPagePickUpStyle />
    </>
  );
};

export default MyPagePickUp;
