import Auction from "./Auction";
import DropShipping from "./DropShipping";
import Overhaul from "./Overhaul";
import WaitingVehicle from "./WaitingVehicle";
import Commercialization from "./Commercialization";
import SalesComplete from "./SalesComplete";
import { DropShipping2, SalesComplete2 } from "./DropShipping2";

// status에 따른 title / stepBar
const SelfSellCarStepBar = ({ selfSellCarData, auctionResult }) => {
  return (
    <>
      {selfSellCarData?.status === "입고대기" && <WaitingVehicle />}

      {selfSellCarData?.status === "정밀점검" && (
        <Overhaul selfSellCarData={selfSellCarData} />
      )}

      {selfSellCarData?.status === "점검완료" && (
        <Overhaul selfSellCarData={selfSellCarData} />
      )}

      {selfSellCarData?.status === "상품화" && (
        <Commercialization selfSellCarData={selfSellCarData} />
      )}

      {selfSellCarData?.status === "상품화완료" && (
        <Commercialization selfSellCarData={selfSellCarData} />
      )}

      {selfSellCarData?.status === "경매신청" && (
        <Auction selfSellCarData={selfSellCarData} />
      )}

      {selfSellCarData?.status === "경매완료" && (
        <Auction
          selfSellCarData={selfSellCarData}
          auctionResult={auctionResult}
        />
      )}

      {selfSellCarData?.status === "위탁판매" &&
        selfSellCarData?.auctionDone && (
          <DropShipping selfSellCarData={selfSellCarData} />
        )}

      {selfSellCarData?.status === "위탁판매" &&
        selfSellCarData?.auctionDone === false && (
          <DropShipping2 selfSellCarData={selfSellCarData} />
        )}

      {selfSellCarData?.status === "정산완료" &&
        selfSellCarData?.auctionDone && (
          <SalesComplete selfSellCarData={selfSellCarData} />
        )}

      {selfSellCarData?.status === "정산완료" &&
        selfSellCarData?.auctionDone === false && <SalesComplete2 />}
    </>
  );
};

export default SelfSellCarStepBar;
