import { useNavigate } from "react-router";
import Header, { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import PickUpRequestDetailStyle from "../../components/pick-up/PickUpRequestDetailStyle";

const PickUpRequestDetail = () => {
  const position = false;
  const textBold = true;
  const bold = true;
  const hidden = false;
  const navigate = useNavigate();
  return (
    <>
      <Header position={position} textBold={textBold} />
      <HeaderMenuBar hidden={hidden}>
        <HeaderMenuText
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/pickUpApply")}
        >
          픽업 정비 신청
        </HeaderMenuText>
        <HeaderMenuText
          style={{ marginLeft: 28, cursor: "pointer" }}
          bold={bold}
        >
          나의 신청 현황
        </HeaderMenuText>
      </HeaderMenuBar>
      <PickUpRequestDetailStyle />
    </>
  );
};

export default PickUpRequestDetail;
