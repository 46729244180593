import styled from "@emotion/styled/macro";
import axios from "axios";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import { useEffect, useState, useRef } from "react";
import CheckBoxImage2 from "../../assets/img/checkboxImage2.png";
import CheckBoxImage3 from "../../assets/img/checkboxImage3.png";
import { BASIC_URL } from "../CommonStyle";
import "../Common.css";

// 회원 가입 약관동의 전체 Div
const AgreementWrapDiv = styled.div`
  width: 100%;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AgreementTitle = styled.h1`
  margin-top: 268px;
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.36px;
`;

export const AgreementSubTitle = styled.span`
  margin-top: 15px;
  letter-spacing: -0.8px;
  font-size: 20px;
  color: #868e96;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
`;

export const AgreemenntLine = styled.div`
  margin-top: 50px;
  width: 1080px;
  height: 2px;
  background: #e9ecef;
`;

const AgreementContentWrapDiv = styled.div`
  width: 1080px;
  margin-top: 100px;
`;

const AgreementMenu = styled.p`
  font-size: 24px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  font-weight: 700;
  color: #212529;
`;

const AgreementContentDiv = styled.div`
  height: 280px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  margin-top: 20px;
  overflow-y: scroll;
`;

const CheckAreaDiv = styled.div`
  margin-top: 26px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AgremmentText = styled.label`
  font-size: 18px;
  cursor: pointer;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  font-weight: 400;
`;

export const NextButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  border-radius: 10px;
  color: #fff;
  letter-spacing: -0.88px;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  cursor: pointer;
  font-weight: 500;
  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

export const NextButton2 = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  background-color: #ff6a13;
  border-radius: 10px;
  letter-spacing: -0.88px;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
`;

export const JoinCheckbox = styled(Checkbox)`
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none !important ;
  justify-content: center;

  &::after {
    background-image: ${(props) =>
      props.unactive ? `url("${CheckBoxImage2}")` : `url("${CheckBoxImage3}")`};
    background-position: center;
    width: 24px;
    height: 24px;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
`;

const JoinStyle = ({ margin }) => {
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [infoCheck, setInfoCheck] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [buttonColor, setButtonColor] = useState(false);

  const titleRef = useRef();

  useEffect(() => {
    if (margin === false) {
      titleRef.current.style = "margin-top : 128px";
    }
  });

  // 이용약관 가져오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/policy`,
      method: "GET",
    }).then((res) => {
      console.log(res.data);
    });
  }, []);

  const checkBoxHandler = (e) => {
    if (e.target.id === "check") {
      setAgreementCheck((prev) => !prev);
    } else if (e.target.id === "check1") {
      setInfoCheck((prev) => !prev);
    } else {
      setMarketing((prev) => !prev);
    }
  };
  // 전체 약관 동의
  const checkBoxAllHandler = () => {
    setCheckAll((prev) => !prev);
    if (checkAll) {
      setInfoCheck(false);
      setMarketing(false);
      setAgreementCheck(false);
    } else {
      setInfoCheck(true);
      setMarketing(true);
      setAgreementCheck(true);
    }
  };

  useEffect(() => {
    if (agreementCheck === true && infoCheck === true && marketing === true) {
      setCheckAll(true);
      setButtonColor(true);
    } else if (
      agreementCheck === true &&
      infoCheck === true &&
      marketing === false
    ) {
      setCheckAll(false);
      setButtonColor(true);
    } else if (agreementCheck === false || infoCheck === false) {
      setButtonColor(false);
    }
  }, [agreementCheck, infoCheck, marketing, buttonColor, checkAll]);

  useEffect(() => {
    if (checkAll === true) {
      setInfoCheck(true);
      setAgreementCheck(true);
      setMarketing(true);
      setButtonColor(true);
    } else {
    }
  }, [checkAll]);

  return (
    <AgreementWrapDiv>
      <AgreementTitle ref={titleRef}>약관동의</AgreementTitle>
      <AgreementSubTitle>서비스 이용 약관에 동의해주세요.</AgreementSubTitle>
      <AgreemenntLine />
      <AgreementContentWrapDiv>
        <AgreementMenu>서비스 이용약관</AgreementMenu>
        <AgreementContentDiv>
          서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.서비스 이용약관입니다.
        </AgreementContentDiv>
        <CheckAreaDiv>
          <JoinCheckbox
            unactive={!agreementCheck}
            type="checkbox"
            id="check"
            style={{ marginRight: 10 }}
            onChange={checkBoxHandler}
            checked={agreementCheck}
            className="selfsellcar_checkbox"
          />
          <AgremmentText htmlFor="check">
            탈것 서비스 이용약관에 동의합니다. (필수)
          </AgremmentText>
        </CheckAreaDiv>
      </AgreementContentWrapDiv>
      <AgreementContentWrapDiv>
        <AgreementMenu>개인정보 수집 및 이용동의</AgreementMenu>
        <AgreementContentDiv>
          개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.개인정보 수집 및 이용동의입니다.
        </AgreementContentDiv>
        <CheckAreaDiv>
          <JoinCheckbox
            type="checkbox"
            className="selfsellcar_checkbox"
            id="check1"
            style={{ marginRight: 10, marginTop: 2 }}
            onChange={checkBoxHandler}
            unactive={!infoCheck}
            checked={infoCheck}
          />
          <AgremmentText htmlFor="check1">
            탈것 서비스 개인정보 수집 및 이용에 동의합니다. (필수)
          </AgremmentText>
        </CheckAreaDiv>
      </AgreementContentWrapDiv>
      <AgreementContentWrapDiv>
        <AgreementMenu>마케팅 메세지 수신동의</AgreementMenu>
        <AgreementContentDiv>
          마케팅 메세지 수신동의입니다.
        </AgreementContentDiv>
        <CheckAreaDiv>
          <JoinCheckbox
            type="checkbox"
            className="selfsellcar_checkbox"
            style={{ marginRight: 10, marginTop: 2 }}
            id="check2"
            onChange={checkBoxHandler}
            checked={marketing}
            unactive={!marketing}
          />
          <AgremmentText htmlFor="check2">
            마케팅 메시지 수신에 동의합니다. (선택)
          </AgremmentText>
        </CheckAreaDiv>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1080,
            marginTop: 132,
          }}
        >
          <JoinCheckbox
            style={{ marginRight: 10 }}
            type="checkbox"
            id="checkAll"
            onChange={checkBoxAllHandler}
            checked={checkAll}
            unactive={!checkAll}
            className="selfsellcar_checkbox"
          />
          <AgremmentText htmlFor="checkAll">
            전체 약관에 동의합니다.
          </AgremmentText>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: 1080,
            marginTop: 151,
            marginBottom: 176,
          }}
        >
          {buttonColor ? (
            <Link to="/join_info" state={{ marketingAgree: marketing }}>
              <NextButton2>다음</NextButton2>
            </Link>
          ) : (
            <NextButton disabled>다음</NextButton>
          )}
        </div>
      </AgreementContentWrapDiv>
    </AgreementWrapDiv>
  );
};

export default JoinStyle;
