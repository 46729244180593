import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

export const LiveAuctionWrapDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;

export const LiveAuctionContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  position: relative;
`;

export const LiveAuctionBox = styled.div`
  width: 336px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  min-height: 1200px;
`;

export const LiveAuctionBox2 = styled.div`
  width: 709px;
  display: flex;
  flex-direction: column;
  text-align: right;
  /* align-items: flex-end; */
`;

export const LiveAuctionBox2Line = styled.div`
  width: 100%;
  height: 1px;
  background: #868e96;
  margin-top: 20px;
`;

export const LiveAuctionBox2Line2 = styled.div`
  width: 100%;
  height: 1px;
  background: #ededed;
`;

export const LiveAuctionBoxLine = styled.div`
  width: 100%;
  height: 1px;
  background: #868e96;
  margin-top: 20px;
`;

export const LiveAuctionBoxContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 63px;
  flex-direction: row;
  justify-content: space-between;
`;

export const LiveAuctionCategoryContainer = styled.div`
  width: 100%;
  display : flex;
  display: ${props =>props.active ? 'none'  : ' flex'};
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  padding-right: 5px;
  align-items: center;
`;

export const LiveAuctionCateogryText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 28px;
  color: #212529;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.margin &&
    css`
      margin-bottom: 40px;
      margin-top: 40px;
      cursor: pointer;
    `};
`;

export const LiveAuctionCarModelContainer = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow-y: scroll;
`;

export const LiveAuctionCarImageBox = styled.img`
  border-radius: 10px;
  width: 260px;
  height: 162px;
  background-color: #f8f9fa;
  object-fit: cover;
`;

export const LiveAuctionCarInfoBox = styled.div`
  height: 162px;
  display: flex;
  flex-direction: column;
  border: none;
  margin-left: 30px;
`;

export const LiveAutcionCarInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.margin};
`;

export const CarInfoBadge = styled.div`
  min-width: 71px;
  padding: 0 8px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6a13;
  font-weight: 500;
  border: 1px solid #fc6a28;
  font-size: 16px;
  margin-right: 10px;
  letter-spacing: -0.48px;
  font-family: SpoqaHanSansNeo;
`;

export const CarInfoBadge2 = styled.div`
  width: 112px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6a13;
  font-weight: 500;
  border: 1px solid #fc6a28;
  font-size: 20px;
  margin-right: 10px;
  letter-spacing: -0.6px;
  font-family: SpoqaHanSansNeo;
`;

export const LiveAuctionButton = styled.button`
  width: 336px;
  height: 70px;
  background-color: #ff6a13;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #fff;
  display: flex;
  align-items : center;
  justify-content : center;

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

export const LiveAuctionText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
`;

export const LiveAuctionCarTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
`;

export const LiveAuctionCarInfoText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
`;

export const LiveAuctionPriceText = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  color: #212529;

  ${(props) =>
    props.weight &&
    css`
      font-weight: 500;
    `};
`;

export const LiveAuctionDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LiveAuctionDetailBox = styled.div`
  width: 1080px;
  margin-top: 100px;
  flex-direction: column;
  position: relative;
`;

export const LiveAuctionDetailTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.12px;
  color: #212529;
`;

export const LiveAuctionDetailPrice = styled.p`
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.36px;
  color: #ff6a13;
`;

export const LiveAuctionDetailCarImageDiv = styled.div`
  width: 100%;
  height: 530px;
  margin-top: 50px;
  border-radius: 10px;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LiveAuctionCarImage = styled.img`
  width: 844px;
  height: 407px;
  object-fit: cover;
  border: none;
`;

export const LiveAuctionBidButton = styled.button`
  width: 118px;
  height: 118px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  cursor: pointer;
  border-radius: 100%;
  background-color: #ff6a13;
  border: none;
  outline: none;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  top: 125px;
  right: -175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LiveAuctionDetailSubText = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
`;

export const AuctionDayCircle = styled.div`
  height: 28px;
  width: 28px;
  position: absolute;
  margin-top: 5px;
  margin-left: 8px;
  border: 1px solid #ff6a13;
  border-radius: 100%;
  margin-bottom: 10px;
  top: -2px;
  right: 5px;
`;

export const LiveAuctionCarFunctionTitle = styled.div`
  width: 118px;
  height: 71px;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LiveAuctionCarFunctionContent = styled.div`
  width: 115px;
  height: 71px;
  background: #ffff;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

export const AuctionPriceContainer = styled.div`
  width: 1080px;
  height: 170px;
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0px 3px 15px #0000000d;
  padding: 0 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AuctionNumberText = styled.p`
  width: 1080px;
  margin-top: 100px;
  font-size: 16px;
  color: #212529;
  font-weight: 500;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;

  strong {
    font-weight: 700;
  }
`;

export const AuctionCarInfoContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
`;

export const AuctionCarInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  position:relative;
  /* &:hover > div {
    display: block;
  } */
  align-items: center;
  ${(props) =>
    props.margin &&
    css`
      margin-top: 15px;
    `};
`;

export const AuctionCarInfoBox2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

export const NextCarInfo = styled.div`
width : 190px;
padding : 20px 15px;
text-align: center;
box-shadow: 0px 3px 6px #00000029;
border-radius : 10px;
margin-top: 8px;
`

export const AuctionCarBadge = styled.div`
  border-radius: 8px;
  background: #ff6a13;
  margin-left: 16px;
  width: 70px;
  height: 30px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.64px;
  font-size: 16px;
  text-align: center;
  padding-top: 8px;
`;

export const AuctionNextCarLink = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #212529;
  cursor: pointer;
`;

export const AuctionDetailInfoText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #868e96;
`;

export const AuctionCarPhoto = styled.img`
  width: 558px;
  height: 380px;
  border-radius: 10px;
  border: none;
  outline: none;
  object-fit: cover;
`;

export const AuctionCarInfoPhoto = styled.img`
  width: 502px;
  height: 240px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 20px;
`;

export const AuctionLine = styled.div`
  width: 502px;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: #dee2e6;
`;

export const AuctionCategoryBox = styled.div`
  width: 502px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const CategoryText = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
`;

export const AuctionCarIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const AuctionStatus = styled.div`
  width: 82px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #212529;
`;

export const AuctionStepCircle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #e9ecef;
  margin-right: 10px;
  ${(props) =>
    props.backgroundcolor &&
    css`
      background-color: #5be24a;
    `};
`;

export const AuctionStepCircle2 = styled(AuctionStepCircle)`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #e9ecef;
  margin-right: 10px;
  ${(props) =>
    props.backgroundcolor &&
    css`
      background-color: #fed731;
    `};
`;

export const AuctionStepCircle3 = styled(AuctionStepCircle)`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #e9ecef;
  margin-right: 10px;
  ${(props) =>
    props.backgroundcolor &&
    css`
      background-color: #ff383b;
    `};
`;

export const AuctionPrice = styled.div`
  width: 80px;
  height: 100px;
  background-color: #f8f9fa;
  border-radius: 10px;
  border: 1px solid #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

export const BidButton = styled.button`
  width: 70px;
  height: 70px;
  background: #ff6a13;
  border: none;
  margin-right: 12px;
  border-radius: 10px;

  &:disabled {
    background: #e9ecef;
  }
`;

export const PriceText = styled.p`
  font-size: 44px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.76px;
  color: #868e96;
`;

export const PriceUnitPrice = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
  position: absolute;
  bottom: 6px;
  right: 6px;
`;

export const StatusText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;
`;

export const Comma = styled.p`
  font-size: 30px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.12px;
`;

export const AuctionButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  background-color: #ff6a13;
  border-radius: 10px;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -0.88px;
  color: #fff;

  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }
`;

export const CarDetailDiv = styled.div`
  width: 1080px;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: flex-start;
`;
