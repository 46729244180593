import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { ReactComponent as MyPageIcon } from "../../assets/img/myPageIcon.svg";
import { ReactComponent as MyPageIcon2 } from "../../assets/img/myPageIcon2.svg";
import { ReactComponent as MyPageIcon3 } from "../../assets/img/myPageIcon3.svg";
import { ReactComponent as MyPageIcon4 } from "../../assets/icons/MyPageIcon4.svg";
import { useUserType } from "../../hooks/useUserInfo";
import Loading from "react-loading";
import MyPageMenu from "./MyPageMenu";
import tokenState from "../../states/TokenState";
import {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageActiveDiv,
  MyPageEngText,
  MyPageLine,
  MyPageContentListDiv,
  ContentDiv,
  MyPageText,
} from "./MyPageMenu";

const MyPageStyle = ({ useNumbers, isLoading }) => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  }, [navigate, token]);

  const userType = useUserType();

  return (
    <MyPageWrapDiv>
      <MyPageContentDiv>
        <MyPageMenu userType={userType} />
        <MyPageActiveDiv>
          <MyPageEngText>My page</MyPageEngText>
          <MyPageLine style={{ marginTop: 20, marginBottom: 24 }} />

          {userType === "기업회원" ? null : (
            <>
              <MyPageContentListDiv>
                <ContentDiv>
                  <MyPageIcon />
                  <MyPageText
                    onClick={() => navigate("/selfSellCarList")}
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      marginLeft: 14,
                      cursor: "pointer",
                    }}
                  >
                    셀프 셀카
                  </MyPageText>
                </ContentDiv>
                <ContentDiv style={{ paddingRight: 8 }}>
                  {isLoading ? (
                    <Loading
                      type="spin"
                      color="#ff6a13"
                      width={32}
                      height={32}
                    />
                  ) : (
                    <MyPageText
                      style={{
                        fontSize: 22,
                        fontWeight: 700,
                        color: "#FF6A13",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/selfSellCarList")}
                    >
                      {useNumbers.selfSellcar}건 &nbsp;&gt;
                    </MyPageText>
                  )}
                </ContentDiv>
              </MyPageContentListDiv>
              <MyPageLine
                style={{
                  marginTop: 20,
                  marginBottom: 24,
                  borderColor: "#EDEDED",
                }}
              />{" "}
            </>
          )}

          <MyPageContentListDiv>
            <ContentDiv>
              <MyPageIcon2 />
              <MyPageText
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginLeft: 14,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/pickUpDetail")}
              >
                픽업 정비
              </MyPageText>
            </ContentDiv>
            <ContentDiv style={{ paddingRight: 8 }}>
              {isLoading ? (
                <Loading type="spin" color="#ff6a13" width={32} height={32} />
              ) : (
                <MyPageText
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    color: "#FF6A13",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/pickUpDetail")}
                >
                  {useNumbers.pickupMaintenance}건 &nbsp;&gt;
                </MyPageText>
              )}
            </ContentDiv>
          </MyPageContentListDiv>
          <MyPageLine
            style={{ marginTop: 20, marginBottom: 24, borderColor: "#EDEDED" }}
          />
          {userType === "기업회원" ? null : (
            <>
              <MyPageContentListDiv>
                <ContentDiv>
                  <MyPageIcon3 />
                  <MyPageText
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      marginLeft: 14,
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/carStorageApplyList")}
                  >
                    차량 보관
                  </MyPageText>
                </ContentDiv>
                <ContentDiv style={{ paddingRight: 8 }}>
                  {isLoading ? (
                    <Loading
                      type="spin"
                      color="#ff6a13"
                      width={32}
                      height={32}
                    />
                  ) : (
                    <MyPageText
                      style={{
                        fontSize: 22,
                        fontWeight: 700,
                        color: "#FF6A13",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/carStorageApplyList")}
                    >
                      {useNumbers.carStorage}건 &nbsp;&gt;
                    </MyPageText>
                  )}
                </ContentDiv>
              </MyPageContentListDiv>
              <MyPageLine
                style={{
                  marginTop: 20,
                  marginBottom: 24,
                  borderColor: "#EDEDED",
                }}
              />
            </>
          )}
          {userType === "일반회원" || userType === "기업회원" ? null : (
            <>
              <MyPageContentListDiv>
                <ContentDiv>
                  <MyPageIcon4 />
                  <MyPageText
                    onClick={() => navigate("/liveAuctionList")}
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      marginLeft: 14,
                      cursor: "pointer",
                    }}
                  >
                    라이브 옥션
                  </MyPageText>
                </ContentDiv>
                <ContentDiv style={{ paddingRight: 8 }}>
                  {isLoading ? (
                    <Loading
                      type="spin"
                      color="#ff6a13"
                      width={32}
                      height={32}
                    />
                  ) : (
                    <MyPageText
                      style={{
                        fontSize: 22,
                        fontWeight: 700,
                        color: "#FF6A13",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/liveAuctionList")}
                    >
                      {useNumbers.liveAuction}건 &nbsp;&gt;
                    </MyPageText>
                  )}
                </ContentDiv>
              </MyPageContentListDiv>
              <MyPageLine
                style={{
                  marginTop: 20,
                  marginBottom: 24,
                  borderColor: "#EDEDED",
                }}
              />{" "}
            </>
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};

export default MyPageStyle;
