import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router";
import { ReactComponent as DisabledLiveAuctionIcon } from "../../assets/icons/DisabledLiveAuction.svg";
import { CarStorageButton } from "../../components/carStorage/CarStorageStyle";
import { useUserType } from "../../hooks/useUserInfo";

const DisabledLiveAuctionText = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
`;

const DisabledLiveAuctionText2 = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #adb5bd;
`;

const DisabledLiveAuctionBox = styled.div`
  width: 350px;
  margin: 16% auto;
  text-align: center;
`;

// 일반회원이 라이브옥션 눌렀을때
const DisabledLiveAuction = () => {
  const navigate = useNavigate();
  const userType = useUserType();

  return (
    <>
      <DisabledLiveAuctionBox>
        <DisabledLiveAuctionIcon style={{ marginBottom: 30 }} />
        <DisabledLiveAuctionText>
          딜러회원만 이용 가능한 서비스 입니다.
        </DisabledLiveAuctionText>

        {userType === "일반회원" ? (
          <>
            <DisabledLiveAuctionText2 style={{ marginTop: 11 }}>
              (마이페이지에서 딜러 회원 신청이 가능합니다.)
            </DisabledLiveAuctionText2>
            <CarStorageButton
              style={{ marginTop: 70 }}
              onClick={() => navigate("/userType")}
            >
              딜러회원 신청
            </CarStorageButton>
          </>
        ) : (
          userType && null
        )}
      </DisabledLiveAuctionBox>
    </>
  );
};

export default DisabledLiveAuction;
