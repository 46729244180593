import { useNavigate  , useLocation} from "react-router";
import { Radio, DatePicker, Select } from "antd";
import { useToken } from "../../hooks/useToken";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled/macro";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";
import "../../components/carStorage/CarStorage.css";
import {
  CarStorageWrapDiv,
  CarStorageApplyTextDiv,
  CarStorageDiv,
  CarStorageStepContentDiv,
  CarStorageText5,
  CarStorageText2,
  CarStorageText3,
  CarStorageText4,
  NumberText,
  CarStorageLine,
  CarStorageLine2,
  CarStorageDropBoxDiv,
  CarStorageApplyLabel,
  CarSelectDiv,
  CarStorageApplyInput,
  ContainerDiv,
  CarStorageText7,
  CategoryVehicles,
  Container,
  CarStorageButton,
} from "../../components/carStorage/CarStorageStyle";
import Header from "../../components/Header";
import { BASIC_URL } from "../../components/CommonStyle";
import { ReactComponent as CarIcon } from "../../assets/img/stepicon.svg";
import useUserInfo from "../../hooks/useUserInfo";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";

const CarStorageApply = () => {
  const position = false;
  const carStoragetextBold = true;
  const token = useToken();
  const navigate = useNavigate();
  const { Option } = Select;
  const location = useLocation();

  console.log(location.pathname.includes("/carStorageApply"))
  

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigate("/login");
    }
  });

  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  // 신청 정보
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [carNumber, setCarNumber] = useState("");
  const [payer, setPayer] = useState("");
  const [account, setAccount] = useState("");
  const [carType, setCarType] = useState(""); // 차량 종류
  const [option, setOption] = useState(""); // 주차권 옵션
  const [quantity, setQuantity] = useState("");
  const [bank, setBank] = useState("");
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (price > 0) {
      setTotalPrice(price * quantity);
    }
  }, [price, quantity]);

  const [payMethod, setPayMethod] = useState("계좌이체");

  const PayRadioGroup = styled(Radio.Group)`
    margin-top: 53px;
  `;

  // 날짜
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const dateHandler = (datesStrings) => {
    setStartDate(moment(datesStrings._d).format("YYYY-MM-DD"));
    setEndDate("");
  };

  const disabledDate = (current) => {
    if (!startDate) {
      return false;
    } else {
      return (
        (current && current < moment(startDate).add(1, "days")) ||
        current > moment(startDate).add(14, "days")
      );
    }
  };

  const disabledDate2 = (current) => {
    return current && current < moment().startOf("day");
  };

  const [agreementCheck, setAgreementCheck] = useState();

  // 휴대폰 번호 유효성 검사
  const [phoneNumberTest, setPhoneNumberTest] = useState(false);

  useEffect(() => {
    const phoneNumberCheck = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberCheck) {
        setPhoneNumberTest(true);
      } else {
        setPhoneNumberTest(false);
      }
    } else {
      setPhoneNumberTest(false);
    }
  }, [phoneNumber]);

  // 차량 번호 유효성 검사
  const [carNumberTest, setCarNumberTest] = useState(false);

  useEffect(() => {
    const carNumberCheck = /\d{2,3}[가-힣]{1}\d{4}/gm.test(carNumber);
    if (carNumber) {
      if (carNumberCheck) {
        setCarNumberTest(true);
      } else {
        setCarNumberTest(false);
      }
    } else {
      setCarNumberTest(false);
    }
  }, [carNumber]);

  // 차량보관 옵션
  const [carStorageOption, setCarStorageOption] = useState([]);

  useEffect(() => {
    if (carType) {
      axios({
        url: `${BASIC_URL}/carstorage/option?type=${carType}`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.data.options.length > 0) {
            setCarStorageOption(res.data.options);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => setCarStorageOption([]);
  }, [token, carType]);

  // 버튼 활성화
  const [disabled, setDisabled] = useState(true);
  const [paymentButtonDisabled, setPayMentButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      name &&
      phoneNumberTest &&
      carNumberTest &&
      carType &&
      option &&
      quantity &&
      startDate &&
      endDate &&
      payMethod &&
      agreementCheck
    ) {
      setPayMentButtonDisabled(false);
    } else {
      setPayMentButtonDisabled(true);
    }
  }, [
    name,
    phoneNumberTest,
    carType,
    carNumberTest,
    option,
    quantity,
    startDate,
    endDate,
    payMethod,
    agreementCheck,
  ]);

  useEffect(() => {
    if (
      name &&
      phoneNumberTest &&
      carNumberTest &&
      carType &&
      option &&
      quantity &&
      startDate &&
      bank &&
      payer &&
      endDate &&
      payMethod &&
      agreementCheck
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    name,
    phoneNumberTest,
    carType,
    carNumberTest,
    option,
    quantity,
    payer,
    account,
    bank,
    startDate,
    endDate,
    payMethod,
    agreementCheck,
  ]);

  const userInfo = useUserInfo();

  const payMent = () => {
    const { IMP } = window;
    IMP.init("imp57762674");
    const data = {
      pg: "html5_inicis", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      amount: 100, // 결제금액
      name: "탈것 차량 보관",
      buyer_name: name,
      buyer_email: userInfo?.email,
      buyer_tel: phoneNumber,
      app_scheme: "",
    };
    IMP.request_pay(data, callback);
  };

  function callback(response) {
    const { success, error_msg } = response;
    console.log(response);
    if (success) {
      axios({
        url: `${BASIC_URL}/carstorage`,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          information: {
            name,
            phoneNumber,
            carNumber,
            carType,
          },
          options: {
            option,
            quantity,
            startDay: startDate,
            endDay: endDate,
          },
          payments: {
            type: payMethod,
            imp_uid: response?.imp_uid,
          },
          totalPrice,
        },
      })
        .then((result) => {
          if (result.data.success === true) {
            navigate("/carStorageSuccess", {
              state: {
                price: totalPrice,
                method: payMethod,
              },
            });
          } else {
            alert(result.data.message);
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  // 차량보관 신청하기
  const applyCarStorage = () => {
    axios({
      url: `${BASIC_URL}/carstorage`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        information: {
          name,
          phoneNumber,
          carNumber,
          carType,
        },
        options: {
          option,
          quantity,
          startDay: startDate,
          endDay: endDate,
        },
        payments: {
          type: payMethod,
          bank,
          account,
          payerName: payer,
        },
        totalPrice,
      },
    })
      .then((res) => {
        if (res.data.success) {
          navigate("/carStorageSuccess", {
            state: {
              price: totalPrice,
              method: payMethod,
              bank,
              account,
              payer,
            },
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <CarStorageWrapDiv>
      <Header position={position} carStoragetextBold={carStoragetextBold} borderactive={location?.pathname.includes('/carStorage')}/>
      <CarStorageApplyTextDiv>
        <CarStorageText5>차량보관 신청</CarStorageText5>
        <CarStorageText2 color="true" margin="16px">
          위치 : 경기도 이천시 마장면 중부대로644번길 299 <br />
          운영시간 : 평일 09~18시(별도문의 031-281-5211)
        </CarStorageText2>
      </CarStorageApplyTextDiv>
      <CarStorageStepContentDiv style={{ marginTop: 100, marginBottom: 205 }}>
        <CarStorageDiv>
          <CarStorageText3>1. 기본 정보 입력</CarStorageText3>
          <CarStorageLine />
          <CarStorageApplyLabel htmlFor="name">신청인</CarStorageApplyLabel>
          <CarStorageApplyInput
            placeholder="신청인 입력"
            value={name}
            onChange={(event) => setName(event.target.value)}
            id="name"
            autoFocus
            autoComplete="off"
          />
          <CarStorageApplyLabel htmlFor="phoneNumber">
            휴대폰번호
          </CarStorageApplyLabel>
          <CarStorageApplyInput
            placeholder="(-)없이 숫자만 입력"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            autoComplete="off"
            id="phoneNumber"
          />
          <CarStorageApplyLabel htmlFor="carNumber">
            차량번호
          </CarStorageApplyLabel>
          <CarStorageApplyInput
            placeholder="차량번호 입력"
            id="carNumber"
            autoComplete="off"
            value={carNumber}
            onChange={(event) => setCarNumber(event.target.value)}
          />
          <CarSelectDiv>
            <CarStorageApplyLabel>차종 선택</CarStorageApplyLabel>
            <ContainerDiv
              style={{ position: "relative" }}
              onMouseOver={() => setOpenCategoryModal(true)}
              onMouseOut={() => setOpenCategoryModal(false)}
            >
              <CarIcon style={{ marginRight: 7, marginTop: 49 }} />
              <CarStorageText7 margin="52px">
                차종 구분은 어떻게 하나요?
              </CarStorageText7>
              {openCategoryModal ? (
                <CategoryVehicles>
                  <CarStorageText4>차종 구분</CarStorageText4>
                  <CarStorageText7 margin="12px">
                    소형 (길이 0m 초과 ~ 6m 이하 / 너비 2.3m 이하)
                  </CarStorageText7>
                  <CarStorageText7 margin="10px">
                    중형 (길이 0m 초과 ~ 8m 이하 / 너비 2.5m 이하)
                  </CarStorageText7>
                  <CarStorageText7 margin="10px">
                    소형 (길이 8m 초과 ~ 12m 이하 / 너비 2.5m 이하)
                  </CarStorageText7>
                </CategoryVehicles>
              ) : null}
            </ContainerDiv>
          </CarSelectDiv>
          <Select
            placeholder="차종 선택"
            className="carStorage_select"
            onChange={(value) => {
              setCarType(value);
            }}
          >
            <Option value="소형">소형</Option>
            <Option value="중형">중형</Option>
            <Option value="대형">대형</Option>
          </Select>
          <CarStorageText3 margin="120px">2. 주차권 옵션</CarStorageText3>
          <CarStorageLine />
          <CarStorageApplyLabel>주차권 선택</CarStorageApplyLabel>
          <Select
            placeholder="주차권 선택"
            className="carStorage_select"
            dropdownClassName="carStorage_dropdown"
            onChange={(value) => {
              setOption(value);

              const priceOption = carStorageOption?.find(
                (item) => item?.option === value
              );

              setPrice(priceOption?.price);
            }}
          >
            {carStorageOption?.map((option) => {
              return (
                <Option key={option.id} value={option.option}>
                  {option.option}
                </Option>
              );
            })}
          </Select>
          <CarStorageApplyLabel>장수 선택</CarStorageApplyLabel>
          <Select
            placeholder="장수 선택"
            className="carStorage_select"
            onChange={(value) => setQuantity(value)}
          >
            <Option value={1}>1장</Option>
            <Option value={2}>2장</Option>
            <Option value={3}>3장</Option>
          </Select>
          <CarStorageApplyLabel>날짜 선택</CarStorageApplyLabel>
          <Container>
            <DatePicker
              className="carStorage_datePicker"
              placeholder="시작일"
              disabledDate={disabledDate2}
              locale={locale}
              showToday={false}
              allowClear={false}
              onChange={dateHandler}
            />{" "}
            <CarStorageText2 color="true">
              {" "}
              &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
            </CarStorageText2>
            <DatePicker
              className="carStorage_datePicker"
              placeholder="종료일"
              locale={locale}
              showToday={false}
              allowClear={false}
              disabledDate={disabledDate}
              onChange={(datesStrings) =>
                setEndDate(moment(datesStrings._d).format("YYYY-MM-DD"))
              }
            />{" "}
          </Container>

          <CarStorageText3 margin="120px">3. 결제 정보</CarStorageText3>
          <CarStorageLine />

          <PayRadioGroup
            value={payMethod}
            onChange={(event) => setPayMethod(event.target.value)}
          >
            <Radio value="계좌이체">계좌이체</Radio>
            <Radio value="신용카드">신용카드</Radio>
          </PayRadioGroup>
          {payMethod === "계좌이체" ? (
            <>
              <CarStorageApplyLabel>은행명</CarStorageApplyLabel>
              <Select
                placeholder="은행 선택"
                className="carStorage_select"
                onChange={(value) => setBank(value)}
              >
                <Option value="국민은행">국민은행</Option>
                <Option value="신한은행">신한은행</Option>
                <Option value="우리은행">우리은행</Option>
              </Select>
              <CarStorageApplyLabel htmlFor="payer" autoComplete="off">
                입금자명
              </CarStorageApplyLabel>
              <CarStorageApplyInput
                placeholder="입금자명 입력"
                id="payer"
                value={payer}
                autoComplete="off"
                onChange={(event) => setPayer(event.target.value)}
              />
       
            </>
          ) : null}
        </CarStorageDiv>
        <CarStorageDropBoxDiv>
          <CarStorageText3 weight>최종 결제 금액</CarStorageText3>
          <CarStorageLine2 />
          <Container justify="space-between" margin="10px">
            <CarStorageText4>{option ? option : "옵션 가격"}</CarStorageText4>
            <CarStorageText4>
              {Number(price).toLocaleString("en")}원
            </CarStorageText4>
          </Container>
          <Container justify="space-between" margin="24px">
            <CarStorageText4>장수 </CarStorageText4>
            {quantity > 0 ? (
              <CarStorageText4>{quantity}장 </CarStorageText4>
            ) : (
              <CarStorageText4>- </CarStorageText4>
            )}
          </Container>
          <Container justify="space-between" margin="24px">
            <CarStorageText4>날짜 </CarStorageText4>
            {startDate && endDate ? (
              <CarStorageText4>
                {moment(startDate).format("YYYY.MM.DD")} &nbsp;~ &nbsp;
                {moment(endDate).format("YYYY.MM.DD")}
              </CarStorageText4>
            ) : (
              <CarStorageText4>-</CarStorageText4>
            )}
          </Container>
          <CarStorageLine2 />
          <Container justify="space-between" margin="24px">
            <CarStorageText2 style={{ fontWeight: 700, color: "#212529" }}>
              총 결제 금액
            </CarStorageText2>
            <NumberText>{Number(totalPrice).toLocaleString("en")}원</NumberText>
          </Container>
          <Container margin="50px" justify="center">
            <JoinCheckbox
              unactive={!agreementCheck}
              checked={agreementCheck}
              onChange={(event) => setAgreementCheck(event.target.checked)}
            />
            <CarStorageText4 style={{ marginTop: 2 }}>
              주문할 상품 및 결제에 확인 하였으며 동의합니다.
            </CarStorageText4>
          </Container>
          <Container margin="62px" justify="center">
            {payMethod === "계좌이체" ? (
              <CarStorageButton disabled={disabled} onClick={applyCarStorage}>
                결제하기
              </CarStorageButton>
            ) : (
              <CarStorageButton
                disabled={paymentButtonDisabled}
                onClick={payMent}
              >
                결제하기
              </CarStorageButton>
            )}
          </Container>
        </CarStorageDropBoxDiv>
      </CarStorageStepContentDiv>
    </CarStorageWrapDiv>
  );
};

export default CarStorageApply;
