import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageLiveAuction from "../../components/myPage/MyPageLiveAuction";

const DelearLiveAuction = () => {
  const position = false;
  const location = useLocation();
  return (
    <>
      <Header position={position}  borderactive={location.pathname.includes('/myPage')} />
      <MyPageLiveAuction />
    </>
  );
};

export default DelearLiveAuction;
