import Header from "../../components/Header";
import {
  SelfSellCarWrapDiv,
  SelfSellCarApplyTextDiv,
  SelfSellCarApplyLine2,
  SelfSellCarApplySuccessText,
  SelfSellCarApplyText,
  SelfSellCarApplyButton,
} from "../../components/selfSellCar/SelfSellCarStyle";
import { ReactComponent as Success } from "../../assets/img/successcheck.svg";
import { useNavigate } from "react-router";

const SelfSellCarApplySuccess = () => {
  const position = false;
  const selfSellCarText = true;
  const navigate = useNavigate();
  return (
    <SelfSellCarWrapDiv>
      <Header position={position} selfSellCarText={selfSellCarText} />
      <SelfSellCarApplyTextDiv>
        <SelfSellCarApplyText>셀프 셀카 신청 완료 </SelfSellCarApplyText>
        <SelfSellCarApplyLine2 />
        <Success style={{ marginTop: 200 }} />
        <SelfSellCarApplySuccessText>
          회원님의 셀프 셀카 서비스 신청이 <br />
          성공적으로 완료되었습니다.
        </SelfSellCarApplySuccessText>
        <SelfSellCarApplyButton
          style={{ marginTop: 220 }}
          onClick={() => navigate("/selfSellCarList")}
        >
          나의 신청 현황
        </SelfSellCarApplyButton>
      </SelfSellCarApplyTextDiv>
    </SelfSellCarWrapDiv>
  );
};

export default SelfSellCarApplySuccess;
