import styled from "@emotion/styled/macro";
import axios from "axios";
import { Modal } from "antd";
import { useState  , useEffect} from "react";
import { useNavigate } from "react-router";
import { JoinCheckbox } from "../login&join/Joinstyle";
import { SelfSellCarApplyButton } from "../../components/selfSellCar/SelfSellCarStyle";
import { useToken } from "../../hooks/useToken";
import { BASIC_URL } from "../CommonStyle";

const BidModalText = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.6px;
`;

const BidModalContainer = styled.div`
  width: 558px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const BidModalBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 60px;
`;

const BidModalLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: #ededed;
`;

const BidModalLabel = styled.label`
  font-size: 20px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  display: block;
  letter-spacing: -0.6px;
`;

const BidModalTermsofservice = styled.p`
  margin-top: ${(props) => props.margin && "22px"};
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #868e96;
`;

const BidModalInput = styled.input`
  width: 100%;
  height: 60px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  padding-left: 18px;
  font-size: 18px;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  margin-top: 20px;
  font-weight: 400;
  &:focus {
    border-color : #212529;
  }
  ::placeholder {
    color: #dee2e6;
  }
`;

const BidModalInputBox = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

const AgreementDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

const AgreementText = styled.p`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
`;

const BidButton = styled(SelfSellCarApplyButton)`
  margin-top: 0;
  margin-bottom: 50px;
`;

const CancelBidButton = styled(BidButton)`
  background-color: #212529;
  margin-right: 20px;
`;

export const BidModal = ({
  bidModalOpen,
  setBidModalOpen,
  id,
  bidModalInfo,
}) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const token = useToken();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  

  // 부재자 입찰 취소하기
  const cancelBid = () => {
    axios({
      url: `${BASIC_URL}/liveauction/me/absentee-bidding/${bidModalInfo?.id}`,
      method: "delete",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("취소 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 버튼 활성화
  useEffect(() => {
    if (name && phoneNumber && price && checked) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [phoneNumber, price, name, checked]);

  const applyBid = () => {
    axios({
      url: `${BASIC_URL}/liveauction/${id}/absentee-bidding`,
      method: "post",
      data: {
        name,
        price,
        phoneNumber,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("부재자 입찰 완료");
          navigate("/liveAuctionList");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateBid = () => {
    axios({
      url: `${BASIC_URL}/liveauction/me/absentee-bidding/${bidModalInfo?.id}`,
      method: "post",
      data: {
        name,
        price,
        phoneNumber,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("수정 완료");
          navigate("/liveAuctionList");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (bidModalInfo) {
      setName(bidModalInfo?.name);
      setPrice(bidModalInfo?.price);
      setPhoneNumber(bidModalInfo?.phoneNumber);
    }
  }, [bidModalInfo]);

  return (
    <Modal
      width={700}
      title="부재자 입찰"
      bodyStyle={{ height: 860, overflowY: "scroll" }}
      centered
      visible={bidModalOpen}
      onCancel={() => setBidModalOpen(false)}
      footer={null}
    >
      <BidModalContainer>
        <BidModalBox>
          <BidModalText>출품번호</BidModalText>
          <BidModalText>
            {bidModalInfo ? bidModalInfo?.auctionEntryNumber : id}
          </BidModalText>
        </BidModalBox>
        <BidModalLine />
        <BidModalInputBox>
          <BidModalLabel htmlFor="name">신청인</BidModalLabel>
          <BidModalInput
            placeholder="신청인 입력"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoComplete="off"
            autoFocus
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel htmlFor="price">신청 가격</BidModalLabel>
          <BidModalInput
            placeholder="신청 가격 입력"
            id="price"
            autoComplete="off"
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel htmlFor="phoneNumber">휴대폰 번호</BidModalLabel>
          <BidModalInput
            placeholder="(-) 없이 입력"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            autoComplete="off"
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel>이용 약관</BidModalLabel>
          <BidModalTermsofservice margin>
            • 낙찰가는 신청가격보다 낮거나 4만원 까지 높을 수 있습니다.
          </BidModalTermsofservice>
          <BidModalTermsofservice margin>
            • 부재자 응찰 후 성능점검 등 내용이 변경될 수 있습니다.
          </BidModalTermsofservice>
          <BidModalTermsofservice margin>
            • 경매 당일 마감 (오전11시) 이후 반드시 변경 내용을 재확인하여 주시기
            바랍니다.
          </BidModalTermsofservice>
          <BidModalTermsofservice margin>
            • 부재자 입찰 수정 및 취소는 경매 시작 30분(13시)까지 가능합니다.
          </BidModalTermsofservice>
        </BidModalInputBox>
        <AgreementDiv>
          <JoinCheckbox
            unactive={!checked}
            className="selfsellcar_checkbox"
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
          <AgreementText>이용약관을 확인하였으며 동의합니다.</AgreementText>
        </AgreementDiv>

        <AgreementDiv>
          {bidModalInfo ? (
            <>
              <CancelBidButton onClick={cancelBid}>취소하기</CancelBidButton>
              {bidModalInfo?.editable ? (
                <BidButton onClick={updateBid} disabled={buttonDisabled}>
                  수정하기
                </BidButton>
              ) : null}
            </>
          ) : (
            <BidButton onClick={applyBid} disabled={buttonDisabled} >
              입찰하기
            </BidButton>
          )}
        </AgreementDiv>
      </BidModalContainer>
    </Modal>
  );
};

export const PostConsultationModal = ({
  consultation,
  setConsultation,
  id,
}) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const token = useToken();
  const [checked, setChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // 버튼 활성화
  useEffect(() => {
    if (name && phoneNumber && price && checked) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [phoneNumber, price, name, checked]);

  const applyConsultation = () => {
    axios({
      url: `${BASIC_URL}/liveauction/${id}/after-consultation`,
      method: "POST",
      data: {
        name,
        price,
        phoneNumber,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("후 상담 신청 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      width={700}
      title="후 상담 신청"
      bodyStyle={{ height: 860, overflowY: "scroll" }}
      centered
      visible={consultation}
      onCancel={() => setConsultation(false)}
      footer={null}
    >
      <BidModalContainer>
        <BidModalBox>
          <BidModalText>출품번호</BidModalText>
          <BidModalText>1004</BidModalText>
        </BidModalBox>
        <BidModalLine />
        <BidModalInputBox>
          <BidModalLabel htmlFor="name">신청인</BidModalLabel>
          <BidModalInput
            placeholder="신청인 입력"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoComplete="off"
            autoFocus
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel htmlFor="price">신청 가격</BidModalLabel>
          <BidModalInput
            placeholder="신청 가격 입력"
            id="price"
            autoComplete="off"
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel htmlFor="phoneNumber">휴대폰 번호</BidModalLabel>
          <BidModalInput
            placeholder="(-) 없이 입력"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            autoComplete="off"
          />
        </BidModalInputBox>
        <BidModalInputBox>
          <BidModalLabel>이용 약관</BidModalLabel>
          <BidModalTermsofservice margin>
            • 후 상담 입찰은 출품자와 협의하여 판매 가능합니다.
          </BidModalTermsofservice>
          <BidModalTermsofservice margin>
            • 출품인이 상담 불가 / 반출 신청을 요청한 경우 차량은 상담 판매가 불가
            합니다.
          </BidModalTermsofservice>
          <BidModalTermsofservice margin>
            • 낙찰 / 출품 취소된 차량은 상담 판매가 불가합니다.
          </BidModalTermsofservice>
        </BidModalInputBox>
        <AgreementDiv>
          <JoinCheckbox
            className="selfsellcar_checkbox"
            checked={!checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
          <AgreementText>이용약관을 확인하였으며 동의합니다.</AgreementText>
        </AgreementDiv>

        <AgreementDiv>
          <BidButton disabled={buttonDisabled} onClick={applyConsultation}>
            입찰 하기
          </BidButton>
        </AgreementDiv>
      </BidModalContainer>
    </Modal>
  );
};
