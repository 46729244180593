import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import CarStorageMain from "../../assets/img/CarStorageImage.png";

export const CarStorageWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CarStorageImageDiv = styled.div`
  width: 100%;
  height: 741px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${CarStorageMain}");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CarStorageTextDiv = styled.div`
  width: 1080px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CarStorageStepDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 219px;
  margin-bottom: 200px;
`;

export const CarStorageStepContentDiv = styled.div`
  width: 1080px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const CarStorageDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarStorageContentDiv = styled.div`
  width: 708px;
  height: 163px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #868e96;
  &:nth-of-type(3n) {
    border-bottom: 2px solid #868e96;
  }

  position: relative;
`;

export const Container = styled.div`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.column && "column"};
  justify-content: ${(props) => props.justify};
  margin-top: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const AnimationDiv = styled.div`
  width: ${(props) => props.width};
  height: 50px;
  background-color: #ff6a13;
  border-radius: 24px;
  position: absolute;
  z-index: -1;
  left: 555px;
  transition: width 0.3s;
`;

export const CarStorageText = styled.p`
  font-family: SpoqaHanSansNeo;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 60px;
  color: #fff;
`;

export const CarStorageText2 = styled.p`
  font-family: SpoqaHanSansNeo;
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => (props.color ? "#868E96" : "#fff")};
  margin-top: ${(props) => props.margin};
  line-height: 30px;
  letter-spacing: -1px;
`;

export const CarStorageText3 = styled.p`
  font-family: SpoqaHanSansNeo;
  font-size: 24px;
  font-weight: ${(props) => (props.weight ? 700 : 400)};
  color: #212529;
  letter-spacing: -0.96px;
  line-height: 40px;
  margin-top: ${(props) => props.margin};
`;

export const CarStorageText4 = styled.span`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  font-weight: 400;
  letter-spacing: -0.72px;
  margin-left: ${(props) => props.margin && "38px"};
`;

export const CarStorageText5 = styled.p`
  font-size: 30px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  font-weight: 700;
  letter-spacing: -1.2px;
`;

export const CatStorageText6 = styled.p`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => props.color};
  font-weight: 500;
  letter-spacing: -0.9px;
  cursor: pointer;
  transition: color 0.5s;
`;

export const CarStorageText7 = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  margin-top: ${(props) => props.margin};
  color: #212529;
  cursor: pointer;
  letter-spacing: -0.64px;
`;

export const CarStorageEngText = styled.p`
  font-family: Poppins;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: -1.12px;
  color: #fff;
`;

export const CarStorageEngText2 = styled.p`
  letter-spacing: -0.8px;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
  color: #ff6a13;
`;

export const CarStorageEngText3 = styled.p`
  font-size: 30px;
  font-family: Poppins;
  font-weight: 600;
  color: #212529;
  letter-spacing: -1.2px;
`;

export const NumberText = styled.p`
  font-size: 28px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  color: #ff6a13;
  letter-spacing: -1.12px;
`;

export const CarStorageApplyLabel = styled.label`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 50px;
`;

export const CarStorageApplyInput = styled.input`
  width: 558px;
  height: 60px;
  outline: none;
  padding-left: 18px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;

  &:focus { 
    border-color : #212529;
  }

  ::placeholder {
    color: #dee2e6;
  }
`;

export const CarStorageDropBoxDiv = styled.div`
  width: 480px;
  height: 544px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px #adb5bd66;
  padding: 30px 23px;
  margin-top: 55px;
  position: sticky;
  top: 30%;
`;

export const CarStorageLine = styled.div`
  width: 558px;
  height: 2px;
  background-color: #dee2e6;
  margin-top: 20px;
`;

export const CarStorageLine2 = styled.div`
  width: 412px;
  height: 1px;
  background-color: #dee2e6;
  margin: 20px 0;
`;

export const CatStorageLine3 = styled.div`
  width: 1080px;
  height: 1px;
  background: #dee2e6;
  margin-top: 60px;
  margin-bottom: 70px;
`;
export const CarStorageButton = styled.button`
  width: 240px;
  height: 70px;
  color: #fff;
  border: none;
  font-size: 22px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.88px;
  background-color: #ff6a13;
  border-radius: 10px;
  cursor: pointer;

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  ${(props) =>
    props.transparent &&
    css`
      background: #fff;
      border: 1px solid #ff6a13;
      color: #ff6a13;
    `};
`;

export const CarStorageApplyTextDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

export const CarSelectDiv = styled.div`
  width: 558px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerDiv = styled.div`
  display: flex;
  cursor: pointer;
`;

export const CategoryVehicles = styled.div`
  width: 416px;
  height: 156px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 30px 23px;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  top: 85px;
  right: 0;
`;

export const CarStorageListDiv = styled.div`
  width: 522px;
  height: 170px;
  cursor: pointer;
  box-shadow: 0px 3px 15px #0000000d;
  border-radius: 10px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  &:nth-of-type(2n) {
    margin-left: 36px;
  }
`;

export const CarStorageListWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CarStorageListText = styled.p`
  font-size: 18px;
  color: #ff6a13;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  font-weight: 700;
`;

export const CarStorageListHeaderDiv = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 60px;
  border: none;
  padding: 0 17px;
  background-color: rgb(233, 236, 239, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CarStorageStatusText = styled.p`
  font-size: 16px;
  color: ${(props) => props.color};
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  font-weight: 500;
  &:nth-of-type(2n) {
    margin-left: 30px;
  }
`;

export const CarStorageStatusDiv = styled.div`
  display: flex;
  padding: 0 17px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 22px;
  flex-wrap: wrap;
`;

export const CarStorageStatusInnerDiv = styled.div`
  display: flex;
  &:nth-of-type(2n) {
    margin-left: 127px;
  }
`;

export const CatStorageStatusLine = styled.div`
  width: 490px;
  height: 1px;
  margin-top: 12px;
  background: #ededed;
`;

export const CatStorageApplyListWrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 200px;
`;

export const CarStorageImage = styled.img`
  width: 522px;
  height: 380px;
  border-radius: 10px;
  border: none;
  object-fit: cover;
`;

export const CarStorageSubImage = styled.img`
  width: 123px;
  height: 84px;
  border-radius: 5px;
  border: none;
  object-fit: cover;
  cursor: pointer;
  margin-right: 10px;
  &:focus,
  &:hover {
    border: 2px solid #ff7248;
  }
  &:nth-of-type(4n) {
    margin-right: 0;
  }
`;

export const CarStorageInfoText = styled.p`
  font-size: 20px;
  font-weight: ${(props) => (props.weight ? 700 : 500)};
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.8px;
  margin-top: ${(props) => props.margin};
`;

export const CarStorageInfoText2 = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.72px;
`;

export const CarStorageInfoText3 = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
  margin-left: 102px;
`;

export const CarStorageInfoDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  justify-content: space-between;
  align-items: ${(props) => props.align};
  margin-top: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginBottom};
  min-height: ${(props) => props.height};
`;

export const CarStorageCarInfoDiv = styled.div`
  width: 522px;
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  margin-top: ${(props) => props.margin};
`;

export const CarStorageInfoLine = styled.div`
  margin-top: 16px;
  width: 522px;
  height: 1px;
  background: #dee2e6;
`;

export const CarStorageInfoTableDiv = styled.div`
  width: 1080px;
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-top: ${(props) => props.margin};
`;

export const CarStorageInfoMenuDiv = styled.div`
  width: 20%;
  align-items: center;
  justify-content: center;
  height: 61px;
  display: flex;
  border-right: 1px solid #ededed;
`;

export const CarStorageBox = styled.div`
  width: 1080px;
  padding-top: 23px;
  padding-bottom: 21px;
  padding-left: 30px;
  height: 60px;
  box-shadow: 0px 3px 15px #adb5bd33;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

export const CarStorageBadge = styled.div`
  height: 26px;
  width: 100px;
  border-radius: 6px;
  border: 1px solid #ff6a13;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarStorageBadgeText = styled.p`
  font-size: 15px;
  letter-spacing: -0.6px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #ff6a13;
`;

export const CarStorageBadgeText2 = styled.p`
  font-size: 16px;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: ${(props) => props.color};
  margin-left: ${(props) => props.margin && "18px"};
`;

export const CarStorageImgText = styled.p`
  color: #868e96;
  font-size: 14px;
  letter-spacing: -0.56px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
`;

export const CarStorageImg = styled.div`
  width: 522px;
  height: 380px;
  border-radius: 10px;
  border: none;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
`;
