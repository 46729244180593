import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BASIC_URL } from "../../components/CommonStyle";
import Header from "../../components/Header";
import MyPageUserQuestion from "../../components/myPage/MyPageUserQuestion";
import { useToken } from "../../hooks/useToken";

const UserQuestion = () => {
  const position = false;
  const location = useLocation();

  // 1:1 문의 내역
  const token = useToken();
  const [qna, setQna] = useState();
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/qna`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data) {
        setQna(res.data);
      }
    });
    return () => setQna();
  }, [token]);
  return (
    <>
      <Header position={position} borderactive={location.pathname.includes('/userQuestion')} />
      <MyPageUserQuestion qna={qna} />
    </>
  );
};

export default UserQuestion;
