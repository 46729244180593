import moment from "moment";
import {
  SelfSellCarStepSubText,
  SelfSellCarCarImage,
  SelfSellCarInformationDiv,
  SelfSellCarInputContainer,
  SelfSellCarTypeText,
  SelfSellCarLine3,
  ErrorText,
  CarInfoDIv,
  CarInfoImage,
  CarInfoMenutDiv,
} from "../../../components/selfSellCar/SelfSellCarStyle";
import { ReactComponent as Logo } from "../../../assets/img/WhiteLogo.svg";

const CarInfo = ({ selfSellCarData }) => {
  return (
    <>
      <SelfSellCarInformationDiv>
        <SelfSellCarCarImage>
          {selfSellCarData?.carInform?.cover ? (
            <CarInfoImage
              src={selfSellCarData?.carInform?.cover.url}
              alt="car"
            />
           
          ) : (
            <Logo />
          )}
        </SelfSellCarCarImage>
        <SelfSellCarInputContainer>
          {selfSellCarData?.carInform?.carType ? (
            <SelfSellCarTypeText>
              {selfSellCarData?.carInform?.carType}
            </SelfSellCarTypeText>
          ) : (
            <SelfSellCarTypeText>
              상담원이 연락드릴 예정입니다.
            </SelfSellCarTypeText>
          )}
          <SelfSellCarLine3 />
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>차량 번호</SelfSellCarStepSubText>
            </CarInfoMenutDiv>

            <ErrorText font>{selfSellCarData?.carInform?.carNumber}</ErrorText>
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>주행 거리</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.distanceDriven ? (
              <ErrorText font>
                {Number(
                  selfSellCarData?.carInform?.distanceDriven
                ).toLocaleString("en")}
                km
              </ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>배기량</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.displacement ? (
              <ErrorText font>
                {Number(
                  selfSellCarData?.carInform?.displacement
                ).toLocaleString("en")}
                cc
              </ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>연식</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.year ? (
              <ErrorText font>{selfSellCarData?.carInform?.year}년형</ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>색상</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.color ? (
              <ErrorText font>{selfSellCarData?.carInform?.color}</ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>변속기</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.gearbox ? (
              <ErrorText font>{selfSellCarData?.carInform?.gearbox}</ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>연료</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.fuel ? (
              <ErrorText font>{selfSellCarData?.carInform?.fuel}</ErrorText>
            ) : (
              <ErrorText font>-</ErrorText>
            )}
          </CarInfoDIv>
          {/* {selfSellCarData?.status === "입고대기" ? (
            <CarInfoDIv>
              <CarInfoMenutDiv>
                <SelfSellCarStepSubText>탁송 예정일</SelfSellCarStepSubText>
              </CarInfoMenutDiv>
              {selfSellCarData?.carInform?.deliveryDueDate ? (
                <ErrorText font>
                  {moment(selfSellCarData?.carInform?.deliveryDueDate).format(
                    "YYYY-MM-DD"
                  )}
                </ErrorText>
              ) : (
                <ErrorText font>-</ErrorText>
              )}
            </CarInfoDIv>
          ) : null} */}
          <CarInfoDIv>
            <CarInfoMenutDiv>
              <SelfSellCarStepSubText>즉시 판매가</SelfSellCarStepSubText>
            </CarInfoMenutDiv>
            {selfSellCarData?.carInform?.nowSellPrice ? (
              <ErrorText font>
                {Number(
                  selfSellCarData?.carInform?.nowSellPrice
                ).toLocaleString("en")}
                원
              </ErrorText>
            ) : (
              <ErrorText font>점검 후 산정 예정</ErrorText>
            )}
          </CarInfoDIv>
        </SelfSellCarInputContainer>
      </SelfSellCarInformationDiv>
    </>
  );
};

export default CarInfo;
