import styled from "@emotion/styled/macro";

export const MainContentTextDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
`;

export const MainContentText = styled.span`
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -1.12px;
`;

export const MainContentText2 = styled.span`
  font-family: SpoqaHanSansNeo;
  font-size: 40px;
  font-weight: 700;
  margin-top: 23px;
  color: #fff;
  line-height: 60px;
  letter-spacing: -2px;
`;

export const MainContentText3 = styled.span`
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  line-height: 30px;
  letter-spacing: -1px;
  color: #fff;
`;

export const MainPickUpLinkDiv = styled.div`
  margin-top: 54px;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.9px;
  font-size: 18px;
  z-index: 999;
`;

export const CompanyOverViewDiv = styled.div`
  width: 100%;
  height: 1080px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6a13;
`;

export const CompanyOverViewContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
`;

export const ComplayOverViewTextDiv = styled.div`
  width: 1080px;
  display: flex;
  justify-content: space-between;
`;

export const CompanyOverViewText = styled.h1`
  font-size: 40px;
  font-family: SpoqaHanSansNeo;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 60px;
`;

export const CompanyOverViewText2 = styled.span`
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -1.12px;
`;

export const CompanyOverViewText3 = styled.p`
  margin-top: 12px;
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #fff;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  line-height: 25px;
`;

export const ApplicationButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 170px;
  height: auto;
`;

export const ApplicationButton = styled.button`
  width: 530px;
  height: 110px;
  border-radius: 10px 0;
  background-color: transparent;
  border: 1px solid #ff6a13;
  color: #ff6a13;
  cursor: pointer;

  &:hover {
    border: none;
    background-color: #212529;
    color: #fff;
  }
`;

export const ApplicationButtonText = styled.p`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.9px;
  font-family: SpoqaHanSansNeo;
`;

export const AutoOutletDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 242px;
  justify-content: center;
  align-items: center;
`;

export const AutoOutletText = styled.span`
  display: block;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -2px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

export const AutoOutletText2 = styled.span`
  margin-top: 20px;
  font-size: 18px;
  color: #212529;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -0.9px;
  font-family: SpoqaHanSansNeo;
  -webkit-line-clamp: 2;
  white-space: noraml;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutoOutletContentWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 190px;
`;

export const AutoOutletContentDiv = styled.div`
  width: 530px;
  height: 548px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 40px;

  &:nth-of-type(2n) {
    margin-left: 20px;
  }

  &:hover {
    border-color: #212529;
  }
`;

export const AutoOutletContentImgDiv = styled.div`
  width: 100%;
  position : relative;
  height: 397px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const AutoOutletTextDiv = styled.div`
  width: 100%;
  padding: 24px 20px 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutoOuteContentText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutoOuteContentText2 = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.8px;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
`;

export const MainReviewContainer = styled.div`
  margin-top: 16px;
  min-height: 46px;
  min-width: 468px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const TalgutReviewDiv = styled.div`
  width: 100%;
  height: 1060px;
  background-color: #ff6a13;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const TalgutReviewImgDiv = styled.div`
  width: 792px;
  height: 588px;
  position: absolute;
  left: 0;
  top: 360px;

  @media (max-width: 1441px) {
    width: 500px;
  }
`;

export const TalgutImg = styled.img`
  width: 100%;
`;

export const TalgutReviewContentWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TalgutReviewContentDiv = styled.div`
  width: 600px;
  height: 180px;
  background-color: #ffffff;
  border-radius: 20px 20px 0 20px;
  display: flex;
  padding: 30px 24px 0 24px;
  margin-bottom: 30px;
  cursor: pointer;
`;

export const TalgutReviewNumber = styled.p`
  font-size: 50px;
  font-weight: 700;
  color: #343a40;
  font-family: SpoqaHanSansNeo;
`;

export const TalgutReviewTextDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const TalgutReviewFooterDiv = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  justify-content: space-between;
`;

export const BoardDiv = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BoardWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 92px;
`;

export const BoardContentDiv = styled.div`
  width: 520px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  &:nth-of-type(2n) {
    margin-left: 40px;
  }
`;

export const BoardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoardTitleDiv = styled.div`
  width: 520px;
  height: 61px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
`;

export const BoardLine = styled.div`
  margin-top: 24px;
  width: 520px;
  height: 1px;
  border: 1px solid #343a40;
`;

export const BoardText = styled.span`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

export const BoardTitle = styled.span`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  margin: 20px 0;
  letter-spacing: -0.72px;
  cursor: pointer;
  font-weight: 400;
  color: #212529;
`;

export const LinkText = styled.div`
  margin-top: 30px;
  margin-left: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.9px;
  border-radius: 25px;
  width: 170px;
  height: 50px;
  position: relative;
  cursor: pointer;
  z-index: 99;

  ::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -18px;
    width: 48px;
    height: 48px;
    border-radius: 25px;
    transition: width 0.3s;
    z-index: -1;
    background-color: #212529;
  }
  :hover:before {
    width: 144px;
  }
`;

export const LinkText2 = styled.div`
  margin-top: 30px;
  color: #212529;
  margin-left: 20px;
  font-weight: 500;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.9px;
  border-radius: 25px;
  width: 170px;
  height: 50px;
  position: relative;
  cursor: pointer;
  z-index: 99;

  ::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -18px;
    width: 48px;
    height: 48px;
    border-radius: 25px;
    transition: width 0.3s;
    z-index: -1;
    background-color: #ff6a13;
  }
  :hover:before {
    width: 144px;
  }
  :hover {
    color: #ffff;
  }
`;

export const MainAutoOutletImage = styled.img`
  width: 100%;
  height : 100%;
  border-top-left-radius : 10px;
  border-top-right-radius : 10px;
  object-fit: cover; 
  
`;
