import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import useUserInfo, { useUserType } from "../../hooks/useUserInfo";

export const MyPageWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
`;

export const MyPageContentDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MyPageActiveListDiv = styled.div`
  width: 332px;
  padding-bottom: 30px;
  border-radius: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ededed;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  justify-content: flex-start;
  position: sticky;
  top: 20%;
`;

export const MyPageActiveDiv = styled.div`
  width: 708px;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const MyPageEngText = styled.span`
  font-size: 24px;
  font-weight: bold;
  font-family: Poppins;
  color: #212529;
`;

export const MyPagecategoryText = styled.p`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.96px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

export const MyPageText = styled.span`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
  ${(props) =>
    props.textdecoration &&
    css`
      text-decoration: underline;
      text-underline-position: under;
      font-weight: bold !important;
      cursor: pointer;
    `};
`;

export const UserType = styled.div`
  width: 68px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SpoqaHanSansNeo;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #ff6a13;
`;

export const MyPageLine = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid #868e96;
`;

export const MyPageListLine = styled.div`
  width: 290px;
  height: 1px;
  border: 1px solid #ededed;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const MyPageContentListDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.border &&
    css`
      height: 53px;
      margin-top: 30px;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      justify-content: flex-start;
    `};
`;

export const ContentDiv = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

const MyPageMenu = ({
  vehicleText,
  textdecoration,
  deliveryText,
  liveAuction,
  pickUpText,
  questionText,
  infoText,
  monthlyPayoutText,
}) => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const userType = useUserType();

  return (
    <MyPageActiveListDiv>
      <UserType style={{ marginBottom: 20 }}>{userType}</UserType>

      <MyPageText
        style={{ lineHeight: 1.5, cursor: "pointer" }}
        onClick={() => navigate("/myPage")}
      >
        안녕하세요!
        <br />
        {userInfo.name} 회원님
      </MyPageText>
      <MyPageListLine />
      <MyPageText style={{ fontSize: 18, fontWeight: 500, marginTop: 6 }}>
        {userType?.userData?.type === "기업회원"
          ? "픽업정비"
          : "나의 이용 내역"}
      </MyPageText>

      {userType === "딜러회원" ? (
        <MyPageText
          onClick={() => navigate("/myPageliveAuction")}
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 24,
            cursor: "pointer",
          }}
          textdecoration={liveAuction?.current}
        >
          라이브옥션
        </MyPageText>
      ) : null}

      {userType === "기업회원" ? null : (
        <MyPageText
          onClick={() => navigate("/myPageSelfSellCar")}
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 24,
            cursor: "pointer",
          }}
          textdecoration={textdecoration?.current}
        >
          셀프셀카
        </MyPageText>
      )}
      <MyPageText
        style={{
          fontSize: 16,
          fontWeight: 400,
          marginTop: 24,
          cursor: "pointer",
        }}
        onClick={() => navigate("/myPagePickUp")}
        textdecoration={pickUpText?.current}
      >
        {userType === "기업회원" ? "이용내역" : "픽업정비"}
      </MyPageText>
      {userType === "기업회원" ? (
        <MyPageText
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 24,
            cursor: "pointer",
          }}
          onClick={() => navigate("/monthlyPayout")}
          textdecoration={monthlyPayoutText?.current}
        >
          월별정산
        </MyPageText>
      ) : (
        <MyPageText
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 24,
            cursor: "pointer",
          }}
          onClick={() => navigate("/myPageVehicle")}
          textdecoration={vehicleText?.current}
        >
          차량보관
        </MyPageText>
      )}
      <MyPageListLine />
      <MyPageText style={{ fontSize: 18, fontWeight: 500 }}>
        회원 정보
      </MyPageText>
      <MyPageText
        style={{
          fontSize: 16,
          fontWeight: 400,
          marginTop: 30,
          cursor: "pointer",
        }}
        onClick={() => navigate("/myPageInfo")}
        textdecoration={infoText?.current}
      >
        회원 정보 수정
      </MyPageText>
      <MyPageText
        style={{
          fontSize: 16,
          fontWeight: 400,
          marginTop: 24,
          cursor: "pointer",
        }}
        textdecoration={deliveryText?.current}
        onClick={() => navigate("/myPageDelivery")}
      >
        배송지 관리
      </MyPageText>
      {userType === "일반회원" ? (
        <MyPageText
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 24,
            cursor: "pointer",
          }}
          onClick={() => navigate("/userType")}
        >
          딜러회원 신청
        </MyPageText>
      ) : null}
      <MyPageListLine />
      <MyPageText
        style={{ fontSize: 16, fontWeight: 400, cursor: "pointer" }}
        textdecoration={questionText?.current}
        onClick={() => navigate("/userQuestion")}
      >
        1:1 문의내역
      </MyPageText>
    </MyPageActiveListDiv>
  );
};

export default MyPageMenu;
