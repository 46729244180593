import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import { StepDiv, StepText } from "../../components/pick-up/PickUpMainStyle";
import successCheck from "../../assets/img/successcheck.svg";

const FindEmailCompleteWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SpoqaHanSansNeo;
`;

const FindEmailCompleteLine = styled.div`
  width: 600px;
  height: 2px;
  margin-top: 60px;
  background-color: #dee2e6;
`;

const FindEmailInfoDiv = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 209px 0 0 0;
  text-align: center;
`;

const FindEmailText = styled.span`
  font-size: 20px;
  color: #212529;
  font-weight: 400;
  line-height: 30px;
  font-family: SpoqaHanSansNeo;
`;

const FindEmailImg = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 32px;
`;

const ButtonDiv = styled.div`
  width: 516px;
  height: 70px;
  margin-top: 208px;
  margin-bottom: 141px;
  display: flex;
  justify-content: center;
`;

const FindPasswordButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  outline: none;
  background-color: #ff6a13;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.88px;
  border-radius: 10px;
  cursor: pointer;
`;

const PickUpApplySuccess = () => {
  const navigate = useNavigate();
  const position = false;

  return (
    <>
      <Header position={position} />
      <FindEmailCompleteWrapDiv>
        <StepDiv style={{ marginTop: 100 }}>
          <StepText>픽업정비 신청 완료</StepText>
        </StepDiv>
        <FindEmailCompleteLine />
        <FindEmailInfoDiv>
          <FindEmailImg src={successCheck} alt="check" />
          <FindEmailText>
            회원님의 픽업정비 서비스 신청이 <br />
            성공적으로 완료되었습니다.
          </FindEmailText>
        </FindEmailInfoDiv>
        <ButtonDiv>
          <FindPasswordButton onClick={() => navigate("/pickUpDetail")}>
            나의 신청 현황
          </FindPasswordButton>
        </ButtonDiv>
      </FindEmailCompleteWrapDiv>
    </>
  );
};

export default PickUpApplySuccess;
