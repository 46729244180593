import {
  AboutUsWrapDiv,
  AboutUsHeaderDiv,
  AboutUsEngText,
  AboutUsCircle,
  AboutUsText,
  AboutUsImageDiv,
  PhilosophyContentContainer,
  PhilosophyText,
  TextContainer,
  BasicCotainer,
  BusinessText,
  ImageDivContainer,
  AboutUsPartnetDiv,
  BusinessText2,
  BusinessContentWrapContainer,
  BusinessContentContainer,
  BusinessSubContentBox,
  BusinessContentBox,
  BusinessContainer,
  BusinessContentText2,
  BusinessContentText,
  PartnerText,
  HistoryWrapDiv,
  HistoryContent,
  HistoryYearText,
  HistoryMonthText,
  HistoryContentText,
  HistoryContainer,
  LogoImage,
  HistoryContentBox,
  HistoryTextBox,
  HistoryYear,
} from "../../components/aboutUs/AboutUsStyle";
import { useLocation } from "react-router";
import { ReactComponent as Arrow } from "../../assets/img/aboutUsArrow.svg";
import Hyundai from "../../assets/icons/Hyundai.png";
import AutoPlus from "../../assets/img/AutoPlus.png";
import Ucar from "../../assets/img/Ucar.png";
import Kcar from "../../assets/icons/Kcar.png";
import Header from "../../components/Header";
import { useEffect, useRef, useState } from "react";

const AboutUs = () => {
  const aboutUsText = 700;
  const position = false;
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const containerRef3 = useRef(null);
  const containerRef4 = useRef(null);
  const containerRef5 = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const [isInitialized2, setIsInitialized2] = useState(false);
  const [isInViewport2, setIsInViewport2] = useState(false);
  const [isInitialized3, setIsInitialized3] = useState(false);
  const [isInViewport3, setIsInViewport3] = useState(false);
  const [isInitialized4, setIsInitialized4] = useState(false);
  const [isInViewport4, setIsInViewport4] = useState(false);
  const [isInitialized5, setIsInitialized5] = useState(false);
  const [isInViewport5, setIsInViewport5] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setIsInViewport(entries[0].isIntersecting);
      },
      { root: document, threshold: 0.3 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    const observer2 = new IntersectionObserver(
      (entries) => {
        setIsInViewport2(entries[0].isIntersecting);
      },
      { root: document, threshold: 0.3 }
    );

    if (containerRef2.current) {
      observer2.observe(containerRef2.current);
    }
  }, []);

  useEffect(() => {
    const observer3 = new IntersectionObserver(
      (entries) => {
        setIsInViewport3(entries[0].isIntersecting);
      },
      { root: document, threshold: 0.3 }
    );

    if (containerRef3.current) {
      observer3.observe(containerRef3.current);
    }
  }, []);

  useEffect(() => {
    const observer4 = new IntersectionObserver(
      (entries) => {
        setIsInViewport4(entries[0].isIntersecting);
      },
      { root: document, threshold: 0.2 }
    );

    if (containerRef4.current) {
      observer4.observe(containerRef4.current);
    }
  }, []);

  useEffect(() => {
    const observer5 = new IntersectionObserver(
      (entries) => {
        setIsInViewport5(entries[0].isIntersecting);
      },
      { root: document, threshold: 0.1 }
    );

    if (containerRef5.current) {
      observer5.observe(containerRef5.current);
    }
  }, []);

  return (
    <AboutUsWrapDiv>
      <Header aboutUsText={aboutUsText} position={position} borderactive={location.pathname.includes("/aboutUs")} />
      <AboutUsHeaderDiv>
        <AboutUsEngText>Always there for your car</AboutUsEngText>
        <AboutUsText>
          자동차 생활에 필요한 순간마다 <br />
          “탈것”이 함께 합니다.
        </AboutUsText>
      </AboutUsHeaderDiv>
      <AboutUsImageDiv>
        <ImageDivContainer>
          <AboutUsEngText titletext>Philosophy</AboutUsEngText>

          <PhilosophyContentContainer
            ref={containerRef}
            play={isInViewport && isInitialized === false}
            onAnimationEnd={() => {
              setIsInitialized(true);
            }}
          >
            <BasicCotainer column>
              <PhilosophyText>40년 전통의 경력과 기술력</PhilosophyText>
              <AboutUsCircle />
              <PhilosophyText sub className="sub_text">
                소비자에게 믿을 수 있는 자동차 애프터 마켓 서비스 제공
              </PhilosophyText>
            </BasicCotainer>
            <TextContainer>
              <PhilosophyText sub>
                흔히들 ‘베테랑’이라고 평가할 때 단순히 그 사람의 유명세만으로
                이야기 하지 않습니다. 오랜 경력을 통해 쌓아온 기술력이 뒷받침될
                때에만 들을 수 있는 평가입니다. 고려자동차는 1979년 성도용
                초대회장에 의해 대구광역시 용산동에서 1,000평 규모의
                ‘고려자동차정비’서비스로 시작하여 1996년 성태근 2대 회장에 의해{" "}
                대구광역시 월암동에 5,000평 규모의 ‘고려자동차 경매장 및
                매매단지’를 설립하고 자동차 경매 및 매매 서비스를
                시작하였습니다. 지난 40년간 쌓아온 노하우를 바탕으로 브랜드
                ‘탈것’을 통해 전국 소비자들에게 믿을 수 있는 자동차 애프터 마켓
                서비스를 제공하고자 합니다.
              </PhilosophyText>
            </TextContainer>
          </PhilosophyContentContainer>

          <PhilosophyContentContainer
            ref={containerRef2}
            play={isInViewport2 && isInitialized2 === false}
            onAnimationEnd={() => {
              setIsInitialized2(true);
            }}
          >
            <BasicCotainer column>
              <PhilosophyText>우리는 업자가 아닌 매매 조력자</PhilosophyText>
              <AboutUsCircle style={{ right: -30 }} />
              <PhilosophyText sub className="sub_text">
                상품 가치 상승을 통해 고객과 ‘윈윈’하는 기업
              </PhilosophyText>
            </BasicCotainer>
            <TextContainer>
              <PhilosophyText sub>
                중고 자동차 매매 과정은 흩어져 있는 유통과정으로 인하여 불필요한
                비용이 발생하게 됩니다. 브랜드 ‘탈것’은 중고차 매매에 필요한
                모든 과정의 서비스를 제공하여 중고차 유통에서 발생하는 비용을
                최소화 하려고 노력합니다. 유통 과정에서 발생하는 비용은 상품의
                가치 상승 없이 고객들의 비용 부담을 증가시키기 때문입니다. 대신
                상품화(정비&튜닝) 과정을 통해 고객이 원하는 니즈를 만족시키고
                이를 통해 상품의 가치를 상승시켜 향후 고객들이 차량을 처분할
                경우에도 높은 가격을 받을 수 있도록 합니다. 고객과 ‘윈윈’을 통한
                이익 나눔이 브랜드 ‘탈것’의 또 하나의 철학입니다.
              </PhilosophyText>
            </TextContainer>
          </PhilosophyContentContainer>

          <PhilosophyContentContainer
            ref={containerRef3}
            play={isInViewport3 && isInitialized3 === false}
            onAnimationEnd={() => {
              setIsInitialized3(true);
            }}
          >
            <BasicCotainer column>
              <PhilosophyText>가격 결정권자는 결국 소비자다.</PhilosophyText>
              <AboutUsCircle style={{ right: -30 }} />
              <PhilosophyText sub className="sub_text">
                고객에게 더 좋은 거래 조건을 지원하는 기업
              </PhilosophyText>
            </BasicCotainer>
            <TextContainer>
              <PhilosophyText sub>
                수요와 공급이 시장 가격을 결정하는 기본 권리임에도 많은 제품들은
                공급자에 의해 가격이 결정되거나 상승하게 됩니다. 소비자들은
                공급자들이 과한 이익을 챙기는 건 아닌지, 제품 품질에 얼마나
                노력하는지 의심이 생기게 되지만, 어쩔 수 없이 이를 수용 할 수
                밖에 없는 경우가 있습니다. 브랜드 ‘탈것’은 고객이 가격을
                결정하도록 합니다. ‘탈것’은 차량의 가치는 높여주고, 발생되는
                비용은 최소화하여 판매자와 구매자가 합리적인 조건으로 거래할 수
                있도록 지원해드립니다. 소비자의 가격 결정권에서 시작하여 품질
                향상으로 이어지고 이것이 다시 높은 가격으 로 평가 받아 회사
                수익이 상승되는 선순환 구조를 만들고자 합니다.
              </PhilosophyText>
            </TextContainer>
          </PhilosophyContentContainer>
        </ImageDivContainer>
      </AboutUsImageDiv>
      <BusinessContainer>
        <BusinessText>Business</BusinessText>
        <BusinessContentWrapContainer
          margin="80px"
          ref={containerRef4}
          play={isInViewport4 && isInitialized4 === false}
          onAnimationEnd={() => {
            setIsInitialized4(true);
          }}
        >
          <BusinessContentContainer>
            <BusinessContentBox>
              <BusinessText2>One stop 상품화 서비스</BusinessText2>
              <BusinessContentText margin="33px">
                공정별로 분산 되어 있는 상품화 공정을<br />
                하나로 모아, 체계적으로 기획하고, 효율적 수행하여<br />
                투명하게 모니터링 합니다.
              </BusinessContentText>
            </BusinessContentBox>
            <Arrow />
            <BusinessSubContentBox>
              <BusinessContentText2>
                정확한 차량진단
              </BusinessContentText2>
            </BusinessSubContentBox>
            <BusinessSubContentBox>
              <BusinessContentText2>
                상품화 공정의 표준화
              </BusinessContentText2>
            </BusinessSubContentBox>
            <BusinessSubContentBox>
              <BusinessContentText2>
                자동차의 가치 상승
              </BusinessContentText2>
            </BusinessSubContentBox>
          </BusinessContentContainer>
          <BusinessContentContainer>
            <BusinessContentBox>
              <BusinessText2>차량 매매 지원 서비스</BusinessText2>
              <BusinessContentText margin="33px">
                차량 거래를 위한 노하우와 서비스를 지원하여<br />
                유통에 발생하는 비용과 수고를 최소화합니다.
              </BusinessContentText>
            </BusinessContentBox>
            <Arrow />
            <BusinessSubContentBox>
              <BusinessContentText2>
                편리한 거래 시스템
              </BusinessContentText2>
            </BusinessSubContentBox>
            <BusinessSubContentBox>
              <BusinessContentText2>
                경쟁적인 매매환경
              </BusinessContentText2>
            </BusinessSubContentBox>
            <BusinessSubContentBox>
              <BusinessContentText2>
                신속한 거래 진행
              </BusinessContentText2>
            </BusinessSubContentBox>
          </BusinessContentContainer>
          <BusinessContentContainer>
            <BusinessContentBox>
              <BusinessText2>탁송 및 보관 서비스</BusinessText2>
              <BusinessContentText margin="44px">
                차량 보관을 위한 공간과<br />
                필요한 장소로의 이동을 지원합니다.
              </BusinessContentText>
            </BusinessContentBox>
            <Arrow />
            <BusinessSubContentBox>
              <BusinessContentText2>
                전문적인 차량 보관 거점 간 이동
              </BusinessContentText2>
            </BusinessSubContentBox>
            <BusinessSubContentBox>
              <BusinessContentText2>
                공급자와 소비자간 탁송 진행
              </BusinessContentText2>
            </BusinessSubContentBox>
          </BusinessContentContainer>
        </BusinessContentWrapContainer>
        <BusinessText margin="270px">Partner</BusinessText>
        <PartnerText className="partner_text">
          ‘탈것’은 중고차 시장을 이끄는 리더들과 함께합니다.
        </PartnerText>
        {/* 파트너 로고 */}
        <BusinessContentWrapContainer
          margin="100px"
          ref={containerRef5}
          play={isInViewport5 && isInitialized5 === false}
          onAnimationEnd={() => {
            setIsInitialized5(true);
          }}
        >
          <AboutUsPartnetDiv>
            <LogoImage src={Hyundai} alt="logo" />
          </AboutUsPartnetDiv>
          <AboutUsPartnetDiv>
            <LogoImage src={Kcar} alt="logo" />
          </AboutUsPartnetDiv>
          <AboutUsPartnetDiv>
            <LogoImage src={AutoPlus} alt="logo" />
          </AboutUsPartnetDiv>
          <AboutUsPartnetDiv>
            <LogoImage src={Ucar} alt="logo" />
          </AboutUsPartnetDiv>
        </BusinessContentWrapContainer>
        <BusinessText margin="300px">History</BusinessText>
        <HistoryWrapDiv>
          <HistoryContainer>
            <HistoryYear href="#history_2023">2023</HistoryYear>
            <HistoryYear href="#history_2022">2022</HistoryYear>
            <HistoryYear href="#history_2018">2018</HistoryYear>
            <HistoryYear href="#history_2017">2017</HistoryYear>
            <HistoryYear href="#history_2013">2013</HistoryYear>
            <HistoryYear href="#history_2004">2004</HistoryYear>
            <HistoryYear href="#history_2002">2002</HistoryYear>
            <HistoryYear href="#history_1998">1998</HistoryYear>
            <HistoryYear href="#history_1996">1996</HistoryYear>
            <HistoryYear href="#history_1992">1992</HistoryYear>
            <HistoryYear href="#history_1989">1989</HistoryYear>
            <HistoryYear href="#history_1979">1979</HistoryYear>
          </HistoryContainer>
          <HistoryContentBox>
            <HistoryContent id="history_2023">
              <HistoryYearText>2023</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  H사 인증 중고차 우선 협력업체 선정
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  S사 인증 중고차 우선 협력업체 선정
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_2022">
              <HistoryYearText>2022</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  온라인 전용 판매&매입&정비 시스템 런칭
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>3월</HistoryMonthText>
                <HistoryContentText>
                  ‘탈것’ 자동차 매매 복합지원 센터 TAPO 이천 오픈
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent>
              <HistoryYearText id="history_2018">2018</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>3월</HistoryMonthText>
                <HistoryContentText>
                  탈것 인증경매장 경기 용인 1호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>2월</HistoryMonthText>
                <HistoryContentText>
                  현대캐피탈 인증중고차 고려자동차 경기 2호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_2017">
              <HistoryYearText>2017</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>11월</HistoryMonthText>
                <HistoryContentText margin>
                  탈것 인증경매장 경기 용인 1호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox margin>
                <HistoryMonthText>10월</HistoryMonthText>
                <HistoryContentText margin>
                  자동차 애프터마켓 서비스 통합 브랜드 ‘탈것’ 런칭
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox margin>
                <HistoryMonthText>9월</HistoryMonthText>
                <HistoryContentText>
                  업계 최초 360도 자동차 실내외 촬영장 오픈
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>2월</HistoryMonthText>
                <HistoryContentText>
                  현대캐피탈 인증중고차 고려자동차 대구1호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_2013">
              <HistoryYearText>2013</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>7월</HistoryMonthText>
                <HistoryContentText>현대캐피탈 선물사 계약</HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_2004">
              <HistoryYearText>2004</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  업계 최초 철근식 자동차 주차 타워 건설
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent>
              <HistoryYearText id="history_2002">2002</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>10월</HistoryMonthText>
                <HistoryContentText margin>
                  자동차 경매 전산화 실시
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_1998">
              <HistoryYearText>1998</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>9월</HistoryMonthText>
                <HistoryContentText>
                  대구 자동차경매장 성능검사소 오픈
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_1996">
              <HistoryYearText>1996</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>7월</HistoryMonthText>
                <HistoryContentText>대구자동차경매장 오픈</HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent>
              <HistoryYearText>1994</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  고려자동차 중고차 매매단지 오픈
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>

            <HistoryContent id="history_1992">
              <HistoryYearText>1992</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  고려자동차 정비공장 대구 2호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>3월</HistoryMonthText>
                <HistoryContentText>
                  고려자동차 주식회사 전환
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_1989">
              <HistoryYearText>1989</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>1월</HistoryMonthText>
                <HistoryContentText>
                  고려자동차 법인회사 전환
                </HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
            <HistoryContent id="history_1979">
              <HistoryYearText>1979</HistoryYearText>
              <HistoryTextBox margin style={{ marginTop: 43 }}>
                <HistoryMonthText>5월</HistoryMonthText>
                <HistoryContentText>
                  고려자동차 정비공장 대구 1호점 오픈
                </HistoryContentText>
              </HistoryTextBox>
              <HistoryTextBox>
                <HistoryMonthText>3월</HistoryMonthText>
                <HistoryContentText>고려자동차 회사 설립</HistoryContentText>
              </HistoryTextBox>
            </HistoryContent>
          </HistoryContentBox>
        </HistoryWrapDiv>
      </BusinessContainer>
    </AboutUsWrapDiv>
  );
};

export default AboutUs;
