import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

export const PickUpStepWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SpoqaHanSansNeo;
`;

export const PickUpStepHeaderDiv = styled.div`
  width: 570px;
  height: auto;
  margin: 80px auto 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PickUpStepHeaderTitle = styled.span`
  font-family: SpoqaHanSansNeo;
  font-size: 30px;
  font-weight: 500;
  color: #212529;
  letter-spacing: -1.2px;
`;

export const PickUpCheckImg = styled.img`
  width: 32px;
  height: 32px;
`;

export const PickUpStepHeaderSubTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
`;

export const PickUpOptionText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  cursor: ${(props) => props.cursor};

  span {
    color: #ff6a13;
    font-weight: 500;
  }
`;

export const PickUpStepFooterText = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
`;

export const PickUpCheckText = styled.p`
  font-size: 24px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  color: #212529;
  letter-spacing: -0.96px;
`;

export const PickUpCheckSubText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #868e96;
  letter-spacing: -0.8px;
  margin-left: 30px;
`;

export const ResultText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  color: #212529;
  letter-spacing: -0.8px;
  margin-left: 24px;
  margin-top: 30px;
`;

export const PickupContainer = styled.div`
  display: flex;
`;

export const PickUpStepHeaderText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  letter-spacing: -0.64px;
  line-height: 25px;
  font-family: SpoqaHanSansNeo;
`;

export const StepsDiv = styled.div`
  width: 1080px;
  margin: 72px auto 0 auto;
  display: flex;
  justify-content: center;
`;

export const StepText = styled.span`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.72px;
  color: #ff6a13;
`;

export const StepContentDiv = styled.div`
  width: 1080px;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UserCarImgDiv = styled.div`
  width: 522px;
  height: 420px;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  &:nth-of-type(2n) {
    margin-left: 38px;
  }
`;

export const CheckCarImgDiv = styled.div`
  width: 666px;
  height: 420px;
  border: none;
  border-radius: 10px 0 0 10px;
  background-color: transparent;
`;

export const UserCarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  border: none;
  object-fit: cover;
`;

export const UserCarDiv = styled.div`
  width: 100%;
  height: 420px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
`;

export const StepContentTItle = styled.span`
  font-family: SpoqaHanSansNeo;
  color: #212529;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.96px;
`;

export const StepContentLine = styled.div`
  width: 522px;
  height: 2px;
  border: 1px solid #dee2e6;
  margin-top: 10px;
`;

export const StepContentTextDiv = styled.div`
  width: 522px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

export const ContentTextDiv = styled.div`
  width: 170px;
  text-align: left;
  margin-right: 45px;
`;

export const ContentTextDiv2 = styled.div`
  width: 322px;
  text-align: left;
`;

export const PickUpStepLine = styled.div`
  width: 1080px;
  height: 2px;
  border: 1px solid #dee2e6;
  margin: 22px auto 0 auto;
`;

export const TabsDiv = styled.div`
  width: 1080px;
  margin: 70px auto 0 auto;
`;

export const CarDescDiv = styled.div`
  background-color: #fafafa;
  width: 414px;
  height: 420px;
  display: flex;
  flex-direction: column;
  border-radius: 0 10px 10px 0;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 3px 6px #0000000d;
`;

export const MarkImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const DescriptionImg = styled.img`
  width: 300px;
  height: 120px;
  position: absolute;
  top: -120px;
  left: 0;
`;

export const OptionWrapDiv = styled.div`
  width: 1080px;
  margin-top: 60px;
  display: flex;
  position: relative;
`;

export const OptionContenstDiv = styled.div`
  display: inline-block;
  width: 630px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
`;

export const OptionContentDiv = styled.div`
  width: 100%;
  height: auto;
  background: #f8f9fa;
  padding-bottom: 30px;
  border: none;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const PayDiv = styled.div`
  width: 414px;
  height: 100%;
  min-height: 650px;
  background-color: #fff;
  box-shadow: 0 3px 15px rgb(173, 181, 189, 0.4);
  margin-left: 36px;
  padding: 30px 30px;
  border-radius: 10px;
  position: sticky;
  z-index: 999;
  top: 20%;

  @media (max-height: 761px) {
    top: 1%;
  }
`;

export const PayTextDiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
`;

export const OptionTextDiv = styled.div`
  display: flex;
  width: 560px;
  margin-left: 40px;
  margin-top: 38px;
  margin-right: 40px;
  align-items: center;
`;

export const OptionLine = styled.div`
  width: 550px;
  height: 1px;
  border: 1px solid #ededed;
  margin: 19px 0 0 0;
`;

export const PayButton = styled.button`
  border: none;
  outline: none;
  width: 240px;
  height: 70px;
  border-radius: 10px;
  background-color: #ff6a13;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  font-weight: 600;
  &:disabled {
    background-color: #e9ecef;
    font-weight: 500;
  }
`;

export const PayInfoWrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const PayInfoDiv = styled.div`
  width: 20%;
  height: 61px;
  border: 1px solid #e8e8e8;
  text-align: center;
  padding-top: 18px;
`;

export const PayInfoDiv2 = styled.div`
  width: 80%;
  height: 61px;
  border: 1px solid #e8e8e8;
  border-left: none;
  padding: 18px 0 0 40px;
`;

export const ResultImg = styled.img`
  width: 530px;
  height: 320px;
  border-radius: 10px;
  border: none;
  background-color: #808080;
  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;

export const SelectOptionText = styled.span`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  flex: 1;
  color: #212529;
`;

export const ReviewTextArea = styled.textarea`
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-family: SpoqaHanSansNeo;
  resize: none;
  padding: 16px;
  font-size: 18px;
  letter-spacing: -0.72px;
  line-height: 22px;
  font-weight: 400;
  outline: none;
  &::placeholder {
    color: #dee2e6;
  }
`;

export const ReviewDiv = styled.textarea`
  width: 100%;
  height: 190px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  font-family: SpoqaHanSansNeo;
  outline: none;
  padding: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -0.72px;
  color: #212529;
  background-color: #f8f9fa;
`;

export const PickUpStepTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PickUpStepBarDiv = styled.div`
  margin-top: 60px;
  display: flex;
`;

export const PickUpStepTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
`;

export const StepBarText = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #ff6a13;
  margin-top: 14px;

  ${(props) =>
    props.done &&
    css`
      color: #ffd0b8;
    `};
`;

export const StepBarText2 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
  margin-top: 14px;
`;

export const PayHistoryText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: #212529;
  margin-top: 70px;
`;

export const PayHistoryText2 = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #ff6a13;

  ${(props) =>
    props.day &&
    css`
      color: #868e96;
    `};
`;
