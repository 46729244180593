import styled from "@emotion/styled/macro";
import Header from "../../components/Header";

const DisabledMenuWrapDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisableMenuDiv = styled.div`
  width: 457px;
  margin: 16% auto;
  text-align: center;
`;

const DisabledMenuText = styled.p`
  font-size: 40px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -2px;
  color: #ff6a13;
`;

const DisabledMenuText2 = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin-top: 20px;
  color: #212529;
`;

const DisabledMenu = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <DisabledMenuWrapDiv>
        <DisableMenuDiv>
          <DisabledMenuText>Not Available</DisabledMenuText>
          <DisabledMenuText2>
            기업 회원은 해당 서비스를 이용 하실 수 없습니다.
          </DisabledMenuText2>
        </DisableMenuDiv>
      </DisabledMenuWrapDiv>
    </>
  );
};

export default DisabledMenu;
