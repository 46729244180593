import Header from "../../components/Header";
import MainuserReviewStyle from "../../components/main/MainuserReviewStyle";

const MainUserReview = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <MainuserReviewStyle />
    </>
  );
};

export default MainUserReview;
