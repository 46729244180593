import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

export const AutoOutleWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AutoOutletContentDiv = styled.div`
  margin-top: 103px;
  width: 1080px;
  min-height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AutoOutletFilterDiv = styled.div`
  width: 336px;
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
`;

export const AutoOutletCarInfoDiv = styled.div`
  width: 709px;
  display: flex;
  flex-direction: column;
  min-height: 2000px;
  cursor: pointer;
`;

export const AutoOutletCarImageDiv = styled.div`
  border: none;
  background-color: #f8f9fa;
  width: 260px;
  height: 162px;
  border-radius: 10px;
`;

export const AutoOutletCarImage = styled.img`
  border: none;
  width: 260px;
  border-radius: 10px;
  height: 162px;
  object-fit: cover;
`;

export const AutoOuteltContainer = styled.div`
  /* display: flex; */
  display: ${props => props.active ? 'none' : 'flex'};
  width: 100%;
  height: ${(props) => props.height};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.column && "column"};
  cursor: ${(props) => props.cursor};
  margin-top: 12px;
`;

export const AutoOutletBadge = styled.div`
  width: 84px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #ff6a13;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AutoOutletBadgeText = styled.p`
  font-size: 14px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #ff6a13;
  letter-spacing: -0.42px;
`;
export const AutoOutletText = styled.label`
  font-size: 16px;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
  ${(props) =>
    props.cursorOn &&
    css`
      cursor: pointer;
    `};
`;

export const AutoOutletText2 = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => (props.color ? props.color : "#212529")};
  letter-spacing: -0.72px;
`;

export const AutoOutletText3 = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;
`;

export const AutoOutletLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 4px;
  background-color: #ededed;
`;

export const AutoOutletLine2 = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 40px;
  background-color: #868e96;
`;

export const AutoOutletInput = styled.input`
  width: 336px;
  height: 40px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-size: 16px;
  letter-spacing: -0.64px;
  padding-left: 10px;
  margin-top: ${(props) => props.margin};

  ::placeholder {
    color: #dee2e6;
  }
`;

export const AutoOutletCalculator = styled.div`
  width: 300px;
  margin-left: 60px;
  height: 445px;
  box-shadow: 0px 3px 15px #adb5bd66;
  border-radius: 10px;
  background-color: #fff;
  position: sticky;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
`;

export const AutoOutletCarName = styled.p`
  font-size: 28px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.12px;
`;

export const AutoOutletPrice = styled.p`
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #ff6a13;
  letter-spacing: -1.36px;
`;

export const AutoOutletDetailDiv = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AutoOutletDetailHeaderDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  margin-left: 360px;
`;

export const AutoOutletDetailFlexDiv = styled.div`
  display: flex;
  margin-top: ${(props) => props.margin};
  flex-direction: ${(props) => props.column && "column"};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`;

export const AutoOutletDetailCarImageDiv = styled.div`
  width: 1080px;
  height: 520px;
  border-radius: 10px;
  margin-top: 50px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const AutoOutletSmallCarDiv = styled.div`
  margin-top: 30px;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
`;

export const AutoOutletDetailCarSmallimg = styled.div`
  width: 98px;
  height: 70px;
  background-color: #f8f9fa;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  :focus {
    border: 1px solid #fd6a21;
  }
  :hover {
    border: 1px solid #fd6a21;
  }

  :after {
    content: "";
    margin-top: 120px;
    width: 108px;
    height: 2px;
    background: #dee2e6;
    position: absolute;
  }
  :hover:after {
    background: #fd6a21;
  }
`;

export const SmallCarImage = styled.img`
  width: 89px;
  height: 43px;
  object-fit: cover;
  border: none;
`;

export const CarImage = styled.img`
  width: 1080px;
  height: 100%;
  object-fit: cover;
  border: none;
`;

export const AdvancePayment = styled.input`
  width: 140px;
  height: 29px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #dee2e6;
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  padding-left: 55px;
  padding-right: 5px;
  &:focus {
    border-color: #212529;
  }
`;

export const AutoOutletTelePhoneDiv = styled.div`
  width: 240px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: rgb(233, 236, 239, 0.2);
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AutoOutletMainInput = styled.input`
  outline: none;
  margin-top: 12px;
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.64px;
  color: #212529;
  padding: 0 10px;

  ::placeholder {
    color: #dee2e6;
  }
`;

export const InputContainer = styled.div`
  width: 336px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AutoOutletMainText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #dee2e6;
`;

export const OutletCarModelDiv = styled.div`
  width: 336px;
  height: 280px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0 20px;
  border: none;
`;

export const OutletMainMenuDiv = styled.div`
  width: 150px;
  height: 243px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
`;

export const AutoOutletFilterButton = styled.button`
  width: 336px;
  height: 70px;
  border-radius: 10px;
  background-color: #ff6a13;
  color: #fff;
  font-size: 22px;
  font-family: SpoqaHanSansNeo;
  font-weight: 600;
  letter-spacing: -0.96px;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }
`;

export const AutoOutletDetailCarModelDiv = styled.div`
  width: 336px;
  margin-top: 17px;
  height: 280px;
  border: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-right: 15px;
`;

export const DisabledLiveAuctionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// 디테일 페이지 component
export const OutletDetailContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 220px;
`;

export const OutletDetailCarInfoWrapDiv = styled.div`
  width: 708px;
  display: flex;
  flex-direction: column;
`;

export const OutletDetialTitleDiv = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
`;

export const OutletDetailContainer2 = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #ededed;
  z-index: 99;
  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #ededed;
    `};
`;

export const OutletDetailTitleContainer = styled.div`
  width: 160px;
  height: 71px;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OutletDetailContentContainer = styled.div`
  width: 200px;
  height: 71px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

export const OutletDetailLine = styled.div`
  width: 300px;
  height: 2px;
  background-color: #868e96;
  margin-bottom: 21px;
`;

export const OutletDetailText = styled.p`
  font-size: 24px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  color: #212529;
`;

export const OutletDetailText2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
  ${(props) =>
    props.weight &&
    css`
      font-weight: 500;
    `};
`;

export const OutletDetailText3 = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
`;
export const OutletDetailText4 = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
`;

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailKeyPotintDiv = styled.div`
  width: 708px;
  height: 60px;
  border-radius: 12px;
  border: 1px solid #ff6a13;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 22px;
  margin-bottom: 16px;
`;

export const KeyPointIndex = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  background-color: #ff6a13;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
`;

export const IndexText = styled.p`
  font-size: 14px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #fff;
`;

export const CarDetailOptionWrapDiv = styled.div`
  width: 708px;
  padding: 0 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(255, 208, 184, 0.2);
`;

export const VehiclesAllOptionDiv = styled.div`
  display: flex;
  width: 708px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 0;
`;

export const CarDetailOptionDiv = styled.div`
  width: 210px;
  margin-bottom: 18px;
  margin-right: 9px;
  cursor: pointer;
`;

export const CarDetailOptionLine = styled.div`
  width: 648px;
  height: 2px;
  background-color: #fff;
`;

export const CarMainOptionContainer = styled.div`
  width: 708px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 38px;
    `};
`;

export const CarMainOptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.marginright &&
    css`
      margin-right: 0;
    `};
`;

export const Description = styled.div`
  display: none;
  text-align: left;
  width: 416px;
  height: 210px;
  position: absolute;
  top: 100px;
  padding: 30px 23px;
  color: #212529;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  z-index: 99;
`;

export const CarMainOptionText = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  line-height: 24px;
  color: #212529;
  letter-spacing: -0.64px;
  cursor: pointer;
  position: relative;

  &:hover > div {
    display: block;
  }

  ${(props) =>
    props.colorchange &&
    css`
      color: #adb5bd;
    `};
`;
