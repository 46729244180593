import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Collapse } from "antd";
import { useEffect, useRef, useState } from "react";
import { useUserType } from "../../hooks/useUserInfo";
import { DeleteQnaModal, InquireModal } from "./MyPageModal";
import MyPageMenu, {
  MyPageContentDiv,
  MyPageWrapDiv,
  MyPageText,
  MyPageActiveDiv,
} from "./MyPageMenu";
import { MyPageLine, Container } from "./MyPageUpdateInfoStyle";
import { MyPageImg, DeliveryListModal } from "./MyPageDeliveryListStyle";
import { MyPageSubText } from "./MyPagePickUpStyle";
import myPageImg from "../../assets/img/myPageImg.png";
import "./myPage.css";

const QnaText = styled.span`
  font-size: 18px;
  letter-spacing: -0.72px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  word-break: break-all;
  line-height: 28px;
  ${(props) =>
    props.font &&
    css`
      color: #868e96;
      font-size: 16px;
      letter-spacing: -0.64px;
      cursor: pointer;
    `};
`;

const QnaDiv = styled.div`
  width: 668px;
  height: 75px;
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

const MyPageUserQuestion = ({ qna }) => {
  const { Panel } = Collapse;
  const userType = useUserType();
  const questionText = useRef(false);
  useEffect(() => {
    questionText.current = true;

    return () => (questionText.current = false);
  }, [questionText]);

  // 1:1 문의 내역 모달
  const [openInquireModal, setOpenInquireModal] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [qnaId, setQnaId] = useState(0);
  const [qnaTitle, setQnaTitle] = useState("");
  const [qnaType, setQnaType] = useState("");
  const [qnaQuestion, setQnaQuestion] = useState("");

  // 1:1 문의 내역 리스트
  const qnaList = qna?.qna?.map((item) => {
    const header = `[${item?.type}] ${item?.title}`;

    return (
      <Collapse key={item.id} accordion expandIconPosition="right">
        <Panel header={<QnaText>{header}</QnaText>}>
          <QnaDiv>
            <QnaDiv>
              <QnaText
                style={{
                  fontWeight: 700,
                  color: "#FF6A13",
                  fontSize: 16,
                  marginRight: 8,
                }}
              >
                Q.
              </QnaText>
              <QnaText style={{ fontWeight: 400 }}>{item?.question}</QnaText>
            </QnaDiv>
          </QnaDiv>
          {item.answer === null ? (
            <QnaDiv
              style={{
                marginTop: 28,
                marginRight: 18,
                justifyContent: "flex-end",
              }}
            >
              <QnaText
                font
                onClick={() => {
                  setQnaId(item?.id);
                  setQnaQuestion(item?.question);
                  setQnaTitle(item?.title);
                  setQnaType(item?.type);
                  setOpenInquireModal(true);
                }}
              >
                수정
              </QnaText>
              <QnaText
                font
                onClick={() => {
                  setDeleteModalOpen(true);
                  setQnaId(item.id);
                }}
              >
                &nbsp; | &nbsp;삭제
              </QnaText>
            </QnaDiv>
          ) : (
            <QnaDiv style={{ marginLeft: 18, marginTop: 11 }}>
              <QnaText
                style={{
                  fontWeight: 700,
                  color: "#FF6A13",
                  fontSize: 16,
                  marginRight: 8,
                }}
              >
                A.
              </QnaText>
              <QnaText style={{ fontWeight: 400 }}>{item?.answer}</QnaText>
            </QnaDiv>
          )}
        </Panel>
      </Collapse>
    );
  });

  return (
    <MyPageWrapDiv>
      <DeleteQnaModal
        setDeleteModalOpen={setDeleteModalOpen}
        deleteModalOpen={deleteModalOpen}
        qnaId={qnaId}
      />
      <InquireModal
        qnaId={qnaId}
        qnaTitle={qnaTitle}
        qnaQuestion={qnaQuestion}
        qnaType={qnaType}
        openInquireModal={openInquireModal}
        setOpenInquireModal={setOpenInquireModal}
      />
      <MyPageContentDiv>
        <MyPageMenu userType={userType} questionText={questionText} />
        <MyPageActiveDiv>
          <Container
            style={{ justifyContent: "space-between" }}
            center="center"
          >
            <MyPageText>1:1 문의 내역</MyPageText>
            <MyPageText
              style={{
                fontWeight: 500,
                fontSize: 17,
                textDecoration: "underline",
                textUnderlinePosition: "under",
                cursor: "pointer",
              }}
              onClick={() => setOpenInquireModal(true)}
            >
              1:1 문의하기{" "}
            </MyPageText>
          </Container>
          <MyPageLine style={{ marginBottom: 12 }} />
          {qna?.qna?.length === 0 ? (
            <>
              <DeliveryListModal>
                <MyPageImg src={myPageImg} alt="myPage" />
                <MyPageSubText color="true">
                  문의하신 내역이 없습니다.
                </MyPageSubText>
              </DeliveryListModal>
              <MyPageSubText font2 onClick={() => setOpenInquireModal(true)}>
                1:1 문의 하기
              </MyPageSubText>{" "}
            </>
          ) : (
            qnaList
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};

export default MyPageUserQuestion;
