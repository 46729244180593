import emotionReset from "emotion-reset";
import { css } from "@emotion/react";
import Medium from "../assets/fonts/SpoqaHanSansNeo-Medium.otf";
import Light from "../assets/fonts/SpoqaHanSansNeo-Light.otf";
import Bold from "../assets/fonts/SpoqaHanSansNeo-Bold.otf";
import Regular from "../assets/fonts/SpoqaHanSansNeo-Regular.otf";
import PoppinsMedium from "../assets/fonts/Poppins/Poppins-Medium.ttf";

const globalStyle = css`
  ${emotionReset}
  @font-face {
    font-family: SpoqaHanSansNeo;
    src: url("${Medium}") format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: SpoqaHanSansNeo;
    font-weight: 700;
    src: url("${Bold}") format("truetype");
  }

  @font-face {
    font-family: SpoqaHanSansNeo;
    font-weight: 300;
    src: url("${Light}") format("truetype");
  }

  @font-face {
    font-family: SpoqaHanSansNeo;
    font-weight: 400;
    src: url("${Regular}") format("truetype");
  }

  @font-face {
    font-family: Poppins;
    font-weight: 500;
    src: url("${PoppinsMedium}") format("truetype");
  }

  ,
  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Montserrat", "Noto Sans KR";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    font-family: "Montserrat", "Noto Sans KR";
  }
`;

export default globalStyle;
