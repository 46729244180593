import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import tokenState from "../../states/TokenState";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import {
  TableMenuDiv,
  TableMenu,
  InspectionText,
  TableContent,
  TableContentDiv,
  DropShippingText,
  ConfirmText,
} from "../selfSellCar/SelfSellCarStyle";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageText,
  MyPagecategoryText,
  MyPageActiveDiv,
  MyPageContentListDiv,
} from "./MyPageMenu";
import {
  DeliveryListModal,
  MyPageImg,
  MyPageSubText as EmptyText,
} from "./MyPageDeliveryListStyle";
import { MyPageSubText as LinkText } from "./MyPagePickUpStyle";
import "../Common.css";
import "../PickUp.css";
import { useUserType } from "../../hooks/useUserInfo";
import axios from "axios";
import { BASIC_URL } from "../CommonStyle";
import myPageImg from "../../assets/img/myPageImg.png";
import { MyPageLine } from "./MyPageUpdateInfoStyle";

export const MyPageSubText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => (props.color ? "#868e96" : "#212529")};
  font-weight: 400;
  letter-spacing: -0.64px;

  ${(props) =>
    props.font &&
    css`
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ededed;
      height: 100%;
    `};

  ${(props) =>
    props.font2 &&
    css`
      font-weight: 700;
      font-size: 16px;
      color: #ff6a13;
      text-decoration: underline;
      text-underline-position: under;
      cursor: pointer;
    `};
`;

export const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: props.column && "column",
  width: props.width && "100%",
  alignItems: props.center && "center",
}));

export const TableDiv = styled.div((props) => ({
  marginTop: props.margin && "32px",
  display: "flex",
  flexDirection: "row",
  width: "708px",
  height: "53px",
  borderBottom: "1px solid #ededed",
  borderTop: props.border && " 1px solid #ededed",
  alignItems: "center",
}));

const MyPageSelfSellCar = () => {
  const { TabPane } = Tabs;

  const navigate = useNavigate();

  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  }, [navigate, token]);

  const userType = useUserType();
  const [selfSellcarList, setSelfSellCarList] = useState([]);

  // 나의 셀프셀카 이용내역 불러오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/selfsellcars/web`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setSelfSellCarList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const userSelfSellCarList = selfSellcarList?.map((list) => {
    return (
      <TabPane tab={list?.carNumber} key={list?.id}>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container center>
            <MyPageText>진행 상태 </MyPageText>
            <MyPageSubText style={{ marginLeft: 13 }} color="true">
              자세한 정보는 신청현황에서 확인 할 수 있습니다.
            </MyPageSubText>{" "}
          </Container>
          <MyPageText
            textdecoration
            style={{ fontWeight: 500, fontSize: 18 }}
            onClick={() => navigate("/selfSellCarList")}
          >
            신청현황 바로가기
          </MyPageText>
        </MyPageContentListDiv>
        <TableDiv margin border>
          <MyPageSubText font color="true">
            {" "}
            진행 상태
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            {list?.status} ({list?.type})
          </MyPageSubText>
        </TableDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <TableMenuDiv>
            <TableMenu>
              <InspectionText>상품</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>가격</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>총 예상 소요일</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>총 금액</InspectionText>
            </TableMenu>
          </TableMenuDiv>
          <TableContentDiv>
            <TableContent>
              {list?.closeInspectionsData &&
                list?.closeInspectionsData?.closeInspections?.map((option) => {
                  return (
                    <ConfirmText style={{ marginTop: 12, marginBottom: 12 }}>
                      {option?.name}
                    </ConfirmText>
                  );
                })}
            </TableContent>
            <TableContent>
              {list?.closeInspectionsData &&
                list?.closeInspectionsData?.closeInspections?.map((option) => {
                  return (
                    <ConfirmText style={{ marginTop: 12, marginBottom: 12 }}>
                      {Number(option?.price).toLocaleString("en")}원
                    </ConfirmText>
                  );
                })}
            </TableContent>
            <TableContent>
              <DropShippingText>
                {list?.closeInspectionsData?.totalDay
                  ? list?.closeInspectionsData?.totalDay
                  : 0}
                일
              </DropShippingText>
            </TableContent>
            <TableContent>
              <DropShippingText>
                {list?.closeInspectionsData?.totalPrice
                  ? `${Number(
                      list?.closeInspectionsData?.totalPrice
                    ).toLocaleString("en")}원`
                  : "0원"}
              </DropShippingText>
            </TableContent>
          </TableContentDiv>
        </MyPageContentListDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true">
            <MyPageText>비용정산 </MyPageText>
            <TableDiv margin border>
              <MyPageSubText color="true" font>
                {" "}
                정산현황
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {list?.status}
              </MyPageSubText>
            </TableDiv>
            {list?.type === "경매" ? null : (
              <>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    선 지급 금액
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    {Number(list?.payment?.advancePayment).toLocaleString("en")}
                    원
                  </MyPageSubText>
                </TableDiv>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    추가 정산금액
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    1,550,000원
                  </MyPageSubText>
                </TableDiv>{" "}
              </>
            )}
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                정산 계좌
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {list?.payment?.bank}&nbsp;{list?.payment?.account} &nbsp;
                {list?.payment?.name}
              </MyPageSubText>
            </TableDiv>{" "}
          </Container>
        </MyPageContentListDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true" style={{ marginBottom: 80 }}>
            <MyPageText>차량대금 정산 </MyPageText>
            <TableDiv margin border>
              <MyPageSubText color="true" font>
                {" "}
                차량 판매 금액
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {Number(list?.vehiclePayment?.sellPrice).toLocaleString("en")}원
              </MyPageSubText>
            </TableDiv>

            {list?.type === "경매" ? null : (
              <>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    필수비용
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    {Number(
                      list?.vehiclePayment?.essentialPrice
                    ).toLocaleString("en")}
                    원
                  </MyPageSubText>
                </TableDiv>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    광고비용
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    {Number(list?.vehiclePayment?.adPrice).toLocaleString("en")}
                    원
                  </MyPageSubText>
                </TableDiv>
              </>
            )}
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                상품화비용
              </MyPageSubText>
              <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                {Number(list?.closeInspectionsData?.totalPrice).toLocaleString(
                  "en"
                )}
                원
              </MyPageSubText>
            </TableDiv>
            <TableDiv>
              <MyPageSubText color="true" font>
                {" "}
                최종 판매 금액
              </MyPageSubText>
              <MyPageSubText style={{ color: "#FF6A13", marginLeft: 20 }}>
                {Number(list?.vehiclePayment?.totalSellPrice).toLocaleString(
                  "en"
                )}
                원
              </MyPageSubText>
            </TableDiv>
            {list?.type === "경매" ? null : (
              <>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    선지급 금액
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    {Number(
                      list?.vehiclePayment?.advancePayment
                    ).toLocaleString("en")}
                    원
                  </MyPageSubText>
                </TableDiv>
                <TableDiv>
                  <MyPageSubText color="true" font>
                    {" "}
                    추가 정산 금액
                  </MyPageSubText>
                  <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
                    {Number(list?.vehiclePayment?.extraPrice).toLocaleString(
                      "en"
                    )}
                    원
                  </MyPageSubText>
                </TableDiv>
              </>
            )}
          </Container>
        </MyPageContentListDiv>
      </TabPane>
    );
  });

  // const [textdecoration, setTextdecoration] = useState(true);
  const textdecoration = useRef(false);
  useEffect(() => {
    textdecoration.current = true;
    return () => (textdecoration.current = false);
  });
  return (
    <MyPageWrapDiv>
      <MyPageContentDiv>
        <MyPageMenu userType={userType} textdecoration={textdecoration} />
        <MyPageActiveDiv className="myPage_div">
          <MyPagecategoryText>
            나의 이용내역 &nbsp; &gt; &nbsp; 셀프 셀카
          </MyPagecategoryText>
          <MyPageLine style={{ marginBottom: 0 }} />
          {selfSellcarList?.length > 0 ? (
            <Tabs
              defaultActiveKey="1"
              style={{ marginTop: 40, fontSize: 18 }}
              tabBarStyle={{ fontSize: 18 }}
              tabBarGutter={30}
            >
              {userSelfSellCarList}
            </Tabs>
          ) : (
            <>
              <DeliveryListModal>
                <MyPageImg src={myPageImg} alt="myPage" />
                <EmptyText color="true">
                  이용하신 셀프셀카 내역이 없습니다.
                </EmptyText>
              </DeliveryListModal>
              <LinkText font2 onClick={() => navigate("/selfSellcarMain")}>
                셀프셀카 신청하기
              </LinkText>
            </>
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};
export default MyPageSelfSellCar;
