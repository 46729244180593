import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import SelfSellCarImage from "../../assets/img/SelfSellCarImage.png";
import CarInpsection from "../../assets/img/CarInspection.png";

export const SelfSellCarImageDiv = styled.div`
  width: 100%;
  height: 741px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${SelfSellCarImage}");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SelfSellCarContentDiv = styled.div`
  width: 708px;
  height: 163px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #868e96;
  &:nth-of-type(5n) {
    border-bottom: 2px solid #868e96;
  }
  position: relative;
`;

export const SelfSellCarWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

export const SelfSellCarApplyTextDiv = styled.div`
  margin: 100px auto 0 auto;
  text-align: center;
`;

export const SelfSellCarFooterDiv = styled.div`
  display: flex;
  margin-top: 100px;
  flex-direction: row;
  align-items: center;
`;

export const SelfSellCarApplyText = styled.p`
  font-size: 30px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.2px;
`;

export const SelfSellCarApplySubText = styled.p`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.8px;

  ${(props) =>
    props.font &&
    css`
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.72px;
      margin-top: 0;
      cursor: pointer;
      margin-right: 8px;
    `};
`;

export const Container = styled.div`
  width: 522px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SelfSellCarDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1080px;
  margin-top: 100px;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 120px;
    `};
`;

export const SelfSellCarApplyMenuText = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  color: #212529;
`;

export const SelfSellCarLine = styled.div`
  width: 1080px;
  height: 1px;
  background: #dee2e6;
  margin-top: 20px;
`;

export const SelfSellCarLine3 = styled.div`
  width: 522px;
  height: 2px;
  background: #dee2e6;
  margin-top: 10px;
`;

export const SelfSellCarApplyLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  cursor: pointer;
`;

export const SelfSellCarInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.align &&
    css`
      align-items: center;
    `};
`;

export const SelfSellCarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;

  ${(props) =>
    props.justify &&
    css`
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    `};
`;

export const SelfSellCarApplyInput = styled.input`
  width: 522px;
  height: 60px;
  outline: none;
  margin-top: 16px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.borderColor};
  padding-left: 17px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  color: #212529;

  ::placeholder {
    color: #dee2e6;
  }

  &:focus {
    border-color: #212529;
  }
`;

export const SelfSellCarPostInput = styled.input`
  margin-top: 16px;
  width: 384px;
  height: 60px;
  outline: none;
  border: 1px solid #dee2e6;
  padding-left: 17px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  color: #212529;
  border-radius: 10px;

  ::placeholder {
    color: #dee2e6;
  }
`;

export const SelfSellCarPostButton = styled.button`
  width: 120px;
  height: 60px;
  background: #212529;
  margin-top: 16px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.72px;
  cursor: pointer;
`;

export const SelfSellCarApplyButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.88px;
  cursor: pointer;
  color: #fff;
  background-color: #ff6a13;
  margin-top: 100px;

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  ${(props) =>
    props.margin &&
    css`
      margin-top: 50px;
    `};
`;

export const AgreementText = styled.label`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;
  cursor: pointer;
  ${(props) =>
    props.font &&
    css`
      margin-left: 16px;
      color: #868e96;
      text-decoration: underline;
    `};
`;

export const ErrorText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  margin-top: 14px;
  color: #ff383b;

  ${(props) =>
    props.font &&
    css`
      color: #212529;
      margin-top: 20px;
    `};
`;

export const SelfSellCarApplyLine2 = styled.div`
  width: 600px;
  height: 1px;
  margin-top: 60px;
  background-color: #dee2e6;
`;

export const SelfSellCarApplySuccessText = styled.p`
  margin-top: 30px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.8px;
  color: #212529;
`;

export const SelfSellCarListDiv = styled.div`
  width: 1080px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;

  ${(props) =>
    props.justify &&
    css`
      margin-top: 20px;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    `};
`;

export const SelfSellCarStepTitle = styled.p`
  font-weight: 500;
  font-size: 30px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.2px;
`;

export const SelfSellCarStepSubText = styled.p`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #868e96;
  margin-top: 20px;
  letter-spacing: -0.72px;

  ${(props) =>
    props.auctionMenu &&
    css`
      font-weight: 700;
      margin-top: 0;
    `};
`;

export const SelfSellCarCheck = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 10px;
`;

export const SelfSellCarStepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.column && "column"};
`;

export const SelfSellCarCarImage = styled.div`
  width: 522px;
  background-color: #e9ecef;
  height: 420px;
  border-radius: 10px;
  border: none;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelfSellCarInformationDiv = styled.div`
  margin-top: 100px;
  width: 1080px;
  display: flex;
  flex-direction: row;
`;

export const SelfSellCarTypeText = styled.p`
  font-size: 24px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #212529;
  letter-spacing: -0.96px;
`;

export const CarInfoDIv = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const CarInfoMenutDiv = styled.div`
  width: 90px;
  margin-right: 100px;
`;

export const SelfSellCarStepBarDiv = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
`;

export const SelfSellCarStepText = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.72px;
  margin-top: 14px;

  ${(props) =>
    props.active &&
    css`
      color: #ff6a13;
      font-weight: 500;
    `};

  ${(props) =>
    props.done &&
    css`
      color: #ffd0b8;
      font-weight: 500;
    `}
`;

export const HistorySubText = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  margin-left: 23px;
  color: #868e96;

  ${(props) =>
    props.insuranceText &&
    css`
      color: #ff6a13;
      margin-left: 0;
      font-weight: 400;
      cursor: pointer;
    `};
`;

export const SelfSellCarLine4 = styled.div`
  width: 1080px;
  height: 2px;
  margin-top: 20px;
  background-color: #dee2e6;
`;

export const CarInsuranceDiv = styled.div`
  width: 100%;
  height: 267px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #0000000d;
  border: 1px solid #ededed;
  background-color: #fff;
  margin-top: 28px;
  padding-top: 30px;
`;

export const CarInsuraceInfoDiv = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 50px;
`;

export const CarInsuranceCountDiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: row;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 20px;
    `};
`;

export const CarInsuranceCountTextDiv = styled.div`
  width: 216px;
  text-align: center;
`;

export const SelfSellCarResult = styled.img`
  width: 530px;
  height: 320px;
  border: none;
  border-radius: 10px;
  object-fit: cover;

  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;

export const SelfSellCarText2 = styled.p`
  font-size: 18px;
  margin-top: 40px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  line-height: 28px;
  letter-spacing: -0.72px;
  display: inline-block;

  ${(props) =>
    props.weight &&
    css`
      font-weight: 700;
      margin-top: 0;
      cursor: pointer;
    `};

  ${(props) =>
    props.color &&
    css`
      font-weight: 500;
      color: #868e96;
    `};
`;

export const AuctionInfoDiv = styled.div`
  width: 1080px;
  height: 71px;
  margin-top: 31px;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:nth-of-type(2n) {
    border-bottom: 1px solid #ededed;
    height: auto;
  }
`;

export const AuctionMenuDiv = styled.div`
  height: 71px;
  width: 200px !important;
  background-color: #f8f9fa;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 0;
`;

export const DropShippingDiv = styled.div`
  border: none;
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 880px;

  ${(props) =>
    props.width &&
    css`
      width: 340px;
    `};
`;

export const AuctionInfoInput = styled.input`
  width: 340px;
  height: 71px;
  border: none;
  outline: none;
  padding: 0 20px;
  font-size: 18px;
  letter-spacing: -0.72px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
  ::placeholder {
    color: #dee2e6;
  }
`;

export const ReturnPriceTextDiv = styled.div`
  width: 340px;
  height: 71px;
  border: none;
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const AuctionInfoContainer = styled.div`
  width: 340px;
  height: 71px;
  border: none;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

export const ReviewText = styled.p`
  margin-top: 70px;
  margin-bottom: 40px;
  font-size: 20px;
  letter-spacing: -0.8px;
  font-weight: 700;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

export const AuctionText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #ff6a13;
`;

export const AuctionButton = styled.button`
  width: 240px;
  height: 70px;
  background-color: #212529;
  border-radius: 10px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  letter-spacing: -0.88px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;

  ${(props) =>
    props.background &&
    css`
      background-color: #ff6a13;
    `};

  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
`;

export const DropShippingText = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
`;

export const DropShippingContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ConfirmCarBox = styled.div`
  width: 1080px;
  height: 138px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #0000000d;
  margin-top: 30px;
  padding: 30px;
  border: 1px solid #ededed;
`;

export const ConfirmCarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ConfirmText = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
`;

export const InspectionText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
`;

export const InspectionLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
`;

export const ConfirmText2 = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #868e96;
`;

export const ComfirmLink = styled.a`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
  margin-right: 55px;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: #212529;
    text-decoration: underline;
  }
`;

export const AdvertisingText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #ff6a13;
`;

export const InspectionText2 = styled.span`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.48px;
  color: #212529;
`;

export const InspectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const CarInspecitonImgDiv = styled.div`
  width: 100%;
  height: 450px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  background-image: url("${CarInpsection}");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CarInspectionImg = styled.img`
  width: 100%;
  height: 450px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  margin-top: 40px;
  object-fit: contain;
`;

export const InspectionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const InspectionButton = styled.button`
  width: 200px;
  height: 70px;
  border: none;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  letter-spacing: -0.72px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  background: #e9ecef;
  cursor: pointer;

  ${(props) =>
    props.margin &&
    css`
      margin-right: 15px; ;
    `};

  ${(props) =>
    props.changebackground &&
    css`
      background-color: #ff6a13;
    `};
`;

export const InspectionOptionDiv = styled.div`
  width: 630px;
  border-radius: 10px;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  padding: 0 42px;
`;

export const InspectionLine = styled.div`
  width: 550px;
  height: 1px;
  background: #ededed;
`;

export const InspectionOptionCategory = styled.div`
  width: 550px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #ff6a13;
  background-color: #fff;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 36px;
`;

export const InspectionOptionCategory2 = styled.div`
  width: 550px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 36px;

  ${(props) =>
    props.border &&
    css`
      border-color: #ff6a13;
    `};
`;

export const RecommendOptionBox = styled.div`
  width: 550px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 36px;
`;

export const InspectionPayBox = styled.div`
  position: sticky;
  top: 20%;
  width: 414px;
  height: 100%;
  box-shadow: 0px 3px 15px #adb5bd66;
  border-radius: 10px;
  background: #fff;
`;

export const InspectionCategoryText = styled.p`
  color: #868e96;
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.8px;
`;

export const PayBoxContainer = styled.div`
  width: 346px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin: 0 auto;
`;

export const PayBoxLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 16px;
  background: #ededed;
`;

export const PayBoxPriceText = styled.p`
  font-size: 28px;
  font-weight: 700;
  color: #ff6a13;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.12px;
`;

export const CarInfoImage = styled.img`
  width: 522px;
  height: 420px;
  border-radius: 10px;
  object-fit: cover;
  border: none;
`;

export const CloseInspectionContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropShippingModalOpenText = styled.span`
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;
  text-decoration: underline;
`;

export const UserSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
`;

export const TableMenuDiv = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

export const TableContentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
`;

export const TableMenu = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
