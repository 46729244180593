import { useNavigate} from "react-router";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import { useState } from "react";
import {
  CarStorageImageDiv,
  CarStorageEngText,
  CarStorageText,
  CarStorageText2,
  CarStorageText3,
  CarStorageText4,
  CarStorageText5,
  CatStorageText6,
  AnimationDiv,
  CarStorageTextDiv,
  CarStorageDiv,
  CarStorageWrapDiv,
  CarStorageStepDiv,
  CarStorageEngText2,
  CarStorageEngText3,
  CarStorageStepContentDiv,
  Container,
  CarStorageContentDiv,
} from "../../components/carStorage/CarStorageStyle";
import { ReactComponent as CarStorageIcon } from "../../assets/icons/CarStorageIcon.svg";
import { ReactComponent as CarStorageIcon2 } from "../../assets/icons/CarStorageIcon2.svg";
import { ReactComponent as CarStorageIcon3 } from "../../assets/icons/CarStorageIcon3.svg";
import Header from "../../components/Header";
import { useUserType } from "../../hooks/useUserInfo";
import DisabledMenu from "../main/DisabledMenu";
import { useToken } from "../../hooks/useToken";

const CarStorageMain = () => {
  const navigate = useNavigate();
  const position = false;
  const carStoragetextBold = true;
  const hidden = false;
  const token = useToken();

  const [width, setWidth] = useState("48px");
  const [color, setColor] = useState("#212529");
  const userType = useUserType();


  return (
    <CarStorageWrapDiv>
      {token && userType !== "" && userType === "기업회원" ? (
        <DisabledMenu />
      ) : token && userType === "" ? null : (
        <>
          <Header position={position} carStoragetextBold={carStoragetextBold} />
          <HeaderMenuBar hidden={hidden}>
            <HeaderMenuText
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/carStorageApply")}
            >
              차량 보관 신청
            </HeaderMenuText>
            <HeaderMenuText
              style={{ marginLeft: 28, cursor: "pointer" }}
              onClick={() => navigate("/carStorageApplyList")}
            >
              나의 신청 현황
            </HeaderMenuText>
          </HeaderMenuBar>
          <CarStorageImageDiv>
            <CarStorageTextDiv>
              <CarStorageEngText>More than easy -</CarStorageEngText>
              <CarStorageText>
                자동차 보관, 어려우셨죠? <br />
                탈것에서 보관해드립니다.
              </CarStorageText>
              <CarStorageText2>
                사용하지 않는 내 차를 보관하고 싶다면? <br />
                언제든 입고 &#xB7; 출고가 가능한 차량보관 서비스를 만나보세요.
              </CarStorageText2>
            </CarStorageTextDiv>
          </CarStorageImageDiv>
          <CarStorageStepDiv>
            <CarStorageStepContentDiv>
              <CarStorageDiv>
                <CarStorageEngText2>Step Guide</CarStorageEngText2>
                <CarStorageText3 style={{ marginTop: 20 }}>
                  입고와 출고를 내 마음대로! <br />
                  더욱 더 쉬운 자동차 보관
                </CarStorageText3>
              </CarStorageDiv>{" "}
              <CarStorageDiv>
                <CarStorageContentDiv>
                  <Container>
                    <CarStorageIcon style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.1</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    원하는 날짜를 선택하여 결제합니다.
                  </CarStorageText4>
                </CarStorageContentDiv>
                <CarStorageContentDiv>
                  <Container>
                    <CarStorageIcon2 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.2</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    고객님의 차량이 ‘탈것’ 보관 센터에 입고됩니다.
                  </CarStorageText4>
                </CarStorageContentDiv>
                <CarStorageContentDiv>
                  <Container>
                    <CarStorageIcon3 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.3</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    임시 출고를 원하실 경우, 고객님의 대체 차량이 입고됩니다.
                  </CarStorageText4>
                </CarStorageContentDiv>
                <CarStorageContentDiv
                  style={{ border: "none", marginTop: 120 }}
                >
                  <Container>
                    <CarStorageDiv>
                      <CarStorageText5>
                        지금 바로 서비스를 이용해보세요.
                      </CarStorageText5>
                      <CarStorageText2
                        style={{ color: "#212529", marginTop: 12 }}
                      >
                        가장 편리한 자동차 보관, 탈것에서 시작하세요!
                      </CarStorageText2>
                    </CarStorageDiv>
                  </Container>
                  <Container
                    onMouseOver={() => {
                      setColor("#fff");
                      setWidth("182px");
                    }}
                    onMouseOut={() => {
                      setColor("#212529");
                      setWidth("48px");
                    }}
                    onClick={() => navigate("/carStorageApply")}
                  >
                    <AnimationDiv width={width} />
                    <CatStorageText6 color={color}>
                      신청 바로가기 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;
                    </CatStorageText6>
                  </Container>
                </CarStorageContentDiv>
              </CarStorageDiv>
            </CarStorageStepContentDiv>
          </CarStorageStepDiv>
        </>
      )}
    </CarStorageWrapDiv>
  );
};

export default CarStorageMain;
