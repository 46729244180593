import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { BASIC_URL, WrapDiv } from "../../components/CommonStyle";

import {
  AuctionPriceContainer,
  AuctionNumberText,
  AuctionCarBadge,
  AuctionCarInfoContainer,
  AuctionCategoryBox,
  AuctionCarInfoBox,
  AuctionCarInfoBox2,
  AuctionCarPhoto,
  AuctionCarInfoPhoto,
  AuctionLine,
  AuctionStatus,
  AuctionPrice,
  AuctionCarIconDiv,
  AuctionDetailInfoText,
  AuctionButton,
  LiveAuctionContainer,
  LiveAuctionCateogryText,
  LiveAuctionPriceText,
  CarDetailDiv,
  CategoryText,
  BidButton,
  StatusText,
  PriceText,
  AuctionStepCircle,
  AuctionStepCircle2,
  AuctionStepCircle3,
  NextCarInfo,
  AuctionNextCarLink,
} from "../../components/liveAuction/LiveAuctionStyle";
import {
  InspectionContainer,
  InspectionText2,
} from "../../components/selfSellCar/SelfSellCarStyle";
import { ReactComponent as Swap } from "../../assets/icons/InspectionIcon.svg";
import { ReactComponent as Hammer } from "../../assets/icons/hammer.svg";
import { ReactComponent as Next } from "../../assets/icons/Nextcar.svg";
import { ReactComponent as Work } from "../../assets/icons/InspectionIcon2.svg";
import { ReactComponent as Painting } from "../../assets/icons/inpsection/inspection_06.svg";
import { ReactComponent as Painting2 } from "../../assets/icons/inpsection/inspection_07.svg";
import { ReactComponent as Break } from "../../assets/icons/inpsection/inspection_08.svg";
import { ReactComponent as Side } from "../../assets/icons/inpsection/inspection_09.svg";
import { ReactComponent as Scratch } from "../../assets/icons/inpsection/inspection_10.svg";
import { ReactComponent as FailBid } from "../../assets/icons/failBid.svg";
import { ReactComponent as Change } from "../../assets/icons/inpsection/inspection_03.svg";
import { ReactComponent as Plate } from "../../assets/icons/inpsection/inspection_04.svg";
import { ReactComponent as Management } from "../../assets/icons/inpsection/inspection_05.svg";
import { ReactComponent as SuccessBid } from "../../assets/icons/successBid.svg";
import Header from "../../components/Header";
import { useEffect, useState, useRef } from "react";
import { useToken } from "../../hooks/useToken";

const AuctionPage = () => {
  const liveAuctionText = true;
  const position = false;
  const token = useToken();
  const navigate = useNavigate();
  const websocketRef = useRef(null);
  const state = useLocation();
  const { entryBid } = state?.state;
  const [nextId, setNextId] = useState(0);
  const [auctionId, setAuctionId] = useState(entryBid?.id);
  const [idx, setIdx] = useState(0);
  const [price, setPrice] = useState(null);
  const [bidCar, setBidCar] = useState({});

  const [backgroundcolor, setBackgroundcolor] = useState(false);
  const [backgroundcolor2, setBackgroundcolor2] = useState(false);
  const [backgroundcolor3, setBackgroundcolor3] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const [bidButtonDisabled, setBidButtonDisabled] = useState(true);
  const [ownButton, setOwnButton] = useState(true);

  const next = (id) => {
    setIdx(idx + 1);

    axios({
      url: `${BASIC_URL}/liveauction/${id}/auction`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((result) => {
        if (result.data) {
          setBidCar(result.data);
          setAuctionId(result.data.id);
          setPrice(result.data.price / 10000);
          setBackgroundcolor(false);
          setBackgroundcolor2(false);
          setBackgroundcolor3(false);
          setOwnButton(true);
          setBidStatus("대기");
        } else {
          alert("error");
          console.log(result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (bidCar) {
      websocketRef.current = new WebSocket(
        "ws://localhost:8080"
      );

      websocketRef.current.onerror = (error) => {
        console.log("socket", error);
      };

      websocketRef.current.onclose = (event) => {
        console.log(event);
      };

      websocketRef.current.onopen = () => {
        console.log("connected!");

        websocketRef.current.send(
          JSON.stringify({
            event: "enter",
            data: {
              room: `${auctionId}`,
              jwt: token,
            },
          })
        );
      };

      websocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        
        setSocketData(data);

        if (data.roundIsEnd) {
          alert('경매가 종료되었습니다.');
          navigate('/liveAuctionMain');
        }

        if (data.highPrice) {
          setPrice(data.highPrice);
        }

        // 망치
        if (data.isOwn === true && data.isOwn !== undefined) {
          setOwnButton(false);
        } else if (data.isOwn === false && data.isOwn !== undefined) {
          setOwnButton(true);
        }

        if (data?.end && websocketRef.current) {
          websocketRef.current.send(
            JSON.stringify({
              event: "quit",
              data: {
                room: `${auctionId}`,
                jwt: token,
              },
            })
          );
        }

        if (data?.nextcarId && websocketRef.current) {
          setTimeout(() => {
            websocketRef.current.send(
              JSON.stringify({
                event: "enter",
                data: {
                  room: `${data?.nextcarId}`,
                  jwt: token,
                },
              })
            );

            next(data?.nextcarId);
          }, 4000);
        } else if (data?.nextcarId === null) {
          setTimeout(() => {
            alert("금일 차량의 모든 경매가 종료 되었습니다.");
            navigate("/liveAuctionMain");
          }, 4000);
        }
      };
    }

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
        setSocketData(null);
      }
    };
  }, [bidCar, auctionId, token]);

  // 경매 상황
  const [bidStatus, setBidStatus] = useState("대기");
  useEffect(() => {
    if (socketData) {
      if (
        socketData?.status !== bidStatus &&
        socketData?.status !== undefined
      ) {
        setBidStatus(socketData?.status);
      }
    }
  }, [socketData, bidStatus]);

  // 응찰버튼
  useEffect(() => {
    if (socketData) {
      if (
        bidStatus === "유찰" ||
        bidStatus === "낙찰" ||
        bidStatus === "대기"
      ) {
        setBidButtonDisabled(true);
      } else {
        setBidButtonDisabled(false);
      }
    } else {
      setBidButtonDisabled(true);
    }
  }, [socketData, bidStatus]);

  // 신호등
  useEffect(() => {
    if (socketData) {
      if (socketData.userCount < 1) {
        setBackgroundcolor(true);
        setBackgroundcolor3(false);
        setBackgroundcolor2(false);
      } else if (socketData?.userCount === 2) {
        setBackgroundcolor2(true);
        setBackgroundcolor(false);
        setBackgroundcolor3(false);
      } else if (socketData?.userCount > 2) {
        setBackgroundcolor3(true);
        setBackgroundcolor2(false);
        setBackgroundcolor(false);
      }
    }
  }, [socketData]);

  // 처음 경매 하는 차량 정보
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/liveauction/${entryBid?.id}/auction`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((result) => {
        if (result.data) {
          setBidCar(result.data);
          setAuctionId(result.data.id);
          setPrice(result.data.price / 10000);
          setNextId(result.data.nextcarId);
        } else {
          alert("error");
          console.log(result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [entryBid, token]);

  return (
    <WrapDiv>
      <Header liveAuctionText={liveAuctionText} position={position} />
      <AuctionNumberText>
        회차 #{bidCar?.entryNumber} 출품번호 #{idx + 1}&nbsp;<strong></strong>
      </AuctionNumberText>
      <AuctionPriceContainer>
        <AuctionCarInfoBox2>
          <AuctionStatus>
            <StatusText>{bidStatus}</StatusText>
          </AuctionStatus>
          <AuctionCarInfoBox margin>
            <AuctionStepCircle backgroundcolor={backgroundcolor} />
            <AuctionStepCircle2 backgroundcolor={backgroundcolor2} />
            <AuctionStepCircle3 backgroundcolor={backgroundcolor3} />
          </AuctionCarInfoBox>
        </AuctionCarInfoBox2>
        <AuctionCarInfoBox>
          <AuctionPrice style={{ width: 300 }}>
            <PriceText>{price} 만원</PriceText>
            {/* <PriceUnitPrice>만원</PriceUnitPrice> */}
          </AuctionPrice>
          {/* <AuctionPrice>
            <PriceText>0</PriceText>
            <PriceUnitPrice>억</PriceUnitPrice>
          </AuctionPrice> */}
          {/* <AuctionPrice>
            <PriceText>0</PriceText>
            <PriceUnitPrice>천</PriceUnitPrice>
          </AuctionPrice>
          <AuctionPrice>
            <PriceText>0</PriceText>
            <PriceUnitPrice>백</PriceUnitPrice>
          </AuctionPrice>
          <AuctionPrice>
            <PriceText>0</PriceText>
            <PriceUnitPrice>십</PriceUnitPrice>
          </AuctionPrice>
          <AuctionPrice>
            <PriceText>0</PriceText>
            <PriceUnitPrice>일</PriceUnitPrice>
          </AuctionPrice> */}
        </AuctionCarInfoBox>
        <AuctionCarInfoBox>
          <BidButton disabled={ownButton}>
            <Hammer />
          </BidButton>
          <AuctionButton
            disabled={bidButtonDisabled}
            onClick={() => {
              // setPrice(price + 5);
              websocketRef.current.send(
                JSON.stringify({
                  event: "applyBid",
                  data: {
                    room: `${auctionId}`,
                    jwt: token,
                    price: price + 5,
                  },
                })
              );
            }}
          >
            {" "}
            응찰
          </AuctionButton>
        </AuctionCarInfoBox>
      </AuctionPriceContainer>
      <AuctionCarInfoContainer>
        <AuctionCarInfoBox>
          <LiveAuctionPriceText weight>{bidCar?.name}</LiveAuctionPriceText>
          {/* <AuctionCarBadge> {bidCar?.carScore}</AuctionCarBadge> */}
        </AuctionCarInfoBox>
        {
          bidCar?.nextId !== '' && bidCar?.nextId !== null && (
            <AuctionCarInfoBox style={{cursor : "pointer", flexDirection: 'column', alignItems: 'flex-end'}}>
              <Next style={{marginRight : 4}} />
              <AuctionNextCarLink> 다음 차량</AuctionNextCarLink>
              <NextCarInfo>
                <LiveAuctionCateogryText style={{color : '#868E96'}}>
                  {bidCar?.nextId}
                </LiveAuctionCateogryText>
              </NextCarInfo>
            </AuctionCarInfoBox> 
          )
        }
      </AuctionCarInfoContainer>
      <CarDetailDiv>
        <AuctionDetailInfoText>{bidCar?.number} &nbsp;| </AuctionDetailInfoText>
        <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;{bidCar?.year}년식 &nbsp;&nbsp; |
        </AuctionDetailInfoText>
        <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;{bidCar?.evaluation}&nbsp;&nbsp; |{" "}
        </AuctionDetailInfoText>
        <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;{bidCar?.fuel}&nbsp;&nbsp; |
        </AuctionDetailInfoText>
        <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;
          {Number(bidCar?.distanceDriven).toLocaleString(
            "en"
          )}km&nbsp;&nbsp;|{" "}
        </AuctionDetailInfoText>
        <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;{bidCar?.classificationOfUse}&nbsp;&nbsp;
        </AuctionDetailInfoText>
        {/* <AuctionDetailInfoText>
          &nbsp;&nbsp;&nbsp;법인상품
        </AuctionDetailInfoText> */}
      </CarDetailDiv>
      <LiveAuctionContainer style={{ marginTop: 30 }}>
        <AuctionCarPhoto src={bidCar?.photo} alt="img" />
        {bidStatus === "유찰" ? (
          <FailBid style={{ position: "absolute", top: 130, left: 200 }} />
        ) : null}
        {bidStatus === "낙찰" ? (
          <SuccessBid style={{ position: "absolute", top: 130, left: 230 }} />
        ) : null}
        <AuctionCategoryBox>
          <CategoryText>성능 점검</CategoryText>
          <AuctionLine />
          <AuctionCarIconDiv style={{ justifyContent: "flex-start" }}>
            <InspectionContainer style={{ marginBottom: 10 }}>
              <Swap style={{ marginRight: 4 }} />
              <InspectionText2>교환</InspectionText2>
            </InspectionContainer>
            <InspectionContainer style={{ marginBottom: 10 }}>
              <Side style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>부식</InspectionText2>
            </InspectionContainer>

            <InspectionContainer style={{ marginBottom: 10 }}>
              <Break style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>꺾임</InspectionText2>
            </InspectionContainer>
            <InspectionContainer style={{ marginBottom: 10 }}>
              <Plate style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>판금필요</InspectionText2>
            </InspectionContainer>
            <InspectionContainer style={{ marginBottom: 10 }}>
              <Management style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>조정필요</InspectionText2>
            </InspectionContainer>

            <InspectionContainer style={{ marginBottom: 10 }}>
              <Painting style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>도장</InspectionText2>
            </InspectionContainer>
            <InspectionContainer>
              <Painting2 style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>도장필요</InspectionText2>
            </InspectionContainer>
            <InspectionContainer>
              <Work style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>판금/용접</InspectionText2>
            </InspectionContainer>
            <InspectionContainer>
              <Change style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>교환필요</InspectionText2>
            </InspectionContainer>

            <InspectionContainer>
              <Scratch style={{ marginLeft: 22, marginRight: 4 }} />
              <InspectionText2>상처</InspectionText2>
            </InspectionContainer>
          </AuctionCarIconDiv>

          <AuctionCarInfoPhoto src={bidCar?.inspectionImage} alt="car" />

          <CategoryText style={{ marginTop: 40 }}>특이사항</CategoryText>
          <AuctionLine />
          <LiveAuctionCateogryText>{bidCar?.opinion}</LiveAuctionCateogryText>
        </AuctionCategoryBox>
      </LiveAuctionContainer>
    </WrapDiv>
  );
};

export default AuctionPage;
