import axios from "axios";
import { useParams, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { SelfSellCarWrapDiv } from "../../../components/selfSellCar/SelfSellCarStyle";
import { useToken } from "../../../hooks/useToken";
import { BASIC_URL } from "../../../components/CommonStyle";
import Header from "../../../components/Header";
import CarInfo from "./CarInfo";
import CarInsuranceHistory from "./CarInsuranceHistory";
import SelfSellCarStepBar from "./stepBar/SelfSellCarStepBar";
import VehicleInspection from "./VehicleInspection";

import CarInfoTabBar from "./CarInfoTabBar";

const SelfSellCarStep = () => {
  const position = false;
  const location = useLocation();
  const selfSellCarText = true;
  const token = useToken();
  const { id } = useParams();

  const [selfSellCarData, setSelfSellCarData] = useState();

  // 셀프 셀카 정보 가져오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data) {
        setSelfSellCarData(res?.data);
      }
    });

    return () => setSelfSellCarData();
  }, [token, id]);

  const [auctionResult, setAuctionResult] = useState("");
  useEffect(() => {
    if (
      selfSellCarData?.status === "경매완료" ||
      selfSellCarData?.status === "위탁판매"
    ) {
      axios({
        url: `${BASIC_URL}/selfsellcar/${id}/auction/result`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setAuctionResult(res.data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, [id, token, selfSellCarData]);

  // console.log(selfSellCarData);

  return (
    <SelfSellCarWrapDiv>
      <Header position={position} selfSellCarText={selfSellCarText} borderactive={location?.pathname?.includes("/selfSellCar")}/>
      <SelfSellCarStepBar
        selfSellCarData={selfSellCarData}
        auctionResult={auctionResult}
      />
      <CarInfo selfSellCarData={selfSellCarData} />
      {selfSellCarData?.status === "점검완료" && (
        <>
          {/* <CarInsuranceHistory selfSellCarData={selfSellCarData} /> */}
          <VehicleInspection selfSellCarData={selfSellCarData} />
        </>
      )}

      {(selfSellCarData?.status === "상품화" ||
        selfSellCarData?.status === "상품화완료" ||
        selfSellCarData?.status === "경매신청" ||
        selfSellCarData?.status === "경매완료" ||
        selfSellCarData?.status === "위탁판매" ||
        selfSellCarData?.status === "정산완료") && (
        <CarInfoTabBar selfSellCarData={selfSellCarData} />
      )}
    </SelfSellCarWrapDiv>
  );
};

export default SelfSellCarStep;
