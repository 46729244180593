import styled from "@emotion/styled/macro";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Loading from "react-loading";
import { useRecoilValue } from "recoil";
import { BASIC_URL } from "../CommonStyle";
import tokenState from "../../states/TokenState";
import stepIcon from "../../assets/img/stepicon.svg";
import { PickUpStepModal } from "../login&join/Modals";
import "../Common.css";

const RequestDetailWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const RequestDetailHeaderDiv = styled.div`
  margin: 100px auto 0 auto;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  flex: 1;
`;

const RequestDetailText = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: #212529;
  letter-spacing: -1.2px;
  font-family: SpoqaHanSansNeo;
`;

const RequestDetailText2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.8px;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
`;

const RequestDetailLine = styled.div`
  width: 1080px;
  height: 2px;
  background-color: #dee2e6;
  margin-top: 60px;
`;

const RequestContentDiv = styled.div`
  width: 1080px;
  margin: 51px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RequestConetent = styled.div`
  width: 522px;
  height: 167px;
  margin-top: 40px;
  border-radius: 10px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);

  &:nth-of-type(2n) {
    margin-right: 0;
  }
`;

const RequestContentHeader = styled.div`
  width: 100%;
  height: 60px;
  background-color: rgba(233, 236, 239, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RequestContentHeaderText = styled.span`
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  color: #ff6a13;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
`;

const RequestContentTextDiv = styled.div`
  width: auto;
  display: flex;
  height: 19px;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 16px;
  align-items: center;
  &:nth-of-type(4n) {
    margin-top: 8px;
  }
`;

const RequestContentTextInnerDiv = styled.div`
  width: 185px;
  margin-top: 16px;
  &:nth-of-type(2n) {
    margin-left: 96px;
  }
`;

const RequestContentLine = styled.div`
  width: 490px;
  height: 1px;
  border: 1px solid #ededed;
  margin-top: 24px;
  margin-left: 16px;
`;

const RequestContentText = styled.span`
  font-size: 16px;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  font-weight: 500;
  &:nth-of-type(2n) {
    color: #212529;
    margin-left: 33px;
    font-weight: 500;
  }
`;

const PickUpStepContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  top: 150px;
  right: 0;
`;

const StepImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;

const StepText = styled.p`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.72px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

const PickUpRequestDetailStyle = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // 토큰
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  });

  // 신청 정보
  const [userApply, setUserApply] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios({
      url: `${BASIC_URL}/pickupmaintenance/mine`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((result) => {
      if (result.data.userCars) {
        setUserApply(
          result.data.userCars?.map((item) => {
            return (
              (
                <RequestConetent key={item.id}>
                  <RequestContentHeader>
                    <RequestContentHeaderText
                      onClick={() => navigate(`/pickUpStep/${item.id}`)}
                    >
                      {item.carNumber}
                    </RequestContentHeaderText>
                    <RequestContentHeaderText
                      style={{
                        fontSize: 24,
                        color: "#212529",
                        paddingRight: 8,
                      }}
                      onClick={() => navigate(`/pickUpStep/${item.id}`)}
                    >
                      &gt;{" "}
                    </RequestContentHeaderText>
                  </RequestContentHeader>
                  <RequestContentTextDiv>
                    <RequestContentTextInnerDiv>
                      <RequestContentText>신청 종류</RequestContentText>
                      <RequestContentText>{item.type}</RequestContentText>
                    </RequestContentTextInnerDiv>
                    <RequestContentTextInnerDiv>
                      <RequestContentText>신청일</RequestContentText>
                      <RequestContentText>
                        {moment(item.createdAt).format("YYYY.MM.DD")}
                      </RequestContentText>
                    </RequestContentTextInnerDiv>
                  </RequestContentTextDiv>
                  <RequestContentLine />
                  <RequestContentTextDiv>
                    <RequestContentTextInnerDiv>
                      <RequestContentText>진행 상태</RequestContentText>
                      <RequestContentText>{item.status}</RequestContentText>
                    </RequestContentTextInnerDiv>
                    <RequestContentTextInnerDiv>
                      <RequestContentText>신청인</RequestContentText>
                      <RequestContentText>{item.name}</RequestContentText>
                    </RequestContentTextInnerDiv>
                  </RequestContentTextDiv>
                </RequestConetent>
              ) ?? []
            );
          })
        );
        setIsLoading(false);
      } else {
        alert("신청 정보가 없습니다.");
      }
    });

    return [];
  }, [token, navigate]);

  // 진행단계 모달
  const [stepModalOpen, setStepModalOpen] = useState(false);

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "50vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        style={{
          width: 1080,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading type="spin" width={150} height={150} color="#ff6a13" />
      </div>
    </div>
  ) : (
    <RequestDetailWrapDiv>
      <>
        <PickUpStepModal
          stepModalOpen={stepModalOpen}
          setStepModalOpen={setStepModalOpen}
        />
        <RequestDetailHeaderDiv>
          <RequestDetailText>픽업 정비 나의 신청 현황</RequestDetailText>
          <RequestDetailText2 style={{ marginTop: 12 }}>
            {" "}
            나의 신청 현황을 확인해보세요.
          </RequestDetailText2>
          <RequestDetailLine />
          <PickUpStepContainer onClick={() => setStepModalOpen(true)}>
            <StepText>진행 단계</StepText>
            <StepImg src={stepIcon} alt="icon" />
          </PickUpStepContainer>
        </RequestDetailHeaderDiv>

        <RequestContentDiv>{userApply}</RequestContentDiv>
      </>
    </RequestDetailWrapDiv>
  );
};

export default PickUpRequestDetailStyle;
