import DaumPostcode from "react-daum-postcode";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { Modal, DatePicker } from "antd";
import { useNavigate , useLocation } from "react-router";
import { useEffect, useState } from "react";
import {
  DeliveryListModal,
  AddressModal,
  PrivacyPolicyModal,
} from "../../components/login&join/Modals";
import {
  SelfSellCarWrapDiv,
  SelfSellCarApplyTextDiv,
  SelfSellCarApplyText,
  SelfSellCarDataDiv,
  SelfSellCarFooterDiv,
  SelfSellCarApplyMenuText,
  AgreementText,
  SelfSellCarLine,
  SelfSellCarApplyLabel,
  SelfSellCarPostButton,
  SelfSellCarContainer,
  SelfSellCarApplySubText,
  Container,
  SelfSellCarApplyInput,
  SelfSellCarPostInput,
  SelfSellCarInputContainer,
  SelfSellCarApplyButton,
  ErrorText,
} from "../../components/selfSellCar/SelfSellCarStyle";
import { useToken } from "../../hooks/useToken";
import { BASIC_URL } from "../../components/CommonStyle";
import Header from "../../components/Header";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";

const SelfSellCarApply = () => {
  const location = useLocation();
  const position = false;
  const selfSellCarText = true;
  const navigate = useNavigate();
  const token = useToken();

  // 회원 마이페이지 배송지 불러오기
  const [userShippingList, setUserShippingList] = useState();
  const [addressModal, setAddressModal] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const deliveryList = userShippingList?.shippings;

  // 토큰값이 없을시 로그인 페이지로 이동
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      axios({
        url: `${BASIC_URL}/user/mypage/shippings`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.data) {
            setUserShippingList(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => setUserShippingList();
  }, [token, navigate]);

  // data
  const [applyData, setApplyData] = useState({
    name: "",
    carNumber: "",
    phoneNumber: "",
  });

  const { name, carNumber, phoneNumber } = applyData;

  const onChange = (event) => {
    const { value, name } = event.target;
    setApplyData({
      ...applyData,
      [name]: value,
    });
  };

  const [pickUpday, setPickUpDay] = useState("");

  const dateHandler = (datesStrings) => {
    setPickUpDay(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  // 우편번호
  const [openPostModal, setOpenPostModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [myAdress, setMyAdress] = useState("");
  const [pickUpAddressDetail, setPickUpAddressDetail] = useState("");

  const Post = () => {
    const handleComplete = (data) => {
      setZipCode(data.zonecode);
      setMyAdress(data.address);
      setOpenPostModal((prev) => !prev);
    };

    return (
      <Modal
        centered
        visible={openPostModal}
        footer={null}
        width={613}
        closable={false}
        onCancel={() => setOpenPostModal(false)}
        bodyStyle={{ height: "auto" }}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  };

  // 차량 번호 유효성 검사
  const [checkCarNumber, setCheckCarNumber] = useState(false);
  const [carNumberBorder, setCarNumberBorder] = useState("#DEE2E6");

  useEffect(() => {
    const carNumberCheck = /\d{2,3}[가-힣]{1}\d{4}/gm.test(carNumber);
    if (carNumber) {
      if (carNumberCheck) {
        setCheckCarNumber(true);
        setCarNumberBorder("#DEE2E6");
      } else {
        setCheckCarNumber(false);
        setCarNumberBorder("#FF383B");
      }
    } else {
      setCheckCarNumber(true);
      setCarNumberBorder("#DEE2E6");
    }
  }, [carNumber, checkCarNumber]);

  // 휴대폰 번호 유효성 검사
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [phoneNumberBorder, setPhoneNumberBorder] = useState("#DEE2E6");
  useEffect(() => {
    const phoneNumberCheck = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberCheck) {
        setCheckPhoneNumber(true);
        setPhoneNumberBorder("#DEE2E6");
      } else {
        setCheckPhoneNumber(false);
        setPhoneNumberBorder("#FF383B");
      }
    } else {
      setPhoneNumberBorder("#DEE2E6");
      setCheckPhoneNumber(true);
    }
  }, [phoneNumber, checkPhoneNumber]);

  // 버튼 활성화
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);

  useEffect(() => {
    if (name && carNumber && phoneNumber && pickUpday && zipCode && myAdress) {
      if (checkCarNumber && checkPhoneNumber && agreementCheck) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      setButtonDisabled(true);
    }
  }, [
    name,
    carNumber,
    phoneNumber,
    pickUpday,
    zipCode,
    myAdress,
    checkPhoneNumber,
    checkCarNumber,
    agreementCheck,
  ]);

  // 셀프셀카 신청하기
  const applySelfSellCar = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name,
        carNumber,
        phoneNumber,
        zipCode,
        address: myAdress,
        addressDetail: pickUpAddressDetail,
        pickUpday,
      },
    })
      .then((res) => {
        if (res.data.success) {
          navigate("/selfSellCarApplySuccess");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SelfSellCarWrapDiv>
      <Post />
      <AddressModal
        addressModal={addressModal}
        setAddressModal={setAddressModal}
      />
      <DeliveryListModal
        openListModal={openListModal}
        setOpenListModal={setOpenListModal}
        setZipCode={setZipCode}
        setMyAdress={setMyAdress}
        setPickUpAddressDetail={setPickUpAddressDetail}
        deliveryList={deliveryList}
      />
      <PrivacyPolicyModal
        policyModal={policyModal}
        setPolicyModal={setPolicyModal}
      />
      <Header position={position} selfSellCarText={selfSellCarText} borderactive={location?.pathname?.includes("/selfSellCar")} />
      <SelfSellCarApplyTextDiv>
        <SelfSellCarApplyText>셀프셀카 신청</SelfSellCarApplyText>
        <SelfSellCarApplySubText>
          내 차를 파는 가장 현명한 방법! &nbsp;1분이면 끝
        </SelfSellCarApplySubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarDataDiv>
        <SelfSellCarApplyMenuText>1.기본 정보 입력</SelfSellCarApplyMenuText>
        <SelfSellCarLine />
        <SelfSellCarContainer>
          <SelfSellCarInputContainer>
            <SelfSellCarApplyLabel htmlFor="name">신청인</SelfSellCarApplyLabel>
            <SelfSellCarApplyInput
              autoFocus
              placeholder="신청인 입력"
              borderColor="#DEE2E6"
              autoComplete="off"
              id="name"
              name="name"
              value={applyData.name}
              onChange={onChange}
            />
          </SelfSellCarInputContainer>
          <SelfSellCarInputContainer>
            <SelfSellCarApplyLabel htmlFor="carNumber">
              차량 번호
            </SelfSellCarApplyLabel>
            <SelfSellCarApplyInput
              placeholder="차량번호 입력"
              autoComplete="off"
              id="carNumber"
              name="carNumber"
              borderColor={carNumberBorder}
              onChange={onChange}
            />
            {checkCarNumber ? null : (
              <ErrorText>올바른 차량번호를 입력해주세요.</ErrorText>
            )}
          </SelfSellCarInputContainer>
        </SelfSellCarContainer>

        <SelfSellCarContainer>
          <SelfSellCarInputContainer>
            <SelfSellCarApplyLabel htmlFor="phoneNumber">
              휴대폰 번호
            </SelfSellCarApplyLabel>
            <SelfSellCarApplyInput
              placeholder="휴대폰 번호 입력"
              autoComplete="off"
              id="phoneNumber"
              name="phoneNumber"
              borderColor={phoneNumberBorder}
              onChange={onChange}
            />
            {checkPhoneNumber ? null : (
              <ErrorText>올바른 휴대폰번호를 입력해주세요.</ErrorText>
            )}
          </SelfSellCarInputContainer>
          <SelfSellCarInputContainer>
            <SelfSellCarApplyLabel>차량 픽업 요청일</SelfSellCarApplyLabel>
            <DatePicker
              className="pickUpDatePicker"
              showToday={false}
              allowClear={false}
              disabledDate={disabledDate}
              locale={locale}
              placeholder="날짜 선택"
              autoComplete="off"
              onChange={dateHandler}
            />
          </SelfSellCarInputContainer>
        </SelfSellCarContainer>
        <SelfSellCarContainer justify>
          <Container>
            <SelfSellCarApplyLabel>차량 픽업 주소</SelfSellCarApplyLabel>
            <SelfSellCarApplySubText
              font
              onClick={() => {
                if (userShippingList?.isExist) {
                  setOpenListModal(true);
                } else {
                  setAddressModal(true);
                }
              }}
            >
              기존 배송지 선택 &nbsp;&nbsp;&gt;
            </SelfSellCarApplySubText>
          </Container>
          <Container>
            <SelfSellCarPostInput
              placeholder="우편번호 입력"
              readOnly
              value={zipCode}
            />
            <SelfSellCarPostButton onClick={() => setOpenPostModal(true)}>
              우편번호
            </SelfSellCarPostButton>
          </Container>
          <Container>
            <SelfSellCarApplyInput
              placeholder="주소 입력"
              readOnly
              borderColor="#DEE2E6"
              value={myAdress}
            />
          </Container>
          <Container>
            <SelfSellCarApplyInput
              placeholder="상세주소 입력"
              borderColor="#DEE2E6"
              autoComplete="off"
              value={pickUpAddressDetail}
              onChange={(event) => setPickUpAddressDetail(event.target.value)}
            />
          </Container>
        </SelfSellCarContainer>
      </SelfSellCarDataDiv>
      <SelfSellCarFooterDiv>
        <JoinCheckbox
          unactive={!agreementCheck}
          id="agreement"
          className="selfsellcar_checkbox"
          onChange={(event) => setAgreementCheck(event.target.checked)}
        />
        <AgreementText htmlFor="agreement">
          개인 정보 수집 및 이용에 동의합니다. (필수)
        </AgreementText>
        <AgreementText font onClick={() => { setPolicyModal(true); }}>더보기</AgreementText>
      </SelfSellCarFooterDiv>
      <SelfSellCarApplyButton
        disabled={buttonDisabled}
        onClick={applySelfSellCar}
      >
        셀프 셀카 신청
      </SelfSellCarApplyButton>
    </SelfSellCarWrapDiv>
  );
};

export default SelfSellCarApply;
