import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import Header from "../../components/Header";
import axios from "axios";
import { useSetRecoilState, useRecoilState } from "recoil";
import snsToken from "../../states/snsToken";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { BASIC_URL } from "../../components/CommonStyle";
import {
  CertifiCationModal,
  CertifiCationIdentyModal,
} from "../../components/login&join/Modals";
import tokenState from "../../states/TokenState";

const WrapConatiner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;

const TextContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.36px;
  color: #212529;
  margin-top: 140px;
`;

const SubTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  margin-top: 16px;
  color: #868e96;
`;

const ErrorText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.64px;
  color: #ff5252;
  margin-top: 12px;
`;

const MenuTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  color: #212529;
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  cursor: pointer;
`;

const InformationInput = styled.input`
  width: 522px;
  height: 60px;
  outline: none;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  padding-left: 16px;
  margin-top: 16px;

  ::placeholder {
    color: #dee2e6;
  }

  ${(props) =>
    props.small &&
    css`
      width: 384px;
    `};

  ${(props) =>
    props.border &&
    css`
      border-color: #ff383b;
    `};
`;
const ConfirmButton = styled.button`
  border: none;
  outline: none;
  width: 120px;
  height: 60px;
  background-color: #212529;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  cursor: pointer;
  margin-top: 16px;

  :disabled {
    background-color: #e9ecef;
  }
`;

const Line = styled.div`
  width: 1080px;
  height: 2px;
  background-color: #e9ecef;
  margin: 50px 0;
`;

const RowBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnBox = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
`;

const ConfirmBox = styled.div`
  width: 522px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const TimerText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  position: absolute;
  top: 38px;
  right: 160px;
`;

const SimpleJoinInfo = () => {
  const position = false;

  const snsTokenValue = useRecoilState(snsToken);
  const setSnsToken = useSetRecoilState(snsToken);
  const setToken = useSetRecoilState(tokenState);

  useEffect(() => {
    if (snsTokenValue?.type === undefined) {
      const naverLogin = new window.naver.LoginWithNaverId({
        clientId: "jAny6YPumptxESXef6CP",
        callbackUrl: "https://talgut.boundary.team",
      });

      naverLogin.init();

      naverLogin.getLoginStatus(function (status) {
        setSnsToken({
          id: naverLogin.user.getId(),
          type: "naver",
        });

        axios({
          url: `${BASIC_URL}/login/social`,
          method: "post",
          data: {
            token: naverLogin.user.getId(),
            type: "naver",
          },
        })
          .then((result) => {
            if (result.data.success === true) {
              setToken(result.data.token);
              navigate("/");
            } else {
              setSnsToken({
                id: naverLogin.user.getId(),
                type: "naver",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, []);

  const buttonRef = useRef();

  const [name, setName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
  const [phoneNumberInputBorder, setPhoneNumberInputBorder] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonDisabled2, setButtionDisabled2] = useState(true);
  const [buttonText, setButtonText] = useState("인증번호");

  useEffect(() => {
    const phoneNumberChecks = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberChecks) {
        setPhoneNumberCheck(true);
        setPhoneNumberInputBorder(false);
        setButtonDisabled(false);
      } else {
        setPhoneNumberCheck(false);
        setPhoneNumberInputBorder(true);
        setButtonDisabled(true);
      }
    } else {
      setPhoneNumberInputBorder(false);
      setPhoneNumberCheck(true);
      setButtonDisabled(true);
    }
  }, [phoneNumber]);

  const [modalOpen, setModalOpen] = useState(false);
  const [certifiCationModalOpen, setCertificationModalOpen] = useState(false);

  const socialJoin = () => {
    axios({
      url: `${BASIC_URL}/login/signup`,
      method: "post",
      data: {
        name,
        phoneNumber,
        token: snsTokenValue[0]?.id,
        signupType: snsTokenValue[0]?.type,
        marketingAgree: false,
      },
    })
      .then((res) => {
        if (res.data.success) {
          navigate("/login");
        } else {
          alert("이미 가입된 회원 입니다.");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 휴대폰 인증 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const [timeStart, setTimeStart] = useState(false);

  useEffect(() => {
    if (timeStart) {
      const countdown = setInterval(() => {
        if (parseInt(sec) > 0) {
          setSec(parseInt(sec) - 1);
        }
        if (parseInt(sec) === 0) {
          if (parseInt(min) === 0) {
            clearInterval(countdown);
            setTimeStart(false);
            setMin(4);
            setSec(0);
            alert("시간이 초과 되었습니다.");
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [timeStart, min, sec]);

  // 인증번호 요청 하기
  const getAuthenticationNumber = () => {
    setMin(4);
    setSec(0);
    setTimeStart(true);

    axios({
      url: `${BASIC_URL}/login/send/sms`,
      method: "post",
      data: {
        phoneNumber,
      },
    }).then((res) => {
      if (res.status === 201) {
        setButtionDisabled2(false);
        buttonRef.current.focus();
        setModalOpen(true);
        setButtonText("재전송");
      } else {
        alert("잠시 후 다시 시도해 주세요.");
      }
    });
  };
  const [authCode, setAuthCode] = useState("");

  useEffect(() => {
    if (authCode) {
      setButtionDisabled2(false);
    } else {
      setButtionDisabled2(true);
    }
  }, [authCode]);

  const checkAuthCode = () => {
    axios({
      url: `${BASIC_URL}/login/auth/check`,
      method: "post",
      data: {
        phoneNumber,
        authCode,
      },
    }).then((res) => {
      if (res.data.success) {
        setTimeStart(false);
        setMin(4);
        setSec(0);
        setCertificationModalOpen(true);
        setButtionDisabled2(true);
        setButtonDisabled(true);
        socialJoin();
      } else {
        alert("인증번호를 다시 입력해주세요.");
        buttonRef.current.focus();
      }
    });
  };

  const [birth, setBirth] = useState("");
  const [birthCheck, setBirthCheck] = useState(false);
  const [brithInputBorder, setBirthInputBorder] = useState(false);

  useEffect(() => {
    const birthChecks =
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        birth
      );
    if (birth) {
      if (birthChecks) {
        setBirthCheck(true);
        setBirthInputBorder(false);
      } else {
        setBirthCheck(false);
        setBirthInputBorder(true);
      }
    } else {
      setBirthInputBorder(false);
      setBirthCheck(true);
    }
  }, [birth]);

  const navigate = useNavigate();

  return (
    <WrapConatiner>
      <CertifiCationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CertifiCationIdentyModal
        setCertificationModalOpen={setCertificationModalOpen}
        certifiCationModalOpen={certifiCationModalOpen}
      />
      <Header position={position} />
      <Title>정보입력</Title>
      <SubTitle>가입에 필요한 정보를 입력해주세요.</SubTitle>
      <Line />
      <TextContainer>
        <MenuTitle>개인정보 입력</MenuTitle>
        <RowBox>
          <ColumnBox>
            <Label htmlFor="name">이름</Label>
            <InformationInput
              id="name"
              placeholder="이름 입력"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </ColumnBox>
          <ColumnBox>
            <Label htmlFor="birth">생년원일</Label>
            <InformationInput
              id="birth"
              border={brithInputBorder}
              placeholder="(-)없이 8자리 입력"
              value={birth}
              onChange={(event) => setBirth(event.target.value)}
            />
            {birthCheck ? null : (
              <ErrorText>올바른 생년원일을 입력해주세요.</ErrorText>
            )}
          </ColumnBox>
        </RowBox>
        <RowBox>
          <ColumnBox>
            <Label htmlFor="phoneNumber">휴대폰번호</Label>
            <ConfirmBox>
              <InformationInput
                small
                id="phoneNumber"
                value={phoneNumber}
                placeholder="(-)없이 입력"
                autoComplete="off"
                onChange={(event) => setPhoneNumber(event.target.value)}
                border={phoneNumberInputBorder}
              />
              <ConfirmButton
                disabled={buttonDisabled}
                onClick={getAuthenticationNumber}
              >
                {buttonText}
              </ConfirmButton>
            </ConfirmBox>
            {phoneNumberCheck ? null : (
              <ErrorText>올바른 휴대폰번호를 입력해주세요.</ErrorText>
            )}
          </ColumnBox>
          <ColumnBox>
            <Label>인증번호</Label>
            <ConfirmBox>
              <InformationInput
                small
                ref={buttonRef}
                placeholder="인증번호 입력"
                onChange={(event) => setAuthCode(event.target.value)}
                value={authCode}
              />
              <TimerText>
                {min.toString().padStart(2, "0")}:
                {sec.toString().padStart(2, "0")}{" "}
              </TimerText>
              <ConfirmButton disabled={buttonDisabled2} onClick={checkAuthCode}>
                인증 확인
              </ConfirmButton>
            </ConfirmBox>
          </ColumnBox>
        </RowBox>
      </TextContainer>
    </WrapConatiner>
  );
};

export default SimpleJoinInfo;
