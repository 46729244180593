import styled from "@emotion/styled/macro";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import { AgreementSubTitle, AgreementTitle, AgreemenntLine } from "./Joinstyle";
import { TextInput } from "./LoginContent";
import { NextButton, NextButton2 } from "./Joinstyle";
import { CertifiCationIdentyModal, CertifiCationModal } from "./Modals";
import grayCheck from "../../assets/img/passwordCorrectCheck.svg";
import correctCheck from "../../assets/img/passwordCorrect.png";
import { BASIC_URL } from "../CommonStyle";

const JoinUserInfoWrapDiv = styled.div`
  width: 100%;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SpoqaHanSansNeo;
`;

export const UserInfoInputDiv = styled.div`
  width: 1080px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const InputWrapDiv = styled.div`
  display: flex;
  width: 1080px;
`;

export const InputDiv = styled.div`
  height: auto;
  width: 522px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const InputLabel = styled.label`
  font-size: 18px;
  font-weight: 600;
`;

export const PhoneInput = styled.input`
  font-size: 18px;
  width: 384px;
  height: 60px;
  margin-top: 16px;
  border-radius: 10px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  outline: none;
  padding: 0 16px;
  border: 1px solid #dee2e6;
  color: #212529;

  &:focus {
    border: 1px solid #333;
    border-color: ${(props) => (props.color ? props.color : props.color)};
  }

  &::placeholder {
    color: #dee2e6;
  }
  font-weight: 400;
`;

export const CertificationButton = styled.button`
  font-family: SpoqaHanSansNeo;
  width: 120px;
  height: 60px;
  border: none;
  border-radius: 10px;
  background-color: #212529;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.72px;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 16px;
  &:disabled {
    cursor: not-allowed;
    background-color: #e9ecef;
  }
`;

export const ErrorText = styled.p`
  padding-top: 12px;
  padding-left: 3px;
  font-size: 16px;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #ff5252;
  margin-right: 10px;
`;

export const ErrorImg = styled.img`
  width: 24px;
  height: 24px;
  margin-top: 6px;
`;

export const CorrectText = styled(ErrorText)`
  color: #2443eb;
  font-size: 18px;
`;

const BirthDayInput = styled.input`
  font-size: 18px;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  margin-top: 16px;
  outline: none;
  padding: 0 16px;
  border: 1px solid #dee2e6;
  color: #333333;
  &:focus {
    border: 1px solid #333;
    border-color: ${(props) => (props.color ? props.color : props.color)};
  }
  &::placeholder {
    color: #dee2e6;
    font-size: 18px;
  }
`;

const JoinInfoStyle = () => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const [certifiCationModalOpen, setCertificationModalOpen] = useState(false);

  // Input State
  const [inputs, setInputs] = useState({
    name: "",
    birth: "",
    phoneNumber: "",
    email: "",
    password: "",
    passwordCheck: "",
  });

  const { name, birth, phoneNumber, email, password, passwordCheck } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // 회원가입 버튼 활성화
  const [buttonChange, setButtonChange] = useState(false);

  // 휴대폰 인증
  const [disabled, setDisabled] = useState(true);
  const [authCode, setAuthCode] = useState();
  const [disabledPhoneNumber, setDisabledPhoneNumber] = useState(false);

  // 휴대폰 인증 통과 State
  const [passPhone, setPassPhone] = useState(false);
  const [certificationButton, setCertificationButton] = useState(true);
  const phoneAutoFocus = useRef();
  const emailRef = useRef();
  const certifyNumberRef = useRef();

  // 휴대폰 인증 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const [timeStart, setTimeStart] = useState(false);

  useEffect(() => {
    if (timeStart) {
      const countdown = setInterval(() => {
        if (parseInt(sec) > 0) {
          setSec(parseInt(sec) - 1);
        }
        if (parseInt(sec) === 0) {
          if (parseInt(min) === 0) {
            clearInterval(countdown);
            setTimeStart(false);
            setMin(4);
            setSec(0);
            alert("시간이 초과 되었습니다.");
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [timeStart, min, sec]);

  const certifiCationPhoneNumber = () => {
    axios({
      url: `${BASIC_URL}/login/send/sms`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((result) => {
        if (result.status === 201) {
          if (result.data.status === 404) {
            alert(result.data.status_message);
          } else {
            setDisabled(false);
            certifyNumberRef.current.focus();
            setCertificationButton(false);
            setMin(4);
            setSec(0);
            setModalOpen(true);
            setTimeStart(true);
          }
        } else {
          alert("휴대폰 번호를 잘못 입력하셨습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 인증 확인
  const checkCertificationNumber = () => {
    axios({
      url: `${BASIC_URL}/login/auth/check`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        authCode: authCode,
      },
    })
      .then((result) => {
        if (result.data.success) {
          setCertificationModalOpen(true);
          setPassPhone(true);
          setDisabled(true);
          setTimeStart(false);
          setMin(4);
          setSec(0);
          setDisabledPhoneButton(true);
          setCertificationButton(true);
          setDisabledPhoneNumber(true);
        } else {
          alert(result.data.status_message);
          certifyNumberRef.current.focus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 이메일 중복 검사
  const [duplicateCheck, setDuplicateCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [duplicateButton, setDuplicateButton] = useState(true);
  const [emailBorder, setEmailBorder] = useState("#333");

  useEffect(() => {
    if (email.length === 0) {
      setDuplicateCheck(false);
      setDuplicateButton(true);
    }
  }, [email]);

  useEffect(() => {
    const emailExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );

    if (email) {
      if (emailExp === false) {
        setEmailCheck(false);
        setDuplicateButton(true);
      } else {
        setEmailCheck(true);
        setDuplicateButton(false);
      }
    } else {
      setEmailCheck(false);
    }

    if (email.length > 0 && emailExp === false) {
      setEmailBorder("#ff5252");
    } else if (email.length > 0 && emailExp === true) {
      setEmailBorder("#333");
    }
  }, [email, emailCheck, emailBorder]);
  const emailDuplicateCheck = () => {
    axios({
      url: `${BASIC_URL}/login/check/duplicateid`,
      method: "POST",
      data: {
        id: email,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          if (email.length === 0) {
            setDuplicateCheck(false);
          } else {
            setDuplicateCheck(true);
            setEmailBorder("#333");
          }
        } else {
          setDuplicateCheck(false);
          alert("이미 가입된 이메일 입니다.");
          setInputs({ ...inputs, email: "" });
          emailRef.current.focus();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 생년월일 유효성 검사
  const [birthCheck, setBirthCheck] = useState(false);
  const [birthInputBorder, setBirthInputBorder] = useState("#333");

  useEffect(() => {
    const birthChecks =
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        birth
      );
    if (birth) {
      if (birthChecks === false) {
        setBirthCheck(false);
      } else {
        setBirthCheck(true);
      }
    } else {
      setBirthCheck(false);
    }

    if (birth.length > 0 && birthChecks === false) {
      setBirthInputBorder("#ff5252");
    } else if (birth.length > 0 && birthChecks === true) {
      setBirthInputBorder("#333");
    }
  }, [birth]);

  // 휴대폰 유효성 검사
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
  const [disabledPhoneButton, setDisabledPhoneButton] = useState(true);
  const [phoneInputBorder, setPhoneInputBorder] = useState("#333");

  useEffect(() => {
    const phoneNumberChecks = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);

    if (phoneNumber) {
      if (phoneNumberChecks === false) {
        setPhoneNumberCheck(false);
      } else {
        setPhoneNumberCheck(true);
        setDisabledPhoneButton(false);
      }
    } else {
      setPhoneNumberCheck(false);
      setDisabledPhoneButton(true);
    }

    if (phoneNumber.length > 0 && phoneNumberChecks === false) {
      setPhoneInputBorder("#ff5252");
    } else if (phoneNumber.length > 0 && phoneNumberChecks === true) {
      setPhoneInputBorder("#333");
    }
  }, [phoneNumber, phoneNumberCheck, phoneInputBorder]);

  // 비밀번호 유효성 검사
  const [pwLengthCheck, setPwLengthCheck] = useState(false);
  const [engCheck, setEngCheck] = useState(false);
  const [specialCharCheck, setSpecialCharCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [sameCheck, setSameCheck] = useState(false);

  // 일치여부 State
  useEffect(() => {
    const englishCheck = /[a-zA-Z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /^(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (password.length > 7 && password.length < 21) {
        setPwLengthCheck(true);
      } else {
        setPwLengthCheck(false);
      }

      if (englishCheck === true) {
        setEngCheck(true);
      } else {
        setEngCheck(false);
      }

      if (specialCharCheck === true) {
        setSpecialCharCheck(true);
      } else {
        setSpecialCharCheck(false);
      }

      if (numberCheck === true) {
        setNumberCheck(true);
      } else {
        setNumberCheck(false);
      }
    } else {
      setEngCheck(false);
      setSpecialCharCheck(false);
      setNumberCheck(false);
      setPwLengthCheck(false);
    }
  }, [password, pwLengthCheck, engCheck, specialCharCheck, numberCheck]);

  // 비밀번호 일치여부
  const [checkPasswordInputBorder, setPasswordInputBorder] = useState("#333");
  useEffect(() => {
    if (passwordCheck) {
      if (passwordCheck === password) {
        setSameCheck(true);
        setPasswordInputBorder("#333");
      } else {
        setSameCheck(false);
        setPasswordInputBorder("#ff5252");
      }
    } else {
      setSameCheck(false);
      setPasswordInputBorder("#333");
    }
  }, [password, passwordCheck, sameCheck, checkPasswordInputBorder]);

  const CorrectText = styled(ErrorText)`
    color: #0081f5;
    font-size: 18px;
  `;

  // 마케팅 수신 동의
  const marketingAgree = useLocation()?.state?.marketingAgree;

  // 회원 가입 버튼 활성화
  useEffect(() => {
    if (
      birthCheck &&
      phoneNumberCheck &&
      emailCheck &&
      duplicateCheck &&
      engCheck &&
      specialCharCheck &&
      pwLengthCheck &&
      sameCheck &&
      passPhone
    ) {
      setButtonChange(true);
    } else {
      setButtonChange(false);
    }
  }, [
    passPhone,
    birthCheck,
    phoneNumberCheck,
    emailCheck,
    duplicateCheck,
    engCheck,
    numberCheck,
    specialCharCheck,
    pwLengthCheck,
    sameCheck,
  ]);

  // 회원가입 API
  const userJoin = () => {
    axios({
      url: `${BASIC_URL}/login/signup`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        birthday: birth,
        email: email,
        password: password,
        name: name,
        signupType: "email",
        marketingAgree: marketingAgree,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          alert("회원 가입 완료");
          navigate("/login");
        } else {
          if (result.data.message === "isExist") {
            alert("이미 가입된 휴대폰 번호 입니다.");
            setInputs({ ...inputs, phoneNumber: "" });
            certifyNumberRef.current.value = "";
            setDisabledPhoneNumber(false);
            setDisabled(false);
            setAuthCode("");
            phoneAutoFocus.current.focus();
          }
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <JoinUserInfoWrapDiv>
      <CertifiCationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CertifiCationIdentyModal
        certifiCationModalOpen={certifiCationModalOpen}
        setCertificationModalOpen={setCertificationModalOpen}
      />
      <AgreementTitle style={{ marginTop: 128 }}>정보 입력</AgreementTitle>
      <AgreementSubTitle>본인 인증이 필요합니다.</AgreementSubTitle>
      <AgreemenntLine />
      <UserInfoInputDiv>
        <AgreementTitle style={{ fontSize: 24, margin: 0 }}>
          개인정보 입력
        </AgreementTitle>
        <InputWrapDiv>
          <InputDiv>
            <InputLabel htmlFor="name">이름</InputLabel>
            <TextInput
              autoFocus
              onChange={onChange}
              name="name"
              value={name}
              placeholder="이름 입력"
              style={{ marginTop: 16 }}
              id="name"
              autoComplete="off"
            />
          </InputDiv>
          <InputDiv style={{ marginLeft: 40 }}>
            <InputLabel htmlFor="birth">생년월일</InputLabel>
            <BirthDayInput
              onChange={onChange}
              color={birthInputBorder}
              name="birth"
              value={birth}
              placeholder="(-) 없이 8자리 입력"
              id="birth"
              autoComplete="off"
              type="text"
            />
            {birthCheck ? null : birth.length === 0 ? null : (
              <ErrorText>올바른 생년원일을 입력해주세요.</ErrorText>
            )}
          </InputDiv>
        </InputWrapDiv>
        <InputWrapDiv>
          <InputDiv>
            <InputLabel htmlFor="phone_number">휴대폰 번호</InputLabel>
            <div style={{ display: "flex" }}>
              <PhoneInput
                ref={phoneAutoFocus}
                color={phoneInputBorder}
                autoComplete="off"
                onChange={onChange}
                readOnly={disabledPhoneNumber}
                value={phoneNumber}
                name="phoneNumber"
                placeholder="(-) 없이 숫자만 입력"
                id="phone_number"
                type="text"
              />
              {timeStart ? (
                <CertificationButton
                  onClick={() => {
                    certifiCationPhoneNumber();
                    setTimeStart(true);
                  }}
                  disabled={disabledPhoneButton}
                >
                  재전송
                </CertificationButton>
              ) : (
                <CertificationButton
                  onClick={() => {
                    certifiCationPhoneNumber();
                  }}
                  disabled={disabledPhoneButton}
                >
                  인증 하기
                </CertificationButton>
              )}
            </div>
            {phoneNumberCheck ? null : phoneNumber.length === 0 ? null : (
              <ErrorText>올바른 휴대폰 번호를 입력해주세요.</ErrorText>
            )}
          </InputDiv>
          <InputDiv style={{ marginLeft: 40 }}>
            <InputLabel htmlFor="certification_number">인증번호</InputLabel>
            <div style={{ display: "flex", position: "relative" }}>
              <AgreementTitle
                style={{
                  fontSize: 18,
                  marginTop: 0,
                  position: "absolute",
                  right: 160,
                  top: 38,
                }}
              >
                {min.toString().padStart(2, "0")}:
                {sec.toString().padStart(2, "0")}
              </AgreementTitle>
              <PhoneInput
                ref={certifyNumberRef}
                type="text"
                readOnly={disabled}
                placeholder="인증번호 입력"
                onChange={() => setAuthCode(certifyNumberRef.current.value)}
                id="certification_number"
              />
              <CertificationButton
                onClick={checkCertificationNumber}
                disabled={certificationButton}
              >
                인증 확인
              </CertificationButton>
            </div>
          </InputDiv>
        </InputWrapDiv>
      </UserInfoInputDiv>
      <AgreemenntLine style={{ marginTop: 100 }} />
      <UserInfoInputDiv>
        <AgreementTitle style={{ fontSize: 24, margin: 0 }}>
          가입정보 입력
        </AgreementTitle>
        <InputWrapDiv>
          <InputDiv>
            <InputLabel htmlFor="email">아이디</InputLabel>
            <div style={{ display: "flex" }}>
              <PhoneInput
                onChange={onChange}
                color={emailBorder}
                name="email"
                ref={emailRef}
                value={email}
                placeholder="이메일 입력"
                id="email"
                autoComplete="off"
              />
              <CertificationButton
                onClick={emailDuplicateCheck}
                disabled={duplicateButton}
              >
                중복확인
              </CertificationButton>
            </div>

            {emailCheck || email.length === 0 ? null : (
              <ErrorText>이메일을 형식에 맞게 입력해주세요.</ErrorText>
            )}
            {duplicateCheck ? (
              <CorrectText>사용가능한 이메일 입니다.</CorrectText>
            ) : null}
          </InputDiv>
        </InputWrapDiv>
        <InputWrapDiv>
          <InputDiv>
            <InputLabel htmlFor="password">비밀번호</InputLabel>
            <form>
              <TextInput
                autoComplete="password"
                onChange={onChange}
                name="password"
                value={password}
                placeholder="영문 + 숫자 + 특수문자 포함 8~20자 내외"
                style={{ marginTop: 16 }}
                type="password"
                id="password"
              />
            </form>
            <div style={{ display: "flex" }}>
              {engCheck ? (
                <>
                  <ErrorImg src={correctCheck} alt="check" />
                  <CorrectText>영문</CorrectText>
                </>
              ) : (
                <>
                  <ErrorImg src={grayCheck} alt="check" />
                  <ErrorText style={{ color: "#808080", fontSize: 18 }}>
                    영문
                  </ErrorText>
                </>
              )}
              {numberCheck ? (
                <>
                  <ErrorImg src={correctCheck} alt="check" />
                  <CorrectText>숫자</CorrectText>
                </>
              ) : (
                <>
                  <ErrorImg src={grayCheck} alt="check" />
                  <ErrorText style={{ color: "#808080", fontSize: 18 }}>
                    숫자
                  </ErrorText>
                </>
              )}
              {specialCharCheck ? (
                <>
                  <ErrorImg src={correctCheck} alt="check" />
                  <CorrectText>특수문자</CorrectText>
                </>
              ) : (
                <>
                  <ErrorImg src={grayCheck} alt="check" />
                  <ErrorText style={{ color: "#808080", fontSize: 18 }}>
                    특수문자
                  </ErrorText>
                </>
              )}
              {pwLengthCheck ? (
                <>
                  <ErrorImg src={correctCheck} alt="check" />
                  <CorrectText>8~20자</CorrectText>
                </>
              ) : (
                <>
                  <ErrorImg src={grayCheck} alt="check" />
                  <ErrorText style={{ color: "#808080", fontSize: 18 }}>
                    8~20자
                  </ErrorText>
                </>
              )}
            </div>
          </InputDiv>
          <InputDiv style={{ marginLeft: 40 }}>
            <InputLabel htmlFor="check-password">비밀번호 확인</InputLabel>
            <form>
              <BirthDayInput
                autoComplete="password-check"
                onChange={onChange}
                value={passwordCheck}
                name="passwordCheck"
                type="password"
                placeholder="영문 + 숫자 + 특수문자 포함 8~20자 내외"
                color={checkPasswordInputBorder}
                id="check-password"
              />
            </form>

            {sameCheck ? (
              <CorrectText>비밀번호 일치</CorrectText>
            ) : passwordCheck.length === 0 ? null : (
              <ErrorText>비밀번호가 일치하지 않습니다.</ErrorText>
            )}
          </InputDiv>
        </InputWrapDiv>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: 1080,
            marginTop: 114,
            marginBottom: 150,
          }}
        >
          {buttonChange ? (
            <NextButton2 onClick={userJoin}>회원가입</NextButton2>
          ) : (
            <NextButton disabled>회원가입</NextButton>
          )}
        </div>
      </UserInfoInputDiv>
    </JoinUserInfoWrapDiv>
  );
};

export default JoinInfoStyle;
