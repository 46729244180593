import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import Header from "../../components/Header";
import Background from "../../assets/img/MainBackground.png";
import reviewCarImg from "../../assets/img/reviewCar.png";
import { BASIC_URL } from "../../components/CommonStyle";
import { ReactComponent as Logo } from "../../assets/img/AutoOutletLogo.svg";
// import { ReactComponent as Car } from "../../assets/img/AutoOutletCar.svg";
import Car from "../../assets/img/AutoOutletCar.svg";
import { ReactComponent as Plus } from "../../assets/img/boardPlus.svg";
import {
  AutoOuteContentText2,
  MainReviewContainer,
  MainAutoOutletImage,
  TalgutImg,
  TalgutReviewContentDiv,
  TalgutReviewFooterDiv,
  TalgutReviewContentWrapDiv,
  AutoOuteContentText,
  TalgutReviewImgDiv,
  AutoOutletTextDiv,
  LinkText,
  AutoOutletContentImgDiv,
  AutoOutletContentWrapDiv,
  CompanyOverViewContainer,
  MainContentTextDiv,
  MainContentText,
  MainContentText2,
  MainContentText3,
  BoardContainer,
  MainPickUpLinkDiv,
  CompanyOverViewDiv,
  ComplayOverViewTextDiv,
  CompanyOverViewText,
  CompanyOverViewText3,
  CompanyOverViewText2,
  ApplicationButton,
  ApplicationButtonDiv,
  ApplicationButtonText,
  AutoOutletDiv,
  AutoOutletText,
  AutoOutletText2,
  AutoOutletContentDiv,
  TalgutReviewDiv,
  BoardDiv,
  BoardWrapDiv,
  BoardLine,
  BoardTitle,
  BoardContentDiv,
  BoardText,
  TalgutReviewNumber,
  LinkText2,
  BoardTitleDiv,
  TalgutReviewTextDiv,
} from "../../components/main/MainStyle";
import { Circle2 } from "../../components/pick-up/PickUpMainStyle";

const MainWrapDiv = styled.div`
  width: 100%;
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
  position: relative;

  &:after {
    background-image: url("${Background}");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const AboutUsLine = styled.div`
width : 372px;
height : 1px;
background : rgba(255,255,255 , .2);
margin-top : 30px;
margin-bottom :30px;
`

const Main = () => {
  const [width, setWidth] = useState("48px");
  const [width2, setWidth2] = useState("48px");
  const navigate = useNavigate();

  const [mainCampPost, setMainCampPost] = useState();
  const [mainFqsPost, setMainFqsPost] = useState();
  const [mainCarsPost, setMainCarsPost] = useState();
  const [mainAnnouncementsPost, setMainAnnouncementPost] = useState();

  // 메인 화면 노출 게시글
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/home/boards`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setMainCampPost(res.data.data.campings);
          setMainFqsPost(res.data.data.fqs);
          setMainCarsPost(res.data.data.cars);
          setMainAnnouncementPost(res.data.data.announcements);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      setMainCampPost();
      setMainFqsPost();
      setMainCarsPost();
      setMainAnnouncementPost();
    };
  }, []);

  const announcementPost = mainAnnouncementsPost?.map((board) => {
    return (
      <BoardTitleDiv key={board.id}>
        <BoardTitle
          style={{
            width: 818,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={() => navigate(`boardDetail/${board.id}`)}
        >
          [{board.type}] {board.title}
        </BoardTitle>
      </BoardTitleDiv>
    );
  });

  const carsPost = mainCarsPost?.map((board) => {
    return (
      <BoardTitleDiv key={board.id}>
        <BoardTitle
          style={{
            width: 818,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={() => navigate(`boardDetail/${board.id}`)}
        >
          [{board.type}] {board.title}
        </BoardTitle>
      </BoardTitleDiv>
    );
  });

  const campingsPost = mainCampPost?.map((board) => {
    return (
      <BoardTitleDiv key={board.id}>
        <BoardTitle
          style={{
            width: 818,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={() => navigate(`boardDetail/${board.id}`)}
        >
          [{board.type}] {board.title}
        </BoardTitle>
      </BoardTitleDiv>
    );
  });

  const fqsPost = mainFqsPost?.map((board) => {
    return (
      <BoardTitleDiv key={board.id}>
        <BoardTitle
          style={{
            width: 818,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={() => navigate(`boardDetail/${board.id}`)}
        >
          [{board.type}] {board.title}
        </BoardTitle>
      </BoardTitleDiv>
    );
  });

  // 메인 화면 오토아울렛
  const [mainAutoOutlet, setMainAutoOutlet] = useState();
  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASIC_URL}/home/auto-outlet?skip${0}&take=${8}`,
    })
      .then((res) => {
        if (res.data) {
          setMainAutoOutlet(res.data.cars);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 메인 화면 UserReview
  const [userReview, setUserReview] = useState();

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASIC_URL}/home/reviews`,
    })
      .then((res) => {
        if (res.data.success) {
          setUserReview(res.data.reviews);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const mainReview = userReview?.slice(0, 3);

  const HomeReview = mainReview?.map((review, index) => {
    return (
      <TalgutReviewContentDiv
        key={review?.id}
        onClick={() =>
          navigate("/mainReview", { state: { userReview: userReview } })
        }
      >
        <TalgutReviewNumber>{index + 1}.</TalgutReviewNumber>
        <TalgutReviewTextDiv>
          <AutoOuteContentText style={{ fontWeight: 700 }}>
            {review?.name}
          </AutoOuteContentText>
          <MainReviewContainer>
            <AutoOutletText2 style={{ marginTop: 0 }}>
              {review?.content}
            </AutoOutletText2>
          </MainReviewContainer>
          <TalgutReviewFooterDiv style={{ marginTop: 12 }}>
            <AutoOuteContentText2>
              {review?.type === "pickup" ? "픽업정비" : "셀프셀카"}{" "}
              &nbsp;이용자&nbsp;{review?.userName}님
            </AutoOuteContentText2>
            <AutoOuteContentText2 style={{ marginRight: 10 }}>
              {moment(review?.createdAt).format("YYYY.MM.DD")}
            </AutoOuteContentText2>
          </TalgutReviewFooterDiv>
        </TalgutReviewTextDiv>
      </TalgutReviewContentDiv>
    );
  });

  // 메인화면에 오토아울렛 8개 보여주기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/home/auto-outlet?skip=${0}&take=${8}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setMainAutoOutlet(res.data.cars);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => setMainAutoOutlet();
  }, []);

  const homeAutoOutletData = mainAutoOutlet?.map((auto) => {
    return (
      <AutoOutletContentDiv key={auto.id}>
        <AutoOutletContentImgDiv>
          <Logo style={{ width: 140, height: 56  , position : 'absolute' , top : 20 , left : 20 , zIndex : 99}} />
          {auto.image === null ? (
            <MainAutoOutletImage src={Car} alt="image" />
          ) : (
            <MainAutoOutletImage src={auto.image} alt="image" />
          )}
        </AutoOutletContentImgDiv>
        <AutoOutletTextDiv>
          <AutoOuteContentText>{auto.name}</AutoOuteContentText>
          <AutoOuteContentText2 style={{ marginTop: 12 }}>
            {auto.year} 년형 &nbsp;|&nbsp;{" "}
            {Number(auto.distanceDriven).toLocaleString("en")} km &nbsp;|&nbsp;{" "}
            {auto.fuel}
          </AutoOuteContentText2>
          <AutoOuteContentText style={{ fontWeight: 700, marginTop: 20 }}>
            {Number(auto.nowSellPrice).toLocaleString("en")}만원
          </AutoOuteContentText>
        </AutoOutletTextDiv>
      </AutoOutletContentDiv>
    );
  });

  return (
    <>
      <Header />
      <MainWrapDiv>
        <MainContentTextDiv>
          <MainContentText style={{ marginTop: 50 }}>
            More than convenient &nbsp;-
          </MainContentText>
          <MainContentText2>
            가장 편리한 자동차 비대면 수리 <br /> 탈것과 함께 하세요!
          </MainContentText2>
          <MainContentText3>
            3분이면 정보 입력끝! 간단하게 신청하고, 비대면 정비 받아보세요.{" "}
            <br />
            신청부터 픽업 & 딜리버리까지 편리한 비대면 정비 서비스를 제공합니다.
          </MainContentText3>
          <MainPickUpLinkDiv
            onMouseOver={() => setWidth("190px")}
            onMouseOut={() => setWidth("50px")}
            onClick={() => navigate("/pickUpMain")}
            style={{ marginLeft: 20 }}
          >
            픽업정비 바로가기 &nbsp; &gt;
            <Circle2 width={width} style={{ top: -18 }} />
          </MainPickUpLinkDiv>
        </MainContentTextDiv>
      </MainWrapDiv>

      <CompanyOverViewDiv>
        <ComplayOverViewTextDiv>
          <CompanyOverViewContainer column>
            <CompanyOverViewText>
              탈것은, 자동차를 운전하는
              <br />
              사람들이 필요로 하는 <br />
              모든 순간을 함께 합니다.
            </CompanyOverViewText>

            <MainPickUpLinkDiv
              style={{ marginLeft: 22, marginTop: 134, zIndex: 0 }}
              onMouseOver={() => setWidth2("210px")}
              onMouseOut={() => setWidth2("48px")}
              onClick={() => navigate("/aboutUs")}
            >
              회사 소개 바로가기 &nbsp;&nbsp;&nbsp;&nbsp;&gt;
              <Circle2
                style={{
                  background: "#212529",
                  top: -17,
                  left: -20,
                  height: 48,
                }}
                width={width2}
              />
            </MainPickUpLinkDiv>
          </CompanyOverViewContainer>

          <div style={{ marginLeft: 311 }}>
            <div >
              <CompanyOverViewText2>Buy And Sell</CompanyOverViewText2>
              <CompanyOverViewText3>
                내 차 사고 팔 때, 쉽고 안전하게 도움되는
                <br />
                믿음직스러운 중고차 거래 서비스
              </CompanyOverViewText3>
              <AboutUsLine/>
            </div>
            <div >
              <CompanyOverViewText2 >
                repair
              </CompanyOverViewText2>
              <CompanyOverViewText3>
                내 차에 문제가? 차량정비의
                <br />
                복잡함과 불편함을 없애주는 픽업 정비 서비스
              </CompanyOverViewText3>
              <AboutUsLine />
            </div>
            <CompanyOverViewText2>
              Keep
            </CompanyOverViewText2>
            <CompanyOverViewText3>
              내 차를 보관하고 싶다면? 언제든
              <br />
              입고 / 출고가 가능한 차량 보관 서비스
            </CompanyOverViewText3>
          </div>
        </ComplayOverViewTextDiv>
      </CompanyOverViewDiv>

      <ApplicationButtonDiv>
        <ApplicationButton onClick={() => navigate("/selfSellCarApply")}>
          {" "}
          <ApplicationButtonText>
            셀프 셀카 신청하기 &nbsp; &nbsp; &gt;{" "}
          </ApplicationButtonText>
        </ApplicationButton>
        <ApplicationButton
          style={{ marginLeft: 20 }}
          onClick={() => navigate("/pickUpApply")}
        >
          <ApplicationButtonText>
            픽업 정비 신청하기 &nbsp;&nbsp; &gt;
          </ApplicationButtonText>
        </ApplicationButton>
      </ApplicationButtonDiv>
      <AutoOutletDiv>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', width: 1080 }}>
          <MainContentTextDiv style={{ width: 'auto', flex: 1 }}>
            <AutoOutletText>오토아울렛</AutoOutletText>
            <AutoOutletText2>
              고객님께 탈것에서 직접 보유한 차량만을 소개합니다.
              <br />
              오토아울렛에서 다양한 차량을 만나보세요.
            </AutoOutletText2>
            <LinkText2 onClick={() => navigate("/autoOutlet")}>
              바로가기 &nbsp; &nbsp; &gt;
            </LinkText2>
          </MainContentTextDiv>
          <MainContentTextDiv style={{ width: 'auto', flex: 1 }}>
            <AutoOutletText2>
            <span style={{ color: "#FF6A13" }}>실시간으로 대구자동차경매장</span> 단지 내<br />
            매물을 확인해 보세요.
            </AutoOutletText2>
            <LinkText2 onClick={() => { window.open("http://www.talgut-daa.com/index.asp") }}>
              매물 확인하기 &nbsp; &nbsp; &gt;
            </LinkText2>
          </MainContentTextDiv>
        </div>
        <AutoOutletContentWrapDiv>
          {homeAutoOutletData}
        </AutoOutletContentWrapDiv>
      </AutoOutletDiv>
      <TalgutReviewDiv>
        <AutoOutletTextDiv
          style={{ flexDirection: "column", width: 1080, marginTop: 123 }}
        >
          <AutoOutletText style={{ color: "#fff" }}>탈것 어때?</AutoOutletText>
          <AutoOutletText2
            style={{ color: "#fff", marginTop: 16, marginLeft: 12 }}
          >
            탈것과 함께한 고객님들의 후기를 확인해보세요!
          </AutoOutletText2>
          <LinkText
            onClick={() =>
              navigate("/mainReview", { state: { userReview: userReview } })
            }
          >
            바로가기 &nbsp; &nbsp; &nbsp; &gt;
          </LinkText>
        </AutoOutletTextDiv>
        <TalgutReviewImgDiv>
          <TalgutImg src={reviewCarImg} alt="review" />
        </TalgutReviewImgDiv>
        <TalgutReviewContentWrapDiv>{HomeReview}</TalgutReviewContentWrapDiv>
      </TalgutReviewDiv>
      <BoardDiv>
        <AutoOutletTextDiv
          style={{
            flexDirection: "column",
            width: 1080,
            marginTop: 233,
            padding: 0,
          }}
        >
          <AutoOutletText>탈것 게시판</AutoOutletText>
          <AutoOutletText2 style={{ marginTop: 16 }}>
            탈것의 다양한 소식을 확인해보세요.
          </AutoOutletText2>
          <LinkText2 onClick={() => navigate("/board")}>
            바로가기 &nbsp; &nbsp; &gt;
          </LinkText2>
        </AutoOutletTextDiv>
        <BoardWrapDiv>
          <BoardContentDiv>
            <BoardContainer>
              <BoardText>공지사항</BoardText>
              <Plus style={{ cursor: "pointer" }} />
            </BoardContainer>
            <BoardLine />

            {announcementPost}
          </BoardContentDiv>
          <BoardContentDiv>
            <BoardContainer>
              <BoardText>자동차정보</BoardText>
              <Plus style={{ cursor: "pointer" }} />
            </BoardContainer>
            <BoardLine />

            {carsPost}
          </BoardContentDiv>
          <BoardContentDiv style={{ marginTop: 60 }}>
            <BoardContainer>
              <BoardText>캠핑정보</BoardText>
              <Plus style={{ cursor: "pointer" }} />
            </BoardContainer>
            <BoardLine />

            {campingsPost}
          </BoardContentDiv>
          <BoardContentDiv style={{ marginTop: 60 }}>
            <BoardContainer>
              <BoardText>F&A</BoardText>
              <Plus style={{ cursor: "pointer" }} />
            </BoardContainer>
            <BoardLine />

            {fqsPost}
          </BoardContentDiv>
        </BoardWrapDiv>
      </BoardDiv>
    </>
  );
};

export default Main;
