import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  SelfSellCarDataDiv,
  SelfSellCarApplyMenuText,
  HistorySubText,
  InspectionOptionDiv,
  SelfSellCarStepContainer,
  SelfSellCarContainer,
  InspectionCategoryText,
  DropShippingText,
  ConfirmCarContainer,
  SelfSellCarLine4,
  InspectionText,
  InspectionLine,
  ConfirmText2,
  InspectionLabel,
  UserSelectContainer,
  CloseInspectionContainer,
  PayBoxPriceText,
  PayBoxLine,
  RecommendOptionBox,
  PayBoxContainer,
  SelfSellCarInputContainer,
  InspectionOptionCategory,
  InspectionContainer,
  InspectionButton,
  SelfSellCarListDiv,
  SelfSellCarApplyButton,
  InspectionBox,
  CarInfoDIv,
  InspectionPayBox,
  CarInspectionImg,
  InspectionOptionCategory2,
} from "../../../components/selfSellCar/SelfSellCarStyle";
import {
  CarStorageText4,
  CarStorageBadgeText2,
} from "../../../components/carStorage/CarStorageStyle";
import "../../../components/selfSellCar/SelfSellCar.css";
import { useState } from "react";
import { ReactComponent as Icon } from "../../../assets/icons/SelfSellCarIcon6.svg";
import { useEffect } from "react";
import axios from "axios";
import { BASIC_URL } from "../../../components/CommonStyle";
import { useNavigate, useParams } from "react-router";
import { useToken } from "../../../hooks/useToken";
import { CategoryText } from "../../../components/InspectionImage";
import { IconText } from "../../../components/InspectionImage";
import { ReactComponent as Icon1 } from "../../../assets/icons/inpsection/inpsection_01.svg";
import { ReactComponent as Icon2 } from "../../../assets/icons/inpsection/inspection_02.svg";
import { ReactComponent as Icon3 } from "../../../assets/icons/inpsection/inspection_03.svg";
import { ReactComponent as Icon4 } from "../../../assets/icons/inpsection/inspection_04.svg";
import { ReactComponent as Icon5 } from "../../../assets/icons/inpsection/inspection_05.svg";
import { ReactComponent as Icon6 } from "../../../assets/icons/inpsection/inspection_06.svg";
import { ReactComponent as Icon7 } from "../../../assets/icons/inpsection/inspection_07.svg";
import { ReactComponent as Icon8 } from "../../../assets/icons/inpsection/inspection_08.svg";
import { ReactComponent as Icon9 } from "../../../assets/icons/inpsection/inspection_09.svg";
import { ReactComponent as Icon10 } from "../../../assets/icons/inpsection/inspection_10.svg";
import { ReactComponent as Icon11 } from "../../../assets/icons/inpsection/inspection_11.svg";
import { ReactComponent as Icon12 } from "../../../assets/icons/inpsection/inspection_12.svg";
import { JoinCheckbox } from "../../../components/login&join/Joinstyle";
import {
  InspecionIconContainer,
  ContentBox,
  InspectionText as ContentText,
} from "../../../components/InspectionImage";

export const CheckBoxContainer = styled(InspectionBox)`
  margin-top: 35px;
`;
export const CheckBoxDiv = styled(CarInfoDIv)`
  margin-top: 0;
  width: 177px;
`;

export const CheckBoxLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #adb5bd;
  letter-spacing: -0.72px;
  margin-left: 8px;

  ${(props) =>
    props.colorChagne &&
    css`
      color: #212529;
    `};
`;

const UserSelectCheckbox = styled(JoinCheckbox)`
  margin-right: 8px;
`;

const VehicleInspection = ({ selfSellCarData }) => {
  const { id } = useParams();
  const { carInsepction } = selfSellCarData;
  const { sortedData } = carInsepction;
  const { repairtParts } = selfSellCarData;
  const token = useToken();
  const navigate = useNavigate();
  const [premiumButton, setPremiumButton] = useState(true);
  const [economicButton, setEconomicButton] = useState(false);
  const [userSelectButton, setUserSelectButton] = useState(false);
  const [overhaul, setOverhaul] = useState([]);
  const [totalDay, setTotalDay] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [grade, setGrade] = useState("프리미엄");

  useEffect(() => {
    if (repairtParts) {
      const flatArray = repairtParts?.map((item) => item.data).flat();
      setOverhaul(
        flatArray?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            price: item?.originalPrice,
            day: item?.day,
          };
        })
      );
    }
  }, [repairtParts]);

  useEffect(() => {
    if (overhaul?.length > 0) {
      setTotalPrice(
        overhaul?.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.price;
        }, 0)
      );

      setTotalDay(
        overhaul?.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.day;
        }, 0)
      );
    }
  }, [overhaul]);

  const premiumOptionHandler = () => {
    setGrade("프리미엄");
    setEconomicButton(false);
    setPremiumButton(true);
    setUserSelectButton(false);

    const flatArray = repairtParts?.map((item) => item.data).flat();
    setOverhaul(
      flatArray?.map((item) => {
        return {
          id: item?.id,
          name: item?.name,
          price: item?.originalPrice,
          day: item?.day,
        };
      })
    );
  };

  const EconomicOptionHanlder = () => {
    setGrade("이코노믹");
    setEconomicButton(true);
    setPremiumButton(false);
    setUserSelectButton(false);

    const recommendArray = repairtParts
      ?.map((item) => item.data)
      .flat()
      .filter((item) => item?.recommend === true);

    setOverhaul(
      recommendArray?.map((item) => {
        return {
          id: item?.id,
          name: item?.name,
          price: item?.originalPrice,
          day: item?.day,
        };
      })
    );
  };

  // 상품화 신청하기
  const [checkbox, setCheckbox] = useState(false);
  const [applyButton, setApplyButton] = useState(true);

  useEffect(() => {
    if (checkbox && totalPrice > 0 && totalDay > 0) {
      setApplyButton(false);
    } else {
      setApplyButton(true);
    }
  }, [checkbox, totalPrice, totalDay]);

  const applyCommercialization = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/overhaul`,
      method: "POST",
      data: {
        overhaul,
        totalDay,
        totalPrice,
        grade,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          navigate(`/CommercializationApply/${id}`, {
            state: {
              overhaul,
              grade,
              totalPrice,
              totalDay,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 신청 박스
  const payBoxOption = repairtParts?.map((option) => {
    const boxData = option?.data?.map((item) => {
      return (
        <>
          <InspectionContainer
            style={{ marginTop: 30, justifyContent: "space-between" }}
          >
            <InspectionText>{item?.name}</InspectionText>
            <InspectionText>
              {overhaul?.find((name) => name?.name === item?.name)
                ? `${Number(item?.originalPrice).toLocaleString("en")}원`
                : "-"}
            </InspectionText>
          </InspectionContainer>
        </>
      );
    });
    return boxData;
  });

  const repairOption = repairtParts?.map((option) => {
    const detailOption = option?.data?.map((item) => {
      return (
        <>
          <InspectionOptionCategory>
            <InspectionText>{item?.name}</InspectionText>
            <InspectionText>
              {Number(item?.originalPrice).toLocaleString("en")}원
            </InspectionText>
          </InspectionOptionCategory>
          {option?.data?.length > 1 ? <InspectionLine /> : null}
        </>
      );
    });
    const economicDetailOption = option?.data?.map((item) => {
      if (item?.recommend) {
        return (
          <>
            <InspectionOptionCategory2 border={item?.recommend}>
              <InspectionText>{item?.name}</InspectionText>
              <InspectionText>
                {Number(item?.originalPrice).toLocaleString("en")}원
              </InspectionText>
            </InspectionOptionCategory2>
            <InspectionLine />
          </>
        );
      } else {
        return (
          <>
            <RecommendOptionBox>
              <InspectionText>{item?.name}</InspectionText>
              <InspectionText>
                {Number(item?.originalPrice).toLocaleString("en")}원
              </InspectionText>
            </RecommendOptionBox>
            <InspectionLine />
          </>
        );
      }
    });

    console.log(overhaul);

    const userSelectOption = option?.data?.map((item) => {
      const checkboxHandler = (event) => {
        const findOption = option?.data?.find(
          (filter) => filter.id === event.target.id
        );
        if (event.target.checked) {
          setOverhaul([
            ...overhaul,
            {
              id: findOption?.id,
              name: findOption?.name,
              price: findOption?.originalPrice,
              day: findOption?.day,
            },
          ]);
        } else {
          setOverhaul(overhaul?.filter((item) => item?.id !== event.target.id));
        }

        return event.target.checked;
      };

      return (
        <>
          <InspectionOptionCategory2 border={overhaul?.find((option) => option.name === item.name)}>
            <UserSelectContainer>
              <UserSelectCheckbox
                unactive={!overhaul?.find((option) => option.name === item.name)}
                className="selfsellcar_checkbox"
                id={item?.id}
                onChange={checkboxHandler}
              />
              <InspectionLabel htmlFor={item?.id} style={{ cursor: "pointer" }}>
                {item?.name}
              </InspectionLabel>
            </UserSelectContainer>
            <InspectionText>
              {Number(item?.originalPrice).toLocaleString("en")}원
            </InspectionText>
          </InspectionOptionCategory2>
          <InspectionLine />
        </>
      );
    });
    return (
      <InspectionOptionDiv>
        <InspectionContainer
          style={{ marginTop: 36, justifyContent: "space-between" }}
        >
          <InspectionCategoryText>
            {option?.mainCategory}
          </InspectionCategoryText>
          <InspectionCategoryText>가격</InspectionCategoryText>
        </InspectionContainer>
        <InspectionLine style={{ marginTop: 20 }} />
        {premiumButton
          ? detailOption
          : economicButton
          ? economicDetailOption
          : userSelectOption}
      </InspectionOptionDiv>
    );
  });

  return (
    <SelfSellCarDataDiv margin>
      <SelfSellCarStepContainer>
        <SelfSellCarApplyMenuText>점검결과</SelfSellCarApplyMenuText>
        <HistorySubText>
          성능 점검 결과(사고유무)와 개선이 필요한 부위(점검사항)을 확인할 수
          있습니다.
        </HistorySubText>
      </SelfSellCarStepContainer>
      <SelfSellCarLine4 />
      <SelfSellCarContainer>
        <InspectionText>자동차 상태 표기</InspectionText>
        <InspecionIconContainer style={{ width: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: 518,
            }}
          >
            {" "}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon1 /> <IconText>교환</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon2 /> <IconText>판금/용접</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon3 /> <IconText>교환필요</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon4 /> <IconText>판금필요</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Icon5 /> <IconText>조정필요</IconText>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 14,
              width: 518,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon6 /> <IconText>도장</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon7 /> <IconText>도장필요 </IconText>{" "}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 22,
                flex: 1,
              }}
            >
              <Icon8 /> <IconText>꺾임</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                marginRight: 22,
              }}
            >
              <Icon9 /> <IconText>부식</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Icon10 /> <IconText>상처</IconText>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 14,
              width: 518,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                marginRight: 7
              }}
            >
              <Icon11 /> <IconText>덴트</IconText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Icon12 /> <IconText>복원</IconText>
            </div>
            <div style={{ flex: 1 }} />
            <div style={{ flex: 1 }} />
            <div style={{ flex: 1 }} />
          </div>
        </InspecionIconContainer>
      </SelfSellCarContainer>
      <CarInspectionImg alt="" src={carInsepction?.insepectionImage} />
      <CloseInspectionContainer>
        <CategoryText>
          • &nbsp;외판부위의 교환, 부식, 판금 및 용접수리
        </CategoryText>
        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[0].hood?.check}
            />
            <ContentText active={sortedData[0].hood?.check}>
              1. 후드
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              unactive={!sortedData[1].frontHenderL?.check}
              className="selfsellcar_checkbox"
            />
            <ContentText active={sortedData[1].frontHenderL?.check}>
              2. 프론트 휀더(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[2].frontHenderR?.check}
            />
            <ContentText active={sortedData[2].frontHenderR?.check}>
              3. 프론트 휀더(우)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[3].frontDoorL?.check}
            />
            <ContentText active={sortedData[3].frontDoorL?.check}>
              4. 프론트 도어(좌)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[4].frontDoorR?.check}
            />
            <ContentText active={sortedData[4].frontDoorR?.check}>
              5. 프론트 도어(우)
            </ContentText>
          </ContentBox>

          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[5].rearDoorL?.check}
            />
            <ContentText active={sortedData[5].rearDoorL?.check}>
              6. 리어 도어(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[6].rearDoorR?.check}
            />
            <ContentText active={sortedData[6].rearDoorR?.check}>
              7. 리어 도어(우)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[7].trunkRid?.check}
            />
            <ContentText active={sortedData[7].trunkRid?.check}>
              8. 트렁크리드
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[8].radiatorSupport?.check}
            />
            <ContentText active={sortedData[8].radiatorSupport?.check}>
              9. 라디에이터 서포트
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[9].loopPanel?.check}
            />
            <ContentText active={sortedData[9].loopPanel?.check}>
              10. 루프 패널
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[10].quarterPanelL?.check}
            />
            <ContentText active={sortedData[10].quarterPanelL?.check}>
              11. 쿼터 패널(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[11].quarterPanelR?.check}
            />
            <ContentText active={sortedData[11].quarterPanelR?.check}>
              12. 쿼터 패널(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
        <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[12].sideSealPanelL?.check}
            />
            <ContentText active={sortedData[12].sideSealPanelL?.check}>
              13. 사이드실 패널(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[13].sideSealPanelR?.check}
            />
            <ContentText active={sortedData[13].sideSealPanelR?.check}>
              14. 사이드실 패널(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CategoryText style={{ marginTop: 67 }}>
          • &nbsp;주요골격 부위의 교환,부식,판금 및 용접수리
        </CategoryText>

        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[14].frontPanel?.check}
            />
            <ContentText active={sortedData[14].frontPanel?.check}>
              15. 프론트 패널
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[15].crossMember?.check}
            />
            <ContentText active={sortedData[15].crossMember?.check}>
              16. 크로스 멤버
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[16].insidePanelL?.check}
            />
            <ContentText active={sortedData[16].insidePanelL?.check}>
              17. 인사이드 패널(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[17].insidePanelR?.check}
            />
            <ContentText active={sortedData[17].insidePanelR?.check}>
              18. 인사이드 패널(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[18].rearPanel?.check}
            />
            <ContentText active={sortedData[18].rearPanel?.check}>
              19. 리어 패널
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[19].trunkFloor?.check}
            />
            <ContentText active={sortedData[19].trunkFloor?.check}>
              20. 트렁크 플로어
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[20].frontSideMemberL?.check}
            />
            <ContentText active={sortedData[20].frontSideMemberL?.check}>
              21. 프론트 사이드 멤버(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[21].frontSideMemberR?.check}
            />
            <ContentText active={sortedData[21].frontSideMemberR?.check}>
              22. 프론트 사이드 멤버(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[22].rearSideL?.check}
            />
            <ContentText active={sortedData[22].rearSideL?.check}>
              23. 리어 사이드 멤버(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[23].rearSideR?.check}
            />
            <ContentText active={sortedData[23].rearSideR?.check}>
              24. 리어 사이드 멤버(우)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[24].frontWheelL?.check}
            />
            <ContentText active={sortedData[24].frontWheelL?.check}>
              25. 프론트 휠하우스(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[25].frontWheelR?.check}
            />
            <ContentText active={sortedData[25].frontWheelR?.check}>
              26. 프론트 휠하우스 (우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[26].rearWheelL?.check}
            />
            <ContentText active={sortedData[26].rearWheelL?.check}>
              27. 리어 휠하우스(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[27].rearWheelR?.check}
            />
            <ContentText active={sortedData[27].rearWheelR?.check}>
              28. 리어 휠하우스(우)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[28].fillerAL?.check}
            />
            <ContentText active={sortedData[28].fillerAL?.check}>
              29. 필러 패널A(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[29].fillerAR?.check}
            />
            <ContentText active={sortedData[29].fillerAR?.check}>
              30. 필러 패널A(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[30].fillerBL?.check}
            />
            <ContentText active={sortedData[30].fillerBL?.check}>
              31. 필러 패널B(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[31].fillerBR?.check}
            />
            <ContentText active={sortedData[31].fillerBR?.check}>
              32. 필러 패널B(우)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[32].fillerCL?.check}
            />
            <ContentText active={sortedData[32].fillerCL?.check}>
              33. 필러 패널C(좌)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[33].fillerCR?.check}
            />
            <ContentText active={sortedData[33].fillerCR?.check}>
              34. 필러 패널C(우)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[34].packageTray?.check}
            />
            <ContentText active={sortedData[34].packageTray?.check}>
              35. 패키지트레이
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[35].dashPanel?.check}
            />
            <ContentText active={sortedData[35].dashPanel?.check}>
              36. 대쉬 패널
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[36].floorPanel?.check}
            />
            <ContentText active={sortedData[36].floorPanel?.check}>
              37. 플로어 패널(바닥)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[37].frontGlass?.check}
            />
            <ContentText active={sortedData[37].frontGlass?.check}>
              38. 앞 유리
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>

        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[38].backGlass?.check}
            />
            <ContentText active={sortedData[38].backGlass?.check}>
              39. 뒷 유리
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[39].rearLeftFrontGlass?.check}
            />
            <ContentText active={sortedData[39].rearLeftFrontGlass?.check}>
              40. 옆 유리 (운전석 앞)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[40].rearLeftBackGlass?.check}
            />
            <ContentText active={sortedData[40].rearLeftBackGlass?.check}>
              41. 옆 유리 (운전석 뒤)
            </ContentText>
          </ContentBox>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[41].rearRightFrontGlass?.check}
            />
            <ContentText active={sortedData[41].rearRightFrontGlass?.check}>
              42. 옆 유리 (조수석 앞)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <ContentBox>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!sortedData[42].rearRightBackGlass?.check}
            />
            <ContentText active={sortedData[42].rearRightBackGlass?.check}>
              43. 옆 유리 (조수석 뒤)
            </ContentText>
          </ContentBox>
        </CheckBoxContainer>
      </CloseInspectionContainer>
      <SelfSellCarStepContainer style={{ marginTop: 200 }}>
        <SelfSellCarApplyMenuText>수리 부위 선택</SelfSellCarApplyMenuText>
      </SelfSellCarStepContainer>
      <SelfSellCarLine4 />
      <InspectionBox style={{ marginTop: 70 }}>
        <InspectionButton
          margin
          changebackground={premiumButton}
          onClick={premiumOptionHandler}
        >
          프리미엄(신차급)
        </InspectionButton>
        <InspectionButton
          changebackground={economicButton}
          margin
          onClick={EconomicOptionHanlder}
        >
          이코노믹(추천형)
        </InspectionButton>
        <InspectionButton
          changebackground={userSelectButton}
          onClick={() => {
            setEconomicButton(false);
            setPremiumButton(false);
            setGrade("선택형(고객추천)");
            setUserSelectButton(true);
            setTotalDay(0);
            setTotalPrice(0);
            setOverhaul([]);
          }}
        >
          선택형(고객추천)
        </InspectionButton>
      </InspectionBox>
      <InspectionBox style={{ marginTop: 30, alignItems: "center" }}>
        <Icon style={{ marginRight: 8 }} />

        {premiumButton ? (
          <CarStorageText4>
            프리미엄 등급은 탈것이 자신 있게 추천하는 최고 등급입니다. 회원님의
            차를 새 차로 만들어드립니다!
          </CarStorageText4>
        ) : economicButton ? (
          <CarStorageText4>
            이코노믹(추천형) 옵션은 탈것에서 적극 추천하는 수리부위만을
            상품화합니다.
          </CarStorageText4>
        ) : (
          <CarStorageText4>
            선택형(고객추천) 옵션은 고객님이 수리하실 부위를 자유롭게 선택할 수
            있습니다.
          </CarStorageText4>
        )}
      </InspectionBox>
      <SelfSellCarListDiv>
        <SelfSellCarInputContainer>{repairOption}</SelfSellCarInputContainer>
        <InspectionPayBox>
          <PayBoxContainer>
            <SelfSellCarApplyMenuText>최종 결제 금액</SelfSellCarApplyMenuText>
            <PayBoxLine />
            {payBoxOption}
            <PayBoxLine style={{ marginTop: 30 }} />
            <InspectionContainer
              style={{ marginTop: 34, justifyContent: "space-between" }}
            >
              <DropShippingText>총 결제 금액</DropShippingText>
              <PayBoxPriceText>
                {Number(totalPrice).toLocaleString("en")}원
              </PayBoxPriceText>
            </InspectionContainer>
            <InspectionContainer
              style={{ marginTop: 30, justifyContent: "space-between" }}
            >
              <DropShippingText>예상 소요일</DropShippingText>
              <DropShippingText>{totalDay}일</DropShippingText>
            </InspectionContainer>

            <ConfirmCarContainer style={{ marginTop: 53 }}>
              <JoinCheckbox
                unactive={!checkbox}
                className="selfsellcar_checkbox"
                checked={checkbox}
                onChange={(event) => setCheckbox(event.target.checked)}
              />
              <CarStorageBadgeText2>
                주문할 상품 및 결제에 확인 하였으며 동의합니다.
              </CarStorageBadgeText2>
            </ConfirmCarContainer>
            <ConfirmCarContainer style={{ justifyContent: "center" }}>
              <SelfSellCarApplyButton
                disabled={applyButton}
                style={{ marginTop: 32 }}
                onClick={applyCommercialization}
              >
                상품화 신청하기
              </SelfSellCarApplyButton>
            </ConfirmCarContainer>
            <ConfirmCarContainer style={{ marginTop: 40 }}>
              <ConfirmText2>
                • 예상소요일은 영업일 기준이며, 부품수급 등의 이유로 변경될 수
                있습니다.
              </ConfirmText2>
            </ConfirmCarContainer>
            <ConfirmCarContainer style={{ marginTop: 19 }}>
              <ConfirmText2>
                • 상품화 비용은 차량판매 후 정산대금에서 차감되며, <br />
                차량이 미판매 될 경우, 비용은 별도 청구될 수 있습니다.
              </ConfirmText2>
            </ConfirmCarContainer>
          </PayBoxContainer>
        </InspectionPayBox>
      </SelfSellCarListDiv>
    </SelfSellCarDataDiv>
  );
};

export default VehicleInspection;
