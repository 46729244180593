import { useNavigate } from "react-router";
import { useState } from "react";
import {
  CarStorageWrapDiv,
  CarStorageTextDiv,
  CarStorageText,
  CarStorageEngText,
  CarStorageText2,
  CarStorageStepDiv,
  CarStorageStepContentDiv,
  CarStorageDiv,
  CarStorageEngText2,
  CarStorageText3,
  CarStorageText4,
  CarStorageEngText3,
  CatStorageText6,
  Container,
  AnimationDiv,
  CarStorageContentDiv,
  CarStorageText5,
} from "../../components/carStorage/CarStorageStyle";
import {
  SelfSellCarImageDiv,
  SelfSellCarContentDiv,
} from "../../components/selfSellCar/SelfSellCarStyle";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import { ReactComponent as SelfSellCarIcon } from "../../assets/icons/SelfSellCarIcon.svg";
import { ReactComponent as SelfSellCarIcon2 } from "../../assets/icons/SelfSellCarIcon2.svg";
import { ReactComponent as SelfSellCarIcon3 } from "../../assets/icons/SelfSellCarIcon3.svg";
import { ReactComponent as SelfSellCarIcon4 } from "../../assets/icons/SelfSellCarIcon4.svg";
import { ReactComponent as SelfSellCarIcon5 } from "../../assets/icons/SelfSellCarIcon5.svg";
import { useUserType } from "../../hooks/useUserInfo";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DisabledMenu from "../main/DisabledMenu";
import { useToken } from "../../hooks/useToken";

const SelfSellCarMain = () => {
  const navigate = useNavigate();
  const position = false;
  const selfSellCarText = true;
  const hidden = false;

  const [width, setWidth] = useState("50px");
  const [color, setColor] = useState("#212529");

  const userType = useUserType();
  const token = useToken();

  return (
    <CarStorageWrapDiv>
      {token && userType !== "" && userType === "기업회원" ? (
        <DisabledMenu />
      ) : userType === "" && token ? null : (
        <>
          <Header position={position} selfSellCarText={selfSellCarText} />
          <HeaderMenuBar hidden={hidden}>
            <HeaderMenuText
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/selfSellCarApply")}
            >
              셀프 셀카 신청
            </HeaderMenuText>
            <HeaderMenuText
              style={{ marginLeft: 28, cursor: "pointer" }}
              onClick={() => navigate("/selfSellCarList")}
            >
              나의 신청 현황
            </HeaderMenuText>
          </HeaderMenuBar>
          <SelfSellCarImageDiv>
            <CarStorageTextDiv>
              <CarStorageEngText>More than sensible -</CarStorageEngText>
              <CarStorageText>
                내 차를 파는 가장 현명한 방법 <br />
                1분이면 신청 끝!
              </CarStorageText>
              <CarStorageText2>
                "내 차 팔기" 어떤 방법으로 하고싶으신가요? <br />
                가장 합리적인 내 차 팔기 경매부터 위탁판매까지 한 번에
                만나보세요.
              </CarStorageText2>
            </CarStorageTextDiv>
          </SelfSellCarImageDiv>
          <CarStorageStepDiv>
            <CarStorageStepContentDiv>
              <CarStorageDiv>
                <CarStorageEngText2>Step Guide</CarStorageEngText2>
                <CarStorageText3 style={{ marginTop: 20 }}>
                  차량 점검, 경매, 판매 까지 <br />
                  집에서 편하게 이용해보세요.
                </CarStorageText3>
              </CarStorageDiv>{" "}
              <CarStorageDiv>
                <SelfSellCarContentDiv>
                  <Container>
                    <SelfSellCarIcon style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.1</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    전문 상담원이 전화를 통해 서비스를 자세히 안내해드립니다.
                  </CarStorageText4>
                </SelfSellCarContentDiv>
                <SelfSellCarContentDiv>
                  <Container>
                    <SelfSellCarIcon2 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.2</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    고객님이 원하는 곳으로 방문하여 픽업 후 정밀점검을
                    시작합니다.
                  </CarStorageText4>
                </SelfSellCarContentDiv>
                <SelfSellCarContentDiv>
                  <Container>
                    <SelfSellCarIcon3 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.3</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    진단 결과를 통해 상담원과 판매전략을 수립합니다.
                  </CarStorageText4>
                </SelfSellCarContentDiv>
                <SelfSellCarContentDiv>
                  <Container>
                    <SelfSellCarIcon4 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.4</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    전국의 검증 받은 딜러들이 차량 매입을 위해 치열하게
                    경쟁합니다.
                  </CarStorageText4>
                </SelfSellCarContentDiv>
                <SelfSellCarContentDiv>
                  <Container>
                    <SelfSellCarIcon5 style={{ marginRight: 24 }} />
                    <CarStorageEngText3>Step.5</CarStorageEngText3>
                  </Container>
                  <CarStorageText4>
                    전국의 검증 받은 딜러들이 차량 매입을 위해 치열하게
                    경쟁합니다.
                  </CarStorageText4>
                </SelfSellCarContentDiv>
                <CarStorageContentDiv
                  style={{ border: "none", marginTop: 120 }}
                >
                  <Container>
                    <CarStorageDiv>
                      <CarStorageText5>
                        지금 바로 서비스를 이용해보세요.
                      </CarStorageText5>
                      <CarStorageText2
                        style={{
                          color: "#212529",
                          marginTop: 12,
                          marginLeft: 12,
                        }}
                      >
                        내 차를 파는 가장 현명한 방법, 탈것에서 시작하세요!
                      </CarStorageText2>
                    </CarStorageDiv>
                  </Container>
                  <Container
                    onMouseOver={() => {
                      setColor("#fff");
                      setWidth("170px");
                    }}
                    onMouseOut={() => {
                      setColor("#212529");
                      setWidth("50px");
                    }}
                    onClick={() => navigate("/selfSellCarApply")}
                  >
                    <AnimationDiv width={width} />
                    <CatStorageText6 color={color}>
                      신청 바로가기 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;
                    </CatStorageText6>
                  </Container>
                </CarStorageContentDiv>
              </CarStorageDiv>
            </CarStorageStepContentDiv>
          </CarStorageStepDiv>
        </>
      )}
    </CarStorageWrapDiv>
  );
};

export default SelfSellCarMain;
