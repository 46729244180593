import axios from "axios";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../../components/CommonStyle";
import Header from "../../components/Header";
import MyPageStyle from "../../components/myPage/MyPageStyle";
import { useToken } from "../../hooks/useToken";

const MyPage = () => {
  const position = false;
  const location = useLocation();

  // 회원 토큰
  const token = useToken();

  // 이용 건수
  const [useNumbers, setUserNumbers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios({
      url: `${BASIC_URL}/user/mypage/history`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setUserNumbers(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  return (
    <>
      <Header position={position}   borderactive={location.pathname.includes('/myPage')}/>
      <MyPageStyle useNumbers={useNumbers} isLoading={isLoading} />
    </>
  );
};

export default MyPage;
