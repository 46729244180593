import styled from "@emotion/styled/macro";
import { PrivacyPolicyModal } from "../components/login&join/Modals";
import { useState } from "react";
import { ReactComponent as Logo } from "../assets/img/MainLogo.svg";
import { ReactComponent as Facebook } from "../assets/img/FooterIcon.svg";
import { ReactComponent as Twitter } from "../assets/img/FooterIcon2.svg";
import { ReactComponent as Google } from "../assets/img/FooterIcon3.svg";
import { ReactComponent as Blog } from "../assets/img/FooterIcon4.svg";
import { ReactComponent as InstaGram } from "../assets/img/FooterIcon5.svg";
import { ReactComponent as Youtube } from "../assets/img/FooterIcon6.svg";
import { ReactComponent as Pot } from "../assets/img/FooterIcon7.svg";

const FooterDiv = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLogoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1080px;
  height: auto;
  margin-top: 42px;
`;

const FooterLine = styled.div`
  margin-top: 20px;
  height: 1px;
  width: 1080px;
  border: 1px solid #ededed;
`;

const IconDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
`;

const FooterText = styled.span`
  font-size: 13px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: -0.39px;
`;

const FooterText2 = styled.span`
  font-family: SpoqaHanSansNeo;
  font-size: 13px;
  letter-spacing: -0.39px;
  margin-right: 20px;
  font-weight: 400;
  color: #707070;
`;

const Footer = () => {
  const [policyModal, setPolicyModal] = useState(false);
  return (
    <FooterDiv>
      <PrivacyPolicyModal
        policyModal={policyModal}
        setPolicyModal={setPolicyModal}
      />
      <FooterLogoDiv>
        <Logo
          style={{
            width: 202,
            height: 64,
            cursor: "pointer",
          }}
        />
        <IconDiv>
          <Facebook
            style={{
              fontSize: 32,
              display: "inline-block",
              cursor: "pointer",
            }}
          />
          <Twitter
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
          <Google
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
          <Blog
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
          <InstaGram
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
          <Youtube
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
          <Pot
            style={{
              fontSize: 32,
              display: "inline-block",
              marginLeft: 24,
              cursor: "pointer",
            }}
          />
        </IconDiv>
      </FooterLogoDiv>
      <FooterLine />
      <div style={{ marginTop: 30, display: "flex", width: 1080 }}>
        <FooterText
          style={{ marginRight: 12.5 }}
          onClick={() => setPolicyModal(true)}
        >
          개인정보처리방침{" "}
        </FooterText>
        <FooterText style={{ marginRight: 12.5 }}> | </FooterText>
        <FooterText>이용약관</FooterText>
      </div>
      <div style={{ marginTop: 30, display: "flex", width: 1080 }}>
        <FooterText2>주소 : 대구광역시 달서구 성서공단로54길 8 (대구광역시 달서구 월암동 1013번지)</FooterText2>
        <FooterText2>문의전화 : 053-583-5211</FooterText2>
        <FooterText2>Fax : 053-582-8211</FooterText2>
        <FooterText2>이메일 : koryoauto100@nate.com</FooterText2>
      </div>
      <div style={{ marginTop: 8, display: "flex", width: 1080 }}>
        <FooterText2>
          Copyright &copy; 2017 대구자동차경매장.All rights reserved{" "}
        </FooterText2>
      </div>
    </FooterDiv>
  );
};

export default Footer;
