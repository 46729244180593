import styled from "@emotion/styled/macro";
import axios from "axios";
import { css } from "@emotion/react";
import License from "../../assets/img/Businesslicense.png";
import {
  Container,
  UpdateInfoLabel,
  UpdateInfo,
} from "./MyPageUpdateInfoStyle";
import { MyPageText } from "./MyPageMenu";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../CommonStyle";
import { useToken } from "../../hooks/useToken";

const DelearAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DelearArea = styled.input`
  outline: none;
  width: 270px;
  padding: 20px 0 20px 16px;
  letter-spacing: -0.72px;
  border-radius: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  font-size: 18px;
  height: 60px;
  font-family: SpoqaHanSansNeo;

  ::placeholder {
    color: #212529;
    font-weight: 400;
    font-family: SpoqaHanSansNeo;
  }
  ${(props) =>
    props.complex &&
    css`
      width: 420px;
    `}
`;

const BusinessLicense = styled.img`
  width: 706px;
  height: 250px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  object-fit: cover;
  cursor: pointer;
`;

const MyPageDelearInfo = () => {
  const token = useToken();
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/dealer/company`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCompanyInfo(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => setCompanyInfo({});
  }, [token]);

  const handleImgError = (e) => {
    e.target.src = License;
  };

  return (
    <Container margin column>
      <MyPageText>상사 정보</MyPageText>
      <UpdateInfoLabel>상사명</UpdateInfoLabel>
      <UpdateInfo autoComplete="off" readOnly value={companyInfo?.name} />
      <UpdateInfoLabel>지역 / 매매단지 </UpdateInfoLabel>
      <DelearAreaContainer>
        <DelearArea value={companyInfo?.area} readOnly />
        <DelearArea complex value={companyInfo?.city} readOnly />
      </DelearAreaContainer>
      <UpdateInfoLabel>상사 우편번호 </UpdateInfoLabel>
      <UpdateInfo autoComplete="off" readOnly value={companyInfo?.zipcode} />
      <UpdateInfo
        autoComplete="off"
        style={{ marginTop: 16 }}
        readOnly
        value={companyInfo?.address}
      />
      <UpdateInfo
        autoComplete="off"
        readOnly
        value={companyInfo?.addressDetail}
        style={{ marginTop: 16 }}
      />
      <UpdateInfoLabel>사무실 번호</UpdateInfoLabel>
      <UpdateInfo
        autoComplete="off"
        readOnly
        value={companyInfo?.phoneNumber}
      />
      <UpdateInfoLabel>사업자 번호</UpdateInfoLabel>
      <UpdateInfo
        autoComplete="off"
        readOnly
        value={companyInfo?.registNumber}
      />
      <UpdateInfoLabel>사업자등록증</UpdateInfoLabel>
      <BusinessLicense
        src={companyInfo?.registImage}
        onError={handleImgError}
        alt=""
      />
    </Container>
  );
};

export default MyPageDelearInfo;
