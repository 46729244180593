import styled from "@emotion/styled/macro";
import axios from "axios";
import { Select } from "antd";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageActiveDiv,
  MyPageText,
} from "../../components/myPage/MyPageMenu";
import {
  TableMenuDiv,
  TableMenu,
  TableContentDiv,
  TableContent,
} from "../../components/selfSellCar/SelfSellCarStyle";
import {
  PickUpText,
  PickUpcontentText,
  PickUpTotalText,
} from "../../components/myPage/MyPagePickUpStyle";
import { useEffect, useRef, useState } from "react";
import { useUserType } from "../../hooks/useUserInfo";
import { BASIC_URL } from "../../components/CommonStyle";
import Header from "../../components/Header";
import { useToken } from "../../hooks/useToken";
import moment from "moment-timezone";
import { useLocation } from "react-router";

const CalculatePageText = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.96px;
`;

const CalculatePageLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #868e96;
  margin-top: 20px;
`;

const CalculatePageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
`;

const MonthSelectBox = styled(Select)`
  border: 1px solid #dee2e6;
  border-radius: 10px;
  width: 150px;
  height: 40px;

  .ant-select-selector {
    border: none !important;
    height: 100% !important;
    padding-top: 0 !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selector > .ant-select-selection-placeholder {
    font-size: 16px;
    font-family: SpoqaHanSansNeo;
    font-weight: 400;
    color: #dee2e6;
    letter-spacing: -0.64px;
  }

  .ant-select-selector .ant-select-selection-item {
    font-size: 16px;
    font-family: SpoqaHanSansNeo;
    font-weight: 400;
    color: #212529;
    letter-spacing: -0.64px;
  }

  .ant-select-dropdown {
    padding: 0;
  }
`;

const CalculatePageTextBox = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-top: 60px;
`;

const TextBox = styled.div`
  height: 53px;
  width: 155px;
  border-right: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CalculatePageText2 = styled(PickUpText)`
  font-weight: 400;
  color: #212529;
  margin-left: 20px;
`;

const BasicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalculatePageTableDiv = styled(TableMenuDiv)`
  margin: 0;
  border-top: none;
`;

const MonthlyPayOut = () => {
  const position = false;
  const userType = useUserType();
  const token = useToken();
  const monthlyPayoutText = useRef(false);
  const [month, setMonth] = useState("05");
  const year = moment().format("YYYY");
  const { Option } = Select;
  const [settlementData, setSettlementData] = useState([]);

  useEffect(() => {
    monthlyPayoutText.current = true;
    return () => (monthlyPayoutText.current = false);
  }, [monthlyPayoutText]);

  // 월별 정산 데이터
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/pickups/monthly-settlement?month=${year}-${month}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((result) => {
        setSettlementData(
          result.data.pickups?.map((data) => {
            return (
              <>
                <CalculatePageTextBox key={data?.id}>
                  <TextBox>
                    <PickUpText>종료일</PickUpText>
                  </TextBox>
                  <BasicContainer>
                    <CalculatePageText2>{data?.endAt}</CalculatePageText2>
                  </BasicContainer>
                </CalculatePageTextBox>
                <CalculatePageTableDiv>
                  <TableMenu>
                    <PickUpText>상품</PickUpText>
                  </TableMenu>
                  <TableMenu>
                    <PickUpText>진행 여부</PickUpText>
                  </TableMenu>
                  <TableMenu>
                    <PickUpText>가격</PickUpText>
                  </TableMenu>
                  <TableMenu>
                    <PickUpText>예상 소요일</PickUpText>
                  </TableMenu>
                  <TableMenu>
                    <PickUpText>총 결제 금액</PickUpText>
                  </TableMenu>
                </CalculatePageTableDiv>
                <TableContentDiv>
                  <TableContent>
                    {data?.closeInpections?.map((option, index) => {
                      return (
                        <PickUpcontentText key={index}>
                          {option?.name}
                        </PickUpcontentText>
                      );
                    })}
                  </TableContent>
                  <TableContent>
                    {data?.closeInpections?.map((option, index) => {
                      return (
                        <PickUpcontentText key={index}>
                          {option?.grade}
                        </PickUpcontentText>
                      );
                    })}
                  </TableContent>
                  <TableContent>
                    {data?.closeInpections?.map((option, index) => {
                      return (
                        <PickUpcontentText key={index}>
                          {Number(option?.price).toLocaleString("en")}원
                        </PickUpcontentText>
                      );
                    })}
                  </TableContent>
                  <TableContent>
                    <PickUpTotalText font2>{data?.totalDay}일</PickUpTotalText>
                  </TableContent>
                  <TableContent>
                    <PickUpTotalText>
                      {Number(data?.totalPrice).toLocaleString("en")}원
                    </PickUpTotalText>
                  </TableContent>
                </TableContentDiv>
              </>
            );
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, month, year]);

  const location = useLocation();

  return (
    <>
      <Header position={position} borderactive={location.pathname.includes('/monthlyPayout')}/>
      <MyPageWrapDiv>
        <MyPageContentDiv>
          <MyPageMenu
            monthlyPayoutText={monthlyPayoutText}
            userType={userType}
          />
          <MyPageActiveDiv className="myPage_div">
            <CalculatePageText>
              픽업 정비 &nbsp; &gt; &nbsp; 월별 정산
            </CalculatePageText>
            <CalculatePageLine />
            <CalculatePageContainer>
              <MyPageText>정산 내역</MyPageText>
              <MonthSelectBox
                placeholder="월 선택"
                dropdownStyle={{ height: 243, padding: 0 }}
                onChange={(value) => setMonth(value)}
              >
                <Option value={"01"}>1월</Option>
                <Option value={"02"}>2월</Option>
                <Option value={"03"}>3월</Option>
                <Option value={"04"}>4월</Option>
                <Option value={"05"}>5월</Option>
                <Option value={"06"}>6월</Option>
                <Option value={"07"}>7월</Option>
                <Option value={"08"}>8월</Option>
                <Option value={"09"}>9월</Option>
                <Option value={10}>10월</Option>
                <Option value={11}>11월</Option>
                <Option value={12}>12월</Option>
              </MonthSelectBox>
            </CalculatePageContainer>
            {settlementData}
          </MyPageActiveDiv>
        </MyPageContentDiv>
      </MyPageWrapDiv>
    </>
  );
};

export default MonthlyPayOut;
