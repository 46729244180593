import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";
import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import { useRecoilValue } from "recoil";
import { Modal } from "antd";
import { MyPageActiveDiv, MyPageText } from "./MyPageMenu";
import { MyPageLine } from "./MyPageUpdateInfoStyle";
import { MyPageSubText } from "./MyPageDeliveryListStyle";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as House } from "../../assets/icons/House.svg";
import { ReactComponent as Write } from "../../assets/icons/Write.svg";
import { ReactComponent as Company } from "../../assets/icons/Company.svg";
import { BASIC_URL } from "../CommonStyle";
import tokenState from "../../states/TokenState";
import { DeliveryDeleteModal } from "./MyPageModal";
import { JoinCheckbox } from "../login&join/Joinstyle";

const AddressInput = styled.input`
  width: ${(props) => (props.width ? "708px" : "570px")};
  height: 60px;
  outline: none;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #212529;
  margin-top: 18px;
  font-family: SpoqaHanSansNeo;
  &::placeholder {
    color: #dee2e6;
  }
  &:focus {
    border-color: #212529;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? "center" : "space-between"};
  align-items: center;
  width: 100%;
`;

const PostButton = styled.button`
  width: 120px;
  height: 60px;
  background-color: #333333;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  cursor: pointer;
  margin-top: 18px;
  font-weight: 500;
`;

const AddressCategory = styled.button`
  width: 226px;
  height: 60px;
  background-color: transparent;
  border: 1px solid #212529;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  outline: none;
  font-size: 18px;
  color: #212529;
  &:focus {
    color: #fff;
    border: none;
    background-color: #212529;
  }
  &:active {
    color: #fff;
    border: none;
    background-color: #212529;
  }
`;

const AddButton = styled.button`
  width: 240px;
  height: 70px;
  font-weight: 500;
  outline: none;
  font-family: SpoqaHanSansNeo;
  font-size: 22px;
  border-radius: 10px;
  color: #fff;
  background-color: #ff6a13;
  border: none;
  cursor: pointer;
  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  ${(props) =>
    props.deleteButton &&
    css`
      background-color: #212529;
      margin-right: 20px;
      cursor: pointer;
    `};
`;

const MyPageAddList = ({ findList }) => {
  // 토큰
  const token = useRecoilValue(tokenState);
  // 주소 , 우편 번호
  const [openPostModal, setOpenPostModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [myAdress, setMyAdress] = useState("");
  const [detailAddress, setDetailAdress] = useState("");

  // 기본 배송지 체크 여부
  const [defaultCheck, setDefaultCheck] = useState(false);

  // 배송지명
  const [addressName, setAddressName] = useState("");

  // 버튼 활성화
  const [disabledButton, setDisabledButton] = useState(true);

  // 직접입력시 인풋 활성화
  const [writeInput, setWrtieInput] = useState(false);

  const buttonRef = useRef();
  const buttonRef2 = useRef();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const inputRef = useRef(null);

  // 클린업
  useEffect(() => {
    window.addEventListener("mousedown", (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        buttonRef2.current &&
        !buttonRef2.current.contains(event.target)
      ) {
        setAddressName("");
      }
    });
  });

  useEffect(() => {
    if (zipCode && myAdress && addressName !== "") {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [disabledButton, myAdress, addressName, zipCode]);

  useEffect(() => {
    if (findList) {
      setZipCode(findList?.zipcode);
      setMyAdress(findList?.address);
      setDetailAdress(findList?.addressDetail);
      setDefaultCheck(findList?.default);
    }
  }, [findList]);

  // 수정 하기 API
  const addressUpdateHandler = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/shipping/${findList.id}`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        zipcode: zipCode,
        address: myAdress,
        addressDetail: detailAddress,
        name: addressName,
        default: defaultCheck,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert("변경 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 추가하기 API
  const addAddressHandler = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/shipping`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        zipcode: zipCode,
        address: myAdress,
        addressDetail: detailAddress,
        name: addressName,
        default: defaultCheck,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert("배송지 추가 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Post = () => {
    const handleComplete = (data) => {
      setZipCode(data.zonecode);
      setMyAdress(data.address);
      setOpenPostModal((prev) => !prev);
    };

    return (
      <Modal
        centered
        visible={openPostModal}
        footer={null}
        width={613}
        closable={false}
        onCancel={() => setOpenPostModal(false)}
        bodyStyle={{ height: "auto" }}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  };

  return (
    <MyPageActiveDiv className="myPage_div">
      <DeliveryDeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        id={findList?.id}
      />
      <Post />
      <MyPageText>회원 정보 &nbsp; &gt; &nbsp; 배송지 관리</MyPageText>
      <MyPageLine />
      <MyPageSubText style={{ marginTop: 71, fontSize: 18 }}>
        배송지
      </MyPageSubText>
      <Container>
        <AddressInput
          placeholder="우편번호 입력"
          value={zipCode || ""}
          autocomplete="off"
          readOnly
        />
        <PostButton
          onClick={() => {
            setOpenPostModal(true);
            setDetailAdress("");
          }}
        >
          우편번호
        </PostButton>
      </Container>
      <AddressInput
        placeholder="주소 입력"
        width="true"
        value={myAdress || ""}
        autocomplete="off"
        readOnly
      />
      <AddressInput
        placeholder="상세주소 입력"
        width="true"
        value={detailAddress || ""}
        onChange={(event) => setDetailAdress(event.target.value)}
        autoComplete="off"
      />
      <Container
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 16,
        }}
      >
        <JoinCheckbox
          unactive={!defaultCheck}
          checked={defaultCheck}
          style={{ marginRight: 13 }}
          onChange={(event) => setDefaultCheck(event.target.checked)}
        />
        <MyPageSubText style={{ fontSize: 18 }}>
          기본 배송지로 저장
        </MyPageSubText>
      </Container>
      <MyPageSubText style={{ marginTop: 60, fontSize: 18 }}>
        배송지명
      </MyPageSubText>
      <Container style={{ marginTop: 18 }} ref={buttonRef}>
        <AddressCategory
          onClick={() => {
            setAddressName("집");
            setWrtieInput(false);
          }}
        >
          <Container justifyContent="true">
            <House />
            &nbsp;집
          </Container>
        </AddressCategory>
        <AddressCategory
          onClick={() => {
            setAddressName("회사");
            setWrtieInput(false);
          }}
          ref={buttonRef2}
        >
          <Container justifyContent="true">
            <Company />
            &nbsp;회사
          </Container>
        </AddressCategory>
        {writeInput ? (
          <AddressCategory
            style={{ background: "#212529" }}
            onClick={() => inputRef.current.focus()}
          >
            <Container justifyContent="true" style={{ color: "#fff" }}>
              <Write />
              &nbsp;직접 입력
            </Container>
          </AddressCategory>
        ) : (
          <AddressCategory
            onClick={() => {
              setWrtieInput(true);
              setAddressName("");
            }}
          >
            <Container justifyContent="true">
              <Write />
              &nbsp;직접 입력
            </Container>
          </AddressCategory>
        )}
      </Container>
      {writeInput ? (
        <AddressInput
          style={{ width: "100%" }}
          placeholder="직접 입력"
          autoComplete="off"
          ref={inputRef}
          value={addressName}
          onChange={(event) => setAddressName(event.target.value)}
        />
      ) : null}
      {Object?.keys(findList)?.length === 0 ? (
        <Container
          ref={buttonRef2}
          justifyContent="true"
          style={{ marginTop: 100, marginBottom: 50 }}
          onClick={addAddressHandler}
        >
          <AddButton disabled={disabledButton}>등록</AddButton>
        </Container>
      ) : (
        <Container
          ref={buttonRef2}
          justifyContent="true"
          style={{ marginTop: 100, marginBottom: 50 }}
        >
          <AddButton deleteButton onClick={() => setOpenDeleteModal(true)}>
            삭제
          </AddButton>
          <AddButton disabled={disabledButton} onClick={addressUpdateHandler}>
            저장
          </AddButton>
        </Container>
      )}
    </MyPageActiveDiv>
  );
};
// onClick={deleteAddressHandler}
export default MyPageAddList;
