import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";
import { css } from "@emotion/react";
import aboutUsBackground from "../../assets/img/aboutUsBackground.png";

export const AboutUsWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AboutUsHeaderDiv = styled.div`
  width: 1080px;
  height: 667px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  animation: textAnimation 1.2s;
  animation-fill-mode: forwards;
  position: relative;

  @keyframes textAnimation {
    from {
      opacity: 0;
      top: 80px;
    }

    to {
      opacity: 1;
      top: 0;
    }
  }

  p {
    margin-bottom: 23px;
  }
`;

export const AboutUsImageDiv = styled.div`
  width: 100%;
  position: relative;
  height: 2040px;
  background-image: url("${aboutUsBackground}");
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const AboutUsEngText = styled.p`
  font-size: 40px;
  font-weight: 700;
  font-family: Poppins;
  letter-spacing: -1.6px;
  color: #ff6a13;
  position: relative;
  ${(props) =>
    props.titletext &&
    css`
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      letter-spacing: -1.2px;
    `};
`;

export const AboutUsText = styled.p`
  font-size: 40px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  opacity: 1;
  letter-spacing: -2px;
  line-height: 60px;
  color: #212529;
`;

export const PhilosophyText = styled.p`
  font-family: SpoqaHanSansNeo;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -1.12px;
  color: #fff;

  ${(props) =>
    props.sub &&
    css`
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.9px;
      margin-bottom: 0;
    `};
`;

export const ImageDivContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PhilosophyContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 180px;
  margin-bottom: 48px;
  position: relative;
  opacity: 0;
  animation: textAnimation2 0.7s;
  animation-fill-mode: forwards;
  animation-play-state: ${(props) => (props.play ? "running" : "paused")};

  @keyframes textAnimation2 {
    from {
      opacity: 0;
      top: 80px;
    }

    to {
      opacity: 1;
      top: 0;
    }
  }

  .sub_text {
    margin-top: 16px;
  }
`;

export const BasicCotainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  position: relative;
`;

export const TextContainer = styled.div`
  width: 558px;
`;

export const BusinessContainer = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 250px;
  position: relative;
  margin-bottom: 200px;
  .partner_text {
    margin-top: 24px;
  }
`;

export const BusinessContentWrapContainer = styled.div`
  width: 100%;
  display: flex;
  opacity: 0;
  justify-content: space-between;
  flex-direction: row;
  margin-top: ${(props) => props.margin};
  position: relative;
  animation: textAnimation3 0.7s;
  animation-fill-mode: forwards;
  animation-play-state: ${(props) => (props.play ? "running" : "paused")};

  @keyframes textAnimation3 {
    from {
      opacity: 0;
      top: 80px;
    }

    to {
      opacity: 1;
      top: 0;
    }
  }
`;

export const BusinessContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 12px;
`;

export const BusinessContentBox = styled.div`
  border-radius: 12px;
  border: 1px solid #ff6a134d;
  width: 340px;
  height: 180px;
  padding: 40px 0;
  text-align: center;
`;

export const BusinessSubContentBox = styled.div`
  border-radius: 12px;
  border: 1px solid #ff6a134d;
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;

export const BusinessText = styled.p`
  font-size: 30px;
  font-family: Poppins;
  font-weight: 600;
  color: #212529;
  letter-spacing: -1.2px;
  position: relative;
  margin-top: ${(props) => props.margin};
`;

export const BusinessText2 = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #ff6a13;
  letter-spacing: -0.96px;
`;

export const BusinessContentText = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  line-height: 21px;
  letter-spacing: -0.7px;
  margin-top: ${(props) => props.margin};
`;

export const BusinessContentText2 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  line-height: 25px;
`;

export const PartnerText = styled.p`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
  position: relative;
  letter-spacing: -0.9px;
`;

export const HistoryYear = styled.a`
  font-size: 30px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.5px;
  color: #212529;
  cursor: pointer;
  margin-bottom: 47px;

  :hover {
    color: #212529;
    font-weight: 700;
  }
`;

export const HistoryYearText = styled.p`
  font-size: 40px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -2px;
`;

export const HistoryMonthText = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #ff6a13;
  letter-spacing: -1.2px;
`;

export const HistoryContentText = styled.p`
  font-size: 24px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.2px;
  margin-left: 35px;

  ${(props) =>
    props.margin &&
    css`
      margin-left: 23px;
    `};
`;

export const HistoryContainer = styled.div`
  width: 336px;
  height: 1080px;
  padding-left: 27px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #212529;
`;

export const HistoryContentBox = styled.div`
  margin-left: 36px;
  display: flex;
  flex-direction: column;
`;

export const HistoryContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-bottom: 128px;
`;

export const HistoryTextBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.margin &&
    css`
      margin-bottom: 32px;
    `};
`;

export const HistoryWrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
`;

export const AboutUsCircle = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  background-color: #ff6a13;
  border-radius: 100%;
  right: 65px;
  top: -20px;
`;

export const AboutUsPartnetDiv = styled.div`
  width: 250px;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.img`
  object-fit: cover;
`;
