import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import styled from "@emotion/styled/macro";
import moment from "moment";
import KaKaoMap from "../KaKaoMap";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { Tabs, Select, Radio, DatePicker, Modal } from "antd";
import { useNavigate, useParams } from "react-router";
import { useState, useEffect, useRef } from "react";
import {
  DeliveryListModal,
  DeliveryModal,
  ReviewModal,
} from "../login&join/Modals";
import findEmail from "../../assets/img/findemail.png";
import mark from "../../assets/img/pickupmark.svg";
import useUserInfo, { useUserType } from "../../hooks/useUserInfo";
import description from "../../assets/img/description.png";
import { BASIC_URL } from "../CommonStyle";
import { InputDiv, InputLabel } from "../login&join/JoinInfoStyle";
import { AddressModal } from "../login&join/Modals";
import { TextInput } from "../login&join/LoginContent";
import {
  PickUpApplyInput,
  PostButton,
  PickUpApplyInputDiv,
} from "./PickUpApplyStyle";
import {
  TableContent,
  TableContentDiv,
  ConfirmText,
  TableMenu,
  TableMenuDiv,
  InspectionText,
} from "../../components/selfSellCar/SelfSellCarStyle";
import {
  PickUpCheckImg,
  PickUpStepHeaderDiv,
  PickUpStepHeaderSubTitle,
  PickUpStepHeaderTitle,
  PayHistoryText,
  PickUpOptionText,
  PayHistoryText2,
  ResultText,
  PickUpStepLine,
  PickUpStepWrapDiv,
  UserCarDiv,
  UserCarImg,
  PickupContainer,
  BoxDiv,
  PickUpStepFooterText,
  UserCarImgDiv,
  CarDescDiv,
  OptionTextDiv,
  PickUpStepHeaderText,
  StepBarText,
  CheckCarImgDiv,
  SelectOptionText,
  OptionContenstDiv,
  OptionContentDiv,
  OptionWrapDiv,
  OptionLine,
  StepContentDiv,
  ResultImg,
  StepContentTItle,
  StepContentLine,
  StepContentTextDiv,
  TabsDiv,
  PickUpStepTitleContainer,
  PayButton,
  MarkImg,
  DescriptionImg,
  PayInfoDiv,
  PayInfoDiv2,
  PayInfoWrapDiv,
  ContentTextDiv,
  ContentTextDiv2,
  PayDiv,
  PickUpStepTextDiv,
  PickUpStepBarDiv,
  PayTextDiv,
  PickUpCheckText,
  PickUpCheckSubText,
  StepBarText2,
  ReviewDiv,
  ReviewTextArea,
} from "./PickUpStyle";
import "../PickUp.css";
import "../Common.css";
import { ReactComponent as Logo } from "../../assets/img/MainLogo.svg";
import { ReactComponent as CheckStep } from "../../assets/img/pickupStepCheck.svg";
import { ReactComponent as CheckStep2 } from "../../assets/img/PickupStepCheck2.svg";
import { ReactComponent as DotLine } from "../../assets/img/PickupStepLine.svg";
import { ReactComponent as DotLine2 } from "../../assets/img/DotLinefinish.svg";
import { ReactComponent as FinishStep } from "../../assets/img/PickupcheckFinished.svg";
import { useToken } from "../../hooks/useToken";
import { JoinCheckbox } from "../login&join/Joinstyle";

const StepBarDot = styled(DotLine)`
  margin-top: 10px;
`;

const StepBarDot2 = styled(DotLine2)`
  margin-top: 10px;
`;

const PickUpStepStyle = () => {
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { id } = useParams();
  const userType = useUserType();
  const navigate = useNavigate();
  const token = useToken();
  const [userApplyInfo, setUserApplyInfo] = useState({});
  const [viewInstructions, setViewInstructions] = useState(false);
  const currentId = useRef(0);

  // 결제 방식 RadioValue
  const [radioValue, setRadioValue] = useState(1);
  const getRadioValue = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    } else {
      axios({
        url: `${BASIC_URL}/pickupmaintenance/${id}`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((result) => {
          if (result.data.status !== "") {
            setUserApplyInfo(result.data);
          } else {
            alert("오류 발생");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, navigate, token]);

  const [stepNumber, setStepNumber] = useState(0);

  // 개인정보 가져오기
  const userInfo = useUserInfo();

  // status 별 stepNumber
  useEffect(() => {
    if (
      userApplyInfo?.status === "입고대기" ||
      userApplyInfo?.status === "픽업신청"
    ) {
      setStepNumber(0);
    } else if (userApplyInfo.status === "정밀점검") {
      setStepNumber(1);
    } else if (userApplyInfo.status === "점검결과확인") {
      setStepNumber(2);
    } else if (userApplyInfo.status === "정비중") {
      setStepNumber(3);
    } else if (userApplyInfo.status === "정비결과확인") {
      setStepNumber(4);
    } else if (userApplyInfo.status === "정비완료") {
      setStepNumber(5);
    }
  }, [stepNumber, userApplyInfo]);

  // 점검 결과 확인 이미지 부분
  const insepctionResult = userApplyInfo?.closeInspectionImage?.map(
    (result) => {
      return (
        <TabPane key={result.id} tab={result?.work}>
          <PickupContainer>
            <CheckCarImgDiv>
              <UserCarImg src={result?.image} alt="image" />
            </CheckCarImgDiv>
            <CarDescDiv>
              <ResultText>{result?.name}</ResultText>
              <ResultText
                style={{
                  marginTop: 16,
                  marginLeft: 24,
                  fontWeight: 400,
                }}
              >
                {result?.description}
              </ResultText>
            </CarDescDiv>
          </PickupContainer>
        </TabPane>
      );
    }
  );

  // 점검 결과 확인 (옵션 선택)
  const { closeInpections } = userApplyInfo;
  const [selectOption, setSelectOption] = useState(["수리 안함"]);
  const [selectOption2, setSelectOption2] = useState([]);
  const [originalOptionIdx, setOriginalOptionIdx] = useState(null);
  const [totalInfo, setTotalInfo] = useState([]);
  const [removeOption, setRemoveOption] = useState();

  const totalPrice = totalInfo?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.price;
  }, 0);

  const totalDay = totalInfo?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.day;
  }, 0);

  // 박스 옵션 구성 하기
  const boxOption = totalInfo
    ?.filter((item) => item.grades !== "수리 안함")
    .map((box, index) => {
      return (
        <BoxDiv key={index}>
          <PickUpOptionText>
            {box?.name === null ? null : box.name}
          </PickUpOptionText>
          <PickUpOptionText>
            {box?.price === null
              ? null
              : Number(box.price).toLocaleString("en")}
            원
          </PickUpOptionText>
        </BoxDiv>
      );
    });

  useEffect(() => {
    if (closeInpections && closeInpections.length > 0) {
      const datas = closeInpections?.map((item) => {
        return item?.data;
      });
      const flattedDatas = datas.flat();

      setTotalInfo((prevTotalInfo) => [
        ...prevTotalInfo, 
        ...flattedDatas.filter((item) => item.status === 'progress').map((item) => ({
          id: item.id,
          currentId: ++currentId.current,
          grades: item.grades[0].name,
          name: item.name,
          day: item.grades[0].day,
          price: item.grades[0].price,
        }))
      ]);
    }
  }, [closeInpections]);

  useEffect(() => {
    console.log('totalInfo', totalInfo);
  }, [totalInfo]);

  const mainCategoriesArea = closeInpections?.map((categories) => {
    const repairOption = categories?.data?.map((option, optionIdx) => {
      const selectOptionHandler = () => {
        const findOption = categories?.data?.find(
          (item) => item?.name === option?.name
        );
        setSelectOption([
          ...findOption?.grades?.map((item) => {
            return `${item?.name}`;
          }),
          "수리 안함",
        ]);
      };

      // 셀렉트 박스 옵션 선택시
      const selectHandler = (value) => {
        // 내가 선택한 옵션 상세
        const filterOption = option?.grades?.find(
          (item) => `${item?.name}` === `${value}`
        );

        const optionValue = {
          id: option?.id,
          currentId: currentId.current,
          grades: filterOption?.name,
          name: option?.name,
          day: filterOption?.day,
          price: filterOption?.price,
        };

        if (value === "수리 안함") {
          optionValue.day = 0;
          optionValue.price = 0;
          optionValue.name = option?.name;
          optionValue.grades = "수리 안함";
        }

        const categoryDatas = categories?.data
          .filter((item) => item.name !== option.name)
          .map((item) => item.name);

        setTotalInfo(prevTotalInfo => [...prevTotalInfo, optionValue]);
        currentId.current++;

        const findIndex = totalInfo.findIndex(
          (item) => item?.name === option?.name
        );

        setRemoveOption(totalInfo[findIndex] || []);

        if (categories?.data?.length > 1) {
          if (selectOption2.length === 0) {
            setOriginalOptionIdx(optionIdx);
          }

          if (originalOptionIdx === optionIdx) {
            setTotalInfo((currentTotalInfo) =>
              currentTotalInfo.filter(
                (item) => !categoryDatas.includes(item.name)
              )
            );
          }

          if (value && value !== "수리 안함") {
            setSelectOption2([value, "수리 안함"]);
          } else if (optionIdx > 0 && value !== "수리 안함") {
            setSelectOption2([...value, "수리 안함"]);
          } else if (optionIdx === 0 && value === "수리 안함") {
            setSelectOption2([...selectOption]);
          }
        }
      };

      const OptionItem = () => {
        if (selectOption2.length > 0 && optionIdx !== originalOptionIdx) {
          return selectOption2?.map((item) => {
            return (
              <Option
                key={item}
                value={item}
                style={{ height: 62, alignItems: "center" }}
              >
                <SelectOptionText>{item}</SelectOptionText>
              </Option>
            );
          });
        }

        return selectOption?.map((item) => {
          return (
            <Option
              key={item}
              value={item}
              style={{ height: 62, alignItems: "center" }}
            >
              <SelectOptionText>{item}</SelectOptionText>
            </Option>
          );
        });
      };

      return (
        <OptionTextDiv
          key={option.name}
          className="option_div"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <PickUpOptionText
            style={{
              flex: 1,
            }}
          >
            {option.name}{" "}
          </PickUpOptionText>
          <Select
            placeholder="옵션 선택"
            style={{ width: 370, padding: 0, flex: 1 }}
            dropdownStyle={{ borderRadius: 10 }}
            onChange={selectHandler}
            onClick={selectOptionHandler}
            value={
              totalInfo.find((item) => item.name === option.name)
                ? `${
                    totalInfo.find((item) => item.name === option.name).grades
                  }`
                : null
            }
          >
            {OptionItem()}
          </Select>
        </OptionTextDiv>
      );
    });
    return (
      <OptionContentDiv key={categories.mainCategory}>
        <OptionTextDiv>
          <PickUpStepHeaderSubTitle
            style={{
              color: "#868E96",
              fontSize: 20,
              fontWeight: 500,
              flex: 1,
            }}
          >
            {categories.mainCategory}
          </PickUpStepHeaderSubTitle>
          <PickUpStepHeaderSubTitle
            style={{
              color: "#868E96",
              fontSize: 20,
              marginLeft: 25,
              fontWeight: 500,
              flex: 1,
            }}
          >
            옵션 선택
          </PickUpStepHeaderSubTitle>
        </OptionTextDiv>
        <OptionLine />
        {repairOption}
      </OptionContentDiv>
    );
  });

  useEffect(() => {
    if (removeOption) {
      setTotalInfo(
        totalInfo.filter((list) => list?.currentId !== removeOption?.currentId)
      );
    }
  }, [removeOption]);

  // 아임포트 결제
  const payMent = () => {
    if (userType === '기업회원') {
      axios({
        url: `${BASIC_URL}/pickupmaintenance/${id}/pay`,
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          imp_uid: '',
        },
      }).then((res) => {
        if (res.data.success) {
          axios({
            url: `${BASIC_URL}/pickupmaintenance/${id}/overhaul`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
              overhaul: totalInfo?.filter(
                (item) => item?.grades !== "수리 안함"
              ),
              totalPrice: totalPrice,
              totalDay: totalDay,
            },
          })
            .then((result) => {
              if (result.data.success === true) {
                navigate(`/paymentComplete/${radioValue}`, {
                  state: { totalPrice },
                });
              } else {
                alert(result.data.message);
                return false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert(res.data.message);
        }
      });
    } else {
      const { IMP } = window;

      IMP.init("imp57762674");

      const data = {
        pg: "html5_inicis", // PG사
        pay_method: "card", // 결제수단
        merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
        amount: totalPrice, // 결제금액
        name: "탈것 픽업 정비",
        buyer_email: userInfo.email,
        buyer_name: userInfo.name,
        buyer_tel: userInfo.phoneNumber,
        app_scheme: "",
      };

      IMP.request_pay(data, callback);
    }
  };
  function callback(response) {
    const { success, error_msg } = response;
    if (success) {
      // 백엔드로 전송
      axios({
        url: `${BASIC_URL}/pickupmaintenance/${id}/pay`,
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          imp_uid: response.imp_uid,
        },
      }).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          alert("결제 완료");
          axios({
            url: `${BASIC_URL}/pickupmaintenance/${id}/overhaul`,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: {
              overhaul: totalInfo?.filter(
                (item) => item?.grades !== "수리 안함"
              ),
              totalPrice: totalPrice,
              totalDay: totalDay,
            },
          })
            .then((result) => {
              if (result.data.success === true) {
                navigate(`/paymentComplete/${radioValue}`, {
                  state: { totalPrice },
                });
              } else {
                alert(result.data.message);
                return false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert(res.data.message);
        }
      });
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  // 결제하기 버튼 활성화
  const [depositor, setDepositor] = useState(""); //입금자
  const [bank, setBank] = useState(""); //은행
  const [pickUpApplyButton, setPickUpApplyButton] = useState(true); //버튼 disabled 유무
  const [checked, setChecked] = useState(); //약관 동의

  useEffect(() => {
    if (radioValue === 2) {
      if (checked && radioValue && totalDay && totalPrice) {
        setPickUpApplyButton(false);
      } else {
        setPickUpApplyButton(true);
      }
    } else {
      if (
        checked &&
        radioValue &&
        totalPrice &&
        totalDay &&
        bank &&
        depositor
      ) {
        setPickUpApplyButton(false);
      } else {
        setPickUpApplyButton(true);
      }
    }
  }, [checked, radioValue, depositor, bank, totalPrice, totalDay]);

  // 픽업정비 신청하기 API(계좌이체)
  const applyPickUpService = async () => {
    await axios({
      url: `${BASIC_URL}/pickupmaintenance/${id}/overhaul?pay=account&name=${depositor}&bank=${bank}`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        overhaul: totalInfo?.filter((item) => item?.grades !== "수리 안함"),
        totalPrice: totalPrice,
        totalDay: totalDay,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          navigate(`/paymentComplete/${radioValue}`, {
            state: { totalPrice, bank, depositor },
          });
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // TabNumber
  const [tabNumber, setTabNumber] = useState(0);

  useEffect(() => {
    if (userApplyInfo.status === "정비중") {
      setTabNumber("2");
    } else if (userApplyInfo.status === "정비결과확인") {
      setTabNumber("3");
    } else if (userApplyInfo.status === "정비완료") {
      setTabNumber("4");
    }
  }, [userApplyInfo.status]);

  const tabNumberHanlder = (key) => {
    setTabNumber(key);
  };

  // 후기 작성 관련 State
  const [review, setReview] = useState("");
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewButton, setReviewButton] = useState(true);

  const reviewHandler = (e) => {
    setReview(e.target.value);
  };

  const [reviewReadonly, setReviewReadOnly] = useState(false);

  useEffect(() => {
    if (review.length > 0) {
      setReviewButton(false);
    } else {
      setReviewButton(true);
    }
  }, [reviewButton, review]);

  // 후기 작성 API
  const writeReview = async () => {
    await axios({
      url: `${BASIC_URL}/pickupmaintenance/${id}/review`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        review: review,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setReviewButton(true);
          setReviewModalOpen(false);
          setReviewReadOnly(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          setReviewReadOnly(false);
          setReviewButton(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 정비 결과 확인 이미지 (전-후)
  const resultCheckImage = userApplyInfo?.maintenanaceImage?.map((item) => {
    return (
      <StepContentDiv
        style={{
          alignItems: "flex-start",
          marginTop: 70,
          flexDirection: "column",
        }}
        key={item.id}
      >
        <PickUpStepHeaderSubTitle
          style={{ color: "#333", fontSize: 20, fontWeight: 600 }}
        >
          {item.name} (전 - 후)
        </PickUpStepHeaderSubTitle>
        <div style={{ display: "flex", marginTop: 20 }}>
          <ResultImg src={item?.imageBefore} alt="img" />
          <ResultImg src={item?.imageAfter} alt="img" />
        </div>
      </StepContentDiv>
    );
  });

  // 배송지 작성 가능 , 불가능 effect
  const [writeDeliveryAddress, setWriteDeliveryAddress] = useState(
    userApplyInfo.shippingInputCheck
  );

  useEffect(() => {
    if (userApplyInfo.shippingInputCheck) {
      setWriteDeliveryAddress(true);
    } else {
      setWriteDeliveryAddress(false);
    }
    if (writeDeliveryAddress) {
      setName(userApplyInfo.autoShippingInput?.name);
      setPhoneNumber(userApplyInfo.autoShippingInput?.phoneNumber);
      if (userApplyInfo.autoShippingInput?.pickupAddress) {
        setZipCode(userApplyInfo.autoShippingInput?.pickupAddress.zipcode);
        setMyAdress(userApplyInfo.autoShippingInput?.pickupAddress.address);
        setPickUpAddressDetail(
          userApplyInfo.autoShippingInput?.pickupAddress.addressDetail
        );
      }
    }
  }, [writeDeliveryAddress, userApplyInfo]);

  // 날짜
  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }
  // 픽업 날짜
  const [pickUpday, setPickUpDay] = useState("");
  const dateHandler = (datesStrings) => {
    setPickUpDay(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  // 주소 , 우편 번호
  const [openPostModal, setOpenPostModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [myAdress, setMyAdress] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [pickUpAddressDetail, setPickUpAddressDetail] = useState("");

  const Post = () => {
    const handleComplete = (data) => {
      setZipCode(data.zonecode);
      setMyAdress(data.address);
      setOpenPostModal((prev) => !prev);
    };

    return (
      <Modal
        centered
        visible={openPostModal}
        footer={null}
        width={613}
        closable={false}
        onCancel={() => setOpenPostModal(false)}
        bodyStyle={{ height: "auto" }}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  };
  const [userShippingList, setUserShippingList] = useState();
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/shippings`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setUserShippingList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  // 방문 수령 배송지
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // 배송지 RadioValue
  const [deliveryRadio, setDeliveryRadio] = useState("배송지입력");

  const getDeliveryRadioValue = (e) => {
    setDeliveryRadio(e.target.value);
    setPickUpDay("");
  };

  useEffect(() => {
    if (userApplyInfo && userApplyInfo?.autoShippingInput?.type) {
      setDeliveryRadio(userApplyInfo?.autoShippingInput?.type);
    }
  }, [userApplyInfo]);

  // 배송지 입력 후 모달
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  // 버튼 활성화
  const [deliveryButtonDisabled, setDeliveryButtonDisabled] = useState(true);

  useEffect(() => {
    if (deliveryRadio === "방문수령") {
      if (phoneNumber && name && pickUpday) {
        setDeliveryButtonDisabled(false);
      } else {
        setDeliveryButtonDisabled(true);
      }
    } else if (deliveryRadio === "배송지입력") {
      if (phoneNumber && name && pickUpday && zipCode && myAdress) {
        setDeliveryButtonDisabled(false);
      } else {
        setDeliveryButtonDisabled(true);
      }
    } else {
      setDeliveryButtonDisabled(true);
    }
  }, [
    phoneNumber,
    name,
    pickUpday,
    deliveryButtonDisabled,
    deliveryRadio,
    zipCode,
    myAdress,
  ]);

  // 방문 수령 배송지 입력
  const visitDelivery = async () => {
    await axios({
      url: `${BASIC_URL}/pickupmaintenance/${id}/shipping`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name: name,
        phoneNumber: phoneNumber,
        type: deliveryRadio,
        visitDate: pickUpday,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setDeliveryModalOpen(true);
          setWriteDeliveryAddress(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setWriteDeliveryAddress(false);
        }
      })
      .catch((error) => console.log(error));
  };

  // 배송지 입력
  const deliveryWrite = async () => {
    await axios({
      url: `${BASIC_URL}/pickupmaintenance/${id}/shipping`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name: name,
        phoneNumber: phoneNumber,
        type: deliveryRadio,
        shippingDate: pickUpday,
        visitDate: "",
        pickupZipcode: zipCode,
        pickupAddress: myAdress,
        pickupAddressDetail: pickUpAddressDetail,
      },
    })
      .then((result) => {
        if (result.data.success === true) {
          setDeliveryModalOpen(true);
          setWriteDeliveryAddress(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setWriteDeliveryAddress(false);
        }
      })
      .catch((error) => console.log(error));
  };

  // 배송지 리스트 모달 open
  const [openListModal, setOpenListModal] = useState(false);

  const ListHandler = () => {
    console.log(userShippingList);
    if (userShippingList) {
      if (userShippingList?.isExist) {
        setOpenListModal(true);
      } else {
        setAddressModal(true);
      }
    }
  };

  const deliveryList = userShippingList?.shippings;

  return (
    <PickUpStepWrapDiv>
      <Post />
      <DeliveryListModal
        setZipCode={setZipCode}
        setMyAdress={setMyAdress}
        setPickUpAddressDetail={setPickUpAddressDetail}
        deliveryList={deliveryList}
        openListModal={openListModal}
        setOpenListModal={setOpenListModal}
      />
      <DeliveryModal
        deliveryModalOpen={deliveryModalOpen}
        setDeliveryModalOpen={setDeliveryModalOpen}
      />
      <ReviewModal
        review={review}
        reviewModalOpen={reviewModalOpen}
        setReviewModalOpen={setReviewModalOpen}
        writeReview={writeReview}
      />
      {userApplyInfo.status === "픽업신청" ||
      userApplyInfo.status === "입고대기" ? (
        <PickUpStepHeaderDiv>
          <PickUpStepTitleContainer>
            <PickUpCheckImg src={findEmail} alt="check" />
            <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
              회원님의 차량은 입고 대기 단계에 있어요.
            </PickUpStepHeaderTitle>
          </PickUpStepTitleContainer>
          <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
            상담원이 연락 드릴 예정입니다.
          </PickUpStepHeaderSubTitle>
        </PickUpStepHeaderDiv>
      ) : null}
      {userApplyInfo.status === "정밀점검" ? (
        <>
          <PickUpStepHeaderDiv>
            <PickUpStepTitleContainer>
              <PickUpCheckImg src={findEmail} alt="check" />
              <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
                회원님은 정밀 점검 단계에 있어요.
              </PickUpStepHeaderTitle>
            </PickUpStepTitleContainer>
            <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
              차량을 픽업하여 정밀 점검을 시작합니다.
            </PickUpStepHeaderSubTitle>
          </PickUpStepHeaderDiv>
        </>
      ) : null}
      {userApplyInfo.status === "점검결과확인" ? (
        <>
          <PickUpStepHeaderDiv>
            <PickUpStepTitleContainer>
              <PickUpCheckImg src={findEmail} alt="check" />
              <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
                회원님은 점검 결과 확인 단계 입니다.
              </PickUpStepHeaderTitle>
            </PickUpStepTitleContainer>
            <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
              정밀 검사 결과를 확인하여 수리하실 부위의 등급을 선택해주세요.
            </PickUpStepHeaderSubTitle>
          </PickUpStepHeaderDiv>
        </>
      ) : null}
      {userApplyInfo.status === "정비중" ? (
        <>
          <PickUpStepHeaderDiv>
            <PickUpStepTitleContainer>
              <PickUpCheckImg src={findEmail} alt="check" />
              <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
                회원님은 정비 중 단계 입니다.
              </PickUpStepHeaderTitle>
            </PickUpStepTitleContainer>
            <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
              회원님의 차량을 정비 중 입니다.
            </PickUpStepHeaderSubTitle>
          </PickUpStepHeaderDiv>
        </>
      ) : null}
      {userApplyInfo.status === "정비결과확인" ? (
        <>
          <PickUpStepHeaderDiv>
            <PickUpStepTitleContainer>
              <PickUpCheckImg src={findEmail} alt="check" />
              <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
                회원님은 정비 결과 확인 단계 입니다.
              </PickUpStepHeaderTitle>
            </PickUpStepTitleContainer>
            <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
              결과를 확인하고 배송지를 입력해주세요.
            </PickUpStepHeaderSubTitle>
          </PickUpStepHeaderDiv>
        </>
      ) : null}
      {userApplyInfo.status === "정비완료" ? (
        <>
          <PickUpStepHeaderDiv>
            <PickUpStepTitleContainer>
              <PickUpCheckImg src={findEmail} alt="check" />
              <PickUpStepHeaderTitle style={{ marginLeft: 12 }}>
                픽업 정비 완료!
              </PickUpStepHeaderTitle>
            </PickUpStepTitleContainer>
            <PickUpStepHeaderSubTitle style={{ marginTop: 20 }}>
              서비스가 어떠셨나요? 후기를 남겨주세요.
            </PickUpStepHeaderSubTitle>
          </PickUpStepHeaderDiv>
        </>
      ) : null}
      {/* 스텝 바 */}
      {userApplyInfo.status === "픽업신청" ||
      userApplyInfo.status === "입고대기" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정밀 점검</StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>점검 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 중</StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 완료 </StepBarText2>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      {userApplyInfo.status === "정밀점검" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>정밀 점검 </StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>점검 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 중 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 결과 확인</StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>점검 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      {userApplyInfo.status === "점검결과확인" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정밀 점검</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>점검 결과 확인 </StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 중 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 완료 </StepBarText2>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      {userApplyInfo.status === "정비중" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정밀 점검</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>점검 결과 확인</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />
          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>정비 중 </StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 결과 확인 </StepBarText2>
          </PickUpStepTextDiv>
          <StepBarDot />
          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 완료</StepBarText2>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      {userApplyInfo.status === "정비결과확인" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정밀 점검</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>점검 결과 확인</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정비 중</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>정비 결과 확인 </StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot />

          <PickUpStepTextDiv>
            <CheckStep2 />
            <StepBarText2>정비 완료</StepBarText2>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      {userApplyInfo.status === "정비완료" ? (
        <PickUpStepBarDiv>
          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>입고 대기</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정밀 점검</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>점검 결과 확인</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정비 중</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />

          <PickUpStepTextDiv>
            <FinishStep />
            <StepBarText done>정비 결과 확인</StepBarText>
          </PickUpStepTextDiv>
          <StepBarDot2 />
          <PickUpStepTextDiv>
            <CheckStep />
            <StepBarText>정비 완료</StepBarText>
          </PickUpStepTextDiv>
        </PickUpStepBarDiv>
      ) : null}

      <StepContentDiv>
        <UserCarImgDiv
          style={{
            width: "100%",
          }}
        >
          {userApplyInfo?.images === null ? (
            <UserCarDiv>
              <Logo />
            </UserCarDiv>
          ) : (
            <UserCarImg src={userApplyInfo?.images} alt="userInfo" />
          )}
        </UserCarImgDiv>
        <UserCarImgDiv>
          {userApplyInfo?.carType ? (
            <StepContentTItle>{userApplyInfo?.carType}</StepContentTItle>
          ) : (
            <StepContentTItle></StepContentTItle>
          )}

          <StepContentLine />
          <StepContentTextDiv>
            <ContentTextDiv>
              <PickUpStepHeaderSubTitle>차량 번호</PickUpStepHeaderSubTitle>
            </ContentTextDiv>
            <ContentTextDiv2>
              <PickUpStepHeaderText>
                {userApplyInfo?.carNumber || '관리자 입력 예정'}
              </PickUpStepHeaderText>
            </ContentTextDiv2>
          </StepContentTextDiv>
          <StepContentTextDiv>
            <ContentTextDiv>
              <PickUpStepHeaderSubTitle>
                수리 부위 및 요청사항
              </PickUpStepHeaderSubTitle>
            </ContentTextDiv>
            <ContentTextDiv2>
              <PickUpStepHeaderText
                style={{
                  height: "auto",
                }}
              >
                {userApplyInfo?.description || '관리자 입력 예정'}
              </PickUpStepHeaderText>
            </ContentTextDiv2>
          </StepContentTextDiv>
          <StepContentTextDiv>
            {stepNumber > 0 ? (
              <>
                <ContentTextDiv>
                  <PickUpStepHeaderSubTitle>입고일</PickUpStepHeaderSubTitle>
                </ContentTextDiv>
                <ContentTextDiv2>
                  <PickUpStepHeaderText>
                    {userApplyInfo.deliveryDate ? moment(userApplyInfo.deliveryDate).format(
                      "YYYY년 MM월 DD일 HH시 mm분"
                    ) : '관리자 입력 예정'}
                  </PickUpStepHeaderText>
                </ContentTextDiv2>{" "}
              </>
            ) : (
              <>
                <ContentTextDiv>
                  <PickUpStepHeaderSubTitle>
                    입고 예정일
                  </PickUpStepHeaderSubTitle>
                </ContentTextDiv>
                <ContentTextDiv2>
                  <PickUpStepHeaderText>
                    {userApplyInfo.pickupDay
                      ? moment(userApplyInfo.pickupDay).format(
                          "YYYY년 MM월 DD일"
                        )
                      : '관리자 입력 예정'}
                  </PickUpStepHeaderText>
                </ContentTextDiv2>{" "}
              </>
            )}
          </StepContentTextDiv>
          {stepNumber >= 1 ? (
            <>
              <StepContentTextDiv>
                <ContentTextDiv>
                  <PickUpStepHeaderSubTitle>주행거리</PickUpStepHeaderSubTitle>
                </ContentTextDiv>
                <ContentTextDiv2>
                  <PickUpStepHeaderText>
                    {`${Number(userApplyInfo.distanceDriven).toLocaleString(
                      "en"
                    )} km` ?? '관리자 입력 예정'}
                  </PickUpStepHeaderText>
                </ContentTextDiv2>
              </StepContentTextDiv>
              <StepContentTextDiv>
                <ContentTextDiv>
                  <PickUpStepHeaderSubTitle>
                    최초 등록일
                  </PickUpStepHeaderSubTitle>
                </ContentTextDiv>
                <ContentTextDiv2>
                  <PickUpStepHeaderText>
                    {moment(userApplyInfo.firstRegistrationDate).format(
                      "YYYY년 MM월 DD일"
                    )}
                  </PickUpStepHeaderText>
                </ContentTextDiv2>
              </StepContentTextDiv>
              <StepContentTextDiv>
                <ContentTextDiv>
                  <PickUpStepHeaderSubTitle>점검일</PickUpStepHeaderSubTitle>
                </ContentTextDiv>
                <ContentTextDiv2>
                  <PickUpStepHeaderText>
                    {userApplyInfo?.maintainDueDate
                      ? moment(userApplyInfo?.maintainDueDate).format(
                          "YYYY년 MM월 DD일"
                        )
                      : '관리자 입력 예정'}
                  </PickUpStepHeaderText>
                </ContentTextDiv2>
              </StepContentTextDiv>
            </>
          ) : null}
        </UserCarImgDiv>
      </StepContentDiv>
      {stepNumber === 2 ? (
        <>
          <StepContentDiv style={{ justifyContent: "flex-start" }}>
            <PickUpCheckText>점검 결과</PickUpCheckText>
            <PickUpCheckSubText>
              정밀 검사 결과를 확인해보세요! 회원님의 차랑에 수리가 필요한 부위
              입니다.
            </PickUpCheckSubText>
          </StepContentDiv>
          <PickUpStepLine />
          <TabsDiv>
            <Tabs defaultActiveKey="1">{insepctionResult}</Tabs>
          </TabsDiv>{" "}
          <StepContentDiv style={{ justifyContent: "flex-start" }}>
            <PickUpStepHeaderSubTitle
              style={{ color: "#333", fontSize: 24, fontWeight: 600 }}
            >
              수리 부위 선택
            </PickUpStepHeaderSubTitle>
            <PickUpStepHeaderSubTitle
              style={{
                color: "#868E96",
                fontSize: 20,
                fontWeight: 400,
                marginLeft: 30,
              }}
            >
              수리하실 부위의 등급을 선택해주세요.(각, 등급에 따라 가격이
              상이합니다.)
            </PickUpStepHeaderSubTitle>
          </StepContentDiv>
          <PickUpStepLine />
          <div
            style={{
              width: 1080,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 24,
              position: "relative",
            }}
            onClick={() => setViewInstructions((prev) => !prev)}
          >
            <MarkImg src={mark} alt="mark" />
            <PickUpStepHeaderSubTitle
              style={{
                color: "#212529",
                marginLeft: 11,
                fontSize: 20,
                fontWeight: 400,
                cursor: "pointer",
              }}
            >
              수리 부위의 등급이란 ?
            </PickUpStepHeaderSubTitle>
            {viewInstructions ? (
              <DescriptionImg src={description} alt="des" />
            ) : null}
          </div>
          <OptionWrapDiv>
            <OptionContenstDiv style={{ opacity: userType === '기업회원' ? 0 : 1 }}>
              {mainCategoriesArea}
              <StepContentDiv style={{ justifyContent: "flex-start" }}>
                <PickUpStepHeaderSubTitle
                  style={{ color: "#333", fontSize: 24, fontWeight: 600 }}
                >
                  결제 수단 선택
                </PickUpStepHeaderSubTitle>
              </StepContentDiv>
              <PickUpStepLine style={{ width: 630 }} />
              <Radio.Group
                defaultValue={1}
                style={{ marginTop: 43 }}
                onChange={getRadioValue}
              >
                <Radio value={1}>계좌이체</Radio>
                <Radio value={2}>신용카드</Radio>
              </Radio.Group>
              {radioValue === 2 ? null : (
                <>
                  <div style={{ marginTop: 60 }}>
                    <p
                      style={{
                        fontWeight: 500,
                        fontFamily: "SpoqaHanSansNeo",
                        fontSize: 18,
                        marginBottom: 18,
                      }}
                    >
                      은행명
                    </p>
                    <Select
                      placeholder="은행 선택"
                      style={{ width: 630 }}
                      dropdownStyle={{
                        borderRadius: 10,
                        height: 210,
                        overflow: "scroll",
                      }}
                      onChange={(value) => setBank(value)}
                    >
                      <Option className="bankOption" value="농협">
                        농협
                      </Option>
                      <Option className="bankOption" value="신한은행">
                        신한은행
                      </Option>
                      <Option className="bankOption" value="우리은행">
                        우리은행
                      </Option>
                      <Option className="bankOption" value="기업은행">
                        기업은행
                      </Option>
                      <Option className="bankOption" value="외환은행">
                        외환은행
                      </Option>
                      <Option className="bankOption" value="국민은행">
                        국민은행
                      </Option>
                    </Select>
                    <p
                      style={{
                        fontWeight: 500,
                        fontFamily: "SpoqaHanSansNeo",
                        fontSize: 18,
                        marginBottom: 18,
                        marginTop: 60,
                      }}
                    >
                      입금자명
                    </p>
                    <TextInput
                      placeholder="입금자명 "
                      style={{ width: 630 }}
                      value={depositor}
                      onChange={(event) => setDepositor(event.target.value)}
                    />
                    <div
                      style={{
                        marginTop: 51,
                        marginBottom: 51,
                      }}
                    >
                      <PickUpStepFooterText>
                        -&nbsp; 입금액과 결제 금액이 일치해야 정상 입금 완료되니
                        금액을 반드시 확인 바랍니다.
                        <br /> -&nbsp; 입금 기한 : 2021년 12월 31일 까지
                        <br />
                        -&nbsp; 예금주 : (주)탈것 110-567-423423
                      </PickUpStepFooterText>
                    </div>
                  </div>
                </>
              )}
            </OptionContenstDiv>

            <PayDiv>
              <PickUpCheckText>최종 결제 금액</PickUpCheckText>

              <PayTextDiv
                style={{
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {boxOption}
              </PayTextDiv>
              <OptionLine style={{ width: "100%", margin: 0 }} />
              <PayTextDiv>
                <PickUpStepHeaderSubTitle
                  style={{
                    color: "#333",
                    fontSize: 20,
                    fontWeight: 700,
                    letterSpacing: "-0.8px",
                  }}
                >
                  총 결제 금액
                </PickUpStepHeaderSubTitle>
                <PickUpStepHeaderSubTitle
                  style={{ color: "#FF6A13", fontSize: 28, fontWeight: 600 }}
                >
                  {Number(totalPrice).toLocaleString("en")} 원
                </PickUpStepHeaderSubTitle>
              </PayTextDiv>
              <PayTextDiv style={{ marginTop: 30 }}>
                <PickUpStepHeaderSubTitle
                  style={{ color: "#333", fontSize: 20, fontWeight: 600 }}
                >
                  예상 소요일
                </PickUpStepHeaderSubTitle>
                <PickUpStepHeaderSubTitle
                  style={{ color: "#333", fontSize: 20, fontWeight: 600 }}
                >
                  {totalDay} 일
                </PickUpStepHeaderSubTitle>
              </PayTextDiv>
              <PickUpStepHeaderText style={{ color: "#868E96", marginTop: 12 }}>
                (예상 소요일은 영업일 기준이며, 부품 수급 등의 이유로 변경될 수
                있습니다.)
              </PickUpStepHeaderText>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 50,
                }}
              >
                <JoinCheckbox
                  unactive={!checked}
                  style={{ marginRight: 14 }}
                  className="selfsellcar_checkbox"
                  onChange={(checkedvalue) =>
                    setChecked(checkedvalue.target.checked)
                  }
                />
                <PickUpStepHeaderText style={{ verticalAlign: "middle" }}>
                  주문할 상품 및 결제에 확인 하였으며 동의합니다.
                </PickUpStepHeaderText>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30,
                }}
              >
                {radioValue === 1 ? (
                  <PayButton
                    disabled={pickUpApplyButton}
                    onClick={applyPickUpService}
                  >
                    { userType === '기업회원' ? '다음' : '결제하기' }
                  </PayButton>
                ) : (
                  <PayButton onClick={payMent} disabled={pickUpApplyButton}>
                    { userType === '기업회원' ? '다음' : '결제하기' }
                  </PayButton>
                )}
              </div>
            </PayDiv>
          </OptionWrapDiv>
        </>
      ) : null}
      <TabsDiv>
        <Tabs size="large" activeKey={tabNumber} onTabClick={tabNumberHanlder}>
          {stepNumber >= 3 ? (
            <>
              <TabPane tab="점검 결과" key="1" className="TabBarStyles">
                <TabsDiv>
                  <Tabs>{insepctionResult}</Tabs>
                </TabsDiv>{" "}
              </TabPane>
              <TabPane tab="결제 내역" key="2" className="TabBarStyles">
                <PayHistoryText>상품 내역</PayHistoryText>
                <TableMenuDiv>
                  <TableMenu>
                    <InspectionText>상품</InspectionText>
                  </TableMenu>
                  <TableMenu>
                    <InspectionText>진행 여부</InspectionText>
                  </TableMenu>
                  <TableMenu>
                    <InspectionText>가격</InspectionText>
                  </TableMenu>
                  <TableMenu>
                    <InspectionText>예상 소요일</InspectionText>
                  </TableMenu>
                  <TableMenu>
                    <InspectionText>총 결제 금액</InspectionText>
                  </TableMenu>
                </TableMenuDiv>
                <TableContentDiv>
                  <TableContent>
                    {userApplyInfo?.productDetails?.productGrades?.map(
                      (text) => {
                        return (
                          <ConfirmText
                            key={text?.id}
                            style={{ marginTop: 12, marginBottom: 12 }}
                          >
                            {text?.name}
                          </ConfirmText>
                        );
                      }
                    )}
                  </TableContent>
                  <TableContent>
                    {userApplyInfo?.productDetails?.productGrades?.map(
                      (text) => {
                        return (
                          <ConfirmText
                            key={text?.id}
                            style={{ marginTop: 12, marginBottom: 12 }}
                          >
                            {text?.grade}
                          </ConfirmText>
                        );
                      }
                    )}
                  </TableContent>
                  <TableContent>
                    {userApplyInfo?.productDetails?.productGrades?.map(
                      (text) => {
                        return (
                          <ConfirmText
                            key={text?.id}
                            style={{ marginTop: 12, marginBottom: 12 }}
                          >
                            {Number(text?.price).toLocaleString("en")}원
                          </ConfirmText>
                        );
                      }
                    )}
                  </TableContent>
                  <TableContent>
                    <PayHistoryText2 day>
                      {userApplyInfo?.productDetails?.totalDay}일
                    </PayHistoryText2>
                  </TableContent>
                  <TableContent>
                    <PayHistoryText2>
                      {Number(
                        userApplyInfo?.productDetails?.totalPrice
                      ).toLocaleString("en")}
                      원
                    </PayHistoryText2>
                  </TableContent>
                </TableContentDiv>
                <TabsDiv>
                  <PayHistoryText>결제 정보</PayHistoryText>
                </TabsDiv>
                <TabsDiv style={{ marginTop: 40 }}>
                  <PayInfoWrapDiv>
                    {userApplyInfo.paymentDetail.type === "card" ? (
                      <>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            진행 상태
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.status || '관리자 입력 예정'}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            결제 방법
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.method || '관리자 입력 예정'}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            주문 접수 일자
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {moment(
                              userApplyInfo?.paymentDetail?.orderReceptionDay
                            ).format("YYYY년 MM월 DD일 ")}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            결제 금액
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {Number(
                              userApplyInfo.paymentDetail.price
                            ).toLocaleString("en")}
                            원
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                      </>
                    ) : (
                      <>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            진행 상태
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.status || '관리자 입력 예정'}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            결제 방법
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.type || '관리자 입력 예정'}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            주문 접수 일자
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {moment(
                              userApplyInfo?.paymentDetail?.orderReceptionDay
                            ).format("YYYY년 MM월 DD일 ")}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            결제 금액
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {Number(
                              userApplyInfo.paymentDetail.price
                            ).toLocaleString("en")}
                            원
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            입금 예정자명
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.paymentName}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            입금 계좌
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.account}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            예금주명
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.accountHolder}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            입금 기한
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {moment(
                              userApplyInfo.paymentDetail.paymentLimitDay
                            ).format("YYYY년 MM월 DD일")}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2>
                        {/* <PayInfoDiv>
                          <PickUpStepHeaderSubTitle>
                            입금 일자
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv>
                        <PayInfoDiv2>
                          <PickUpStepHeaderSubTitle style={{ color: "#333" }}>
                            {userApplyInfo.paymentDetail.paymentDay !== "-"
                              ? moment(
                                  userApplyInfo.paymentDetail.paymentDay
                                ).format("YYYY년 MM월 DD일")
                              : userApplyInfo.paymentDetail.paymentDay}
                          </PickUpStepHeaderSubTitle>
                        </PayInfoDiv2> */}
                      </>
                    )}
                  </PayInfoWrapDiv>
                </TabsDiv>
              </TabPane>
            </>
          ) : null}

          {stepNumber >= 4 ? (
            <>
              <TabPane tab="정비 결과 확인" key="3" className="TabBarStyles">
                {resultCheckImage}
                <StepContentDiv style={{ justifyContent: "flex-start" }}>
                  <PickUpStepHeaderSubTitle
                    style={{ color: "#333", fontSize: 20, fontWeight: 600 }}
                  >
                    배송 정보 입력
                  </PickUpStepHeaderSubTitle>
                </StepContentDiv>
                <PickUpStepLine />
                {userApplyInfo?.status === "정비완료" &&
                userApplyInfo?.autoShippingInput?.type === "배송지입력" ? (
                  <Radio checked value="배송지입력" style={{ marginTop: 43 }}>
                    배송지 입력
                  </Radio>
                ) : userApplyInfo?.status === "정비완료" &&
                  userApplyInfo?.autoShippingInput?.type === "방문수령" ? (
                  <Radio checked style={{ marginTop: 43 }} value="방문수령">
                    방문수령(경기도 이천시 마장면 표교리 388)
                  </Radio>
                ) : (
                  <Radio.Group
                    defaultValue={
                      userApplyInfo?.autoShippingInput?.type
                        ? userApplyInfo?.autoShippingInput?.type
                        : "배송지입력"
                    }
                    style={{ marginTop: 43 }}
                    onChange={getDeliveryRadioValue}
                  >
                    <Radio value="배송지입력">배송지 입력</Radio>
                    <Radio value="방문수령">
                      방문수령(경기도 이천시 마장면 표교리 388)
                    </Radio>
                  </Radio.Group>
                )}
                {deliveryRadio === "방문수령" ? (
                  <>
                    {/* <KaKaoMap />{" "} */}
                    {writeDeliveryAddress ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <InputDiv>
                            <InputLabel
                              htmlFor="name"
                              style={{ fontWeight: 500 }}
                            >
                              받는 사람
                            </InputLabel>
                            <TextInput
                              placeholder="받으시는 분의 성함을 입력해주세요."
                              id="name"
                              autoComplete="off"
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                          <InputDiv style={{ marginLeft: 36 }}>
                            <InputLabel
                              htmlFor="phoneNumber"
                              style={{ fontWeight: 500 }}
                            >
                              휴대폰 번호
                            </InputLabel>
                            <TextInput
                              autoComplete="off"
                              value={phoneNumber}
                              onChange={(event) =>
                                setPhoneNumber(event.target.value)
                              }
                              placeholder="받으시는 분의 휴대폰 번호를 입력해주세요."
                              id="phoneNumber"
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                        </div>
                        <InputDiv>
                          <InputLabel style={{ fontWeight: 500 }}>
                            방문 희망일
                          </InputLabel>
                          <DatePicker
                            className="pickUpDatePicker"
                            disabledDate={disabledDate}
                            locale={locale}
                            showToday={false}
                            allowClear={false}
                            onChange={dateHandler}
                          />
                        </InputDiv>
                        <StepContentDiv
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 150,
                          }}
                        >
                          <PayButton
                            disabled={deliveryButtonDisabled}
                            onClick={visitDelivery}
                          >
                            입력 완료
                          </PayButton>
                        </StepContentDiv>{" "}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <InputDiv>
                            <InputLabel
                              htmlFor="name"
                              style={{ fontWeight: 500 }}
                            >
                              받는 사람
                            </InputLabel>
                            <TextInput
                              disabled
                              placeholder="받으시는 분의 성함을 입력해주세요."
                              id="name"
                              autoComplete="off"
                              value={
                                userApplyInfo &&
                                userApplyInfo.autoShippingInput.name
                              }
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                          <InputDiv style={{ marginLeft: 36 }}>
                            <InputLabel
                              htmlFor="phoneNumber"
                              style={{ fontWeight: 500 }}
                            >
                              휴대폰 번호
                            </InputLabel>
                            <TextInput
                              disabled
                              autoComplete="off"
                              value={
                                userApplyInfo &&
                                userApplyInfo.autoShippingInput.phoneNumber
                              }
                              readOnly
                              placeholder="받으시는 분의 휴대폰 번호를 입력해주세요."
                              id="phoneNumber"
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                        </div>
                        <InputDiv>
                          <InputLabel style={{ fontWeight: 500 }}>
                            배송 희망일
                          </InputLabel>
                          {userApplyInfo.autoShippingInput.visitDate && (
                            <TextInput
                              disabled
                              autoComplete="off"
                              value={
                                userApplyInfo &&
                                moment(
                                  userApplyInfo.autoShippingInput.visitDate
                                ).format("YYYY-MM-DD")
                              }
                              readOnly
                              placeholder="받으시는 분의 휴대폰 번호를 입력해주세요."
                              id="phoneNumber"
                              style={{ marginTop: 16 }}
                            />
                          )}
                        </InputDiv>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {writeDeliveryAddress ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <InputDiv>
                            <InputLabel
                              htmlFor="name"
                              style={{ fontWeight: 500 }}
                            >
                              받는 사람
                            </InputLabel>
                            <TextInput
                              placeholder="받으시는 분의 성함을 입력해주세요."
                              id="name"
                              autoComplete="off"
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>
                          <InputDiv style={{ marginLeft: 36 }}>
                            <InputLabel
                              htmlFor="phoneNumber"
                              style={{ fontWeight: 500 }}
                            >
                              휴대폰 번호
                            </InputLabel>
                            <TextInput
                              autoComplete="off"
                              value={phoneNumber}
                              onChange={(event) =>
                                setPhoneNumber(event.target.value)
                              }
                              placeholder="받으시는 분의 휴대폰 번호를 입력해주세요."
                              id="phoneNumber"
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                        </div>
                        <div style={{ display: "flex" }}>
                          <InputDiv>
                            <InputLabel style={{ fontWeight: 500 }}>
                              배송 희망일
                            </InputLabel>
                            <DatePicker
                              className="pickUpDatePicker"
                              disabledDate={disabledDate}
                              locale={locale}
                              showToday={false}
                              allowClear={false}
                              onChange={dateHandler}
                            />
                          </InputDiv>
                          <InputDiv style={{ marginLeft: 36 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <InputLabel htmlFor="pickupAddress">
                                차량 픽업 주소
                              </InputLabel>
                              <AddressModal
                                addressModal={addressModal}
                                setAddressModal={setAddressModal}
                              />
                              <InputLabel
                                style={{ color: "#868E96", cursor: "pointer" }}
                                onClick={ListHandler}
                              >
                                기존 배송지 선택 &nbsp;&nbsp; &gt;
                              </InputLabel>
                            </div>
                            <div style={{ display: "flex" }}>
                              <PickUpApplyInput
                                style={{ width: 384, fontSize: 18 }}
                                placeholder="우편번호 입력"
                                id="pickupAddress"
                                autocomplete="off"
                                readOnly
                                value={zipCode}
                              />
                              <PostButton
                                onClick={() => {
                                  setOpenPostModal(true);
                                  setPickUpAddressDetail("");
                                }}
                                style={{ marginTop: 15 }}
                              >
                                우편번호
                              </PostButton>
                            </div>
                            <div>
                              <PickUpApplyInputDiv>
                                <PickUpApplyInput
                                  placeholder="주소 입력"
                                  autocomplete="new-password"
                                  readOnly
                                  value={myAdress}
                                  style={{ fontSize: 18 }}
                                />
                              </PickUpApplyInputDiv>
                              <PickUpApplyInputDiv>
                                <PickUpApplyInput
                                  placeholder="상세 주소 입력"
                                  autocomplete="off"
                                  value={pickUpAddressDetail}
                                  style={{ fontSize: 18 }}
                                  onChange={(event) =>
                                    setPickUpAddressDetail(event.target.value)
                                  }
                                />
                              </PickUpApplyInputDiv>
                            </div>
                          </InputDiv>
                        </div>
                        <StepContentDiv
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 150,
                          }}
                        >
                          <PayButton
                            disabled={deliveryButtonDisabled}
                            onClick={deliveryWrite}
                          >
                            입력 완료
                          </PayButton>
                        </StepContentDiv>{" "}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <InputDiv>
                            <InputLabel
                              htmlFor="name"
                              style={{ fontWeight: 500 }}
                            >
                              받는 사람
                            </InputLabel>
                            <TextInput
                              placeholder="받으시는 분의 성함을 입력해주세요."
                              id="name"
                              autoComplete="off"
                              value={
                                userApplyInfo &&
                                userApplyInfo.autoShippingInput.name
                              }
                              disabled
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>
                          <InputDiv style={{ marginLeft: 36 }}>
                            <InputLabel
                              htmlFor="phoneNumber"
                              style={{ fontWeight: 500 }}
                            >
                              휴대폰 번호
                            </InputLabel>
                            <TextInput
                              autoComplete="off"
                              value={
                                userApplyInfo &&
                                userApplyInfo.autoShippingInput.phoneNumber
                              }
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              disabled
                              placeholder="받으시는 분의 휴대폰 번호를 입력해주세요."
                              id="phoneNumber"
                              style={{ marginTop: 16 }}
                            />
                          </InputDiv>{" "}
                        </div>
                        {userApplyInfo?.shippingData && (
                          <div style={{ display: "flex" }}>
                            <InputDiv>
                              <InputLabel style={{ fontWeight: 500 }}>
                                배송 희망일
                              </InputLabel>

                              <TextInput
                                autoComplete="off"
                                value={moment(
                                  userApplyInfo.shippingData.shippingDate
                                ).format("YYYY-MM-DD")}
                                disabled
                                style={{ marginTop: 16 }}
                              />
                            </InputDiv>
                            <InputDiv style={{ marginLeft: 36 }}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <InputLabel htmlFor="pickupAddress">
                                  차량 픽업 주소
                                </InputLabel>
                                <AddressModal
                                  addressModal={addressModal}
                                  setAddressModal={setAddressModal}
                                />
                              </div>
                              <div style={{ display: "flex" }}>
                                <PickUpApplyInput
                                  style={{ width: 384, fontSize: 18 }}
                                  placeholder="우편번호 입력"
                                  id="pickupAddress"
                                  autocomplete="off"
                                  disabled
                                  value={
                                    userApplyInfo?.shippingData &&
                                    userApplyInfo?.shippingData?.zipcode
                                  }
                                />
                                <PostButton style={{ marginTop: 15 }} disabled>
                                  우편번호
                                </PostButton>
                              </div>
                              <div>
                                <PickUpApplyInputDiv>
                                  <PickUpApplyInput
                                    placeholder="주소 입력"
                                    autocomplete="new-password"
                                    readOnly
                                    disabled
                                    value={
                                      userApplyInfo?.shippingData &&
                                      userApplyInfo?.shippingData?.address
                                    }
                                    style={{ fontSize: 18 }}
                                  />
                                </PickUpApplyInputDiv>
                                <PickUpApplyInputDiv>
                                  <PickUpApplyInput
                                    placeholder="상세 주소 입력"
                                    disabled
                                    autocomplete="off"
                                    style={{ fontSize: 18 }}
                                    value={
                                      userApplyInfo?.shippingData &&
                                      userApplyInfo?.shippingData?.addressDetail
                                    }
                                    onChange={(e) =>
                                      setPickUpAddressDetail(e.target.value)
                                    }
                                  />
                                </PickUpApplyInputDiv>
                              </div>
                            </InputDiv>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}{" "}
              </TabPane>{" "}
            </>
          ) : null}
          {stepNumber >= 5 ? (
            <>
              <TabPane tab="후기 작성" key="4" className="TabBarStyles">
                {userApplyInfo.reviewable ? (
                  <>
                    <StepContentDiv
                      style={{
                        alignItems: "flex-start",
                        marginTop: 70,
                        flexDirection: "column",
                      }}
                    >
                      <PickUpStepHeaderSubTitle
                        style={{ color: "#333", fontSize: 20, fontWeight: 600 }}
                      >
                        서비스 후기 작성
                      </PickUpStepHeaderSubTitle>
                      <ReviewTextArea
                        value={review}
                        style={{ marginTop: 40 }}
                        readOnly={reviewReadonly}
                        maxLength={500}
                        onChange={reviewHandler}
                        placeholder="픽업 정비 서비스는 어떠셨나요? 후기를 작성해주세요."
                      />
                    </StepContentDiv>
                    <StepContentDiv
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 150,
                      }}
                    >
                      <PayButton
                        disabled={reviewButton}
                        onClick={() => setReviewModalOpen(true)}
                      >
                        입력 완료
                      </PayButton>
                    </StepContentDiv>{" "}
                  </>
                ) : (
                  <>
                    <StepContentDiv
                      style={{
                        alignItems: "flex-start",
                        marginTop: 70,
                        flexDirection: "column",
                      }}
                    >
                      <ReviewDiv readOnly value={userApplyInfo.review} />
                    </StepContentDiv>
                  </>
                )}
              </TabPane>{" "}
            </>
          ) : null}
        </Tabs>
      </TabsDiv>
    </PickUpStepWrapDiv>
  );
};

export default PickUpStepStyle;
