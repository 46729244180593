import styled from "@emotion/styled/macro";
import axios from "axios";
import tokenState from "../../states/TokenState";
import { useNavigate } from "react-router";
import { css } from "@emotion/react";
import { Modal, Select, Input } from "antd";
import { useRecoilValue } from "recoil";

import { useState, useRef, useEffect, useCallback } from "react";
import { ReactComponent as Check } from "../../assets/img/successcheck.svg";
import { BASIC_URL } from "../CommonStyle";
import { useToken } from "../../hooks/useToken";
import {
  ModalWrapDiv,
  ModalText as DeleteModalText,
  CertifiCationIdentyModal,
  CertifiCationModal,
} from "../login&join/Modals";
import "../Common.css";

const ModalDiv = styled.div`
  width: 558px;
  height: ${(props) => (props.height ? "640px" : "860px")};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.width &&
    css`
      width: 246px;
      justify-content: center;
    `}
`;

const WrapDiv = styled.div`
  width: 100%;
  height: 130px;
  margin-top: ${(props) => (props.margin ? "50px" : "100px")};
`;

const ButtonInputDiv = styled.div`
  display: flex;
`;

const ModalButton = styled.button`
  outline: none;
  border: none;
  border-radius: 10px;
  background: #ff6a13;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
  width: 240px;
  height: 70px;
  font-family: SpoqaHanSansNeo;
  margin-top: ${(props) => (props.margin ? "230px" : "65px")};

  &:disabled {
    background-color: #dee2e6;
    cursor: not-allowed;
  }

  ${(props) =>
    props.size &&
    css`
      width: 120px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      background: #212529;
      margin-top: 18px;
      margin-left: 18px;
    `}
`;

const ModalInputLabel = styled.label`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.86px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
`;

const ModalInput = styled.input`
  width: ${(props) => (props.width ? "420px" : "558px")};
  height: 60px;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  padding-left: 16px;
  margin-top: 18px;
  margin-bottom: 12px;
  outline: none;
  border: ${(props) =>
    props.border ? "1px solid #DEE2E6" : "1px solid #FD383B"};
  border-radius: 10px;
  ::placeholder {
    color: #dee2e6;
  }
`;

const InputText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #fd383b;
`;

const ModalText = styled.p`
  font-size: 30px;
  font-family: SpoqaHanSansNeo;
  font-weight: 700;
  color: #212529;
  letter-spacing: -2.6px;

  ${(props) =>
    props.size &&
    css`
      font-size: 20px;
      font-weight: 400;
    `}
`;

const ModalFooterDiv = styled.div`
  width: 100%;
  height: 95px;
  border-top: 1px solid #dee2e6;
  display: flex;
  border-radius: 10px;
`;

const ModalFooterButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:nth-of-type(2n) {
    border-left: 1px solid #dee2e6;
  }
`;

const TimerText = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

const InquireInput = styled.input`
  width: 558px;
  height: 60px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: 400;
  padding-left: 18px;
  ::placeholder {
    color: #dee2e6;
  }
`;

const InquireTextArea = styled(Input.TextArea)`
  width: 558px;
  height: 320px !important;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 20px;
  outline: none;
  padding-top: 16px;
  ::placeholder {
    color: #dee2e6;
  }
  &:focus {
    border-color: #dee2e6 !important;
  }

  &:hover {
    border-color: #dee2e6 !important;
  }
`;

const InquireText = styled.span`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.6px;
  color: #212529;

  ${(props) =>
    props.font &&
    css`
      font-size: 16px;
      font-weight: 400;
      margin-left: 20px;
      color: #868e96;
      letter-spacing: -0.48px;
    `}
`;

const AuctionPriceText = styled.span`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.6px;
  color: #212529;
`;

const Categoryselect = styled(Select)`
  width: 558px;
  height: 60px;
  outline: none;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  display: block;
  margin-top: 20px;
`;

export const UpdatePasswordModal = ({
  openPasswordModal,
  setOpenPasswordModal,
}) => {
  // token
  const token = useRecoilValue(tokenState);

  // password , passwordCheck
  const [password, setPassword] = useState("");

  // 비밀번호 유효성
  const [pwLengthCheck, setPwLengthCheck] = useState(false);
  const [engCheck, setEngCheck] = useState(false);
  const [specialCharCheck, setSpecialCharCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [checkPassword, setCheckPassword] = useState("");

  // input border
  const [inputBorder, setInputBorder] = useState(true);
  const [inputBorder2, setInputBorder2] = useState(true);
  const [inputBorder3, setInputBorder3] = useState(true);

  // errorText
  const [passwordErrorText, setPasswordErrorText] = useState(true);
  const [newPasswordErrorText, setNewPasswordErrorText] = useState(true);
  const [PasswordCheckErrorText, setPasswordCheckErrorText] = useState(true);

  useEffect(() => {
    const englishCheck = /[a-zA-Z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /^(?=.*[$@$!%*#?&])/.test(password);

    if (password) {
      if (password.length > 7 && password.length < 21) {
        setPwLengthCheck(true);
      } else {
        setPwLengthCheck(false);
      }

      if (englishCheck === true) {
        setEngCheck(true);
      } else {
        setEngCheck(false);
      }

      if (specialCharCheck === true) {
        setSpecialCharCheck(true);
      } else {
        setSpecialCharCheck(false);
      }

      if (numberCheck === true) {
        setNumberCheck(true);
      } else {
        setNumberCheck(false);
      }
    } else {
      setEngCheck(false);
      setSpecialCharCheck(false);
      setNumberCheck(false);
      setPwLengthCheck(false);
    }

    if (password) {
      if (engCheck && pwLengthCheck && specialCharCheck && numberCheck) {
        setInputBorder2(true);
        setNewPasswordErrorText(true);
      } else {
        setInputBorder2(false);
        setNewPasswordErrorText(false);
      }
    }
  }, [password, pwLengthCheck, engCheck, specialCharCheck, numberCheck]);

  // 비밀번호 확인
  useEffect(() => {
    if (password && checkPassword) {
      if (password !== checkPassword) {
        setInputBorder3(false);
        setPasswordCheckErrorText(false);
      } else {
        setInputBorder3(true);
        setPasswordCheckErrorText(true);
      }
    }
  }, [password, checkPassword]);

  // button disabled
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // update Success
  const [updateStatus, setUpdateStatus] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && inputRef?.current?.value === "") {
      setInputBorder(true);
      setPasswordErrorText(true);
    }
  }, [inputRef]);

  const passwordCheck = useCallback(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/profile/reset/pw/same`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        password: inputRef?.current?.value,
      },
    })
      .then((res) => {
        if (res.data.same === false) {
          inputRef.current.focus();
          setInputBorder(false);
          setPasswordErrorText(false);
        } else {
          setInputBorder(true);
          setPasswordErrorText(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, inputRef]);

  useEffect(() => {
    inputRef?.current?.addEventListener("focusout", passwordCheck);
  });

  // 버튼 활성화
  useEffect(() => {
    if (password.length > 0 && checkPassword.length > 0) {
      if (newPasswordErrorText && PasswordCheckErrorText) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  }, [newPasswordErrorText, PasswordCheckErrorText, password, checkPassword]);

  // 비밀번호 변경 API
  const updatePasswordHandler = () => {
    axios({
      method: "POST",
      url: `${BASIC_URL}/user/mypage/profile/reset/pw`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        pw: inputRef?.current?.value,
        newPw: password,
        newPwCheck: checkPassword,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          alert(res.data.message);
          setUpdateStatus(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reloadHandler = () => {
    setOpenPasswordModal(false);
    window.location.reload();
  };

  return (
    <Modal
      footer={null}
      width={700}
      title="비밀번호 변경"
      visible={openPasswordModal}
      onCancel={() => setOpenPasswordModal(false)}
    >
      {updateStatus ? (
        <ModalDiv width="true">
          <Check style={{ width: 56, height: 56, marginBottom: 42 }} />
          <ModalText>비밀번호 변경 완료</ModalText>
          <ModalText size="true">비밀번호 변경이 완료되었습니다.</ModalText>
          <ModalButton onClick={reloadHandler} margin="true">
            확인
          </ModalButton>
        </ModalDiv>
      ) : (
        <ModalDiv>
          <WrapDiv>
            <ModalInputLabel htmlFor="password">기존 비밀번호</ModalInputLabel>
            <form>
              <ModalInput
                ref={inputRef}
                id="password"
                placeholder="기존 비밀번호 입력"
                border={inputBorder}
                type="password"
                autoFocus={true}
              />
            </form>
            {passwordErrorText ? null : (
              <InputText>기존 비밀번호와 일치하지 않습니다.</InputText>
            )}
          </WrapDiv>
          <WrapDiv margin="true">
            <ModalInputLabel htmlFor="new_password">
              새 비밀번호
            </ModalInputLabel>
            <form>
              <ModalInput
                id="new_password"
                placeholder="영문 + 숫자 + 특수문자 포함 8 ~ 20자 내외"
                border={inputBorder2}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
              />
            </form>
            {newPasswordErrorText ? null : (
              <InputText>형식에 맞게 입력 해주세요.</InputText>
            )}
          </WrapDiv>
          <WrapDiv margin="true">
            <ModalInputLabel htmlFor="password_check">
              비밀번호 확인
            </ModalInputLabel>
            <form>
              <ModalInput
                id="password_check"
                placeholder="새 비밀번호 입력"
                border={inputBorder3}
                value={checkPassword}
                onChange={(event) => setCheckPassword(event.target.value)}
                type="password"
              />
            </form>
            {PasswordCheckErrorText ? null : (
              <InputText>비밀번호가 일치하지 않습니다.</InputText>
            )}
          </WrapDiv>
          <ModalButton
            disabled={buttonDisabled}
            onClick={updatePasswordHandler}
          >
            완료
          </ModalButton>
        </ModalDiv>
      )}
    </Modal>
  );
};

// 휴대폰 변경 모달
export const UpdatePhoneNumberModal = ({
  setOpenPhoneNumberModal,
  openPhoneNumberModal,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const authCodeRef = useRef();
  // input border
  const [inputBorder, setInputBorder] = useState(true);

  const token = useRecoilValue(tokenState);

  //  버튼 활성화
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState(true);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

  // 유효성 에러 텍스트
  const [errorText, setErrorText] = useState(true);

  // 휴대폰 인증 타이머
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const [timeStart, setTimeStart] = useState(false);
  const [buttonText, setButtonText] = useState("인증하기");

  useEffect(() => {
    if (timeStart) {
      const countdown = setInterval(() => {
        if (parseInt(sec) > 0) {
          setSec(parseInt(sec) - 1);
        }
        if (parseInt(sec) === 0) {
          if (parseInt(min) === 0) {
            clearInterval(countdown);
            setTimeStart(false);
            setMin(4);
            setSec(0);
            alert("시간이 초과 되었습니다.");
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [timeStart, min, sec]);

  // 유효성 검사
  useEffect(() => {
    const phoneNumberChecks = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberChecks) {
        setButtonDisabled(false);
        setInputBorder(true);
        setErrorText(true);
      } else {
        setErrorText(false);
        setButtonDisabled(true);
        setInputBorder(false);
      }
    } else {
      setInputBorder(true);
      setButtonDisabled(true);
      setErrorText(true);
      setButtonText("인증하기");
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (authCode) {
      setCheckButtonDisabled(false);
    } else {
      setCheckButtonDisabled(true);
    }
  }, [authCode]);

  const [modalOpen, setModalOpen] = useState(false);
  const [certifiCationModalOpen, setCertificationModalOpen] = useState(false);

  // 성공시 내용
  const [successPhoneNumberUpdate, setSuccessPhoneNumberUpdate] =
    useState(false);

  // 인증번호 API
  const getCertificationNumber = () => {
    setTimeStart(true);
    setButtonText("재전송");
    setMin(4);
    setSec(0);

    axios({
      method: "POST",
      url: `${BASIC_URL}/user/mypage/profile/reset/phone-number/send`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        phoneNumber: phoneNumber,
      },
    }).then((res) => {
      if (res.data.message === "success") {
        setModalOpen(true);
      }
    });
  };

  // 인증번호 확인 API
  const checkAuthCode = () => {
    axios({
      method: "POST",
      url: `${BASIC_URL}/login/auth/check`,
      data: {
        phoneNumber,
        authCode,
      },
    })
      .then((res) => {
        if (res.data.success === false) {
          alert(res.data.status_message);
          authCodeRef.current.focus();
        } else {
          setUpdateButtonDisabled(false);
          setTimeStart(false);
          setCertificationModalOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 휴대폰번호 업데이트 API
  const updateHandler = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/profile/reset/phone-number`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        phoneNumber,
        authCode,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setSuccessPhoneNumberUpdate(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <CertifiCationIdentyModal
        certifiCationModalOpen={certifiCationModalOpen}
        setCertificationModalOpen={setCertificationModalOpen}
      />
      <CertifiCationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Modal
        footer={null}
        title="휴대폰 번호 변경"
        width={700}
        visible={openPhoneNumberModal}
        onCancel={() => setOpenPhoneNumberModal(false)}
      >
        {successPhoneNumberUpdate ? (
          <ModalDiv width="true">
            <Check style={{ width: 56, height: 56, marginBottom: 42 }} />
            <ModalText>휴대폰 번호 변경 완료</ModalText>
            <ModalText size="true">
              휴대폰 번호 변경이 완료되었습니다.
            </ModalText>
            <ModalButton onClick={() => window.location.reload()} margin="true">
              확인
            </ModalButton>
          </ModalDiv>
        ) : (
          <ModalDiv height="true">
            <WrapDiv>
              <ModalInputLabel htmlFor="update_phoneNumber">
                휴대폰 번호
              </ModalInputLabel>
              <ButtonInputDiv>
                <ModalInput
                  id="update_phoneNumber"
                  placeholder="(-)없이 숫자만 입력"
                  width="true"
                  type="text"
                  autoComplete="off"
                  value={phoneNumber}
                  border={inputBorder}
                  autoFocus
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />

                <ModalButton
                  disabled={buttonDisabled}
                  size="true"
                  onClick={getCertificationNumber}
                >
                  {buttonText}
                </ModalButton>
              </ButtonInputDiv>
              {errorText ? null : (
                <InputText>올바른 휴대폰 번호를 입력해주세요.</InputText>
              )}
            </WrapDiv>
            <WrapDiv margin="true">
              <ModalInputLabel htmlFor="certificationNumber">
                인증번호
              </ModalInputLabel>
              <ButtonInputDiv style={{ position: "relative" }}>
                <ModalInput
                  border={true}
                  id="certificationNumber"
                  placeholder="인증번호 입력"
                  width="true"
                  type="text"
                  value={authCode}
                  onChange={(event) => setAuthCode(event.target.value)}
                  ref={authCodeRef}
                  autoComplete="off"
                />
                <TimerText
                  style={{ position: "absolute", top: 35, right: 160 }}
                >
                  {min.toString().padStart(2, "0")}:
                  {sec.toString().padStart(2, "0")}
                </TimerText>
                <ModalButton
                  disabled={checkButtonDisabled}
                  size="true"
                  onClick={checkAuthCode}
                >
                  인증확인
                </ModalButton>
              </ButtonInputDiv>
            </WrapDiv>
            <ModalButton
              disabled={updateButtonDisabled}
              onClick={updateHandler}
            >
              완료
            </ModalButton>
          </ModalDiv>
        )}
      </Modal>
    </>
  );
};

// 탈퇴 확인 모달
export const DeleteAccountModal = ({ userDeleteModal, setUserDeleteModal }) => {
  const [successDelete, setSuccessDelete] = useState(false);
  const [text, SetText] = useState(true);
  const token = useRecoilValue(tokenState);
  useEffect(() => {
    setSuccessDelete(false);
  }, [userDeleteModal]);

  const deleteUserHandler = () => {
    axios({
      method: "DELETE",
      url: `${BASIC_URL}/user/user`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.message === "진행중인 작업 존재") {
          setSuccessDelete(true);
          SetText(false);
        } else {
          setSuccessDelete(true);
          SetText(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      width={522}
      footer={null}
      visible={userDeleteModal}
      closable={false}
      onCancel={() => setUserDeleteModal(false)}
      bodyStyle={{ height: "auto" }}
      centered
    >
      {successDelete ? (
        <>
          <ModalWrapDiv>
            {text ? (
              <DeleteModalText>
                탈퇴가 완료되었습니다. <br /> 서비스를 이용해주셔서 감사합니다.
              </DeleteModalText>
            ) : (
              <DeleteModalText>
                회원님은 서비스 이용 중에 있습니다. <br /> 서비스 종료 후 탈퇴가
                가능합니다.
              </DeleteModalText>
            )}
          </ModalWrapDiv>
          <ModalFooterDiv>
            <ModalFooterButton onClick={() => setUserDeleteModal(false)}>
              <DeleteModalText bold="true">확인</DeleteModalText>
            </ModalFooterButton>
          </ModalFooterDiv>
        </>
      ) : (
        <>
          <ModalWrapDiv>
            <DeleteModalText>탈퇴 하시겠습니까 ?</DeleteModalText>
          </ModalWrapDiv>
          <ModalFooterDiv>
            <ModalFooterButton onClick={deleteUserHandler}>
              <DeleteModalText bold="true">네</DeleteModalText>
            </ModalFooterButton>
            <ModalFooterButton onClick={() => setUserDeleteModal(false)}>
              <DeleteModalText bold="true">안 할래요</DeleteModalText>
            </ModalFooterButton>
          </ModalFooterDiv>
        </>
      )}
    </Modal>
  );
};

export const ConfirmDelearModal = ({ delearModalOpen, setDelearModalOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal
      width={522}
      footer={null}
      closable={false}
      visible={delearModalOpen}
      bodyStyle={{ height: "auto" }}
      onCancel={() => setDelearModalOpen(false)}
      centered
    >
      <>
        <ModalWrapDiv>
          <DeleteModalText>
            딜러 회원 신청이 완료되었습니다. <br /> 관리자 승인 후 딜러 회원
            이용이 가능합니다.
          </DeleteModalText>
        </ModalWrapDiv>
        <ModalFooterDiv>
          <ModalFooterButton>
            <DeleteModalText
              bold="true"
              onClick={() => {
                setDelearModalOpen(false);
                navigate("/myPage");
              }}
            >
              확인
            </DeleteModalText>
          </ModalFooterButton>
        </ModalFooterDiv>
      </>
    </Modal>
  );
};

export const InquireModal = ({
  setOpenInquireModal,
  openInquireModal,
  qnaTitle,
  qnaQuestion,
  qnaType,
  qnaId,
}) => {
  const { Option } = Select;

  const token = useRecoilValue(tokenState);

  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [type, setType] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (qnaTitle && qnaType && qnaQuestion) {
      setTitle(qnaTitle);
      setQuestion(qnaQuestion);
      setType(qnaType);
    }
  }, [qnaType, qnaTitle, qnaQuestion]);

  useEffect(() => {
    if (title && question && type) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [title, question, type]);

  const inquireHanlder = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/qna`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        question,
        title,
        type,
      },
    }).then((res) => {
      if (res.data.success) {
        alert("등록 완료");
        setOpenInquireModal(false);
        window.location.reload();
      }
    });
  };

  // 1:1 문의 수정하기
  const updateQna = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/qna/${qnaId}`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        question,
        title,
        type,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert("수정 완료");
          setOpenInquireModal(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      title="1 : 1 문의"
      bodyStyle={{
        height: 860,
        paddingLeft: 71,
        paddingTop: 60,
      }}
      visible={openInquireModal}
      centered
      width={700}
      footer={null}
      onCancel={() => {
        setOpenInquireModal(false);
        setTitle("");
        setQuestion("");
        setType("");
      }}
    >
      <InquireText>문의 유형 선택</InquireText>
      <InquireText font>
        *등록한 1:1 문의 내역은 마이페이지에서 확인 가능합니다.
      </InquireText>
      <Categoryselect
        bordered={false}
        placeholder="문의유형 선택"
        value={type}
        onChange={(value) => setType(value)}
      >
        <Option value="셀프셀카">셀프셀카</Option>
        <Option value="오토아울렛">오토아울렛</Option>
        <Option value="픽업정비">픽업정비</Option>
        <Option value="라이브옥션">라이브옥션</Option>
        <Option value="차량보관">차량보관</Option>
        <Option value="기타">기타</Option>
      </Categoryselect>

      <InquireText style={{ marginTop: 41, display: "block" }}>
        내용 입력
      </InquireText>
      <InquireInput
        placeholder="제목 입력 (20자 내)"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
      />
      <InquireTextArea
        placeholder="내용 입력(500자 내)"
        value={question}
        onChange={(event) => setQuestion(event.target.value)}
        maxLength={500}
        showCount
      />
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          paddingRight: 71,
        }}
      >
        {qnaTitle ? (
          <ModalButton
            disabled={buttonDisabled}
            onClick={updateQna}
            style={{ marginBottom: 50 }}
          >
            수정
          </ModalButton>
        ) : (
          <ModalButton
            disabled={buttonDisabled}
            onClick={inquireHanlder}
            style={{ marginBottom: 50 }}
          >
            등록
          </ModalButton>
        )}
      </div>
    </Modal>
  );
};

export const ImageModal = ({ src, imageModalOpen, setImageModalOpen }) => {
  return (
    <Modal
      visible={imageModalOpen}
      footer={null}
      centered
      className="image_modal"
      onCancel={() => setImageModalOpen(false)}
      bodyStyle={{ width: 682, height: 982 }}
    >
      <img
        src={src}
        alt=""
        style={{ width: 682, objectFit: "cover", height: 982 }}
      />
    </Modal>
  );
};

export const DeleteQnaModal = ({
  setDeleteModalOpen,
  deleteModalOpen,
  qnaId,
}) => {
  const token = useToken();
  const deleteQna = () => {
    axios({
      method: "DELETE",
      url: `${BASIC_URL}/user/mypage/qna/${qnaId}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          setDeleteModalOpen(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      width={522}
      footer={null}
      visible={deleteModalOpen}
      closable={false}
      onCancel={() => setDeleteModalOpen(false)}
      centered
    >
      <ModalWrapDiv>
        <DeleteModalText>문의 내역을 삭제하시겠어요? </DeleteModalText>
      </ModalWrapDiv>
      <ModalFooterDiv>
        <ModalFooterButton onClick={() => setDeleteModalOpen(false)}>
          <DeleteModalText>취소</DeleteModalText>
        </ModalFooterButton>
        <ModalFooterButton onClick={deleteQna}>
          <DeleteModalText>확인</DeleteModalText>
        </ModalFooterButton>
      </ModalFooterDiv>
    </Modal>
  );
};

export const AuctionModal = ({
  openAuctionModal,
  setOpenAuctionModal,
  hopePrice,
  id,
}) => {
  const token = useToken();
  const auctionHopePrice = hopePrice?.replaceAll(",", "");
  const applyAuction = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/auction`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        hopePrice: auctionHopePrice,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setOpenAuctionModal(false);
          alert("경매 신청 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      width={522}
      footer={null}
      visible={openAuctionModal}
      closable={false}
      onCancel={() => setOpenAuctionModal(false)}
      centered
    >
      <ModalWrapDiv>
        <DeleteModalText>
          경매 희망가{" "}
          <AuctionPriceText>
            {Number(auctionHopePrice).toLocaleString("en")}
          </AuctionPriceText>
          원에 <br />
          경매를 신청하시겠어요?{" "}
        </DeleteModalText>
      </ModalWrapDiv>
      <ModalFooterDiv>
        <ModalFooterButton onClick={() => setOpenAuctionModal(false)}>
          <DeleteModalText>취소</DeleteModalText>
        </ModalFooterButton>
        <ModalFooterButton onClick={applyAuction}>
          <DeleteModalText>확인</DeleteModalText>
        </ModalFooterButton>
      </ModalFooterDiv>
    </Modal>
  );
};

export const DropshippingModal = ({
  openDropshippingModal,
  setOpenDropshippingModal,
  openDropShippingRequestModal,
  setOpenDropShippingRequestModal,
}) => {
  return (
    <Modal
      width={522}
      footer={null}
      visible={openDropshippingModal}
      closable={false}
      onCancel={() => setOpenDropshippingModal(false)}
      centered
    >
      <ModalWrapDiv>
        <DeleteModalText>
          출품을 거부하고
          <br />
          위탁 판매 신청 단계로 이동하시겠습니까?
        </DeleteModalText>
      </ModalWrapDiv>
      <ModalFooterDiv>
        <ModalFooterButton onClick={() => setOpenDropshippingModal(false)}>
          <DeleteModalText>취소</DeleteModalText>
        </ModalFooterButton>
        <ModalFooterButton
          onClick={() => {
            setOpenDropShippingRequestModal(true);
            setOpenDropshippingModal(false);
          }}
        >
          <DeleteModalText>확인</DeleteModalText>
        </ModalFooterButton>
      </ModalFooterDiv>
    </Modal>
  );
};

export const DeliveryDeleteModal = ({
  openDeleteModal,
  id,
  setOpenDeleteModal,
}) => {
  const token = useToken();
  // 삭제 API
  const deleteAddressHandler = () => {
    axios({
      url: `${BASIC_URL}/user/mypage/shipping/${id}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success === true) {
          alert("삭제 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      width={522}
      footer={null}
      visible={openDeleteModal}
      closable={false}
      onCancel={() => setOpenDeleteModal(false)}
      centered
    >
      <ModalWrapDiv>
        <DeleteModalText>
          선택하신 배송지를 <br />
          삭제하시겠어요?
        </DeleteModalText>
      </ModalWrapDiv>
      <ModalFooterDiv>
        <ModalFooterButton onClick={() => setOpenDeleteModal(false)}>
          <DeleteModalText>취소</DeleteModalText>
        </ModalFooterButton>
        <ModalFooterButton onClick={deleteAddressHandler}>
          <DeleteModalText>확인</DeleteModalText>
        </ModalFooterButton>
      </ModalFooterDiv>
    </Modal>
  );
};
