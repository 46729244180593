import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageUserType from "../../components/myPage/MyPageUserType";

const UserType = () => {
  const position = false;
  const location = useLocation();

  return (
    <>
      <Header position={position} borderactive={location.pathname.includes('/userType')} />
      <MyPageUserType />
    </>
  );
};

export default UserType;
