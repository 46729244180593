import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { useState, useEffect, useRef } from "react";
import { StepDiv } from "../pick-up/PickUpMainStyle";
import { BASIC_URL } from "../CommonStyle";
import {
  InputDiv,
  ErrorText,
  PhoneInput,
  CertificationButton,
} from "./JoinInfoStyle";
import { userEmailState } from "../../states/userInfoState";
import { TextInput } from "./LoginContent";
import { CertifiCationIdentyModal, CertifiCationModal } from "./Modals";

const FindEmailWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SpoqaHanSansNeo;
`;

const FindEmailTitle = styled.p`
  font-size: 34px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.36px;
`;

const FimdEmailSubText = styled.p`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.8px;
  color: #868e96;
  margin-top: 16px;
`;

const FindEamilLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
`;

const FindEamilContainer = styled.div`
  width: 522px;
  margin: 100px auto 0 auto;
`;

export const FindPasswordButton = styled.button`
  width: 240px;
  height: 70px;
  background-color: #f2f2f2;
  border: none;
  color: #808080;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TimerText = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72;
  color: #212529;
  position: absolute;
  top: 56px;
  right: 160px;
`;

const FindEamilStyle = () => {
  const navigate = useNavigate();

  // 정보 관련 state
  const [userInfo, setUserInfo] = useState({
    name: "",
    phoneNumber: "",
  });

  const { name, phoneNumber } = userInfo;

  const userInfoHandler = (e) => {
    const { value, name } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  // 휴대폰 인증 타이머
  const [block, setBlock] = useState(false);
  const [min, setMin] = useState(4);
  const [sec, setSec] = useState(0);
  const [timeStart, setTimeStart] = useState(false);

  useEffect(() => {
    if (timeStart) {
      const countdown = setInterval(() => {
        if (parseInt(sec) > 0) {
          setSec(parseInt(sec) - 1);
        }
        if (parseInt(sec) === 0) {
          if (parseInt(min) === 0) {
            clearInterval(countdown);
            setTimeStart(false);
            setMin(3);
            setSec(0);
            alert("시간이 초과 되었습니다.");
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [timeStart, min, sec]);

  // 휴대폰 유효성
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);

  // 인증 번호 관련 모달
  const [modalOpen, setModalOpen] = useState(false);
  const [certifiCationModalOpen, setCertificationModalOpen] = useState(false);

  // 인증하기 버튼 활성화
  const [certificationButton, setCertificationButton] = useState(true);
  const [checkCertificationButton, setCheckCertificationButton] =
    useState(true);
  const [phoneInputBorder, setPhoneInputBorder] = useState("#333");
  useEffect(() => {
    const phoneNumberChecks = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberChecks) {
        setPhoneNumberCheck(true);
        setCertificationButton(false);
      } else {
        setPhoneNumberCheck(false);
        setCertificationButton(true);
      }
    } else {
      setCertificationButton(true);
      setPhoneInputBorder("#333");
    }

    if (phoneNumber.length > 0 && phoneNumberChecks === false) {
      setPhoneInputBorder("#ff5252");
    } else if (phoneNumber.length > 0 && phoneNumberChecks === true) {
      setPhoneInputBorder("#333");
    }
  }, [phoneNumber, phoneNumberCheck, phoneInputBorder]);

  const certificationInputRef = useRef();
  const [authCode, setAuthoCode] = useState("");

  const certifiCationPhoneNumber = () => {
    setBlock(true);
    axios({
      url: `${BASIC_URL}/login/send/sms`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((result) => {
        console.log(result);
        if (result.status === 201) {
          setModalOpen(true);
          setBlock(true);
          certificationInputRef.current.focus();
          setCheckCertificationButton(false);
        } else {
          alert("휴대폰 번호를 잘못 입력하셨습니다.");
          setBlock(false);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setFindUserEmail = useSetRecoilState(userEmailState);
  const findEmailAxios = async () => {
    try {
      await axios({
        url: `${BASIC_URL}/login/auth/check`,
        method: "post",
        data: {
          phoneNumber: phoneNumber,
          authCode: authCode,
        },
      }).then((result) => {
        if (result.data.success === true) {
          setCertificationModalOpen(true);
          setTimeStart(false);
          setMin(4);
          setSec(0);
          setTimeout(() => {
            axios({
              url: `${BASIC_URL}/login/check/id`,
              method: "POST",
              data: {
                name: name,
                phoneNumber: phoneNumber,
              },
            }).then((result) => {
              if (result.data.success === true) {
                setFindUserEmail(result.data.userId);
                navigate(`/findEmailComplete/${name}`);
              }
            });
          }, 3000);
        } else {
          alert("인증번호가 일치하지 않습니다.");
          certificationInputRef.current.focus();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FindEmailWrapDiv style={{ marginTop: 80, marginBottom: 80 }}>
      <CertifiCationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <CertifiCationIdentyModal
        certifiCationModalOpen={certifiCationModalOpen}
        setCertificationModalOpen={setCertificationModalOpen}
      />
      <StepDiv style={{ marginTop: 76, width: 440 }}>
        <FindEmailTitle>아이디 찾기</FindEmailTitle>
        <FimdEmailSubText>
          등록된 회원님의 정보로 아이디를 찾으실 수 있습니다.
        </FimdEmailSubText>
      </StepDiv>

      <FindEamilContainer>
        <InputDiv>
          <FindEamilLabel htmlFor="name">이름</FindEamilLabel>
          <TextInput
            margin
            name="name"
            value={name}
            placeholder="이름 입력"
            style={{ marginTop: 16 }}
            id="name"
            autoComplete="off"
            onChange={userInfoHandler}
          />
        </InputDiv>

        <InputDiv>
          <FindEamilLabel htmlFor="phone_number">휴대폰 번호</FindEamilLabel>
          <div style={{ display: "flex" }}>
            <PhoneInput
              name="phoneNumber"
              color={phoneInputBorder}
              value={phoneNumber}
              placeholder="(-) 없이 숫자만 입력"
              id="phone_number"
              autoComplete="off"
              onChange={userInfoHandler}
            />
            {timeStart ? (
              <CertificationButton
                disabled={certificationButton}
                onClick={() => {
                  certifiCationPhoneNumber();
                  setMin(4);
                  setSec(0);
                  setTimeStart(true);
                }}
              >
                재전송
              </CertificationButton>
            ) : (
              <CertificationButton
                disabled={certificationButton}
                onClick={() => {
                  certifiCationPhoneNumber();
                  setTimeStart(true);
                }}
              >
                인증하기
              </CertificationButton>
            )}
          </div>
          {phoneNumberCheck ? null : phoneNumber.length === 0 ? null : (
            <ErrorText>올바른 휴대폰 번호를 입력해주세요.</ErrorText>
          )}
        </InputDiv>

        {block ? (
          <InputDiv style={{ position: "relative" }}>
            <FindEamilLabel htmlFor="certificationNumber">
              인증 번호
            </FindEamilLabel>
            <div style={{ display: "flex" }}>
              <PhoneInput
                ref={certificationInputRef}
                onChange={() =>
                  setAuthoCode(certificationInputRef.current.value)
                }
                name="certificationNumber"
                placeholder="인증번호 입력"
                autoComplete="off"
                id="certificationNumber"
              />
              <TimerText>
                {min.toString().padStart(2, "0")}:
                {sec.toString().padStart(2, "0")}
              </TimerText>
              <CertificationButton
                disabled={checkCertificationButton}
                onClick={findEmailAxios}
              >
                인증확인
              </CertificationButton>
            </div>
          </InputDiv>
        ) : null}
      </FindEamilContainer>
    </FindEmailWrapDiv>
  );
};

export default FindEamilStyle;
