import { useNavigate } from "react-router";
import Header from "../../components/Header";
import PickUpMainStyle from "../../components/pick-up/PickUpMainStyle";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import { useEffect, useState } from "react";

const PickUpMain = () => {
  const navigate = useNavigate();
  const position = false;
  const textBold = true;
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(false);
  }, [hidden]);

  const pageHandler = () => {
    navigate("/pickUpApply");
  };

  return (
    <>
      <Header position={position} textBold={textBold} />
      <HeaderMenuBar hidden={hidden}>
        <HeaderMenuText style={{ cursor: "pointer" }} onClick={() => { navigate('/pickUpMain') }}>
          픽업정비 소개
        </HeaderMenuText>
        <HeaderMenuText style={{ marginLeft: 28, cursor: "pointer" }} onClick={pageHandler}>
          픽업정비 신청
        </HeaderMenuText>
        <HeaderMenuText
          style={{ marginLeft: 28, cursor: "pointer" }}
          onClick={() => navigate("/pickUpDetail")}
        >
          나의 신청 현황
        </HeaderMenuText>
      </HeaderMenuBar>
      <PickUpMainStyle />
    </>
  );
};

export default PickUpMain;
