import axios from "axios";
import styled from "@emotion/styled/macro";
import { Select } from "antd";
import { useParams  , useLocation} from "react-router";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../../components/CommonStyle";
import {
  CarImage,
  AutoOutleWrapDiv,
  AutoOutletDetailCarImageDiv,
  AutoOutletDetailHeaderDiv,
  AutoOutletDetailFlexDiv,
  AutoOutletCarName,
  AutoOutletPrice,
  AutoOutletBadge,
  AutoOutletBadgeText,
  AutoOutletLine,
  VehiclesAllOptionDiv,
  AutoOutletText2,
  AutoOutletText,
  AutoOutletTelePhoneDiv,
  OutletDetailText2,
  AutoOutletText3,
  CarMainOptionContainer,
  AdvancePayment,
  AutoOutletCalculator,
  OutletDetialTitleDiv,
  OutletDetailLine,
  OutletDetailText,
  OutletDetailText4,
  OutletDetailContainer,
  OutletDetailCarInfoWrapDiv,
  CarMainOptionDiv,
  OutletDetailContentContainer,
  OutletDetailTitleContainer,
  OutletDetailContainer2,
  OutletDetailText3,
  DetailKeyPotintDiv,
  CarMainOptionText,
  KeyPointIndex,
  CarDetailOptionDiv,
  IndexText,
  AutoOutletDetailDiv,
  DetailContainer,
  AutoOutletSmallCarDiv,
  CarDetailOptionWrapDiv,
  Description,
} from "../../components/autoOutlet/AutoOutletStyle";
import { SmallIamge, ImageButton } from "../../components/liveAuction/Image";
import { ReactComponent as TelePhoone } from "../../assets/icons/TelePhoneIcon.svg";
import { ReactComponent as DetailIcon } from "../../assets/icons/DetailIcon.svg";
import { ReactComponent as DetailIcon2 } from "../../assets/icons/DetailIcon2.svg";
import { ReactComponent as DetailIcon3 } from "../../assets/icons/DetailIcon3.svg";
import { ReactComponent as DetailIcon4 } from "../../assets/icons/DetailIcon4.svg";
import { ReactComponent as DetailIcon5 } from "../../assets/icons/DetailIcon5.svg";
import { ReactComponent as NaviIcon } from "../../assets/icons/NaviIcon.svg";
import { ReactComponent as NaviIcon2 } from "../../assets/icons/NaviIcon2.svg";
import { ReactComponent as SmartKey } from "../../assets/icons/SmartKey.svg";
import { ReactComponent as SmartKey2 } from "../../assets/icons/SmartKey2.svg";
import { ReactComponent as Sunloop } from "../../assets/icons/Sunloop.svg";
import { ReactComponent as Sunloop2 } from "../../assets/icons/Sunloop2.svg";
import { ReactComponent as Windloop2 } from "../../assets/icons/windloop2.svg";
import { ReactComponent as Windloop } from "../../assets/icons/windloop.svg";
import { ReactComponent as HeadLamp } from "../../assets/icons/HeadLamp.svg";
import { ReactComponent as HeadLamp2 } from "../../assets/icons/HeadLamp2.svg";
import { ReactComponent as BackSight } from "../../assets/icons/BackSight.svg";
import { ReactComponent as BackSight2 } from "../../assets/icons/BackSight2.svg";
import { ReactComponent as SmartCrew } from "../../assets/icons/SmartCrew.svg";
import { ReactComponent as SmartCrew2 } from "../../assets/icons/SmartCrew2.svg";
import { ReactComponent as HeadUp } from "../../assets/icons/HeadUp.svg";
import { ReactComponent as HeadUp2 } from "../../assets/icons/HeadUp2.svg";
import { ReactComponent as EscapeLoad } from "../../assets/icons/EscapeLoad.svg";
import { ReactComponent as EscapeLoad2 } from "../../assets/icons/EscapeLoad2.svg";
import { ReactComponent as AroundView } from "../../assets/icons/AroundView.svg";
import { ReactComponent as AroundView2 } from "../../assets/icons/AroundView2.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right_arrow.svg";
import { ReactComponent as Icon } from "../../assets/icons/inpsection/inpsection_01.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/inpsection/inspection_02.svg";
import { ReactComponent as Icon3 } from "../../assets/icons/inpsection/inspection_03.svg";
import { ReactComponent as Icon4 } from "../../assets/icons/inpsection/inspection_04.svg";
import { ReactComponent as Icon5 } from "../../assets/icons/inpsection/inspection_05.svg";
import { ReactComponent as Icon6 } from "../../assets/icons/inpsection/inspection_06.svg";
import { ReactComponent as Icon7 } from "../../assets/icons/inpsection/inspection_07.svg";
import { ReactComponent as Icon8 } from "../../assets/icons/inpsection/inspection_08.svg";
import { ReactComponent as Icon9 } from "../../assets/icons/inpsection/inspection_09.svg";
import { ReactComponent as Icon10 } from "../../assets/icons/inpsection/inspection_10.svg";
import "../../components/autoOutlet/AutoOutlet.css";
import {
  CheckBoxContainer,
  IconText,
  InspecionIconContainer,
  InspectionImage,
  ContentBox,
  InspectionText,
  Line,
  CategoryText,
} from "../../components/InspectionImage";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";
import { StatusText } from "../../components/liveAuction/LiveAuctionStyle";
import Header from "../../components/Header";

const AutoOuteltCheckbox = styled(JoinCheckbox)`
margin-right : 8px;
`

const AutoOutletDetail = () => {
  const position = false;
  const autoOutletText = true;
  const { Option } = Select;
  const { id } = useParams();
  const location = useLocation();
  const [carDetailInfo, setCarDetailInfo] = useState();
  const [imageSrc, setImageSrc] = useState({
    url: "",
    index: 0,
  });

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setCarDetailInfo(res.data);
          setImageSrc({ ...imageSrc, url: res.data.images[0].url });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  // 차 옵션 불러오기
  // const [carDetailOption, setCarDetailOption] = useState();
  // useEffect(() => {
  //   axios({
  //     url: `${BASIC_URL}/autooutlet/${id}/option/web`,
  //     method: "GET",
  //   })
  //     .then((res) => {
  //       setCarDetailOption(res.data.optionList);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [id]);

  const allVehicleOptions = carDetailInfo?.options.map((category, index) => {
    const detailVehicleOption = category.options.map((option) => {
      return (
        <CarDetailOptionDiv key={option.id}>
          <CarMainOptionText>
            • {option.name}
            <Description style={{ top: 35 }}>
              <StatusText>{option.name}</StatusText>
              <CarMainOptionText style={{ marginTop: 12 }}>
                {option.description}
              </CarMainOptionText>
            </Description>
          </CarMainOptionText>
        </CarDetailOptionDiv>
      );
    });
    return (
      <>
        <AutoOutletDetailFlexDiv margin="50px" align="center" key={index}>
          <AutoOutletText2>{category.category}</AutoOutletText2>
          <AutoOutletText2 style={{ color: "#FF6A13" }}>
            &nbsp; {category.options.length}
          </AutoOutletText2>
        </AutoOutletDetailFlexDiv>
        <VehiclesAllOptionDiv>{detailVehicleOption}</VehiclesAllOptionDiv>
      </>
    );
  });

  // KeyPoint
  const keyPoint = carDetailInfo?.keyPoints?.map((content, index) => {
    return (
      <DetailKeyPotintDiv key={index}>
        <KeyPointIndex>
          <IndexText>{index + 1}</IndexText>
        </KeyPointIndex>
        <OutletDetailText4>{content?.contents}</OutletDetailText4>
      </DetailKeyPotintDiv>
    );
  });

  // 금리계산기
  const [advancesReceived, setAdvanceReceived] = useState("");
  const [month, setMonth] = useState(24);
  const [interestRate, setInterestRate] = useState(3.4);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  useEffect(() => {
    if (advancesReceived) {
      axios({
        method: "GET",
        url: `${BASIC_URL}/calculator?totalPrice=${
          carDetailInfo?.price
        }&prevPrice=${parseInt(
          advancesReceived.replaceAll(",", "")
        )}&month=${month}&interestRate=${interestRate}`,
      }).then((res) => {
        if (res.data.result > 0) {
          setMonthlyPayment(res.data.result);
        } else {
          return false;
        }
      });
    }
  }, [advancesReceived, carDetailInfo, interestRate, month]);

  useEffect(() => {
    if (
      parseInt(advancesReceived.replaceAll(",", "")) >
      parseInt(carDetailInfo?.price)
    ) {
      alert("선수금은 차량 판매가 보다 높을 수 없습니다.");
      setAdvanceReceived("");
      setMonthlyPayment(0);
    }
  }, [advancesReceived, carDetailInfo]);

  return (
    <>
      <Header autoOutletText={autoOutletText} position={position} borderactive={location?.pathname?.includes("/autoOutlet")} />
      <AutoOutleWrapDiv>
        <AutoOutletDetailDiv>
          <AutoOutletDetailHeaderDiv>
            <AutoOutletCarName>{carDetailInfo?.name}</AutoOutletCarName>
            <AutoOutletDetailFlexDiv margin="17px" justify="space-between">
              <AutoOutletDetailFlexDiv>
                <AutoOutletBadge>
                  <AutoOutletBadgeText>
                    {carDetailInfo?.year}년식
                  </AutoOutletBadgeText>
                </AutoOutletBadge>
                <AutoOutletBadge>
                  <AutoOutletBadgeText>
                    {Number(carDetailInfo?.distanceDriven).toLocaleString("en")}
                    km
                  </AutoOutletBadgeText>
                </AutoOutletBadge>
                <AutoOutletBadge>
                  <AutoOutletBadgeText>
                    {carDetailInfo?.fuel}
                  </AutoOutletBadgeText>
                </AutoOutletBadge>
              </AutoOutletDetailFlexDiv>
              <AutoOutletPrice>
                판매가 {carDetailInfo?.price / 10000}만원
              </AutoOutletPrice>
            </AutoOutletDetailFlexDiv>
            <AutoOutletDetailFlexDiv column>
              <AutoOutletDetailCarImageDiv>
                {imageSrc.url !== "" && (
                  <CarImage src={imageSrc.url} alt="src" />
                )}
                <ImageButton
                  style={{ left: 30 }}
                  onClick={() => {
                    if (imageSrc.index === 0) {
                      setImageSrc({
                        ...imageSrc,
                        url: carDetailInfo?.images[0].url,
                        index: 0,
                      });
                    } else {
                      const nextImage = carDetailInfo?.images?.find(
                        (item, index) => index === imageSrc.index - 1
                      );
                      setImageSrc({
                        ...imageSrc,
                        url: nextImage.url,
                        index: imageSrc?.index - 1,
                      });
                    }
                  }}
                >
                  <LeftArrow />
                </ImageButton>

                <RightArrow
                  style={{ right: 30, position: "absolute", cursor: "pointer" }}
                  onClick={() => {
                    if (imageSrc?.index === carDetailInfo?.images.length - 1) {
                      const lastImage = carDetailInfo?.images?.find(
                        (item, index) =>
                          index === carDetailInfo?.images?.length - 1
                      );
                      setImageSrc({
                        ...imageSrc,
                        url: lastImage.url,
                        index: carDetailInfo?.images.length - 1,
                      });
                    } else {
                      const nextImage = carDetailInfo?.images?.find(
                        (item, index) => index === imageSrc.index + 1
                      );

                      setImageSrc({
                        ...imageSrc,
                        url: nextImage.url,
                        index: imageSrc?.index + 1,
                      });
                    }
                  }}
                />
              </AutoOutletDetailCarImageDiv>
              <AutoOutletSmallCarDiv>
                {carDetailInfo?.images?.map((list, index) => {
                  return (
                    <SmallIamge
                      active={imageSrc.url === list.url}
                      onClick={() => {
                        setImageSrc({ index, url: list.url });
                      }}
                      style={{ backgroundImage: `url("${list.url}")` }}
                      key={index}
                    />
                  );
                })}
              </AutoOutletSmallCarDiv>
            </AutoOutletDetailFlexDiv>
          </AutoOutletDetailHeaderDiv>
          <AutoOutletCalculator>
            <AutoOutletText2>금융계산기</AutoOutletText2>
            <AutoOutletLine style={{ marginBottom: 22 }} />
            <AutoOutletDetailFlexDiv justify="space-between">
              <AutoOutletText>차량 판매가</AutoOutletText>
              <AutoOutletText weight={500}>
                {Number(carDetailInfo?.price).toLocaleString("en")}원
              </AutoOutletText>
            </AutoOutletDetailFlexDiv>
            <AutoOutletDetailFlexDiv
              justify="space-between"
              margin="26px"
              align="center"
            >
              <AutoOutletText>선수금</AutoOutletText>
              <AdvancePayment
                placeholder="선수금 입력"
                autoFocus
                value={
                  parseInt(advancesReceived) > 0
                    ? Number(
                        advancesReceived.replaceAll(",", "")
                      ).toLocaleString("en")
                    : advancesReceived
                }
                onChange={(event) => setAdvanceReceived(event.target.value)}
              />
            </AutoOutletDetailFlexDiv>
            <AutoOutletDetailFlexDiv
              justify="space-between"
              margin="26px"
              style={{ alignItems: "center" }}
            >
              <AutoOutletText>할부기간</AutoOutletText>
              <Select
                onChange={(value) => setMonth(value)}
                className="calculator_select"
                dropdownClassName="calculator_dropdown"
                placement="bottomRight"
                defaultValue="24개월"
              >
                <Option value="12">12개월</Option>
                <Option value="24">24개월</Option>
                <Option value="36">36개월</Option>
                <Option value="48">48개월</Option>
                <Option value="60">60개월</Option>
              </Select>
            </AutoOutletDetailFlexDiv>
            <AutoOutletDetailFlexDiv justify="space-between" margin="26px">
              <AutoOutletText>금리</AutoOutletText>
              <Select
                onChange={(value) => setInterestRate(value)}
                className="calculator_select"
                dropdownClassName="calculator_dropdown"
                placement="bottomRight"
                defaultValue="3.9%"
              >
                <Option value="3.9">3.9%</Option>
                <Option value="5.5">5.5%</Option>
                <Option value="8.5">8.5%</Option>
                <Option value="10.9">10.9%</Option>
              </Select>
            </AutoOutletDetailFlexDiv>

            <AutoOutletLine />
            <AutoOutletDetailFlexDiv justify="space-between" margin="26px">
              <AutoOutletText3>월 납입금 </AutoOutletText3>
              <AutoOutletText2 color="#FF6A13">
                {Number(monthlyPayment).toLocaleString("en")}원
              </AutoOutletText2>
            </AutoOutletDetailFlexDiv>
            <AutoOutletTelePhoneDiv>
              <TelePhoone style={{ marginRight: 7 }} />
              <AutoOutletText style={{ marginRight: 14 }}>문의</AutoOutletText>
              <AutoOutletText weight={500}>031-281-5211</AutoOutletText>
            </AutoOutletTelePhoneDiv>
          </AutoOutletCalculator>
        </AutoOutletDetailDiv>

        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon style={{ marginRight: 4 }} />
              <OutletDetailText>차량 정보</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량 정보를 한눈에 확인해 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <OutletDetailCarInfoWrapDiv>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>차량번호</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.number}</OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>색상</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.color}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>배기량</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>
                  {Number(carDetailInfo?.displacement).toLocaleString("en")} cc
                </OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>연식</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.year}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>변속기</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.gearbox}</OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>연료</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.fuel}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>주행거리</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>
                  {Number(carDetailInfo?.distanceDriven).toLocaleString("en")}km
                </OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>승차정원</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carDetailInfo?.seater}명</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
          </OutletDetailCarInfoWrapDiv>
        </OutletDetailContainer>
        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon2 style={{ marginRight: 4 }} />
              <OutletDetailText>Key Point</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              고객님께 추천드리는 특별한 이유
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <OutletDetailCarInfoWrapDiv>{keyPoint}</OutletDetailCarInfoWrapDiv>
        </OutletDetailContainer>
        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon4 style={{ marginRight: 4 }} />
              <OutletDetailText>주요 옵션</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량에 장착된 주요 옵션들을 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CarMainOptionContainer>
              {carDetailInfo?.mainOption?.navigation ? (
                <CarMainOptionDiv>
                  <NaviIcon
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>네비게이션</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <NaviIcon2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />

                  <CarMainOptionText colorchange>네비게이션</CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.smartKey ? (
                <CarMainOptionDiv>
                  <SmartKey
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>스마트키</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <SmartKey2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>스마트키</CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.sunloop ? (
                <CarMainOptionDiv>
                  <Sunloop style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText>선루프</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <Sunloop2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>선루프</CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.windSheet ? (
                <CarMainOptionDiv>
                  <Windloop2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>통풍시트</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <Windloop
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>통풍시트</CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.headLamp ? (
                <CarMainOptionDiv marginright>
                  <HeadLamp
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>헤드 램프</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv marginright>
                  <HeadLamp2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>헤드 램프</CarMainOptionText>
                </CarMainOptionDiv>
              )}
            </CarMainOptionContainer>

            <CarMainOptionContainer marginTop>
              {carDetailInfo?.mainOption?.backSight ? (
                <CarMainOptionDiv>
                  <BackSight
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>후측방 경보</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <BackSight2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    {" "}
                    후측방 경보
                  </CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.smartCruiseControl ? (
                <CarMainOptionDiv>
                  <SmartCrew
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>
                    스마트 크루즈
                    <br /> 컨트롤{" "}
                  </CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <SmartCrew2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    스마트 크루즈
                    <br /> 컨트롤{" "}
                  </CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.headUpDisplay ? (
                <CarMainOptionDiv>
                  <HeadUp style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText>
                    헤드업 <br />
                    디스플레이
                  </CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <HeadUp2 style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText colorchange>
                    헤드업 <br />
                    디스플레이
                  </CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.escapeRoadNotifiaction ? (
                <CarMainOptionDiv>
                  <EscapeLoad
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>차선 이탈 경보장치</CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <EscapeLoad2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    차선 이탈 경보장치
                  </CarMainOptionText>
                </CarMainOptionDiv>
              )}
              {carDetailInfo?.mainOption?.aroundView ? (
                <CarMainOptionDiv marginright>
                  <AroundView2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>
                    어라은드뷰 <br />
                    모니터링 시스템
                  </CarMainOptionText>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv marginright>
                  <AroundView
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    어라은드뷰 <br />
                    모니터링 시스템
                  </CarMainOptionText>
                </CarMainOptionDiv>
              )}
            </CarMainOptionContainer>
          </div>
        </OutletDetailContainer>
        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon3 style={{ marginRight: 4 }} />
              <OutletDetailText>차량 옵션</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량에 장착된 전체 옵션들을 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <CarDetailOptionWrapDiv>
            <CarDetailOptionDiv>{allVehicleOptions}</CarDetailOptionDiv>
          </CarDetailOptionWrapDiv>
        </OutletDetailContainer>
        <OutletDetailContainer style={{ marginBottom: 300 }}>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon5 style={{ marginRight: 4 }} />
              <OutletDetailText>성능 정보</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량의 상태 및 성능을 확인해보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <OutletDetailCarInfoWrapDiv>
            <InspecionIconContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: 518,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon /> <IconText>교환</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 22,
                  }}
                >
                  <Icon2 /> <IconText>판금/용접 </IconText>{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon3 /> <IconText>교환필요</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon4 /> <IconText>판금필요</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Icon5 /> <IconText>조정작업</IconText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 14,
                  width: 518,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon6 /> <IconText>도장</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon7 /> <IconText>도장필요 </IconText>{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon8 /> <IconText>꺾임</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 22,
                  }}
                >
                  <Icon9 /> <IconText>부식</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Icon10 /> <IconText>상처</IconText>
                </div>
              </div>
            </InspecionIconContainer>
            <InspectionImage src={carDetailInfo?.inspectionImage} alt="D" />
            <Line />
            <CategoryText>
              • &nbsp;외판부위의 교환, 부식, 판금 및 용접수리
            </CategoryText>
            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[0].hood.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[0].hood.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[0].hood.check}
                >
                  1. 후드
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[1].frontHenderL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[1].frontHenderL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[1].frontHenderL.check}
                >
                  2. 프론트 휀더(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[2].frontHenderR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[2].frontHenderR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[2].frontHenderR.check}
                >
                  3. 프론트 휀더(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[3].frontDoorL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[3].frontDoorL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[3].frontDoorL.check}
                >
                  4. 프론트 도어(좌)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[4].frontDoorR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[4].frontDoorR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[4].frontDoorR.check}
                >
                  5. 프론트 도어(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[5].rearDoorL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[5].rearDoorL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[5].rearDoorL.check}
                >
                  6. 리어 도어(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[6].rearDoorR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[6].rearDoorR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[6].rearDoorR.check}
                >
                  7. 리어 도어(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[7].trunkRid.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[7].trunkRid.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[7].trunkRid.check}
                >
                  8. 트렁크리드
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[8].radiatorSupport.check}
                  className="selfsellcar_checkbox"
                  checked={
                    carDetailInfo?.carInsepction[8].radiatorSupport.check
                  }
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[8].radiatorSupport.check}
                >
                  9. 라디에이터 서포트
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[9].loopPanel.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[9].loopPanel.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[9].loopPanel.check}
                >
                  10. 루프 패널
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[10].quarterPanelL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[10].quarterPanelL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[10].quarterPanelL.check}
                >
                  11. 쿼터 패널(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[11].quarterPanelR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[11].quarterPanelR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[11].quarterPanelR.check}
                >
                  12. 쿼터 패널(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
              <ContentBox>
                <AutoOuteltCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!carDetailInfo?.carInsepction[12].sideSealPanelL.check}
                  checked={
                    carDetailInfo?.carInsepction[12].sideSealPanelL.check
                  }
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[12].sideSealPanelL.check}
                >
                  13. 사이드실 패널(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!carDetailInfo?.carInsepction[13].sideSealPanelR.check}
                  checked={
                    carDetailInfo?.carInsepction[13].sideSealPanelR.check
                  }
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[13].sideSealPanelR.check}
                >
                  14. 사이드실 패널(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>
            <Line />
            <CategoryText>
              • &nbsp;주요골격 부위의 교환,부식,판금 및 용접수리
            </CategoryText>
            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[14].frontPanel.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[14].frontPanel.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[14].frontPanel.check}
                >
                  15. 프론트 패널
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[15].crossMember.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[15].crossMember.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[15].crossMember.check}
                >
                  16. 크로스 멤버
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[16].insidePanelL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[16].insidePanelL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[16].insidePanelL.check}
                >
                  17. 인사이드 패널(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[17].insidePanelR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[17].insidePanelR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[17].insidePanelR.check}
                >
                  18. 인사이드 패널(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[18].rearPanel.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[18].rearPanel.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[18].rearPanel.check}
                >
                  19. 리어 패널
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[19].trunkFloor.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[19].trunkFloor.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[19].trunkFloor.check}
                >
                  20. 트렁크 플로어
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[20].frontSideMemberL.check}
                  className="selfsellcar_checkbox"
                  checked={
                    carDetailInfo?.carInsepction[20].frontSideMemberL.check
                  }
                />
                <InspectionText
                  active={
                    carDetailInfo?.carInsepction[20].frontSideMemberL.check
                  }
                >
                  21. 프론트 사이드 멤버(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!carDetailInfo?.carInsepction[21].frontSideMemberR.check}
                  checked={
                    carDetailInfo?.carInsepction[21].frontSideMemberR.check
                  }
                />
                <InspectionText
                  active={
                    carDetailInfo?.carInsepction[21].frontSideMemberR.check
                  }
                >
                  22. 프론트 사이드 멤버(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[22].rearSideL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[22].rearSideL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[22].rearSideL.check}
                >
                  23. 리어 사이드 멤버(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[23].rearSideR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[23].rearSideR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[23].rearSideR.check}
                >
                  24. 리어 사이드 멤버(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[24].frontWheelL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[24].frontWheelL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[24].frontWheelL.check}
                >
                  25. 프론트 휠하우스(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[25].frontWheelR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[25].frontWheelR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[25].frontWheelR.check}
                >
                  26. 프론트 휠하우스(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[26].rearWheelL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[26].rearWheelL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[26].rearWheelL.check}
                >
                  27. 리어 휠하우스(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[27].rearWheelR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[27].rearWheelR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[27].rearWheelR.check}
                >
                  28. 리어 휠하우스(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[28].fillerAL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[28].fillerAL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[28].fillerAL.check}
                >
                  29. 필러 패널A(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[29].fillerAR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[29].fillerAR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[29].fillerAR.check}
                >
                  30. 필러 패널A(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[30].fillerBL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[30].fillerBL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[30].fillerBL.check}
                >
                  31. 필러 패널B(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[31].fillerBR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[31].fillerBR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[31].fillerBR.check}
                >
                  32. 필러 패널B(우)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[32].fillerCL.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[32].fillerCL.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[32].fillerCL.check}
                >
                  33. 필러 패널C(좌)
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[33].fillerCR.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[33].fillerCR.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[33].fillerCR.check}
                >
                  34. 필러 패널C(우)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[34].packageTray.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[34].packageTray.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[34].packageTray.check}
                >
                  35. 패키지트레이
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[35].dashPanel.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[35].dashPanel.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[35].dashPanel.check}
                >
                  36. 대쉬 패널
                </InspectionText>
              </ContentBox>
              <ContentBox>
                <AutoOuteltCheckbox
                  unactive={!carDetailInfo?.carInsepction[36].floorPanel.check}
                  className="selfsellcar_checkbox"
                  checked={carDetailInfo?.carInsepction[36].floorPanel.check}
                />
                <InspectionText
                  active={carDetailInfo?.carInsepction[36].floorPanel.check}
                >
                  37. 플로어 패널(바닥)
                </InspectionText>
              </ContentBox>
            </CheckBoxContainer>
            <Line />
            <OutletDetailText2 weight>• 특이사항</OutletDetailText2>
            <AutoOutletDetailFlexDiv margin="30px">
              <AutoOutletText3>
                {carDetailInfo?.inspectionDetail}
              </AutoOutletText3>
            </AutoOutletDetailFlexDiv>
          </OutletDetailCarInfoWrapDiv>
        </OutletDetailContainer>
      </AutoOutleWrapDiv>
    </>
  );
};

export default AutoOutletDetail;
