import Header from "../../components/Header";
import LoginContent from "../../components/login&join/LoginContent";

const Login = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <LoginContent />
    </>
  );
};

export default Login;
