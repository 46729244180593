import axios from "axios";
import styled from "@emotion/styled/macro";
import { useParams , useLocation } from "react-router";
import { useEffect, useState } from "react";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";
import { ImageButton } from "../../components/liveAuction/Image";
import { CloseInspectionContainer } from "../../components/selfSellCar/SelfSellCarStyle";
import Header from "../../components/Header";
import {
  LiveAuctionDetailContainer,
  LiveAuctionDetailBox,
  LiveAuctionCategoryContainer,
  LiveAuctionDetailTitle,
  LiveAuctionDetailPrice,
  LiveAuctionDetailCarImageDiv,
  LiveAuctionCarFunctionContent,
  LiveAuctionBidButton,
  Container,
  LiveAuctionCarFunctionTitle,
  CarInfoBadge2,
  StatusText,
} from "../../components/liveAuction/LiveAuctionStyle";
import {
  OutletDetailContainer,
  OutletDetialTitleDiv,
  OutletDetailText4,
  CarMainOptionText,
  CarMainOptionContainer,
  OutletDetailLine,
  CarMainOptionDiv,
  OutletDetailContainer2,
  OutletDetailTitleContainer,
  DetailContainer,
  OutletDetailContentContainer,
  OutletDetailText,
  OutletDetailText3,
  OutletDetailCarInfoWrapDiv,
  AutoOutletDetailFlexDiv,
  OutletDetailText2,
  AutoOutletSmallCarDiv,
  Description,
  CarDetailOptionWrapDiv,
  CarDetailOptionDiv,
  AutoOutletText2,
  VehiclesAllOptionDiv,
} from "../../components/autoOutlet/AutoOutletStyle";
import {
  CheckBoxContainer,
  ContentBox,
  InspectionText,
} from "../../components/InspectionImage";
import {
  InspectionImage,
  Line,
  InspecionIconContainer,
  IconText,
} from "../../components/InspectionImage";
import {
  BidModal,
  PostConsultationModal,
} from "../../components/liveAuction/LiveAuctionModal";
import { CarImage } from "../../components/autoOutlet/AutoOutletStyle";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right_arrow.svg";
import { ReactComponent as DetailIcon } from "../../assets/icons/DetailIcon.svg";
import { ReactComponent as DetailIcon4 } from "../../assets/icons/DetailIcon4.svg";
import { ReactComponent as DetailIcon5 } from "../../assets/icons/DetailIcon5.svg";
import { ReactComponent as NaviIcon } from "../../assets/icons/NaviIcon.svg";
import { ReactComponent as NaviIcon2 } from "../../assets/icons/NaviIcon2.svg";
import { ReactComponent as SmartKey } from "../../assets/icons/SmartKey.svg";
import { ReactComponent as SmartKey2 } from "../../assets/icons/SmartKey2.svg";
import { ReactComponent as Sunloop } from "../../assets/icons/Sunloop.svg";
import { ReactComponent as Sunloop2 } from "../../assets/icons/Sunloop2.svg";
import { ReactComponent as Windloop2 } from "../../assets/icons/windloop2.svg";
import { ReactComponent as Windloop } from "../../assets/icons/windloop.svg";
import { ReactComponent as HeadLamp } from "../../assets/icons/HeadLamp.svg";
import { ReactComponent as HeadLamp2 } from "../../assets/icons/HeadLamp2.svg";
import { ReactComponent as BackSight } from "../../assets/icons/BackSight.svg";
import { ReactComponent as BackSight2 } from "../../assets/icons/BackSight2.svg";
import { ReactComponent as SmartCrew } from "../../assets/icons/SmartCrew.svg";
import { ReactComponent as SmartCrew2 } from "../../assets/icons/SmartCrew2.svg";
import { ReactComponent as HeadUp } from "../../assets/icons/HeadUp.svg";
import { ReactComponent as HeadUp2 } from "../../assets/icons/HeadUp2.svg";
import { ReactComponent as EscapeLoad } from "../../assets/icons/EscapeLoad.svg";
import { ReactComponent as EscapeLoad2 } from "../../assets/icons/EscapeLoad2.svg";
import { ReactComponent as AroundView } from "../../assets/icons/AroundView.svg";
import { ReactComponent as AroundView2 } from "../../assets/icons/AroundView2.svg";
import { ReactComponent as AuctionIcon } from "../../assets/icons/auctionIcon.svg";
import { ReactComponent as DetailIcon3 } from "../../assets/icons/DetailIcon3.svg";
import { ReactComponent as Icon } from "../../assets/icons/inpsection/inpsection_01.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/inpsection/inspection_02.svg";
import { ReactComponent as Icon3 } from "../../assets/icons/inpsection/inspection_03.svg";
import { ReactComponent as Icon4 } from "../../assets/icons/inpsection/inspection_04.svg";
import { ReactComponent as Icon5 } from "../../assets/icons/inpsection/inspection_05.svg";
import { ReactComponent as Icon6 } from "../../assets/icons/inpsection/inspection_06.svg";
import { ReactComponent as Icon7 } from "../../assets/icons/inpsection/inspection_07.svg";
import { ReactComponent as Icon8 } from "../../assets/icons/inpsection/inspection_08.svg";
import { ReactComponent as Icon9 } from "../../assets/icons/inpsection/inspection_09.svg";
import { ReactComponent as Icon10 } from "../../assets/icons/inpsection/inspection_10.svg";
import { SmallIamge } from "../../components/liveAuction/Image";


const LiveAuctionCheckbox = styled(JoinCheckbox)`
margin-right : 8px;
`


const LiveAuctionDetail = () => {
  const position = false;
  const location = useLocation();
  const liveAuctionText = true;
  const token = useToken();
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState({
    url: "",
    index: 0,
  });
  const [carInfo, setCarInfo] = useState();
  const { id } = useParams();

  

  // 특정 라이브옥션 정보
  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASIC_URL}/liveauction/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCarInfo(res.data);

        if (res.data.images.length) {
          setImageSrc({ ...imageSrc, url: res.data.images[0].url });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  const [consultation, setConsultation] = useState(false);
  const allVehicleOptions = carInfo?.options.map((category, index) => {
    const detailVehicleOption = category.options.map((option) => {
      return (
        <CarDetailOptionDiv key={option.id}>
          <CarMainOptionText>
            • {option.name}
            <Description style={{ top: 35 }}>
              <StatusText>{option.name}</StatusText>
              <CarMainOptionText style={{ marginTop: 12 }}>
                {option.description}
              </CarMainOptionText>
            </Description>
          </CarMainOptionText>
        </CarDetailOptionDiv>
      );
    });
    return (
      <>
        <AutoOutletDetailFlexDiv margin="50px" align="center" key={index}>
          <AutoOutletText2>{category.category}</AutoOutletText2>
          <AutoOutletText2 style={{ color: "#FF6A13" }}>
            &nbsp; {category.options.length}
          </AutoOutletText2>
        </AutoOutletDetailFlexDiv>
        <VehiclesAllOptionDiv>{detailVehicleOption}</VehiclesAllOptionDiv>
      </>
    );
  });

  return (
    <>
      <BidModal
        bidModalOpen={bidModalOpen}
        setBidModalOpen={setBidModalOpen}
        id={id}
      />
      <PostConsultationModal
        setConsultation={setConsultation}
        consultation={consultation}
        id={id}
      />
      <Header position={position} liveAuctionText={liveAuctionText} borderactive={location?.pathname?.includes("/liveAcutionDetail")}/>
      <LiveAuctionDetailContainer>
        <LiveAuctionDetailBox>
          <LiveAuctionDetailTitle>
            {carInfo && carInfo?.name}
          </LiveAuctionDetailTitle>
          <LiveAuctionCategoryContainer>
            <Container>
              <CarInfoBadge2>{carInfo && carInfo?.year}년형</CarInfoBadge2>
              <CarInfoBadge2>
                {carInfo &&
                  Number(carInfo?.distanceDriven).toLocaleString("en")}
                km
              </CarInfoBadge2>
              <CarInfoBadge2>{carInfo && carInfo?.fuel}</CarInfoBadge2>
            </Container>
            <LiveAuctionDetailPrice>
              시작가 {carInfo && carInfo?.price / 10000}만원
            </LiveAuctionDetailPrice>
          </LiveAuctionCategoryContainer>
          <LiveAuctionDetailCarImageDiv>
            {imageSrc.url !== "" && <CarImage src={imageSrc.url} alt="src" />}
            <ImageButton
              style={{ left: 30, top: "50%" }}
              onClick={() => {
                if (imageSrc.index === 0) {
                  setImageSrc({
                    ...imageSrc,
                    url: carInfo?.images[0].url,
                    index: 0,
                  });
                } else {
                  const nextImage = carInfo?.images?.find(
                    (item, index) => index === imageSrc.index - 1
                  );
                  setImageSrc({
                    ...imageSrc,
                    url: nextImage.url,
                    index: imageSrc?.index - 1,
                  });
                }
              }}
            >
              <LeftArrow />
            </ImageButton>
            <RightArrow
              style={{
                right: 30,
                position: "absolute",
                cursor: "pointer",
                top: "50%",
              }}
              onClick={() => {
                if (imageSrc?.index === carInfo?.images.length - 1) {
                  const lastImage = carInfo?.images?.find(
                    (item, index) => index === carInfo?.images?.length - 1
                  );
                  setImageSrc({
                    ...imageSrc,
                    url: lastImage.url,
                    index: carInfo?.images.length - 1,
                  });
                } else {
                  const nextImage = carInfo?.images?.find(
                    (item, index) => index === imageSrc.index + 1
                  );

                  setImageSrc({
                    ...imageSrc,
                    url: nextImage.url,
                    index: imageSrc?.index + 1,
                  });
                }
              }}
            />
          </LiveAuctionDetailCarImageDiv>

          <AutoOutletSmallCarDiv>
            {carInfo?.images?.map((list, index) => {
              return (
                <SmallIamge
                  active={imageSrc.url === list.url}
                  onClick={() => {
                    setImageSrc({ index, url: list.url });
                  }}
                  style={{ backgroundImage: `url("${list.url}")` }}
                  key={index}
                />
              );
            })}
          </AutoOutletSmallCarDiv>
          {carInfo?.bidderable === false ? (
            <LiveAuctionBidButton onClick={() => setBidModalOpen(true)}>
              <AuctionIcon style={{ marginBottom: 9 }} /> 부재자 입찰
            </LiveAuctionBidButton>
          ) : null}
          {carInfo?.consultantable === false ? (
            <LiveAuctionBidButton onClick={() => setConsultation(true)}>
              <AuctionIcon style={{ marginBottom: 9 }} /> 후 상담신청
            </LiveAuctionBidButton>
          ) : null}
        </LiveAuctionDetailBox>

        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon style={{ marginRight: 4 }} />
              <OutletDetailText>차량 정보</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량 정보를 한눈에 확인해 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <OutletDetailCarInfoWrapDiv>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>차량번호</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.number}</OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>색상</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.color}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>배기량</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>
                  {Number(carInfo?.displacement).toLocaleString("en")} cc
                </OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>연식</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.year}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>변속기</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.gearbox}</OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>연료</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.fuel}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>주행거리</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>
                  {Number(carInfo?.distanceDriven).toLocaleString("en")}km
                </OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>승차정원</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.seater}명</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>차량이력</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.carRecord}</OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer>
                <OutletDetailText3>차대번호</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>{carInfo?.vin}</OutletDetailText4>
              </OutletDetailContentContainer>
            </OutletDetailContainer2>
            <OutletDetailContainer2>
              <OutletDetailTitleContainer>
                <OutletDetailText3>검사유효일</OutletDetailText3>
              </OutletDetailTitleContainer>
              <OutletDetailContentContainer>
                <OutletDetailText4>
                  {carInfo?.inspectionEffectiveDay}
                </OutletDetailText4>
              </OutletDetailContentContainer>
              <OutletDetailTitleContainer
                style={{ background: "#fff" }}
              ></OutletDetailTitleContainer>
              <OutletDetailContentContainer></OutletDetailContentContainer>
            </OutletDetailContainer2>
          </OutletDetailCarInfoWrapDiv>
        </OutletDetailContainer>
        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon4 style={{ marginRight: 4 }} />
              <OutletDetailText>주요 옵션</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량에 장착된 주요 옵션들을 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CarMainOptionContainer>
              {carInfo?.mainOption?.navigation ? (
                <CarMainOptionDiv>
                  <NaviIcon
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>네비게이션</CarMainOptionText>
                  <Description>
                    <StatusText>네비게이션</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <NaviIcon2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />

                  <CarMainOptionText colorchange>네비게이션</CarMainOptionText>
                  <Description>
                    <StatusText>네비게이션</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.smartKey ? (
                <CarMainOptionDiv>
                  <SmartKey
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>스마트키</CarMainOptionText>
                  <Description>
                    <StatusText>스마트키</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <SmartKey2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>스마트키</CarMainOptionText>
                  <Description>
                    <StatusText>스마트키</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.sunloop ? (
                <CarMainOptionDiv>
                  <Sunloop style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText>선루프</CarMainOptionText>
                  <Description>
                    <StatusText>선루프</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <Sunloop2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>선루프</CarMainOptionText>
                  <Description>
                    <StatusText>선루프</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.windSheet ? (
                <CarMainOptionDiv>
                  <Windloop2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>통풍시트</CarMainOptionText>
                  <Description>
                    <StatusText>통풍시트</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <Windloop
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>통풍시트</CarMainOptionText>
                  <Description>
                    <StatusText>통풍시트</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.headLamp ? (
                <CarMainOptionDiv marginright>
                  <HeadLamp
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>헤드 램프</CarMainOptionText>
                  <Description>
                    <StatusText>헤드 램프</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv marginright>
                  <HeadLamp2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>헤드 램프</CarMainOptionText>
                  <Description>
                    <StatusText>헤드 램프</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
            </CarMainOptionContainer>

            <CarMainOptionContainer marginTop>
              {carInfo?.mainOption?.backSight ? (
                <CarMainOptionDiv>
                  <BackSight
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>후측방 경보</CarMainOptionText>
                  <Description>
                    <StatusText>후측방 경보</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <BackSight2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    {" "}
                    후측방 경보
                  </CarMainOptionText>
                  <Description>
                    <StatusText>후측방 경보</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.smartCruiseControl ? (
                <CarMainOptionDiv>
                  <SmartCrew
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>
                    스마트 크루즈
                    <br /> 컨트롤{" "}
                  </CarMainOptionText>
                  <Description>스마트 크루즈 컨트롤</Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <SmartCrew2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    스마트 크루즈
                    <br /> 컨트롤{" "}
                  </CarMainOptionText>
                  <Description>
                    <StatusText>스마트 크루즈 컨트롤</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.headUpDisplay ? (
                <CarMainOptionDiv>
                  <HeadUp style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText>
                    헤드업 <br />
                    디스플레이
                  </CarMainOptionText>
                  <Description>
                    <StatusText>헤드업 디스플레이</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <HeadUp2 style={{ marginBottom: 6, height: 44, width: 44 }} />
                  <CarMainOptionText colorchange>
                    헤드업 <br />
                    디스플레이
                  </CarMainOptionText>
                  <Description>
                    <StatusText>헤드업 디스플레이</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.escapeRoadNotifiaction ? (
                <CarMainOptionDiv>
                  <EscapeLoad
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>차선 이탈 경보장치</CarMainOptionText>
                  <Description>
                    <StatusText>차선 이탈 경보장치</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv>
                  <EscapeLoad2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    차선 이탈 경보장치
                  </CarMainOptionText>
                  <Description>
                    <StatusText>차선 이탈 경보장치</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
              {carInfo?.mainOption?.aroundView ? (
                <CarMainOptionDiv marginright>
                  <AroundView2
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText>
                    어라운드뷰 <br />
                    모니터링 시스템
                  </CarMainOptionText>
                  <Description>
                    <StatusText>어라운드뷰 모니터링 시스템</StatusText>
                  </Description>
                </CarMainOptionDiv>
              ) : (
                <CarMainOptionDiv marginright>
                  <AroundView
                    style={{ marginBottom: 6, height: 44, width: 44 }}
                  />
                  <CarMainOptionText colorchange>
                    어라운드뷰 <br />
                    모니터링 시스템
                  </CarMainOptionText>
                  <Description>
                    <StatusText>어라운드뷰 모니터링 시스템</StatusText>
                  </Description>
                </CarMainOptionDiv>
              )}
            </CarMainOptionContainer>
          </div>
        </OutletDetailContainer>
        <OutletDetailContainer>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon3 style={{ marginRight: 4 }} />
              <OutletDetailText>차량 옵션</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량에 장착된 전체 옵션들을 보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <CarDetailOptionWrapDiv>
            {/* <CarDetailOptionDiv>{wholeOption}</CarDetailOptionDiv> */}
            {allVehicleOptions}
          </CarDetailOptionWrapDiv>
        </OutletDetailContainer>

        <OutletDetailContainer style={{ marginBottom: 100 }}>
          <OutletDetialTitleDiv>
            <OutletDetailLine />
            <DetailContainer>
              <DetailIcon5 style={{ marginRight: 4 }} />
              <OutletDetailText>성능 정보</OutletDetailText>
            </DetailContainer>
            <OutletDetailText2 style={{ marginTop: 12 }}>
              {" "}
              차량의 상태 및 성능을 확인해보세요.
            </OutletDetailText2>
          </OutletDetialTitleDiv>
          <OutletDetailCarInfoWrapDiv>
            <CloseInspectionContainer style={{ marginTop: 0 }}>
              <InspecionIconContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: 518,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon /> <IconText>교환</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      marginRight: 22,
                    }}
                  >
                    <Icon2 /> <IconText>판금/용접 </IconText>{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon3 /> <IconText>교환필요</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon4 /> <IconText>판금필요</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Icon5 /> <IconText>조정작업</IconText>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 14,
                    width: 518,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon6 /> <IconText>도장</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon7 /> <IconText>도장필요 </IconText>{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 22,
                      flex: 1,
                    }}
                  >
                    <Icon8 /> <IconText>꺾임</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      marginRight: 22,
                    }}
                  >
                    <Icon9 /> <IconText>부식</IconText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Icon10 /> <IconText>상처</IconText>
                  </div>
                </div>
              </InspecionIconContainer>
              <InspectionImage alt="" src={carInfo?.inspectionImage} />
              <Line />
              <OutletDetailText2 weight>
                • &nbsp;외판부위의 교환, 부식, 판금 및 용접수리
              </OutletDetailText2>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[0].hood?.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[0].hood?.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[0].hood?.check}
                  >
                    1. 후드
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[1].frontHenderL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[1].frontHenderL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[1].frontHenderL.check}
                  >
                    2. 프론트 휀더(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[2].frontHenderR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[2].frontHenderR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[2].frontHenderR.check}
                  >
                    3. 프론트 휀더(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[3].frontDoorL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[3].frontDoorL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[3].frontDoorL.check}
                  >
                    4. 프론트 도어(좌)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>
              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[4].frontDoorR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[4].frontDoorR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[4].frontDoorR.check}
                  >
                    5. 프론트 도어(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[5].rearDoorL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[5].rearDoorL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[5].rearDoorL.check}
                  >
                    6. 리어 도어(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[6].rearDoorR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[6].rearDoorR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[6].rearDoorR.check}
                  >
                    7. 리어 도어(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[7].trunkRid.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[7].trunkRid.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[7].trunkRid.check}
                  >
                    8. 트렁크리드
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>
              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[8].radiatorSupport.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[8].radiatorSupport.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[8].radiatorSupport.check}
                  >
                    9. 라디에이터 서포트
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[9].loopPanel.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[9].loopPanel.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[9].loopPanel.check}
                  >
                    10. 루프 패널
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[10].quarterPanelL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[10].quarterPanelL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[10].quarterPanelL.check}
                  >
                    11. 쿼터 패널(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[11].quarterPanelR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[11].quarterPanelR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[11].quarterPanelR.check}
                  >
                    12. 쿼터 패널(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[12].sideSealPanelL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[12].sideSealPanelL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[12].sideSealPanelL.check}
                  >
                    13. 사이드실 패널(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[13].sideSealPanelR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[13].sideSealPanelR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[13].sideSealPanelR.check}
                  >
                    14. 사이드실 패널(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <Line />

              <OutletDetailText2 weight>
                • &nbsp;주요골격 부위의 교환,부식,판금 및 용접수리
              </OutletDetailText2>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[14].frontPanel.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[14].frontPanel.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[14].frontPanel.check}
                  >
                    15. 프론트 패널
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[15].crossMember.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[15].crossMember.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[15].crossMember.check}
                  >
                    16. 크로스 멤버
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[16].insidePanelL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[16].insidePanelL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[16].insidePanelL.check}
                  >
                    17. 인사이드 패널(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[17].insidePanelR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[17].insidePanelR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[17].insidePanelR.check}
                  >
                    18. 인사이드 패널(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[18].rearPanel.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[18].rearPanel.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[18].rearPanel.check}
                  >
                    19. 리어 패널
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[19].trunkFloor.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[19].trunkFloor.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[19].trunkFloor.check}
                  >
                    20. 트렁크 플로어
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[20].frontSideMemberL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[20].frontSideMemberL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[20].frontSideMemberL.check}
                  >
                    21. 프론트 사이드 멤버(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[21].frontSideMemberR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[21].frontSideMemberR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[21].frontSideMemberR.check}
                  >
                    22. 프론트 사이드 멤버(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[22].rearSideL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[22].rearSideL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[22].rearSideL.check}
                  >
                    23. 리어 사이드 멤버(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[23].rearSideR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[23].rearSideR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[23].rearSideR.check}
                  >
                    24. 리어 사이드 멤버(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[24].frontWheelL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[24].frontWheelL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[24].frontWheelL.check}
                  >
                    25. 프론트 휠하우스(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[25].frontWheelR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[25].frontWheelR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[25].frontWheelR.check}
                  >
                    26. 프론트 휠하우스(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[26].rearWheelL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[26].rearWheelL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[26].rearWheelL.check}
                  >
                    27. 리어 휠하우스(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[27].rearWheelR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[27].rearWheelR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[27].rearWheelR.check}
                  >
                    28. 리어 휠하우스(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[28].fillerAL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[28].fillerAL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[28].fillerAL.check}
                  >
                    29. 필러 패널A(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[29].fillerAR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[29].fillerAR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[29].fillerAR.check}
                  >
                    30. 필러 패널A(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[30].fillerBL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[30].fillerBL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[30].fillerBL.check}
                  >
                    31. 필러 패널B(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[31].fillerBR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[31].fillerBR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[31].fillerBR.check}
                  >
                    32. 필러 패널B(우)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[32].fillerCL.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[32].fillerCL.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[32].fillerCL.check}
                  >
                    33. 필러 패널C(좌)
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[33].fillerCR.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[33].fillerCR.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[33].fillerCR.check}
                  >
                    34. 필러 패널C(우)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>

              <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[34].packageTray.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[34].packageTray.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[34].packageTray.check}
                  >
                    35. 패키지트레이
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[35].dashPanel.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[35].dashPanel.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[35].dashPanel.check}
                  >
                    36. 대쉬 패널
                  </InspectionText>
                </ContentBox>
                <ContentBox>
                  <LiveAuctionCheckbox
                    unactive={!carInfo?.carInsepction[36].floorPanel.check}
                    className="selfsellcar_checkbox"
                    checked={carInfo?.carInsepction[36].floorPanel.check}
                  />
                  <InspectionText
                    active={carInfo?.carInsepction[36].floorPanel.check}
                  >
                    37. 플로어 패널(바닥)
                  </InspectionText>
                </ContentBox>
              </CheckBoxContainer>
              <Line />
            </CloseInspectionContainer>
            <OutletDetailText2 weight style={{ marginTop: 57 }}>
              • 기능 / 상태 / 점검사항
            </OutletDetailText2>
            <AutoOutletDetailFlexDiv margin="22px" style={{ flexWrap: "wrap" }}>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>기관</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[0]?.engine}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>전기</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[3]?.electricity}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>동력</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[2]?.power}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>제동</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[1]?.braking}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>변속</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[4]?.shift}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>공조</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[7]?.airConditioning}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>조항</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[6]?.clause}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>현가</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[5]?.suspension}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
              <LiveAuctionCarFunctionTitle>
                {" "}
                <OutletDetailText3>실내</OutletDetailText3>
              </LiveAuctionCarFunctionTitle>
              <LiveAuctionCarFunctionContent>
                <OutletDetailText4>
                  {carInfo?.carFunctions[8]?.interior}
                </OutletDetailText4>
              </LiveAuctionCarFunctionContent>
            </AutoOutletDetailFlexDiv>
          </OutletDetailCarInfoWrapDiv>
        </OutletDetailContainer>
      </LiveAuctionDetailContainer>
    </>
  );
};

export default LiveAuctionDetail;
