import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";
import axios from "axios";
import tokenState from "../states/TokenState";
import { BASIC_URL } from "../components/CommonStyle";

export function useUserType() {
  const [userType, setUserType] = useState("");

  const token = useRecoilValue(tokenState);

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/name`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setUserType(res.data.userData?.type);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);
  return userType;
}

function useUserInfo() {
  const [userInfo, setUserInfo] = useState({});

  const token = useRecoilValue(tokenState);

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/profile`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data) {
        setUserInfo(res.data);
      }
    });
    return setUserInfo({});
  }, [token]);
  return userInfo;
}

export default useUserInfo;
