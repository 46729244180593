import { useLocation, useNavigate } from "react-router";
import { css } from "@emotion/react";
import axios from "axios";
import styled from "@emotion/styled/macro";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../../components/CommonStyle";
import { ReactComponent as Success } from "../../assets/img/successcheck.svg";
import moment from "moment-timezone";

const CarStorageWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CarStorageTitleBox = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleText = styled.p`
  font-size: 30px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.2px;
`;

const Check = styled(Success)`
  margin-right: 10px;
`;

const SubText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.72px;
  margin-top: 20px;
`;

const Line = styled.div`
  width: 600px;
  height: 1px;
  background-color: #dee2e6;
  margin-top: 70px;
  margin-bottom: 76px;
`;

const PaymentText = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.72px;

  ${(props) =>
    props.content &&
    css`
      font-weight: 500;
      color: #212529;
    `};
`;

const PaymentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  align-items: center;
`;

const PaymentBox = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
`;

const Box = styled.div`
  width: 197px;
`;

const ListButton = styled.button`
  width: 240px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ff6a13;
  font-size: 22px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #fff;
  letter-spacing: -0.88px;
  border: none;
  margin-top: 200px;
`;

const CarStorageApplySuccess = () => {
  const position = false;
  const carStoragetextBold = true;
  const state = useLocation();
  const navigate = useNavigate();
  const [deadlinePayment, setDeadLinePayment] = useState(null);

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/calculator/business-days?date=${new Date()}`,
      method: "GET",
    }).then((res) => {
      setDeadLinePayment(res.data);
    });
  }, []);

  return (
    <CarStorageWrapDiv>
      <Header position={position} carStoragetextBold={carStoragetextBold} />
      <CarStorageTitleBox>
        <Check />
        <TitleText>구매 신청이 완료 되었습니다.</TitleText>
      </CarStorageTitleBox>
      <SubText>고객님의 차량이 입고될 예정입니다.</SubText>
      <Line />
      <PaymentWrap>
        <PaymentBox>
          <Box>
            <PaymentText>결제정보</PaymentText>
          </Box>
          <Box>
            <PaymentText content> {state?.state?.method}</PaymentText>
          </Box>
        </PaymentBox>
        {state?.state?.method === "계좌이체" ? (
          <>
            <PaymentBox>
              <Box>
                <PaymentText>입금 계좌</PaymentText>
              </Box>
              <Box>
                <PaymentText content>
                  {" "}
                  {state?.state?.bank}&nbsp;
                  {state?.state?.account}
                </PaymentText>
              </Box>
            </PaymentBox>
            <PaymentBox>
              <Box>
                <PaymentText>예금주</PaymentText>
              </Box>
              <Box>
                <PaymentText content> (주)탈것</PaymentText>
              </Box>
            </PaymentBox>
            <PaymentBox>
              <Box>
                <PaymentText>입금 기간</PaymentText>
              </Box>
              <Box>
                <PaymentText content>
                  {" "}
                  {moment(deadlinePayment).format("YYYY년 MM월 DD일")}
                </PaymentText>
              </Box>
            </PaymentBox>{" "}
          </>
        ) : null}
        <PaymentBox>
          <Box>
            <PaymentText>금액</PaymentText>
          </Box>
          <Box>
            <PaymentText content>
              {Number(state?.state?.price).toLocaleString("en")}원
            </PaymentText>
          </Box>
        </PaymentBox>
      </PaymentWrap>
      <ListButton onClick={() => navigate("/carStorageApplyList")}>
        차량 보관 내역
      </ListButton>
    </CarStorageWrapDiv>
  );
};

export default CarStorageApplySuccess;
