import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ReactComponent as Success } from "../../../assets/img/successcheck.svg";
import Header from "../../../components/Header";

const CommercializationWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .font_margin {
    margin-top: 20px;
  }
`;

const CommercializationBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  margin-top: ${(props) => props.margin};
`;

const CommercialInfoBox = styled.div`
  width: 266px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 26px;

  ${(props) =>
    props.margin &&
    css`
      margin-bottom: 0;
    `};
`;

const OptionBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;

  .font_margin2 {
    margin-bottom: 10px;
  }
`;

const NavigationButton = styled.button`
  width: 240px;
  height: 70px;
  outline: none;
  border: none;
  background-color: #ff6a13;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.88px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 200px;
`;

const SuccessCheck = styled(Success)`
  margin-right: 10px;
`;

const CommercializationText = styled.p`
  font-size: 30px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.12px;
  color: #212529;
`;

const CommercializationText2 = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;

  ${(props) =>
    props.weight &&
    css`
      font-weight: 400;
    `};
`;

const CommercializationText3 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #212529;
`;

const CommercializationLine = styled.div`
  width: 600px;
  height: 1px;
  background-color: #dee2e6;
  margin-top: 70px;
  margin-bottom: 100px;
`;

const CommercializationApply = () => {
  const position = false;
  const selfSellCarText = true;

  const optionState = useLocation();
  const { state } = optionState;
  const { id } = useParams();

  const navigate = useNavigate();
  return (
    <CommercializationWrapDiv>
      <Header position={position} selfSellCarText={selfSellCarText} />
      <CommercializationBox margin="100px">
        <SuccessCheck />{" "}
        <CommercializationText>
          상품화 신청이 완료되었습니다.
        </CommercializationText>
      </CommercializationBox>
      <CommercializationText2 className="font_margin">
        정비가 시작될 예정입니다.
      </CommercializationText2>
      <CommercializationLine />
      <CommercialInfoBox>
        <CommercializationText2 weight>옵션</CommercializationText2>
        <CommercializationText3>
          {state?.grade && state?.grade}
        </CommercializationText3>
      </CommercialInfoBox>
      <CommercialInfoBox>
        <CommercializationText2 weight>상품</CommercializationText2>
        <OptionBox>
          {state?.overhaul &&
            state?.overhaul?.map((item) => {
              return (
                <CommercializationText3 key={item?.id} className="font_margin2">
                  {item?.name}
                </CommercializationText3>
              );
            })}
        </OptionBox>
      </CommercialInfoBox>
      <CommercialInfoBox>
        <CommercializationText2 weight>금액</CommercializationText2>
        <CommercializationText3>
          {state?.totalPrice &&
            `${Number(state?.totalPrice).toLocaleString("en")}원`}
        </CommercializationText3>
      </CommercialInfoBox>
      <CommercialInfoBox margin>
        <CommercializationText2 weight>예상 소요일</CommercializationText2>
        <CommercializationText3>
          {state?.totalDay && `${state?.totalDay}일`}
        </CommercializationText3>
      </CommercialInfoBox>
      <NavigationButton onClick={() => navigate(`/selfSellCarStep/${id}`)}>
        나의 신청 현황
      </NavigationButton>
    </CommercializationWrapDiv>
  );
};

export default CommercializationApply;
