import axios from "axios";
import moment from "moment";
import { useParams, useNavigate , useLocation } from "react-router";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { BASIC_URL } from "../../components/CommonStyle";
import {
  BoardWrapDiv,
  BoardContentDiv,
  BoardContainer,
  BoardCategory,
  BorderDiv,
  BoardDateText,
  BoardDetailContentDiv,
  BorderLine,
  BoardDetailContentText,
} from "../../components/board/BoardStyle";
import { CarStorageButton } from "../../components/carStorage/CarStorageStyle";
import { PickUpOptionText } from "../../components/pick-up/PickUpStyle";

const BoardDetail = () => {
  const position = false;
  const boardText = true;
  const { id } = useParams();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  // 게시글 상세 정보
  const [boardDetail, setBoardDetail] = useState([]);
  const [prevBoard, setPrevBoard] = useState([]);
  const [nextBoard, setNextBoard] = useState([]);
  const [prevId, setPrevId] = useState();
  const [nextId, setNextId] = useState();

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/board/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setBoardDetail([res.data.board]);
          setPrevId(res.data.prevId);
          setNextId(res.data.nextId);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      setBoardDetail([]);
      setPrevId();
      setNextId();
    };
  }, [id]);

  useEffect(() => {
    if (prevId) {
      axios({
        url: `${BASIC_URL}/board/${prevId}`,
        method: "GET",
      })
        .then((res) => {
          if (res.data) {
            setPrevBoard(res.data.board);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return () => setPrevBoard([]);
    }
  }, [prevId]);

  useEffect(() => {
    if (nextId) {
      axios({
        url: `${BASIC_URL}/board/${nextId}`,
        method: "GET",
      })
        .then((res) => {
          if (res.data) {
            setNextBoard(res.data.board);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return () => setNextBoard([]);
    }
  }, [nextId]);

  const boardContent = boardDetail?.map((board) => {
    return (
      <>
        <BorderDiv key={board.id}>
          <PickUpOptionText
            style={{
              width: 818,
            }}
            dangerouslySetInnerHTML={{ __html: board.title }}
          />
          <BoardDateText>
            {moment(board.createdAt).format("YYYY.MM.DD")}
          </BoardDateText>
        </BorderDiv>
        <BoardDetailContentDiv>
          
          <BoardDetailContentText
            dangerouslySetInnerHTML={{ __html: board.content }}
          />
        </BoardDetailContentDiv>
      </>
    );
  });

  return (
    <BoardWrapDiv>
      <Header position={position} boardText={boardText} borderactive={pathname.includes('/board')} />
      <BoardContentDiv margin="100px" align="center">
        <BoardContainer>
          <BoardCategory
            active={boardDetail[0]?.type === "공지사항"}
            onClick={(event) =>
              navigate("/board", {
                state: { type: event.target.outerText.replace(/ /g, "") },
              })
            }
          >
            공지사항
          </BoardCategory>
          <BoardCategory
            active={boardDetail[0]?.type === "캠핑정보"}
            onClick={(event) =>
              navigate("/board", {
                state: { type: event.target.outerText.replace(/ /g, "") },
              })
            }
          >
            캠핑 정보
          </BoardCategory>
          <BoardCategory
            active={boardDetail[0]?.type === "자동차정보"}
            onClick={(event) =>
              navigate("/board", {
                state: { type: event.target.outerText.replace(/ /g, "") },
              })
            }
          >
            자동차 정보
          </BoardCategory>
          <BoardCategory
            active={boardDetail[0]?.type === "FQ"}
            onClick={(event) =>
              navigate("/board", {
                state: { type: event.target.outerText.replace(/ /g, "") },
              })
            }
          >
            FQ
          </BoardCategory>
        </BoardContainer>
   
      </BoardContentDiv>
      <BoardContentDiv column margin="85px">
        {boardContent}
      </BoardContentDiv>
      <BoardContentDiv margin="120px" column>
        <BorderLine />
        <BorderDiv>
          <PickUpOptionText>이전글</PickUpOptionText>
          <PickUpOptionText
            onClick={() => navigate(`/boardDetail/${prevBoard?.id}`)}
            style={{
              width: 818,
              fontWeight: 400,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {prevBoard?.title}
          </PickUpOptionText>
          {prevBoard?.length !== 0 ? (
            <BoardDateText>
              {moment(prevBoard?.createdAt).format("YYYY.MM.DD")}
            </BoardDateText>
          ) : null}
        </BorderDiv>
      </BoardContentDiv>
      <BoardContentDiv style={{ marginBottom: 70 }}>
        <BorderDiv>
          <PickUpOptionText>다음글</PickUpOptionText>
          <PickUpOptionText
            onClick={() => navigate(`/boardDetail/${nextBoard?.id}`)}
            style={{
              width: 818,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            {nextBoard?.title}
          </PickUpOptionText>
          {nextBoard?.length !== 0 ? (
            <BoardDateText>
              {moment(nextBoard?.createdAt).format("YYYY.MM.DD")}
            </BoardDateText>
          ) : null}
        </BorderDiv>
      </BoardContentDiv>
      <BoardContainer style={{ marginBottom: 200 }}>
        <CarStorageButton
          onClick={() =>
            navigate("/board", { state: { type: boardDetail[0]?.type } })
          }
        >
          목록
        </CarStorageButton>
      </BoardContainer>
    </BoardWrapDiv>
  );
};

export default BoardDetail;
