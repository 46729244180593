import styled from "@emotion/styled/macro";
import Check from "../../../../assets/img/findemail.png";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as DotLine } from "../../../../assets/img/PickupStepLine.svg";
import { ReactComponent as CheckStep } from "../../../../assets/img/PickupStepCheck2.svg";
import { ReactComponent as CheckDone } from "../../../../assets/icons/CheckDone.svg";
import { ReactComponent as DotLineDone } from "../../../../assets/icons/DotLineDone.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";

const Commercialization = ({ selfSellCarData }) => {
  const StepBarDot = styled(DotLine)`
    margin-top: 10px;
  `;

  const StepBarDoneDot = styled(DotLineDone)`
    margin-top: 10px;
  `;

  const DoneText = styled(SelfSellCarStepText)`
    color: #ffd0b8;
  `;

  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          {/* 상품화 시작 / 종료에 따라서 텍스트 달라짐 */}

          <SelfSellCarStepTitle>
            회원님의 차량은 상품화 단계에 있어요.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>
        {selfSellCarData?.status === "상품화" && (
          <SelfSellCarStepSubText>상품화 진행 중입니다.</SelfSellCarStepSubText>
        )}
        {selfSellCarData?.status === "상품화완료" && (
          <SelfSellCarStepSubText>
            상품화가 완료되었습니다. 결과를 확인해주세요.
          </SelfSellCarStepSubText>
        )}
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>상품화</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer
          align
          style={{ marginLeft: 13, marginRight: 13 }}
        >
          <CheckStep />
          <SelfSellCarStepText>경매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>위탁 판매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};
export default Commercialization;
