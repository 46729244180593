import { useLocation } from "react-router";
import Header from "../../components/Header";
import PickUpStepStyle from "../../components/pick-up/PickUpStepStyle";


const PickUpStep = () => {
  const position = false;
  const location = useLocation();
  const textBold = true;
  return (
    <>
      <Header position={position} textBold={textBold} borderactive={location.pathname.includes('/pickUp')} />
      <PickUpStepStyle />
    </>
  );
};

export default PickUpStep;
