import Header from "../../components/Header";
import moment from "moment";
import axios from "axios";
import Loading from "react-loading";
import { useNavigate } from "react-router";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import {
  Container,
  CarStorageWrapDiv,
  CarStorageText2,
  CarStorageText5,
  CatStorageLine3,
  CarStorageListDiv,
  CarStorageListWrapDiv,
  CarStorageListHeaderDiv,
  CarStorageListText,
  CarStorageStatusText,
  CarStorageStatusDiv,
  CatStorageStatusLine,
  CarStorageStatusInnerDiv,
  CatStorageApplyListWrapDiv,
} from "../../components/carStorage/CarStorageStyle";
import { ReactComponent as Arrow } from "../../assets/icons/CarStorageListIcon.svg";
import { useEffect, useState } from "react";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";

const CarStorageApplyList = () => {
  const navigate = useNavigate();
  const position = false;
  const carStoragetextBold = true;
  const hidden = false;
  const token = useToken();

  const [userCarStorageList, setUserCarStorageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 차량보관 신청현황
  useEffect(() => {
    setIsLoading(true);
    if (token) {
      axios({
        url: `${BASIC_URL}/carstorage`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setUserCarStorageList(
            res.data.carStorages.map((list) => {
              return (
                <CarStorageListDiv
                  key={list.id}
                  onClick={() => navigate(`/carStorageDetail/${list.id}`)}
                >
                  <CarStorageListHeaderDiv>
                    <CarStorageListText>{list.carNumber}</CarStorageListText>
                    <Arrow />
                  </CarStorageListHeaderDiv>
                  <CarStorageStatusDiv>
                    <CarStorageStatusInnerDiv>
                      <CarStorageStatusText color="#868E96">
                        진행상태
                      </CarStorageStatusText>
                      <CarStorageStatusText color="#212529">
                        {list.status}
                      </CarStorageStatusText>
                    </CarStorageStatusInnerDiv>
                    <CarStorageStatusInnerDiv>
                      <CarStorageStatusText color="#868E96">
                        신청일
                      </CarStorageStatusText>
                      <CarStorageStatusText color="#212529">
                        {moment(list.createdAt).format("YYYY.MM.DD")}
                      </CarStorageStatusText>
                    </CarStorageStatusInnerDiv>
                  </CarStorageStatusDiv>
                  <CatStorageStatusLine />
                  <CarStorageStatusDiv>
                    <CarStorageStatusInnerDiv>
                      <CarStorageStatusText color="#868E96">
                        신청인&nbsp;&nbsp;&nbsp;&nbsp;
                      </CarStorageStatusText>
                      <CarStorageStatusText color="#212529">
                        {list.name}
                      </CarStorageStatusText>
                    </CarStorageStatusInnerDiv>
                  </CarStorageStatusDiv>
                </CarStorageListDiv>
              );
            })
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/login");
    }

    return () => setUserCarStorageList();
  }, [token, navigate]);

  return (
    <CarStorageWrapDiv>
      <Header position={position} carStoragetextBold={carStoragetextBold} />
      <HeaderMenuBar hidden={hidden}>
        <HeaderMenuText
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/carStorageApply")}
        >
          차량 보관 신청
        </HeaderMenuText>
        <HeaderMenuText
          style={{ marginLeft: 28, cursor: "pointer" }}
          onClick={() => navigate("/carStorageApplyList")}
          bold={carStoragetextBold}
        >
          나의 신청 현황
        </HeaderMenuText>
      </HeaderMenuBar>
      <Container margin="41px">
        <CarStorageText5>차량 보관 나의 신청 현황</CarStorageText5>
      </Container>
      <CarStorageText2 color="true" margin="12px">
        나의 신청 현황을 확인해보세요.
      </CarStorageText2>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 1080,
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" width={150} height={150} color="#ff6a13" />
          </div>
        </div>
      ) : (
        <>
          <CatStorageLine3 />
          <CarStorageListWrapDiv>
            <CatStorageApplyListWrapDiv>
              {userCarStorageList}
            </CatStorageApplyListWrapDiv>
          </CarStorageListWrapDiv>
        </>
      )}
    </CarStorageWrapDiv>
  );
};

export default CarStorageApplyList;
