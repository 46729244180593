import styled from "@emotion/styled/macro";
import { Modal, DatePicker, Select } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import { useToken } from "../../hooks/useToken";
import "../Common.css";
import { BASIC_URL } from "../CommonStyle";

const ModalText = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.6px;
  margin-top: 60px;
  margin-bottom: 22px;
`;

const ModalDiv = styled.div`
  width: 558px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ModalInput = styled.input`
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  padding-left: 18px;
  color: #212529;
  ::placeholder {
    color: #dee2e6;
  }
`;

const ModalButton = styled.button`
  width: 240px;
  height: 70px;
  border: none;
  border-radius: 10px;
  outline: none;
  margin: 70px auto;
  background-color: #ff6a13;
  font-size: 22px;
  letter-spacing: -0.88px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    background-color: #dee2e6;
    cursor: not-allowed;
  }
`;

const SetDate = styled(DatePicker)`
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  padding-left: 18px;

  color: #212529;
  ::placeholder {
    color: #dee2e6;
  }
  &:focus,
  &:hover {
    border-color: #dee2e6 !important;
  }
`;

// 임시 출고 모달
export const TemporaryShipmentModal = ({
  openTemporaryModal,
  setOpenTemporaryModal,
  listId,
}) => {
  const { Option } = Select;
  const [spare, setSpare] = useState("");
  const [temporaryDate, setTemporaryDate] = useState("");
  const [time, setTime] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  const dateHandler = (datesStrings) => {
    setTemporaryDate(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  const token = useToken();

  useEffect(() => {
    if (spare && time && temporaryDate) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [spare, time, temporaryDate]);

  const temporaryShipmentHandler = () => {
    const carNumberCheck = /\d{2,3}[가-힣]{1}\d{4}/gm.test(spare);
    if (carNumberCheck) {
      axios({
        url: `${BASIC_URL}/carstorage/${listId}/moment-out`,
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          day: temporaryDate,
          time,
          spare,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert("임시 출고 신청 완료");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("차량 번호를 형식에 맞게 입력해주세요.");
    }
  };
  return (
    <Modal
      title="임시 출고"
      footer={null}
      width={700}
      visible={openTemporaryModal}
      onCancel={() => setOpenTemporaryModal(false)}
    >
      <ModalDiv>
        <ModalText>요청일</ModalText>
        <SetDate
          disabledDate={disabledDate}
          locale={locale}
          showToday={false}
          allowClear={false}
          onChange={dateHandler}
        />
        <ModalText>요청 시간</ModalText>
        <Select
          placeholder="시간 선택"
          autoComplete="off"
          onChange={(value) => setTime(value)}
          style={{ paddingLeft: 18 }}
        >
          <Option value="9:00">9시</Option>
          <Option value="9:30">9시 30분</Option>
          <Option value="10:00">10시</Option>
          <Option value="10:30">10시 30분</Option>
          <Option value="11:00">11시</Option>
          <Option value="11:30">11시 30분</Option>
          <Option value="12:00">12시</Option>
          <Option value="12:30">12시 30분</Option>
          <Option value="13:00">1시</Option>
          <Option value="13:30">13시 30분</Option>
          <Option value="14:00">14시</Option>
          <Option value="14:30">14시 30분</Option>
          <Option value="15:00">15시</Option>
          <Option value="15:30">15시 30분</Option>
          <Option value="16:00">16시</Option>
          <Option value="16:30">16시 30분</Option>
          <Option value="17:00">17시</Option>
          <Option value="17:30">17시 30분</Option>
          <Option value="18:00">18시</Option>
        </Select>
        <ModalText>대체 차량 번호</ModalText>
        <ModalInput
          placeholder="대체 차량 번호 입력"
          autoComplete="off"
          value={spare}
          onChange={(event) => setSpare(event.target.value)}
        />

        <ModalButton
          onClick={temporaryShipmentHandler}
          disabled={buttonDisabled}
        >
          신청
        </ModalButton>
      </ModalDiv>
    </Modal>
  );
};
