import styled from "@emotion/styled/macro";
import axios from "axios";
import { css } from "@emotion/react";
import { Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as CheckBoxImg } from "../../assets/icons/CheckBoxImg.svg";
import { ReactComponent as DropshippingStep1 } from "../../assets/img/DropshippingStep1.svg";
import { ReactComponent as DropshippingStep2 } from "../../assets/img/DropShippingStep2.svg";
import { ReactComponent as DropshippingStep3 } from "../../assets/img/DropShippingStep3.svg";
import { ReactComponent as DropshippingStep4 } from "../../assets/img/DropShippingStep4.svg";
import { JoinCheckbox } from "../login&join/Joinstyle";
import { BASIC_URL } from "../CommonStyle";
import { useToken } from "../../hooks/useToken";
import "./SelfSellCar.css";

const CostModalWrapDiv = styled.div`
  width: 558px;
  padding-top: 10px;
  margin: 0 auto;
`;

const SelfSellCarModalContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 51px;
  justify-content: center;
  flex-direction: row;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 150px;
    `};
`;

const SelfSellCarModalColumnContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 51px;
  align-items: center;
  flex-direction: column;
`;

const SelfSellCarModalRowContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const SelfSellCarTextContainer = styled.div`
  margin-top: 61px;
  width: 558px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 25px;
    `};
`;

const SelfSellCarModalText = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.6px;
  color: #212529;

  ${(props) =>
    props.fontColor &&
    css`
      color: #ff6a13;
    `};
`;

const SelfSellCarModalText2 = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.54px;
  color: #212529;
`;

const SelfSellCarModalText3 = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.48px;
  color: #868e96;
`;

const SelfSellCarModalText4 = styled.p`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
  letter-spacing: -0.72px;
  ${(props) =>
    props.weight &&
    css`
      font-weight: 500;
    `};
`;

const SelfSellCarAdLabel = styled.label`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
  cursor: pointer;
  letter-spacing: -0.72px;
  ${(props) =>
    props.weight &&
    css`
      font-weight: 500;
    `};
`;

const SelfSellCarModalLine = styled.div`
  width: 558px;
  height: 1px;
  margin-top: 10px;
  background-color: #ededed;
`;

const SelfSellCarModalInput = styled.input`
  width: 100%;
  height: 60px;
  outline: none;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
  padding-left: 18px;
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;

  ::placeholder {
    color: #dee2e6;
  }
`;

const SelfSellCarModalButton = styled.button`
  width: 240px;
  height: 70px;
  background-color: #ff6a13;
  border-radius: 10px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  letter-spacing: -0.88px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  ${(props) =>
    props.background &&
    css`
      background-color: #212529;
    `};
`;

export const DropShippingModalContainer = styled.div`
  width: 558px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 80px;
    `};
`;

export const DropShippingModalText = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.6px;
  color: #212529;
`;

export const DropShippingModalInput = styled.input`
  width: 200px;
  border: none;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  outline: none;
  color: #212529;
  letter-spacing: -0.72px;
  border-bottom: 1px solid #dee2e6;

  ::placeholder {
    color: #dee2e6;
    text-align: right;
  }

  &:focus {
    border-color: #212529;
  }
`;

export const RequestCostModal = ({
  setCostModalOpen,
  CostModalOpen,
  willPrice,
  id,
}) => {
  const { Option } = Select;

  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const token = useToken();

  // 비용 정산 은행리스트
  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/bank/list`,
      method: "GET",
    })
      .then((result) => {
        if (result.data.bankList) {
          setBankList(
            result.data.bankList.map((list) => {
              return (
                <Option value={list.name} key={list.name}>
                  {" "}
                  {list.name}
                </Option>
              );
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 모든 항목 입력시 버튼 활성화
  useEffect(() => {
    const accountCheck = /^[0-9]+$/.test(account);

    if (bank && account && accountHolder) {
      if (accountCheck) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    } else {
      setDisabledButton(true);
    }
  }, [bank, account, accountHolder]);

  const applyCost = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/money`,
      method: "post",
      data: {
        bank,
        account,
        price: willPrice,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("정산 신청 완료");
          window.location.reload();
        } else {
          alert("이미 신청 하셨습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      title="비용 정산"
      visible={CostModalOpen}
      onCancel={() => setCostModalOpen(false)}
      width={700}
      footer={null}
      bodyStyle={{ height: 860 }}
    >
      <CostModalWrapDiv>
        <SelfSellCarModalContainer column style={{ flexDirection: "column" }}>
          <SelfSellCarModalText>은행 선택 </SelfSellCarModalText>
          <Select
            placeholder="은행 선택"
            className="bank_select"
            onChange={(value) => setBank(value)}
          >
            {bankList}
          </Select>
        </SelfSellCarModalContainer>
        <SelfSellCarModalContainer style={{ flexDirection: "column" }}>
          <SelfSellCarModalText>계좌번호 입력</SelfSellCarModalText>
          <SelfSellCarModalInput
            placeholder="(-) 없이 숫자만 입력"
            value={account}
            onChange={(event) => setAccount(event.target.value)}
          />
        </SelfSellCarModalContainer>
        <SelfSellCarModalContainer style={{ flexDirection: "column" }}>
          <SelfSellCarModalText>예금주 </SelfSellCarModalText>
          <SelfSellCarModalInput
            placeholder="예금주 입력"
            value={accountHolder}
            onChange={(event) => setAccountHolder(event.target.value)}
          />
        </SelfSellCarModalContainer>
        <SelfSellCarModalContainer margin>
          <SelfSellCarModalButton disabled={disabledButton} onClick={applyCost}>
            신청
          </SelfSellCarModalButton>
        </SelfSellCarModalContainer>
      </CostModalWrapDiv>
    </Modal>
  );
};

export const DropshippingRequestModal = ({
  openDropShippingRequestModal,
  setOpenDropShippingRequestModal,
  totalPrice,
  id,
}) => {
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const token = useToken();

  // 필수 비용 가져오기
  const [essentialPrices, setEssentialPrices] = useState([]);
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/essential-price`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setEssentialPrices(res.data.essentialPrices);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);
  return (
    <Modal
      title="위탁 판매 신청"
      width={700}
      visible={openDropShippingRequestModal}
      onCancel={() => setOpenDropShippingRequestModal(false)}
      footer={null}
      centered
      bodyStyle={{ height: 760 }}
    >
      <SelfSellCarModalColumnContainer>
        <SelectAdvertisingModal
          selectModalOpen={selectModalOpen}
          setSelectModalOpen={setSelectModalOpen}
          totalPrice={totalPrice}
          id={id}
        />
        <SelfSellCarModalText2>필수 비용</SelfSellCarModalText2>
        <SelfSellCarModalRowContainer>
          <DropshippingStep1 />
        </SelfSellCarModalRowContainer>
        <SelfSellCarTextContainer>
          <SelfSellCarModalText>1. 필수 비용 </SelfSellCarModalText>
        </SelfSellCarTextContainer>
        <SelfSellCarModalLine />
        {
          essentialPrices.map(essentialPrice => (
            <SelfSellCarTextContainer margin key={essentialPrice.id}>
              <CheckBoxImg style={{ marginRight: 13 }} />{" "}
              <SelfSellCarModalText4>
                {essentialPrice.name} ({Number(essentialPrice.price).toLocaleString("en")}원)
              </SelfSellCarModalText4>
            </SelfSellCarTextContainer>
          ))
        }
        <SelfSellCarModalContainer margin>
          <SelfSellCarModalButton
            background
            onClick={() => {
              setSelectModalOpen(true);
            }}
          >
            다음
          </SelfSellCarModalButton>
        </SelfSellCarModalContainer>
      </SelfSellCarModalColumnContainer>
    </Modal>
  );
};

export const SelectAdvertisingModal = ({
  setSelectModalOpen,
  selectModalOpen,
  totalPrice,
  id,
}) => {
  const [writeModalOpen, setWriteOpenModal] = useState(false);
  const [adList, setAdList] = useState();
  const [selectAd, setSelectAd] = useState([]);
  const token = useToken();

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/advertisement/list`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setAdList(res.data.adList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  return (
    <Modal
      title="위탁 판매 신청"
      width={700}
      visible={selectModalOpen}
      onCancel={() => setSelectModalOpen(false)}
      footer={null}
      centered
      bodyStyle={{ height: 720 }}
    >
      <SelfSellCarModalColumnContainer>
        <WriteAccountInfoModal
          writeModalOpen={writeModalOpen}
          setWriteOpenModal={setWriteOpenModal}
          totalPrice={totalPrice}
          selectAd={selectAd}
          id={id}
        />
        <SelfSellCarModalText2>필수 비용을 선택해주세요.</SelfSellCarModalText2>
        <SelfSellCarModalRowContainer>
          <DropshippingStep2 />
        </SelfSellCarModalRowContainer>
        <SelfSellCarTextContainer>
          <SelfSellCarModalText>2. 광고 선택 </SelfSellCarModalText>
          <SelfSellCarModalText3 style={{ marginLeft: 13 }}>
            (최대 6개까지 선택이 가능합니다.)
          </SelfSellCarModalText3>
        </SelfSellCarTextContainer>
        <SelfSellCarModalLine />
        {adList?.map((list) => {
          return (
            <SelfSellCarTextContainer margin key={list?.id}>
              <JoinCheckbox
                onChange={() => {
                  if (selectAd.map(item => item.id).includes(list?.id)) {
                    setSelectAd(
                      selectAd.filter((item) => item.id !== list?.id)
                    );
                  } else {
                    setSelectAd([...selectAd, { ...list }]);
                  }
                }}
                unactive={!selectAd.map(item => item.id).includes(list?.id)}
                id={list?.id}
                style={{ marginRight: 13, cursor: "pointer" }}
                className="selfsellcar_checkbox"
              />
              <SelfSellCarAdLabel htmlFor={list?.id}>
                {list?.name}&nbsp;({Number(list?.price).toLocaleString("en")}원)
              </SelfSellCarAdLabel>
            </SelfSellCarTextContainer>
          );
        })}

        <SelfSellCarModalContainer margin>
          <SelfSellCarModalButton
            background
            onClick={() => setSelectModalOpen(false)}
          >
            이전
          </SelfSellCarModalButton>
          <SelfSellCarModalButton
            style={{ marginLeft: 20 }}
            onClick={() => setWriteOpenModal(true)}
          >
            다음
          </SelfSellCarModalButton>
        </SelfSellCarModalContainer>
      </SelfSellCarModalColumnContainer>
    </Modal>
  );
};

export const WriteAccountInfoModal = ({
  writeModalOpen,
  totalPrice,
  selectAd,
  setWriteOpenModal,
  id,
}) => {
  const { Option } = Select;
  const [bank, setBank] = useState("");
  const token = useToken();
  const [account, setAccount] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [advancePayment, setAdvancePayment] = useState(0);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [nowSellPrice, setNowSellPrice] = useState(0);

  // 선지급 금액(즉시판매가의 90%)
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/advancepayment`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setAdvancePayment(res.data.advancePayment);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, id]);

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/auction/beforedata`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.nowSellPrice) {
          setNowSellPrice(res.data.nowSellPrice);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, id]);

  // 은행 리스트
  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/bank/list`,
      method: "get",
    }).then((result) => {
      if (result.data.bankList) {
        setBankList(
          result.data.bankList.map((list) => {
            return (
              <Option key={list.name} value={list.name}>
                {list.name}
              </Option>
            );
          })
        );
      }
    });
  }, []);

  return (
    <Modal
      title="위탁 판매 신청"
      width={700}
      visible={writeModalOpen}
      onCancel={() => setWriteOpenModal(false)}
      footer={null}
      centered
      bodyStyle={{ height: 860, overflowY: "scroll" }}
    >
      <SelfSellCarModalColumnContainer>
        <WritePayInfoModal
          payModalOpen={payModalOpen}
          setPayModalOpen={setPayModalOpen}
          bank={bank}
          selectAd={selectAd}
          account={account}
          totalPrice={totalPrice}
          accountHolder={accountHolder}
          advancePayment={advancePayment}
          nowSellPrice={nowSellPrice}
          id={id}
        />
        <SelfSellCarModalText2>계좌 정보를 입력해주세요.</SelfSellCarModalText2>
        <SelfSellCarModalRowContainer>
          <DropshippingStep3 />
        </SelfSellCarModalRowContainer>
        <SelfSellCarTextContainer style={{ justifyContent: "space-between" }}>
          <SelfSellCarModalText>3. 선지급 금액 </SelfSellCarModalText>
          <SelfSellCarModalText fontColor>
            {Number(advancePayment).toLocaleString("en")}원
          </SelfSellCarModalText>
        </SelfSellCarTextContainer>
        <SelfSellCarModalLine />
        <CostModalWrapDiv style={{ paddingTop: 0 }}>
          <SelfSellCarModalContainer
            style={{ flexDirection: "column", marginTop: 25 }}
          >
            <SelfSellCarModalText>은행 선택 </SelfSellCarModalText>
            <Select
              placeholder="은행 선택"
              className="bank_select"
              onChange={(value) => setBank(value)}
            >
              {bankList}
            </Select>
          </SelfSellCarModalContainer>
          <SelfSellCarModalContainer style={{ flexDirection: "column" }}>
            <SelfSellCarModalText>계좌번호 입력</SelfSellCarModalText>
            <SelfSellCarModalInput
              placeholder="(-) 없이 숫자만 입력"
              value={account}
              onChange={(event) => setAccount(event.target.value)}
            />
          </SelfSellCarModalContainer>
          <SelfSellCarModalContainer style={{ flexDirection: "column" }}>
            <SelfSellCarModalText>예금주 </SelfSellCarModalText>
            <SelfSellCarModalInput
              placeholder="예금주 입력"
              value={accountHolder}
              onChange={(event) => setAccountHolder(event.target.value)}
            />
          </SelfSellCarModalContainer>
          <SelfSellCarModalContainer style={{ flexDirection: "column" }}>
            <SelfSellCarModalText3>
              •최초 즉시 매입가의 90%까지 가능하며, 상황에 따라 변동될 수
              있습니다.
            </SelfSellCarModalText3>
            <SelfSellCarModalText3>
              •120일까지 차량이 판매되지 않을 경우, 당사의 경매를 통해 판매 처리
              됩니다.
            </SelfSellCarModalText3>
            <SelfSellCarModalText3>
              •선지급금은 신청일로부터 3 영업일 이내에 입금됩니다.
            </SelfSellCarModalText3>
          </SelfSellCarModalContainer>
        </CostModalWrapDiv>

        <SelfSellCarModalContainer margin style={{ marginBottom: 80 }}>
          <SelfSellCarModalButton
            background
            onClick={() => setWriteOpenModal(false)}
          >
            이전
          </SelfSellCarModalButton>
          <SelfSellCarModalButton
            style={{ marginLeft: 20 }}
            onClick={() => setPayModalOpen(true)}
          >
            다음
          </SelfSellCarModalButton>
        </SelfSellCarModalContainer>
      </SelfSellCarModalColumnContainer>
    </Modal>
  );
};

export const WritePayInfoModal = ({
  setPayModalOpen,
  payModalOpen,
  accountHolder,
  bank,
  totalPrice,
  selectAd,
  account,
  id,
  advancePayment,
}) => {
  const [hopePrice, setHopePrice] = useState("");
  const token = useToken();
  const applyDropshippingHandler = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/consignmentsales`,
      method: "post",
      data: {
        advertisement: selectAd?.map((ad) => ad.id),
        bank,
        account,
        hopePrice,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.success) {
          alert("위탁 판매 신청 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal
      title="위탁 판매 신청"
      width={700}
      visible={payModalOpen}
      onCancel={() => setPayModalOpen(false)}
      footer={null}
      centered
      bodyStyle={{ height: 860, overflowY: "scroll" }}
    >
      <SelfSellCarModalColumnContainer>
        <SelfSellCarModalText2>
          판매희망가를 입력해주세요.
        </SelfSellCarModalText2>
        <SelfSellCarModalRowContainer>
          <DropshippingStep4 />
        </SelfSellCarModalRowContainer>
        <SelfSellCarTextContainer>
          <SelfSellCarModalText>4. 판매 희망가 입력 </SelfSellCarModalText>
        </SelfSellCarTextContainer>
        <SelfSellCarModalLine />
        <DropShippingModalContainer>
          <DropShippingModalText>판매희망가</DropShippingModalText>
          <DropShippingModalInput
            placeholder="원"
            value={hopePrice}
            onChange={(event) => setHopePrice(event.target.value)}
          />
        </DropShippingModalContainer>
        <DropShippingModalContainer margin>
          <DropShippingModalText>선지급금</DropShippingModalText>
          <SelfSellCarModalText4 weight>
            {Number(advancePayment).toLocaleString("en")}원
          </SelfSellCarModalText4>
        </DropShippingModalContainer>
        <DropShippingModalContainer margin>
          <DropShippingModalText>차감액</DropShippingModalText>
          {/* 상품화 비용 / 차감액 */}
          <SelfSellCarModalText4 weight>
            {Number(
              parseInt(totalPrice) -
                selectAd?.reduce((accumulator, currentObject) => {
                  return accumulator + currentObject?.price;
                }, 0)
            ).toLocaleString("en")}
            원
          </SelfSellCarModalText4>
        </DropShippingModalContainer>
        <DropShippingModalContainer margin>
          <DropShippingModalText>추가정산금</DropShippingModalText>
          <SelfSellCarModalText4 weight>
            {hopePrice &&
            parseInt(hopePrice) -
              (advancePayment +
                parseInt(
                  totalPrice +
                    selectAd?.reduce((accumulator, currentObject) => {
                      return accumulator + currentObject?.price;
                    }, 0)
                )) >
              0
              ? `${Number(
                  parseInt(hopePrice) -
                    (advancePayment +
                      parseInt(
                        totalPrice +
                          selectAd?.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject?.price;
                          }, 0)
                      ))
                ).toLocaleString("en")}원`
              : "0원"}
          </SelfSellCarModalText4>
        </DropShippingModalContainer>
        <SelfSellCarModalContainer style={{ marginTop: 100 }}>
          <SelfSellCarModalButton
            background
            onClick={() => setPayModalOpen(false)}
          >
            이전
          </SelfSellCarModalButton>
          <SelfSellCarModalButton
            style={{ marginLeft: 20 }}
            onClick={applyDropshippingHandler}
          >
            위탁판매 신청
          </SelfSellCarModalButton>
        </SelfSellCarModalContainer>
      </SelfSellCarModalColumnContainer>
    </Modal>
  );
};
