import axios from "axios";
import styled from "@emotion/styled";
import moment from "moment";
import Calender from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "moment/locale/ko";
import { Pagination, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useToken } from "../../hooks/useToken";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";
import { ReactComponent as Arrow } from "../../assets/icons/CarStorageListIcon.svg";
import {ReactComponent as Arrow2} from "../../assets/icons/displayarrow.svg";
import {ReactComponent as Hammer} from "../../assets/icons/hammer.svg";
import { ReactComponent as AuctionIcon } from "../../assets/icons/liveauctionicon.svg";
import "../../components/liveAuction/LiveAuction.css";

import {
  LiveAuctionWrapDiv,
  LiveAuctionContainer,
  LiveAuctionBox,
  LiveAuctionCarModelContainer,
  LiveAuctionBoxContainer,
  LiveAuctionBoxLine,
  LiveAuctionCategoryContainer,
  LiveAuctionBox2,
  LiveAuctionCarImageBox,
  AuctionDayCircle,
  LiveAuctionCarInfoBox,
  LiveAuctionBox2Line,
  LiveAuctionBox2Line2,
  LiveAuctionButton,
  LiveAuctionCateogryText,
  LiveAuctionCarTitle,
  LiveAutcionCarInfoContainer,
  CarInfoBadge,
  LiveAuctionCarInfoText,
  LiveAuctionPriceText,
  LiveAuctionText,
  Container,
} from "../../components/liveAuction/LiveAuctionStyle";
import { AutoOutletFilterButton, AutoOutletText } from "../../components/autoOutlet/AutoOutletStyle";
import "../../components/selfSellCar/SelfSellCar.css";
import "../../components/liveAuction/LiveAuction.css";
import { useLocation, useNavigate } from "react-router";
import { useUserType } from "../../hooks/useUserInfo";
import { BASIC_URL } from "../../components/CommonStyle";
import DisabledLiveAuction from "./DisabledLiveAuction";

const FilterText = styled.p`
margin-top : 30px;
color : #868E96;
font-family : SpoqaHanSansNeo;
font-weight : 400;
font-size : 20px;
letter-spacing : -0.8px;
`

const LiveAuctionMain = () => {
  const position = false;
  const liveAuctionText = true;
  const hidden = false;
  const navigate = useNavigate();
  const { Option } = Select;
  const token = useToken();
  const {pathname } = useLocation();
  const userType = useUserType();
  // 차량 필터
  const [carClass , setCarClass] = useState([]);

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigate("/login");
    }
  }, [token, navigate]);

  // 라이브 옥션 리스트 가져오기
  const [value, onChange] = useState(new Date());
  const [liveAuctionData, setLiveAuctionData] = useState(null);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [company, setCompany] = useState();
  const [entryNumber, setEntryNumber] = useState('');
  const { search } = useLocation();

  useEffect(() => {
    if (token) {
      axios({
        url: `${BASIC_URL}/liveauction/list?day=${moment(value).format(
          "YYYY-MM-DD"
        )}`,
        method: "post",
      })
        .then((res) => {
          if (res.data) {
            setLiveAuctionData(res.data);
            navigate(`/liveAuctionMain?date=${moment(value).format(
              "YYYY-MM-DD"
            )}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [value, token]);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.get('date')) {
      onChange(moment(params.get('date')).toDate());
    }
  }, []);

  // 경매장 입장 여부
  const [entryBid, setEntryBid] = useState(false);

  useEffect(() => {
    let interval = null;

    if (liveAuctionData) {
      interval = setInterval(() => {
        axios({
          url: `${BASIC_URL}/liveauction/current-auction?day=${moment(value).format(
            "YYYY-MM-DD"
          )}${entryNumber ? `&entryNumber=${entryNumber}` : ``}`,
          method: "GET",
        })
          .then((res) => {
            setEntryBid(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [liveAuctionData, value, entryNumber]);

  const [entryButton, setEntryButton] = useState(true);

  useEffect(() => {
    if (liveAuctionData) {
      if (entryBid?.open) {
        setEntryButton(false);
      } else {
        setEntryButton(true);
      }
    }
  }, [liveAuctionData, entryBid]);

  // 옥션 있는 날 가져오기
  const [auctionDay, setAuctionDay] = useState([]);
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/liveauction/calendar?month=${moment(
        activeStartDate
      ).format("YYYY-MM")}`,
      method: "get",
    })
      .then((res) => {
        if (res.data) {
          setAuctionDay(
            res.data.liveAuctions?.map((date) =>
              moment(date.startsAt).subtract(9, "hours").format("YYYY-MM-DD")
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeStartDate]);

  // 차량 타입
  const [carType, setCarType] = useState([]);
  const [manufacturingCompany, setManufacturingCompany] = useState("");

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/liveauction/type`,
      method: "get",
    })
      .then((res) => {
        setCarType(res.data?.carTypeList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [carTypeArray, setCarTypeArray] = useState([]);
  const [carTypeDisplay , setCarTypeDisplay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const carTypeList = carType?.map((type, index) => {
    
    return (
      <LiveAuctionCategoryContainer key={index} active={carTypeDisplay}>
        <Container>
          <JoinCheckbox className="selfsellcar_checkbox" id={type?.type} 
          unactive={!carTypeArray?.find((item) => item === type.type)}
          onChange={ (event) => {
            if(event.target.checked) {
              setCarTypeArray([...carTypeArray , event.target.id]);
            } else {
              const findCarType = carTypeArray?.find(
                (carTypes) => carTypes === event.target.id
              );
              setCarTypeArray(
                carTypeArray?.filter(
                  (carTypes) => carTypes !== findCarType
                )
              );
            }
          }}/>
          <AutoOutletText cursorOn htmlFor={type.type}>
            {type?.type}
          </AutoOutletText>
        </Container>
        <LiveAuctionCateogryText>{type.count}</LiveAuctionCateogryText>
      </LiveAuctionCategoryContainer>
    );
  });

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/manufacturer`,
      method: "GET",
    })
      .then((res) => {
        setCompany(res.data.manufacturers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 차량 제조사 옵션
  const carOption = company?.map((option, index) => {
    return (
      <Option key={index} value={option?.carType_manufacturer}>
        {option?.carType_manufacturer}
      </Option>
    );
  });

  const [carModelList, setCarModelList] = useState([]);

  useEffect(() => {
    if (manufacturingCompany) {
      axios({
        url: `${BASIC_URL}/liveauction/model?manufacturer=${manufacturingCompany}`,
        method: "GET",
      })
        .then((res) => {
          setCarModelList(res.data.carModelList);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  }, [manufacturingCompany]);

  const carModelListData = carModelList?.map((list, index) => {
    
    return (
      (
        <LiveAuctionCategoryContainer key={index}>
          <Container>
            <JoinCheckbox
              className="selfsellcar_checkbox"
              unactive={!carClass?.find((item) => item === list.model)}
              id={list?.model}
              onChange={(event) => {
                if(event.target.checked) {
                  setCarClass([...carClass , event.target.id])
                } else {
                  const findCarClass = carClass?.find(
                    (carTypes) => carTypes === event.target.id
                  );
                  setCarClass(
                    carClass?.filter(
                      (carTypes) => carTypes !== findCarClass
                    )
                  );
                }
              }}
            />
            <AutoOutletText cursorOn htmlFor={list?.model}>
              {list?.model}
            </AutoOutletText>
          </Container>
          <LiveAuctionCateogryText>{list?.count}</LiveAuctionCateogryText>
        </LiveAuctionCategoryContainer>
      ) ?? null
    );
  });

  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);

  useEffect(() => {
    if(carTypeArray.length > 0 || carClass.length > 0 ) {
      setFilterButtonDisabled(false);
    } else {
      setFilterButtonDisabled(true);
    }
  },[carClass , carTypeArray])

  // 필터적용
  const applyFilter = () => {
    axios({
      url: `${BASIC_URL}/liveauction/list?day=${moment(value).format(
        "YYYY-MM-DD"
      )}`,
      method : 'post',
      data : {
        class : carClass,
        type : carTypeArray,
      }
    }).then(res => {
      setLiveAuctionData(res.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const LiveAuctionMain = ({ cars, frontValue }) => {
    return cars.map((auction, index) => {
      return (
        <>
          <Container
            margin
            onClick={() => navigate(`/liveAcutionDetail/${auction?.id}`)}
            key={auction?.id}
          >
            <LiveAuctionCarImageBox src={auction?.image} alt="" />
            <LiveAuctionCarInfoBox>
              <LiveAutcionCarInfoContainer>
                <CarInfoBadge>#{frontValue + index + 1}</CarInfoBadge>
                {
                  auction?.location !== '' && (
                    <CarInfoBadge>{auction?.location}</CarInfoBadge>
                  )
                }
                {
                  auction?.carEvaluation !== '' && (
                    <CarInfoBadge>{auction?.carEvaluation}</CarInfoBadge>
                  )
                }
              </LiveAutcionCarInfoContainer>
              <LiveAutcionCarInfoContainer margin="25px">
                <LiveAuctionCarTitle>{auction?.name}</LiveAuctionCarTitle>
              </LiveAutcionCarInfoContainer>
              <LiveAutcionCarInfoContainer margin="10px">
                <LiveAuctionCarInfoText>
                  {auction?.year}년형&nbsp;&nbsp; |
                </LiveAuctionCarInfoText>
                <LiveAuctionCarInfoText>
                  &nbsp;&nbsp;
                  {Number(auction?.distanceDriven).toLocaleString("en")}
                  km&nbsp;&nbsp; |
                </LiveAuctionCarInfoText>
                <LiveAuctionCarInfoText>
                  &nbsp;&nbsp;{auction?.fuel}
                </LiveAuctionCarInfoText>
              </LiveAutcionCarInfoContainer>
              <LiveAutcionCarInfoContainer margin="21px">
                <LiveAuctionPriceText>
                  시작가{" "}
                  {Number(auction?.nowSellPrice / 10000).toLocaleString("en")}만원
                </LiveAuctionPriceText>
              </LiveAutcionCarInfoContainer>
            </LiveAuctionCarInfoBox>
          </Container>
          {index === liveAuctionData?.cars?.length - 1 ? null : (
            <LiveAuctionBox2Line2 />
          )}
        </>
      );
    })
  };

  return (
    <>
      <Header position={position} liveAuctionText={liveAuctionText} borderactive={pathname.includes('/liveAuction')} />
      {userType !== "" && userType !== "딜러회원" ? (
        <DisabledLiveAuction />
      ) : userType === "" ? null : (
        <>
          <HeaderMenuBar hidden={hidden}>
            <HeaderMenuText style={{ cursor: "pointer" }}>
              라이브 옥션
            </HeaderMenuText>
            <HeaderMenuText
              style={{ marginLeft: 28, cursor: "pointer" }}
              onClick={() => navigate("/liveAuctionList")}
            >
              나의 경매 현황
            </HeaderMenuText>
          </HeaderMenuBar>
          <LiveAuctionWrapDiv>
            <LiveAuctionContainer>
              <LiveAuctionBox>
                <LiveAuctionButton
                  onClick={() =>
                    navigate("/auctionpage", {
                      state: { entryBid },
                    })
                  }
                  disabled={entryButton}
                >
                 <Hammer style={{marginRight : 8 }}/> 실시간 입장
                </LiveAuctionButton>

                <Calender
                  value={value}
                  onChange={onChange}
                  showNeighboringMonth={false}
                  formatDay={(_, date) => moment(date).format("D")}
                  minDetail="month"
                  maxDetail="month"
                  navigationLabel={null}
                  onActiveStartDateChange={(activeStartDate) =>
                    setActiveStartDate(activeStartDate.activeStartDate)
                  }
                  tileContent={({ date }) => {
                    if (
                      auctionDay?.find(
                        (x) => x === moment(date).format("YYYY-MM-DD")
                      )
                    ) {
                      return (
                        <>
                          <AuctionDayCircle />
                        </>
                      );
                    }
                  }}
                />
                <AutoOutletFilterButton style={{marginTop : 63}} disabled={filterButtonDisabled} onClick={applyFilter}>적용하기</AutoOutletFilterButton>
                <LiveAuctionBoxContainer>
                  <LiveAuctionText>차종</LiveAuctionText>
                  {carTypeDisplay ? <Arrow onClick={() => setCarTypeDisplay(false)} style={{cursor : "pointer"}} /> : <Arrow2 onClick={() => setCarTypeDisplay(true)} style={{cursor : "pointer"}} />}
                </LiveAuctionBoxContainer>
                
                {carTypeDisplay ? null : <LiveAuctionBoxLine /> }

                {carTypeList}
                <LiveAuctionBoxContainer>
                  <LiveAuctionText>모델</LiveAuctionText>
                </LiveAuctionBoxContainer>
                <LiveAuctionBoxLine />
                <Select
                  placeholder="브랜드 선택"
                  className="auction_select"
                  dropdownClassName="auction_selectbox"
                  onChange={(value) => setManufacturingCompany(value)}
                  style={{ marginTop: 16 }}
                >
                  {carOption}
                </Select>
                <LiveAuctionCarModelContainer>
                  {carModelListData}
                </LiveAuctionCarModelContainer>
              </LiveAuctionBox>
              {
                liveAuctionData !== null && (
                  <LiveAuctionBox2>
                    <LiveAuctionText>
                      총 {entryNumber ? liveAuctionData.cars.filter(item => item.entryNumber === parseInt(entryNumber, 10)).length : liveAuctionData.cars.filter(item => item.entryNumber === Array.from(new Set(liveAuctionData.cars.map(item => item.entryNumber)))[0]).length}대
                    </LiveAuctionText>
                    <LiveAuctionBox2Line />
                    <Tabs size="small" style={{ fontSize: 12 }} tabBarStyle={{ fontSize: 12 }} onTabClick={(activeKey) => {
                      setEntryNumber(activeKey);
                    }}>
                      {
                        Array.from(new Set(liveAuctionData.cars.map(item => item.entryNumber))).map(entryNumber => (
                          <Tabs.TabPane key={entryNumber} tab={`${entryNumber}번 회차`}>
                            {
                              liveAuctionData.cars.filter(item => item.entryNumber === entryNumber).length === 0 ? 
                                <div style={{width : "100%" ,display : "flex" , alignItems : "center" , flexDirection : "column"}}>
                                  <AuctionIcon style={{marginTop : 227}}/> 
                                  <FilterText> 경매 차량이 없습니다.</FilterText>
                                </div> : (
                                  <>
                                    <LiveAuctionMain frontValue={10} cars={liveAuctionData.cars.filter(item => item.entryNumber === entryNumber)} />
                                    {/* <Pagination current={currentPage} onChange={setCurrentPage} pageSize={10} total={liveAuctionData.cars.filter(item => item.entryNumber === entryNumber).length} /> */}
                                  </>
                                )
                            }
                          </Tabs.TabPane>
                        ))
                      }
                    </Tabs>
                    {
                      liveAuctionData.carCount === 0 && (
                        <div style={{width : "100%" ,display : "flex" , alignItems : "center" , flexDirection : "column"}}>
                          <AuctionIcon style={{marginTop : 227}}/> 
                          <FilterText> 경매 차량이 없습니다.</FilterText>
                        </div>
                      )
                    }
                  </LiveAuctionBox2>
                )
              }
            </LiveAuctionContainer>
          </LiveAuctionWrapDiv>
        </>
      )}
    </>
  );
};

export default LiveAuctionMain;
