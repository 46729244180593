import "antd/dist/antd.min.css";
import { Route, Routes } from "react-router-dom";
import Main from "./view/main/Main";
import Login from "./view/login&join/Login";
import Join from "./view/login&join/Join";
import JoinInfo from "./view/login&join/JoinInfo";
import PickUpMain from "./view/pick-up/PickUpMain";
import DisabledMenu from "./view/main/DisabledMenu";
import FindEmail from "./view/login&join/FindEmail";
import FindEmailCompletion from "./view/login&join/FindEmailCompletion";
import FindPassword from "./view/login&join/FindPassword";
import UpdatePassword from "./view/login&join/UpdatePassword";
import PickUpApply from "./view/pick-up/PickUpApply";
import PickUpApplySuccess from "./view/pick-up/PickUpApplySuccess";
import PickUpRequestDetail from "./view/pick-up/PickUpRequestDetail";
import PickUpStep from "./view/pick-up/PickUpStep";
import PaymentComplete from "./view/pick-up/PaymentComplete";
import MainUserReview from "./view/main/MainUserReview";
import MyPage from "./view/myPage/Mypage";
import AboutUs from "./view/aboutUs/AboutUs";
import MyPageSelfSellCar from "./view/myPage/MyPageSelfSellCar";
import MyPagePickUp from "./view/myPage/MyPagePickUp";
import MyPageVehicleStorage from "./view/myPage/MyPageVehicleStorage";
import MyPageUpdateInfo from "./view/myPage/MyPageUpdateInfo";
import MyPageDeliveryList from "./view/myPage/MyPageDeliveryList";
import UserType from "./view/myPage/UserType";
import UserQuestion from "./view/myPage/UserQuestion";
import DelearLiveAuction from "./view/myPage/DelearLiveAuction";
import CarStorageMain from "./view/carStorage/CarStorageMain";
import CarStorageApply from "./view/carStorage/CarStorageApply";
import CarStorageApplyList from "./view/carStorage/CarStorageApplyList";
import CarStorageDetail from "./view/carStorage/CarStorageDetail";
import AutoOutletMain from "./view/autoOutlet/AutoOutletMain";
import AutoOutletDetail from "./view/autoOutlet/AutoOutletDetail";
import Board from "./view/board/Board";
import BoardDetail from "./view/board/BoardDetail";
import SelfSellCarMain from "./view/selfSellCar/SelfSellCarMain";
import SelfSellCarApply from "./view/selfSellCar/SelfSellCarApply";
import SelfSellCarApplySuccess from "./view/selfSellCar/SelfSellCarApplySuccess";
import SelfSellCarList from "./view/selfSellCar/SelfSellCarList";
import SelfSellCarStep from "./view/selfSellCar/selfSellCarStep/SelfSellCarStep";
import LiveAuctionMain from "./view/liveAuction/LiveAuctionMain";
import LiveAuctionList from "./view/liveAuction/LiveAuctionList";
import LiveAuctionDetail from "./view/liveAuction/LiveAuctionDetail";
import CommercializationApply from "./view/selfSellCar/selfSellCarStep/CommercializationApply";
import AuctionPage from "./view/liveAuction/AuctionPage";
import "./index.css";
import SimpleJoinInfo from "./view/login&join/SimpleJoinInfo";
import CarStorageApplySuccess from "./view/carStorage/CarStorageApplySuccess";
import MonthlyPayOut from "./view/myPage/MonthlyPayOut";
import Footer from "./components/Footer";
import useWindowSize from "./hooks/useWindowSize";
import { ReactComponent as Logo } from './assets/img/MainLogo.svg';
import AppStore from './assets/img/app_store.png';
import PlayStore from './assets/img/play_store.png';

function App() {
  const { width } = useWindowSize();

  return (
    <div className="App">
      <Routes>
        <Route path="/auctionpage" element={<AuctionPage />} />
        <Route path="/liveAcutionDetail/:id" element={<LiveAuctionDetail />} />
        <Route path="/liveAuctionList" element={<LiveAuctionList />} />
        <Route path="/liveAuctionMain" element={<LiveAuctionMain />} />
        <Route
          path="/CommercializationApply/:id"
          element={<CommercializationApply />}
        />
        <Route path="/selfSellCarStep/:id" element={<SelfSellCarStep />} />
        <Route path="/selfSellCarList" element={<SelfSellCarList />} />
        <Route
          path="/selfSellCarApplySuccess"
          element={<SelfSellCarApplySuccess />}
        />
        <Route path="/selfSellCarApply" element={<SelfSellCarApply />} />
        <Route path="/selfSellcarMain" element={<SelfSellCarMain />} />
        <Route path="/boardDetail/:id" element={<BoardDetail />} />
        <Route path="/board" element={<Board />} />
        <Route path="/autoOutletDetail/:id" element={<AutoOutletDetail />} />
        <Route path="/autoOutlet" element={<AutoOutletMain />} />
        <Route path="/carStorageSuccess" element={<CarStorageApplySuccess />} />
        <Route path="/carStorageDetail/:id" element={<CarStorageDetail />} />
        <Route path="/carStorageApplyList" element={<CarStorageApplyList />} />
        <Route path="/carStorageApply" element={<CarStorageApply />} />
        <Route path="/carStorageMain" element={<CarStorageMain />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/myPageliveAuction" element={<DelearLiveAuction />} />
        <Route path="/userQuestion" element={<UserQuestion />} />
        <Route path="/userType" element={<UserType />} />
        <Route path="/monthlyPayout" element={<MonthlyPayOut />} />
        <Route path="/myPageDelivery" element={<MyPageDeliveryList />} />
        <Route path="/myPageInfo" element={<MyPageUpdateInfo />} />
        <Route path="/myPageVehicle" element={<MyPageVehicleStorage />} />
        <Route path="/myPagePickUp" element={<MyPagePickUp />} />
        <Route path="/myPageSelfSellCar" element={<MyPageSelfSellCar />} />
        <Route path="/myPage" element={<MyPage />} />
        <Route
          path="/paymentComplete/:payMethod"
          element={<PaymentComplete />}
        />
        <Route path="/pickUpStep/:id" element={<PickUpStep />} />
        <Route path="/pickUpDetail" element={<PickUpRequestDetail />} />
        <Route path="/pickUpApplySuccess" element={<PickUpApplySuccess />} />
        <Route path="/pickUpApply" element={<PickUpApply />} />
        <Route path="/pickUpMain" element={<PickUpMain />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="/findPassword" element={<FindPassword />} />
        <Route
          path="/findEmailComplete/:userName"
          element={<FindEmailCompletion />}
        />
        <Route path="/findEmail" element={<FindEmail />} />
        <Route path="/signUp/social" element={<SimpleJoinInfo />} />
        <Route path="/join_info" element={<JoinInfo />} />
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        <Route path="/disabledMenu" element={<DisabledMenu />} />
        <Route path="/mainReview" element={<MainUserReview />} />
        <Route path="/" element={<Main />} />
      </Routes>
      <Footer />
      {
        width < 1080 && (
          <div
					style={{
						display: 'flex',
						width: '100vw',
						height: '100vh',
						position: 'fixed',
						top: 0,
						left: 0,
						background: 'rgba(0, 0, 0, 0.5)',
						zIndex: 10000,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							width: 350,
							height: 'auto',
							background: '#ffffff',
							borderRadius: 10,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
              paddingTop: 20,
              paddingBottom: 20
						}}
					>
					  <Logo />
						<p style={{ fontSize: 14, marginTop: 16, textAlign: 'center' ,lineHeight: 1.5 }}>
              탈것 서비스는 PC에서만 이용 가능합니다.<br />
            </p>
            {/* <div style={{ marginTop: 12, display: 'flex', flexDirection: 'row', gap: 12 }}>
              <a href="#" onClick={() => { alert('준비중입니다.') }}>
                <img src={AppStore} style={{ width: 130, marginTop: 4 }} />
              </a>
              <a href="#" onClick={() => { alert('준비중입니다.') }}>
                <img src={PlayStore} style={{ width: 130 }} />
              </a>
            </div> */}
					</div>
				</div>
        )
      }
    </div>
  );
}

export default App;
