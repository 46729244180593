import styled from "@emotion/styled/macro";

const InspectionImage = styled.img`
  width: 708px;
  height: 450px;
  margin-top: 40px;
  border: 1px solid #dee2e6;
  background: #fff;
  object-fit: contain;
  border-radius: 10px;
`;

const InspecionIconContainer = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const IconText = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  margin-left: 4px;
`;

const CheckBoxContainer = styled.div`
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 33px;
`;

const ContentBox = styled.div`
  display: flex;
  width: 225px;
  align-items: center;
  flex-direction: row;
`;

const InspectionText = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: ${(props) => (props.active ? "#212529" : "#ADB5BD")};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
  margin-top: 50px;
  margin-bottom: 40px;
`;

export const CategoryText = styled.p`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #868e96;
`;

export {
  InspectionImage,
  InspecionIconContainer,
  IconText,
  InspectionText,
  CheckBoxContainer,
  ContentBox,
};
