import axios from "axios";
import { Select, Dropdown, Menu, Pagination } from "antd";
import { useNavigate  , useLocation} from "react-router";
import { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../assets/icons/FilterIcon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/CarStorageListIcon.svg";
import {ReactComponent as Arrow2} from "../../assets/icons/displayarrow.svg";
import { JoinCheckbox } from "../../components/login&join/Joinstyle";
import {
  AutoOutleWrapDiv,
  AutoOutletContentDiv,
  AutoOutletMainText,
  AutoOutletFilterDiv,
  AutoOuteltContainer,
  AutoOutletCarInfoDiv,
  AutoOutletDetailCarModelDiv,
  AutoOutletText,
  AutoOutletFilterButton,
  AutoOutletText2,
  AutoOutletLine,
  AutoOutletLine2,
  AutoOutletCarImage,
  AutoOutletCarImageDiv,
  InputContainer,
  AutoOutletBadge,
  AutoOutletBadgeText,
  AutoOutletMainInput,
} from "../../components/autoOutlet/AutoOutletStyle";
import {
  CarStorageInfoText,
  CarStorageText3,
} from "../../components/carStorage/CarStorageStyle";
import "../../components/autoOutlet/AutoOutlet.css";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";
import { useUserType } from "../../hooks/useUserInfo";
import DisabledMenu from "../main/DisabledMenu";
import Header from "../../components/Header";

const AutoOutletMain = () => {
  const { Option } = Select;

  const token = useToken();
  const position = false;
  const autoOutletText = true;

  const navigate = useNavigate();

  const [carBrand, setCarBrand] = useState();

  const [carModel, setCarModel] = useState();

  const [carType, setCarType] = useState();

  const [autoOutletData, setAutoOutletData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [filter, setFilter] = useState("등록순");

  const [carClass, setCarClass] = useState([]);
  const [gearBox, setGearBox] = useState([]);
  const [carFuel, setCarFuel] = useState([]);
  const [carCategory, setCarCategory] = useState([]);
  const [distanceStart, setDistanceStart] = useState("");
  const [distanceEnd, setDistanceEnd] = useState("");
  const [yearStart, setYearStart] = useState("");
  const [yearEnd, setYearEnd] = useState("");

  const location = useLocation();
  const userType = useUserType();

  // 필터 적용하기 버튼 활성화
  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      carClass?.length > 0 ||
      gearBox?.length > 0 ||
      carFuel?.length > 0 ||
      carCategory?.length > 0 ||
      yearStart ||
      yearEnd ||
      distanceEnd ||
      distanceStart
    ) {
      setFilterButtonDisabled(false);
    } else {
      setFilterButtonDisabled(true);
    }
  }, [
    carClass,
    gearBox,
    carFuel,
    carCategory,
    distanceEnd,
    distanceStart,
    yearEnd,
    yearStart,
  ]);

  // 페이징 처리
  const pageSize = 10;
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  // 오토아울렛 데이터 가져오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet`,
      method: "get",
    })
      .then((res) => {
        if (res.data) {
          setTotalCount(res.data.carCount);
          setAutoOutletData(res.data.cars);
          setMaxIndex(pageSize);
          setMinIndex(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => setAutoOutletData([]);
  }, []);

  const pageHandlerchange = (page) => {
    document.body.scrollTop = 0;
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  const autoOutletMainData = autoOutletData?.map((car, index) => {
    return (
      (index >= minIndex && index < maxIndex && (
        <>
          <AutoOuteltContainer
            onClick={() => navigate(`/autoOutletDetail/${car?.id}`)}
            key={car?.id}
          >
            {car?.image === null ? (
              <AutoOutletCarImageDiv />
            ) : (
              <AutoOutletCarImage src={car?.image} alt="img" />
            )}
            <AutoOuteltContainer
              column
              style={{
                marginLeft: 30,
                width: "auto",
                marginTop: 20,
                alignItems: "flex-start",
              }}
            >
              <CarStorageInfoText>{car?.name}</CarStorageInfoText>
              <AutoOuteltContainer margin="12px">
                <AutoOutletBadge>
                  <AutoOutletBadgeText>{car?.year}년식</AutoOutletBadgeText>
                </AutoOutletBadge>
                <AutoOutletBadge>
                  <AutoOutletBadgeText>
                    {Number(car?.distanceDriven).toLocaleString("en")}km
                  </AutoOutletBadgeText>
                </AutoOutletBadge>
                <AutoOutletBadge>
                  <AutoOutletBadgeText>{car?.fuel}</AutoOutletBadgeText>
                </AutoOutletBadge>
              </AutoOuteltContainer>
              <AutoOuteltContainer style={{ marginTop: 37 }}>
                <CarStorageText3 weight="true">
                  판매가 {Number(car?.nowSellPrice).toLocaleString("en")}만원
                </CarStorageText3>
              </AutoOuteltContainer>
            </AutoOuteltContainer>
          </AutoOuteltContainer>
          <AutoOutletLine style={{ marginTop: 40, marginBottom: 40 }} />
        </>
      )) ??
      []
    );
  });

  // 타입 가져오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/type`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setCarType(res.data.types);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => setCarType();
  }, []);

  // 변속기 목록 가져오기
  const [gearBoxes, setGearBoxes] = useState();
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/gearbox`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setGearBoxes(res.data.gearboxes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [gearBoxDisplay , setGearBoxDisplay] = useState(false);

  const autoOutletGearBoxData = gearBoxes?.map((gear, index) => {
    return (
      <AutoOuteltContainer justify="space-between" key={index} active={gearBoxDisplay}>
        <AutoOuteltContainer style={{ marginTop: 0 }}>
          <JoinCheckbox
            style={{ marginRight: 12 }}
            unactive={!gearBox?.find((item) => item === gear.gearbox)}
            id={gear?.gearbox}
            className="selfsellcar_checkbox"
            onChange={(event) => {
              if (event.target.checked) {
                setGearBox([...gearBox, event.target.id]);
              } else {
                const findGear = gearBox?.find(
                  (cargearbox) => cargearbox === event.target.id
                );
                setGearBox(
                  gearBox?.filter((cargearbox) => cargearbox !== findGear)
                );
              }
            }}
          />
          <AutoOutletText
            htmlFor={gear?.gearbox}
            cursorOn
            style={{ marginTop: 2 }}
          >
            {gear?.gearbox}
          </AutoOutletText>
        </AutoOuteltContainer>
        <AutoOutletText style={{ marginTop: 2 }}>{gear?.count}</AutoOutletText>
      </AutoOuteltContainer>
    );
  });

  // 연료 목록 가져오기
  const [fuels, setFuels] = useState();
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/fuel`,
      method: "GET",
    })
      .then((res) => {
        if (res.data) {
          setFuels(res.data.fuels);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [fuelDisplay , setFuelDisplay] = useState(false);

  const autoOutletFuelData = fuels?.map((fuel, index) => {
    return (
      <AutoOuteltContainer justify="space-between" key={index} active={fuelDisplay}>
        <AutoOuteltContainer style={{ marginTop: 0 }}>
          <JoinCheckbox
            style={{ marginRight: 12 }}
            unactive={!carFuel?.find((item) => item === fuel.fuel)}
            id={fuel?.fuel}
            className="selfsellcar_checkbox"
            onChange={(event) => {
              if (event.target.checked) {
                setCarFuel([...carFuel, event.target.id]);
              } else {
                const findFuels = carFuel?.find(
                  (carfuel) => carfuel === event.target.id
                );
                setCarFuel(carFuel?.filter((carfuel) => carfuel !== findFuels));
              }
            }}
          />
          <AutoOutletText htmlFor={fuel?.fuel} cursorOn>
            {fuel?.fuel}
          </AutoOutletText>
        </AutoOuteltContainer>
        <AutoOutletText>{fuel?.count}</AutoOutletText>
      </AutoOuteltContainer>
    );
  });

  const [carTypeDisplay, setCarTypeDisplay] = useState(false);

  const autoOutletCarType = carType?.map((type, index) => {
    return (
      <AutoOuteltContainer justify="space-between" key={index} active={carTypeDisplay}>
        <AutoOuteltContainer style={{ marginTop: 0 }}>
          <JoinCheckbox
            style={{ marginRight: 12 }}
            unactive={!carCategory?.find((item) => item === type.type)}
            id={type?.type}
            className="selfsellcar_checkbox"
            onChange={(event) => {
              if (event.target.checked) {
                setCarCategory([...carCategory, event.target.id]);
              } else {
                const findCareogry = carCategory?.find(
                  (category) => category === event.target.id
                );
                setCarCategory(
                  carCategory?.filter((category) => category !== findCareogry)
                );
              }
            }}
          />
          <AutoOutletText htmlFor={type?.type} cursorOn>
            {type?.type}
          </AutoOutletText>
        </AutoOuteltContainer>
        <AutoOutletText>{type?.count}</AutoOutletText>
      </AutoOuteltContainer>
    );
  });

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/autooutlet/manufacturer`,
      method: "GET",
    })
      .then((res) => {
        setCarBrand(res.data.manufacturers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const carBrandOption = carBrand?.map((brand, index) => {
    return (
      <Option key={index} value={brand.carType_manufacturer}>
        {brand.carType_manufacturer}
      </Option>
    );
  });

  const [detailCarModel, setDetailCarModel] = useState([]);
  useEffect(() => {
    if (carModel) {
      axios({
        url: `${BASIC_URL}/autooutlet/count/car?manufacturer=${carModel}`,
        method: "GET",
      })
        .then((res) => {
          setDetailCarModel(res.data.cars);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  }, [carModel]);

  const detailCarBrandData =
    detailCarModel?.length === 0
      ? null
      : detailCarModel?.map((brand, index) => {
          return (
            <AutoOuteltContainer justify="space-between" key={index}>
              <AutoOuteltContainer style={{ marginTop: 0 }}>
                <JoinCheckbox
                  style={{ marginRight: 12 }}
                  unactive={!carClass?.find((item) => item === brand.class)}
                  id={brand?.class}
                  className="selfsellcar_checkbox"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setCarClass([...carClass, event.target.id]);
                    } else {
                      const findCarClass = carClass?.find(
                        (carTypes) => carTypes === event.target.id
                      );
                      setCarClass(
                        carClass?.filter(
                          (carTypes) => carTypes !== findCarClass
                        )
                      );
                    }
                  }}
                />
                <AutoOutletText htmlFor={brand?.class} cursorOn>
                  {brand?.class}
                </AutoOutletText>
              </AutoOuteltContainer>
              <AutoOutletText>{brand?.count}</AutoOutletText>
            </AutoOuteltContainer>
          );
        });

  // 오토아울렛 검색하기
  const applyFilterAutoOutlet = () => {
    axios({
      url: `${BASIC_URL}/autooutlet`,
      method: "POST",
      data: {
        class: carClass,
        gearbox: gearBox,
        fuel: carFuel,
        type: carCategory,
        yearStart,
        yearEnd,
        distanceStart,
        distanceEnd,
      },
    })
      .then((res) => {
        if (res.data.cars.length > 0) {
          setAutoOutletData(res.data.cars);
        } else {
          alert("해당 조건에 맞는 차량이 없습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 메인 필터
  const AutoOutletFilterMenu = (
    <Menu onClick={(key) => setFilter(key.key)}>
      <Menu.Item key="등록순" onClick={() => window.location.reload()}>
        <AutoOutletText>등록순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="가격 높은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.nowSellPrice < b.nowSellPrice) {
              return 1;
            }

            if (a.nowSellPrice > b.nowSellPrice) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>가격 높은 순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="가격 낮은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.nowSellPrice > b.nowSellPrice) {
              return 1;
            }

            if (a.nowSellPrice < b.nowSellPrice) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>가격 낮은 순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="주행거리 높은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.distanceDriven < b.distanceDriven) {
              return 1;
            }

            if (a.distanceDriven > b.distanceDriven) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>주행거리 높은 순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="주행거리 낮은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.distanceDriven > b.distanceDriven) {
              return 1;
            }

            if (a.distanceDriven < b.distanceDriven) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>주행거리 낮은 순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="연식 높은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.year < b.year) {
              return 1;
            }

            if (a.year > b.year) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>연식 높은 순</AutoOutletText>
      </Menu.Item>
      <Menu.Item
        key="연식 낮은 순"
        onClick={() => {
          autoOutletData?.sort(function (a, b) {
            if (a.year > b.year) {
              return 1;
            }

            if (a.year < b.year) {
              return -1;
            }
            return 0;
          });
        }}
      >
        <AutoOutletText>연식 낮은 순</AutoOutletText>
      </Menu.Item>
    </Menu>
  );

  return (
    <AutoOutleWrapDiv>
      {token && userType !== "" && userType === "기업회원" ? (
        <DisabledMenu />
      ) : token && userType === "" ? null : (
        <>
          <Header position={position} autoOutletText={autoOutletText} borderactive={location?.pathname?.includes("/autoOutlet")} />
          <AutoOutletContentDiv>
            <AutoOutletFilterDiv>
              <AutoOuteltContainer
                height="24px"
                cursor="pointer"
                onClick={() => window.location.reload()}
              >
                <FilterIcon style={{ marginRight: 4 }} />
                <AutoOutletText cursorOn>검색 초기화</AutoOutletText>
              </AutoOuteltContainer>
              <AutoOuteltContainer style={{ marginTop: 32, marginBottom: 60 }}>
                <AutoOutletFilterButton
                  onClick={applyFilterAutoOutlet}
                  disabled={filterButtonDisabled}
                >
                  적용하기
                </AutoOutletFilterButton>
              </AutoOuteltContainer>
              <AutoOuteltContainer
                style={{ marginTop: 30 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>차종</AutoOutletText2>
                {carTypeDisplay ? <Arrow onClick={() => setCarTypeDisplay(false)} style={{cursor : "pointer"}} /> : <Arrow2 onClick={() => setCarTypeDisplay(true)} style={{cursor : "pointer"}} />}
                
              </AutoOuteltContainer>
              {carTypeDisplay ? null : <AutoOutletLine /> }
              

              {autoOutletCarType}
              <AutoOuteltContainer
                style={{ marginTop: 80 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>모델</AutoOutletText2>
                <Arrow />
              </AutoOuteltContainer>
              <AutoOutletLine />
              <Select
                className="brand_select"
                style={{ marginTop: 12 }}
                placeholder="브랜드 선택"
                onChange={(value) => setCarModel(value)}
                dropdownClassName="brand_dropdown"
              >
                {carBrandOption}
              </Select>
              {detailCarModel?.length === 0 ? null : (
                <AutoOutletDetailCarModelDiv>
                  {detailCarBrandData}
                </AutoOutletDetailCarModelDiv>
              )}
              <AutoOuteltContainer
                style={{ marginTop: 80 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>연식</AutoOutletText2>
                <Arrow />
              </AutoOuteltContainer>
              <AutoOutletLine />
              <InputContainer>
                <AutoOutletMainInput
                  placeholder="최소"
                  value={yearStart}
                  onChange={(event) => {
                    setYearStart(event.target.value);
                  }}
                />
                <AutoOutletMainText>~</AutoOutletMainText>
                <AutoOutletMainInput
                  placeholder="최대"
                  value={yearEnd}
                  onChange={(event) => setYearEnd(event.target.value)}
                />
              </InputContainer>
              <AutoOuteltContainer
                style={{ marginTop: 80 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>주행거리</AutoOutletText2>
                <Arrow />
              </AutoOuteltContainer>
              <AutoOutletLine />
              <InputContainer>
                <AutoOutletMainInput
                  placeholder="최소"
                  value={
                    parseInt(distanceStart) > 0
                      ? Number(
                          distanceStart.replaceAll(",", "")
                        ).toLocaleString("en")
                      : distanceStart
                  }
                  onChange={(event) => {
                    setDistanceStart(event.target.value);
                  }}
                />
                <AutoOutletMainText>~</AutoOutletMainText>
                <AutoOutletMainInput
                  placeholder="최대"
                  value={
                    parseInt(distanceEnd) > 0
                      ? Number(distanceEnd.replaceAll(",", "")).toLocaleString(
                          "en"
                        )
                      : distanceEnd
                  }
                  onChange={(event) => setDistanceEnd(event.target.value)}
                />
              </InputContainer>
              <AutoOuteltContainer
                style={{ marginTop: 80 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>연료</AutoOutletText2>
                {fuelDisplay ? <Arrow onClick={() => setFuelDisplay(false)} style={{cursor : "pointer"}} /> : <Arrow2 onClick={() => setFuelDisplay(true)} style={{cursor : "pointer"}} />}
                
              </AutoOuteltContainer>
              {fuelDisplay ? null :<AutoOutletLine /> }
              
              {autoOutletFuelData}
              <AutoOuteltContainer
                style={{ marginTop: 80 }}
                justify="space-between"
                height="21px"
                align="center"
              >
                <AutoOutletText2>변속기</AutoOutletText2>
                {gearBoxDisplay ? <Arrow onClick={() => setGearBoxDisplay(false)} style={{cursor : "pointer"}} /> : <Arrow2 onClick={() => setGearBoxDisplay(true)} style={{cursor : "pointer"}} />}
              </AutoOuteltContainer>
              {gearBoxDisplay ? null :<AutoOutletLine /> }
              
              {autoOutletGearBoxData}
            </AutoOutletFilterDiv>
            {autoOutletData && (
              <AutoOutletCarInfoDiv>
                <AutoOuteltContainer justify="space-between" align="center">
                  {" "}
                  <AutoOutletText2>총 {totalCount} 대</AutoOutletText2>
                  <Dropdown
                    overlay={AutoOutletFilterMenu}
                    overlayClassName="filter_menu"
                  >
                    <AutoOutletText
                      cursorOn
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {filter}&nbsp;&nbsp;&nbsp; <Arrow />
                    </AutoOutletText>
                  </Dropdown>
                </AutoOuteltContainer>
                <AutoOutletLine2 />
                {autoOutletMainData}
                <AutoOuteltContainer style={{ justifyContent: "center" }}>
                  <Pagination
                    style={{ marginTop: 58, marginBottom: 200 }}
                    total={totalCount}
                    responsive
                    onChange={pageHandlerchange}
                    pageSize={pageSize}
                    current={current}
                  />
                </AutoOuteltContainer>
              </AutoOutletCarInfoDiv>
            )}
          </AutoOutletContentDiv>
        </>
      )}
    </AutoOutleWrapDiv>
  );
};

export default AutoOutletMain;
