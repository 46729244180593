import styled from "@emotion/styled/macro";
import { useLocation } from "react-router";
import { useState } from "react";
import { WrapDiv } from "../CommonStyle";
import { ReactComponent as ReviewIcon } from "../../assets/img/reviewicon.svg";
import { MainUserReviewModal } from "../login&join/Modals";

const UserReviewDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const UserReviewTitle = styled.h1`
  font-size: 30px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  white-space: nowrap;
  letter-spacing: -1.2px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserReviewSubTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReviewContentWrapDiv = styled.div`
  width: 1080px;
  margin: 70px auto 170px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ReviewContentDiv = styled.div`
  width: 530px;
  height: 527px;
  border-radius: 10px;
  background: transparent;
  display: flex;
  flex-direction: column;
  border: none;
  padding: 30px 20px;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: 0 3px 15px rgb(173, 181, 189, 0.4);

  &:hover {
    border: 1px solid #212529;
  }

  &:nth-of-type(2n) {
    margin-left: 20px;
  }
`;

const ReviewImg = styled.img`
  width: 490px;
  height: 368px;
  margin-top: 20px;
  border-radius: 10px;
  object-fit: cover;
`;

const ReviewContentText = styled.p`
  font-size: 16px;
  width: 490px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 22px;
  color: #212520;
  margin-top: 10px;
`;

const MainuserReviewStyle = () => {
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const { state } = useLocation();
  const [selectedReview, setSelectedReview] = useState();
  const userReviewData = state?.userReview?.map((review) => {
    return (
      <ReviewContentDiv
        onClick={() => {
          setSelectedReview(
            state?.userReview?.filter((item) => item?.id === review?.id)
          );
          setOpenReviewModal(true);
        }}
        key={review?.id}
      >
        <UserReviewDiv style={{ marginTop: 0, justifyContent: "flex-start" }}>
          <ReviewIcon color="#333" />
          <UserReviewTitle style={{ marginLeft: 4, fontSize: 18 }}>
            {review?.name}
          </UserReviewTitle>
        </UserReviewDiv>
        <ReviewContentText>{review?.content}</ReviewContentText>
        <ReviewImg src={review?.image} alt="reviewCar" />
      </ReviewContentDiv>
    );
  });

  return (
    <WrapDiv>
      <MainUserReviewModal
        openReviewModal={openReviewModal}
        selectedReview={selectedReview}
        setOpenReviewModal={setOpenReviewModal}
      />
      <UserReviewDiv>
        <ReviewIcon color="#FF6A13" />
        <UserReviewTitle style={{ marginLeft: 4 }}>탈것 어때?</UserReviewTitle>
      </UserReviewDiv>
      <UserReviewSubTitle style={{ marginTop: 12 }}>
        탈것 고객님들의 생생한 후기를 확인해보세요!
      </UserReviewSubTitle>
      <ReviewContentWrapDiv>{userReviewData}</ReviewContentWrapDiv>
    </WrapDiv>
  );
};

export default MainuserReviewStyle;
