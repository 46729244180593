// 상품화 완료 단계에서 경매 안하고 바로 위탁판매로 넘어 갔을 경우의 StepBar

import styled from "@emotion/styled/macro";
import Check from "../../../../assets/img/findemail.png";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as CheckStep } from "../../../../assets/img/PickupStepCheck2.svg";
import { ReactComponent as CheckDone } from "../../../../assets/icons/CheckDone.svg";
import { ReactComponent as SelfSellCarLine } from "../../../../assets/img/SelfSellCarLine.svg";
import { ReactComponent as SelfSellCarLine2 } from "../../../../assets/img/SelfSellCarLine2.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";

const StepBarDot = styled(SelfSellCarLine2)`
  margin-top: 10px;
`;

const StepBarDoneDot = styled(SelfSellCarLine)`
  margin-top: 10px;
`;

const DoneText = styled(SelfSellCarStepText)`
  color: #ffd0b8;
`;

// 상품화 완료 단계 에서 바로 위탁판매로 갔을경우 StepBar
export const DropShipping2 = ({ selfSellCarData }) => {
  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            회원님의 차량은 위탁 판매 단계에 있어요.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>

        <SelfSellCarStepSubText>
          판매 전략을 위해 전문가가 연락드릴 예정입니다.
        </SelfSellCarStepSubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>상품화</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />

        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>위탁 판매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};

// 경매 출품 하고 바로 위탁판매로 갔을 경우 (판매 완료시 스텝바)
export const SalesComplete2 = () => {
  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            차량 판매가 완료되었습니다.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>

        <SelfSellCarStepSubText>
          차량 판매가 완료되었습니다. 정산신청 해주세요!
          {/* 정산 전/후 로 텍스트 달라짐 */}
        </SelfSellCarStepSubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>입고 대기</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>정밀 점검</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>상품화</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />

        <SelfSellCarInputContainer align>
          <CheckDone />
          <DoneText active>위탁 판매</DoneText>
        </SelfSellCarInputContainer>
        <StepBarDoneDot />
        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};
