import { useParams } from "react-router";
import { Tabs, Table } from "antd";
import {
  CarStorageWrapDiv,
  CarStorageInfoDiv,
  CarStorageText3,
  CarStorageText2,
  CarStorageButton,
  CarStorageImage,
  CarStorageImg,
  CarStorageSubImage,
  CarStorageInfoText,
  CarStorageCarInfoDiv,
  CarStorageInfoText2,
  CarStorageImgText,
  CarStorageInfoText3,
  CarStorageBadgeText2,
  CarStorageInfoLine,
  CarStorageInfoTableDiv,
  CarStorageInfoMenuDiv,
  CarStorageBadgeText,
  CarStorageText4,
  CarStorageBox,
  CarStorageBadge,
  Container,
} from "../../components/carStorage/CarStorageStyle";
import { ReactComponent as Logo } from "../../assets/img/WhiteLogo.svg";
import myPageImg from "../../assets/img/myPageImg.png";
import { MyPageImg } from "../../components/myPage/MyPageDeliveryListStyle";
import Header from "../../components/Header";
import { ReactComponent as Arrow } from "../../assets/icons/CarStorageListIcon.svg";
import { TemporaryShipmentModal } from "../../components/carStorage/CarStorageModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";
import dayjs from "dayjs";

const CarStorageDetail = () => {
  const position = false;
  const { TabPane } = Tabs;
  const { id } = useParams();
  const carStoragetextBold = true;
  const token = useToken();
  const [carStorageData, setCarStorageData] = useState({});
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASIC_URL}/carstorage/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCarStorageData(res.data);
        setImageSrc(res.data.car?.images[0].url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  const carStorageTableColumn = [
    {
      title: () => <CarStorageText4>옵션</CarStorageText4>,
      dataIndex: "옵션",
      key: "옵션",
      align: "center",
      width : '25%',
      render: (text) => <CarStorageText4>{text}</CarStorageText4>,
    },
    {
      title: () => <CarStorageText4>장수</CarStorageText4>,
      dataIndex: "장수",
      key: "장수",
      width : '25%',
      align: "center",
      render: (text) => <CarStorageText4>{text}</CarStorageText4>,
    },
    {
      title: () => <CarStorageText4>날짜</CarStorageText4>,
      dataIndex: "날짜",
      key: "날짜",
      align: "center",
      width : '30%',
      render: (text) => <CarStorageText4>{text}</CarStorageText4>,
    },
    {
      title: () => <CarStorageText4>총 결제 금액</CarStorageText4>,
      dataIndex: "총결제금액",
      key: "총결제금액",
      width : '25%',
      align: "center",

      render: (text) => (
        <CarStorageInfoText weight="true">{text}</CarStorageInfoText>
      ),
    },
  ];

  const tableData = [
    {
      key: carStorageData?.car?.id,
      옵션: carStorageData?.car?.option,
      장수: `${carStorageData?.car?.quantity}장`,
      날짜: `${dayjs(carStorageData?.car?.startDay).format(
        "YYYY.MM.DD"
      )} ~ ${dayjs(carStorageData?.car?.endDay).format("YYYY.MM.DD")} `,
      총결제금액: `${Number(
        carStorageData?.payment?.detail?.totalPrice
      ).toLocaleString("en")}원`,
    },
  ];

  // 임시출고 모달 오픈 State
  const [openTemporaryModal, setOpenTemporaryModal] = useState(false);

  return (
    <CarStorageWrapDiv>
      <TemporaryShipmentModal
        setOpenTemporaryModal={setOpenTemporaryModal}
        openTemporaryModal={openTemporaryModal}
        listId={id}
      />
      <Header position={position} carStoragetextBold={carStoragetextBold} />
      <CarStorageInfoDiv aligns="center" margin="100px">
        <CarStorageText3 weight="true" margin="40px">차량보관 신청 현황</CarStorageText3>
        {carStorageData?.car?.status && carStorageData?.car?.status === "임시출고요청" ||
        carStorageData?.car?.status === "임시출고완료" ? (
          <CarStorageButton transparent>임시 출고 진행 중 </CarStorageButton>
        ) : (
          <CarStorageButton
            onClick={() => setOpenTemporaryModal(true)}
            disabled={!carStorageData?.car?.outable}
          >
            임시 출고
          </CarStorageButton>
        )}
      </CarStorageInfoDiv>
      <CarStorageInfoDiv margin="40px">
        {carStorageData?.car?.images.length === 0 ? (
          <CarStorageImg>
            <Logo />
          </CarStorageImg>
        ) : (
          <Container column>
            <CarStorageImage src={imageSrc} alt="car" />
            <Container justify="flex-start" width="100%" margin="10px">
              {carStorageData?.car?.images?.map((img, index) => {
                return (
                  <CarStorageSubImage
                    src={img.url}
                    alt="car"
                    key={index}
                    onClick={(e) => setImageSrc(e.target.currentSrc)}
                  />
                );
              })}
            </Container>
            <Container justify="flex-start" width="100%" margin="12px">
              <CarStorageImgText>
                사진 업데이트일&nbsp;&nbsp;
                {dayjs(carStorageData?.car?.updateDay).format(
                  "YYYY.MM.DD"
                )}{" "}
              </CarStorageImgText>
            </Container>
          </Container>
        )}
        <CarStorageCarInfoDiv column>
          <CarStorageCarInfoDiv justify="space-between">
            <CarStorageInfoText>차량 정보</CarStorageInfoText>
            <Arrow />
          </CarStorageCarInfoDiv>
          <CarStorageInfoLine />
          <CarStorageCarInfoDiv
            margin="24px"
            align="center"
            justify="flex-start"
          >
            <CarStorageInfoText2>이용 기간</CarStorageInfoText2>
            <CarStorageInfoText3>
              {dayjs(carStorageData?.car?.startDay).format("YYYY.MM.DD")} ~{" "}
              {dayjs(carStorageData?.car?.endDay).format("YYYY.MM.DD")}
            </CarStorageInfoText3>
          </CarStorageCarInfoDiv>
          <CarStorageCarInfoDiv
            margin="24px"
            align="center"
            justify="flex-start"
          >
            <CarStorageInfoText2>차량번호</CarStorageInfoText2>
            <CarStorageInfoText3>
              &nbsp;{carStorageData?.car?.carNumber}
            </CarStorageInfoText3>
          </CarStorageCarInfoDiv>
          <CarStorageCarInfoDiv
            margin="24px"
            align="center"
            justify="flex-start"
          >
            <CarStorageInfoText2>현재 상태</CarStorageInfoText2>
            <CarStorageInfoText3>
              {carStorageData?.car?.status}
            </CarStorageInfoText3>
          </CarStorageCarInfoDiv>
        </CarStorageCarInfoDiv>
      </CarStorageInfoDiv>
      <CarStorageInfoDiv margin="200px">
        <Tabs
          defaultActiveKey="결제정보"
          tabBarGutter={55}
          size="small"
          style={{ width: "100%" }}
        >
          <TabPane tab="결제정보" key="결제정보">
            <CarStorageInfoDiv column margin="70px" marginBottom="200px">
              <CarStorageInfoText weight="true">상품 내역</CarStorageInfoText>

              <Table
                style={{ marginTop: 40 }}
                columns={carStorageTableColumn}
                dataSource={tableData}
                pagination={false}
              />

              <CarStorageInfoText margin="100px" weight="true">
                결제 정보
              </CarStorageInfoText>
              <CarStorageInfoTableDiv margin="40px">
                <CarStorageInfoMenuDiv>
                  <CarStorageInfoText2>진행 상태</CarStorageInfoText2>
                </CarStorageInfoMenuDiv>
                <CarStorageText4 margin>
                  {carStorageData?.payment?.detail?.payMethod === '카드결제' ? '결제완료' : carStorageData?.car?.paymentStatus}
                </CarStorageText4>
              </CarStorageInfoTableDiv>
              <CarStorageInfoTableDiv>
                <CarStorageInfoMenuDiv>
                  <CarStorageInfoText2>결제방법</CarStorageInfoText2>
                </CarStorageInfoMenuDiv>
                <CarStorageText4 margin>
                  {carStorageData?.payment?.detail?.payMethod}
                </CarStorageText4>
              </CarStorageInfoTableDiv>
              <CarStorageInfoTableDiv>
                <CarStorageInfoMenuDiv>
                  <CarStorageInfoText2>주문 접수 일자</CarStorageInfoText2>
                </CarStorageInfoMenuDiv>
                <CarStorageText4 margin>
                  {dayjs(carStorageData?.payment?.detail.orderDay).format(
                    "YYYY.MM.DD"
                  )}
                </CarStorageText4>
              </CarStorageInfoTableDiv>
              <CarStorageInfoTableDiv>
                <CarStorageInfoMenuDiv>
                  <CarStorageInfoText2>결제 금액</CarStorageInfoText2>
                </CarStorageInfoMenuDiv>
                <CarStorageText4 margin>
                  {Number(
                    carStorageData?.payment?.detail.totalPrice
                  ).toLocaleString("en")}
                  원
                </CarStorageText4>
              </CarStorageInfoTableDiv>
              {carStorageData?.payment?.detail?.type === "계좌이체" ? (
                <>
                  <CarStorageInfoTableDiv>
                    <CarStorageInfoMenuDiv>
                      <CarStorageInfoText2>입금 예정자명</CarStorageInfoText2>
                    </CarStorageInfoMenuDiv>
                    <CarStorageText4 margin>
                      {carStorageData?.payment?.detail.payerName}
                    </CarStorageText4>
                  </CarStorageInfoTableDiv>
                  <CarStorageInfoTableDiv>
                    <CarStorageInfoMenuDiv>
                      <CarStorageInfoText2>입금계좌</CarStorageInfoText2>
                    </CarStorageInfoMenuDiv>
                    <CarStorageText4 margin>
                      {carStorageData?.payment?.detail.payAccount}
                    </CarStorageText4>
                  </CarStorageInfoTableDiv>
                  <CarStorageInfoTableDiv>
                    <CarStorageInfoMenuDiv>
                      <CarStorageInfoText2>예금주명</CarStorageInfoText2>
                    </CarStorageInfoMenuDiv>
                    <CarStorageText4 margin>
                      {carStorageData?.payment?.detail.accountName}
                    </CarStorageText4>
                  </CarStorageInfoTableDiv>
                  <CarStorageInfoTableDiv>
                    <CarStorageInfoMenuDiv>
                      <CarStorageInfoText2>입금기한</CarStorageInfoText2>
                    </CarStorageInfoMenuDiv>
                    <CarStorageText4 margin>
                      {carStorageData?.payment?.detail.limitDay}
                    </CarStorageText4>
                  </CarStorageInfoTableDiv>
                  <CarStorageInfoTableDiv>
                    <CarStorageInfoMenuDiv>
                      <CarStorageInfoText2>입금일자</CarStorageInfoText2>
                    </CarStorageInfoMenuDiv>
                    <CarStorageText4 margin>
                      {carStorageData?.payment?.detail?.payDay}
                    </CarStorageText4>
                  </CarStorageInfoTableDiv>{" "}
                </>
              ) : null}
            </CarStorageInfoDiv>
          </TabPane>
          <TabPane tab="입출고 현황" key="입출고현황">
            {carStorageData?.inoutStatus?.length > 0 ? (
              <Container column margin="70px" marginBottom="150px">
                {carStorageData?.inoutStatus?.map((item) => {
                  return (
                    <>
                      <CarStorageBox>
                        <CarStorageBadge>
                          <CarStorageBadgeText>
                            {item?.status}
                          </CarStorageBadgeText>
                        </CarStorageBadge>
                        <CarStorageBadgeText2 margin color="#212529">
                          {item.day}&nbsp;&nbsp;{item?.time}
                          &nbsp;&nbsp;{" "}
                          {item?.spare && `(대체차량${item?.spare})`}
                        </CarStorageBadgeText2>
                      </CarStorageBox>
                    </>
                  );
                })}
              </Container>
            ) : (
              <Container
                justify="center"
                margin="150px"
                column
                marginBottom="150px"
              >
                <MyPageImg src={myPageImg} alt="img" />
                <CarStorageText2 color="true">
                  입출고 현황이 없습니다.
                </CarStorageText2>
              </Container>
            )}
          </TabPane>
        </Tabs>
      </CarStorageInfoDiv>
    </CarStorageWrapDiv>
  );
};

export default CarStorageDetail;
