import axios from "axios";
import moment from "moment";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import tokenState from "../../states/TokenState";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageText,
  MyPageActiveDiv,
  MyPageContentListDiv,
} from "./MyPageMenu";
import "../Common.css";
import "../PickUp.css";
import myPageImg from "../../assets/img/myPageImg.png";
import { MyPageImg, DeliveryListModal } from "./MyPageDeliveryListStyle";
import { BASIC_URL } from "../CommonStyle";
import { MyPageLine } from "./MyPageUpdateInfoStyle";
import { useUserType } from "../../hooks/useUserInfo";
import {
  TableMenuDiv,
  TableMenu,
  TableContentDiv,
  TableContent,
} from "../selfSellCar/SelfSellCarStyle";

export const MyPageSubText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => (props.color ? "#868e96" : "#212529")};
  font-weight: 400;
  letter-spacing: -0.8px;
  ${(props) =>
    props.font &&
    css`
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ededed;
      height: 100%;
      letter-spacing: -0.8px;
    `};

  ${(props) =>
    props.font2 &&
    css`
      color: #212529;
      font-size: 18px;
      font-weight: 500;
      text-decoration: underline;
      text-underline-position: under;
      margin-top: 20px;
      cursor: pointer;
      width: 100%;
      text-align: center;
    `};
`;

const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: props.column && "column",
  width: props.width && "100%",
  alignItems: props.center && "center",
  position: "relative",
}));

const TableDiv = styled.div((props) => ({
  marginTop: props.margin && "32px",
  display: "flex",
  flexDirection: "row",
  width: "708px",
  height: "53px",
  borderBottom: "1px solid #ededed",
  borderTop: props.border && " 1px solid #ededed",
  alignItems: "center",
}));

const MyPagePickUpInfoText = styled.p`
  font-size: 16px;
  font-family: SpoqaHanSansNeo;
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  letter-spacing: -0.64px;
`;

export const PickUpText = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #868e96;
  letter-spacing: -0.64px;
`;

export const PickUpcontentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
  margin: 10px 0;
`;

export const PickUpTotalText = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.8px;

  ${(props) =>
    props.font2 &&
    css`
      color: #868e96;
    `};
`;

const MyPagePickUpStyle = () => {
  const { TabPane } = Tabs;

  const navigate = useNavigate();

  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  }, [navigate, token]);

  const [userPickups, setUserPickups] = useState();

  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/pickups`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setUserPickups(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const userPickupsInfo = userPickups?.pickups?.map((item) => {
    return (
      <TabPane tab={item.carNumber} key={item.id}>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container center>
            <MyPageText>진행 상태 </MyPageText>
            <MyPagePickUpInfoText
              style={{ marginLeft: 13 }}
              color="#868E96"
              weight={400}
            >
              자세한 정보는 신청현황에서 확인 할 수 있습니다.
            </MyPagePickUpInfoText>{" "}
          </Container>
          <MyPageText
            textdecoration
            style={{ fontWeight: 400, fontSize: 18 }}
            onClick={() => navigate("/pickUpDetail")}
          >
            신청현황 바로가기
          </MyPageText>
        </MyPageContentListDiv>

        <TableDiv margin border>
          <MyPagePickUpInfoText
            color="#868E96"
            weight={500}
            style={{ marginLeft: 30 }}
          >
            {" "}
            진행 상태
          </MyPagePickUpInfoText>

          <MyPagePickUpInfoText color="#212529 " style={{ marginLeft: 40 }}>
            {item?.status}
          </MyPagePickUpInfoText>
        </TableDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true">
            <MyPageText>상품 정보 </MyPageText>

            <TableMenuDiv>
              <TableMenu>
                <PickUpText>상품</PickUpText>
              </TableMenu>
              <TableMenu>
                <PickUpText>진행 여부</PickUpText>
              </TableMenu>
              <TableMenu>
                <PickUpText>가격</PickUpText>
              </TableMenu>
              <TableMenu>
                <PickUpText>예상 소요일</PickUpText>
              </TableMenu>
              <TableMenu>
                <PickUpText>총 결제 금액</PickUpText>
              </TableMenu>
            </TableMenuDiv>
            <TableContentDiv>
              <TableContent>
                {item?.product?.closeInspections?.map((option) => {
                  return <PickUpcontentText>{option?.name}</PickUpcontentText>;
                })}
              </TableContent>
              <TableContent>
                {item?.product?.closeInspections?.map((option) => {
                  return (
                    <PickUpcontentText>
                      {option?.productGrade}등급
                    </PickUpcontentText>
                  );
                })}
              </TableContent>
              <TableContent>
                {item?.product?.closeInspections?.map((option) => {
                  return (
                    <PickUpcontentText>
                      {Number(option?.totalPrice).toLocaleString("en")}원
                    </PickUpcontentText>
                  );
                })}
              </TableContent>
              <TableContent>
                <PickUpTotalText font2>
                  {item?.product?.totalDay}일
                </PickUpTotalText>
              </TableContent>
              <TableContent>
                <PickUpTotalText>
                  {Number(item?.product?.totalPrice).toLocaleString("en")}원
                </PickUpTotalText>
              </TableContent>
            </TableContentDiv>
          </Container>
        </MyPageContentListDiv>
        <MyPageContentListDiv style={{ marginTop: 70 }}>
          <Container column width="true">
            <Container center>
              <MyPageText>배송지 정보 </MyPageText>
              <MyPagePickUpInfoText
                style={{ marginLeft: 13 }}
                color="#868E96"
                weight={400}
              >
                배송지 정보 변경을 원할 경우, 고객센터로 문의바랍니다.
              </MyPagePickUpInfoText>{" "}
            </Container>
            <TableDiv margin border>
              <MyPagePickUpInfoText
                color="#868E96"
                weight={500}
                style={{ marginLeft: 30 }}
              >
                배송방법
              </MyPagePickUpInfoText>{" "}
              <MyPagePickUpInfoText
                color="#212529"
                weight={400}
                style={{ marginLeft: 80 }}
              >
                {item?.shipping?.type} &nbsp;
                {item?.shipping?.type === "방문수령"
                  ? "(경기도이천시 마장면 표교리 388)"
                  : null}
              </MyPagePickUpInfoText>
            </TableDiv>
            <TableDiv>
              <MyPagePickUpInfoText
                color="#868E96"
                weight={500}
                style={{ marginLeft: 30 }}
              >
                받는 사람
              </MyPagePickUpInfoText>{" "}
              <MyPagePickUpInfoText
                color="#212529"
                weight={400}
                style={{ marginLeft: 78 }}
              >
                {item?.shipping?.name}
              </MyPagePickUpInfoText>
            </TableDiv>
            <TableDiv>
              <MyPagePickUpInfoText
                color="#868E96"
                weight={500}
                style={{ marginLeft: 30 }}
              >
                휴대폰 번호
              </MyPagePickUpInfoText>{" "}
              <MyPagePickUpInfoText
                color="#212529"
                weight={400}
                style={{ marginLeft: 65 }}
              >
                {/* {item?.shipping?.phoneNumber?.replace(
                  /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                  "$1-$2-$3"
                )} */}
                {item?.shipping?.phoneNumber}
              </MyPagePickUpInfoText>
            </TableDiv>
            {item?.shipping?.type === "방문수령" ? (
              <TableDiv>
                <MyPagePickUpInfoText
                  color="#868E96"
                  weight={500}
                  style={{ marginLeft: 30 }}
                >
                  방문 희망일
                </MyPagePickUpInfoText>{" "}
                <MyPagePickUpInfoText
                  color="#212529"
                  weight={400}
                  style={{ marginLeft: 65 }}
                >
                  {" "}
                  {moment(item?.shipping?.visitDate).format(
                    "YYYY년 MM월 DD일 HH시"
                  )}
                </MyPagePickUpInfoText>
              </TableDiv>
            ) : (
              <>
                <TableDiv>
                  <MyPagePickUpInfoText
                    color="#868E96"
                    weight={500}
                    style={{ marginLeft: 30 }}
                  >
                    배송희망일
                  </MyPagePickUpInfoText>{" "}
                  <MyPagePickUpInfoText
                    color="#212529"
                    weight={400}
                    style={{ marginLeft: 65 }}
                  >
                    {" "}
                    {moment(item?.shipping?.shippingDate).format(
                      "YYYY년 MM월 DD일 HH시"
                    )}
                  </MyPagePickUpInfoText>
                </TableDiv>
                <TableDiv>
                  <MyPagePickUpInfoText
                    color="#868E96"
                    weight={500}
                    style={{ marginLeft: 30 }}
                  >
                    차량픽업주소
                  </MyPagePickUpInfoText>{" "}
                  <MyPagePickUpInfoText
                    color="#212529"
                    weight={400}
                    style={{ marginLeft: 50 }}
                  >
                    ({item?.shipping?.pickupZipcode}){" "}
                    {item?.shipping?.pickupAddress}&nbsp;&nbsp;
                    {item?.shipping?.pickupAddressDetail}
                  </MyPagePickUpInfoText>
                </TableDiv>{" "}
              </>
            )}
          </Container>
          <MyPageContentListDiv style={{ marginTop: 70 }}>
            <Container column width="true" style={{ marginBottom: 80 }}>
              <MyPageText>결제 정보</MyPageText>

              <TableDiv margin border>
                <MyPagePickUpInfoText
                  color="#868E96"
                  weight={500}
                  style={{ marginLeft: 30 }}
                >
                  결제 방법
                </MyPagePickUpInfoText>{" "}
                <MyPagePickUpInfoText
                  color="#212529"
                  weight={400}
                  style={{ marginLeft: 78 }}
                >
                  {item?.payments?.pay_method
                    ? item?.payments?.pay_method
                    : "-"}
                </MyPagePickUpInfoText>
              </TableDiv>
              <TableDiv>
                <MyPagePickUpInfoText
                  color="#868E96"
                  weight={500}
                  style={{ marginLeft: 30 }}
                >
                  주문접수 일자
                </MyPagePickUpInfoText>{" "}
                <MyPagePickUpInfoText
                  color="#212529"
                  weight={400}
                  style={{ marginLeft: 48 }}
                >
                  {moment(item?.payments?.createdAt).format(
                    "YYYY년 MM월 DD일 HH시 mm분"
                  )}
                </MyPagePickUpInfoText>
              </TableDiv>
              <TableDiv>
                <MyPagePickUpInfoText
                  color="#868E96"
                  weight={500}
                  style={{ marginLeft: 30 }}
                >
                  결제 금액
                </MyPagePickUpInfoText>{" "}
                <MyPagePickUpInfoText
                  color="#212529"
                  weight={400}
                  style={{ marginLeft: 78 }}
                >
                  {Number(item?.payments?.price).toLocaleString("en")}원
                </MyPagePickUpInfoText>
              </TableDiv>
              {item?.payments?.pay_method === "카드결제" ? (
                <TableDiv>
                  <MyPagePickUpInfoText
                    color="#868E96"
                    weight={500}
                    style={{ marginLeft: 30 }}
                  >
                    결제수단
                  </MyPagePickUpInfoText>{" "}
                  <MyPagePickUpInfoText
                    color="#212529"
                    weight={400}
                    style={{ marginLeft: 78 }}
                  >
                    {item?.payments?.card_name
                      ? item?.payments?.card_name
                      : "-"}
                  </MyPagePickUpInfoText>
                </TableDiv>
              ) : (
                <>
                  <TableDiv>
                    <MyPagePickUpInfoText
                      color="#868E96"
                      weight={500}
                      style={{ marginLeft: 30 }}
                    >
                      입금 예정자명
                    </MyPagePickUpInfoText>{" "}
                    <MyPagePickUpInfoText
                      color="#212529"
                      weight={400}
                      style={{ marginLeft: 50 }}
                    >
                      API 추가
                    </MyPagePickUpInfoText>
                  </TableDiv>
                  <TableDiv>
                    <MyPagePickUpInfoText
                      color="#868E96"
                      weight={500}
                      style={{ marginLeft: 30 }}
                    >
                      입금계좌
                    </MyPagePickUpInfoText>{" "}
                    <MyPagePickUpInfoText
                      color="#212529"
                      weight={400}
                      style={{ marginLeft: 78 }}
                    >
                      추가 해야함
                    </MyPagePickUpInfoText>
                  </TableDiv>
                  <TableDiv>
                    <MyPagePickUpInfoText
                      color="#868E96"
                      weight={500}
                      style={{ marginLeft: 30 }}
                    >
                      예금주명
                    </MyPagePickUpInfoText>{" "}
                    <MyPagePickUpInfoText
                      color="#212529"
                      weight={400}
                      style={{ marginLeft: 78 }}
                    >
                      (주)탈것
                    </MyPagePickUpInfoText>
                  </TableDiv>
                  <TableDiv>
                    <MyPagePickUpInfoText
                      color="#868E96"
                      weight={500}
                      style={{ marginLeft: 30 }}
                    >
                      입금 기한
                    </MyPagePickUpInfoText>{" "}
                    <MyPagePickUpInfoText
                      color="#212529"
                      weight={400}
                      style={{ marginLeft: 78 }}
                    >
                      {moment(item?.payments?.paymentLimitDay).format(
                        "YYYY년 MM월 DD일"
                      )}
                    </MyPagePickUpInfoText>
                  </TableDiv>
                  <TableDiv>
                    <MyPagePickUpInfoText
                      color="#868E96"
                      weight={500}
                      style={{ marginLeft: 30 }}
                    >
                      입금 일자
                    </MyPagePickUpInfoText>{" "}
                    <MyPagePickUpInfoText
                      color="#212529"
                      weight={400}
                      style={{ marginLeft: 78 }}
                    >
                      {item?.payments?.paymentDay
                        ? moment(item?.payments?.paymentDay).format(
                            "YYYY년 MM월 DD일"
                          )
                        : "-"}
                    </MyPagePickUpInfoText>
                  </TableDiv>
                </>
              )}
            </Container>
          </MyPageContentListDiv>
        </MyPageContentListDiv>
      </TabPane>
    );
  });

  const userType = useUserType();
  const pickUpText = useRef(false);

  useEffect(() => {
    pickUpText.current = true;
    return () => (pickUpText.current = false);
  }, [pickUpText]);

  return (
    <MyPageWrapDiv>
      <MyPageContentDiv>
        <MyPageMenu userType={userType} pickUpText={pickUpText} />
        <MyPageActiveDiv className="myPage_div">
          <MyPageText>나의 이용내역 &nbsp; &gt; &nbsp; 픽업 정비</MyPageText>
          <MyPageLine style={{ marginBottom: 0 }} />
          {userPickups?.pickups?.length === 0 ? (
            <>
              <DeliveryListModal>
                <MyPageImg src={myPageImg} alt="myPage" />
                <MyPageSubText color="true">
                  이용하신 픽업정비 내역이 없습니다.
                </MyPageSubText>
              </DeliveryListModal>
              <MyPageSubText font2 onClick={() => navigate("/PickUpApply")}>
                픽업정비 신청하기
              </MyPageSubText>
            </>
          ) : (
            <Tabs
              defaultActiveKey="1"
              style={{ marginTop: 40, fontSize: 18 }}
              tabBarStyle={{ fontSize: 18 }}
              size="small"
              tabBarGutter={20}
            >
              {userPickupsInfo}
            </Tabs>
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};
export default MyPagePickUpStyle;
