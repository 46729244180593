import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation } from "react-router";
import { Pagination } from "antd";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  BoardWrapDiv,
  BoardContentDiv,
  BoardContainer,
  SearchInput,
  SearchButton,
  BorderDiv,
  BoardDateText,
  BorderLine,
  ErrorDiv,
  QuestionText,
  ErrorText,
  BoardCategory,
} from "../../components/board/BoardStyle";
import { PickUpOptionText } from "../../components/pick-up/PickUpStyle";
import { MyPageImg } from "../../components/myPage/MyPageDeliveryListStyle";
import myPageImg from "../../assets/img/myPageImg.png";
import { InquireModal } from "../../components/myPage/MyPageModal";
import { BASIC_URL } from "../../components/CommonStyle";
import { useToken } from "../../hooks/useToken";
import "../../components/board/BoardPagination.css";

const Board = () => {
  const navigate = useNavigate();
  const position = false;
  const boardText = true;
  const token = useToken();
  const [searchValue, setSearchValue] = useState("");
  const [boardArticle, setBoardArticle] = useState([]);
  const [searchBoardArticle, setSearchBoardArticle] = useState([]);
  const [filterOn, setfilterOn] = useState(false);
  const [openInquireModal, setOpenInquireModal] = useState(false);

  const { state , pathname } = useLocation();
  
  const [type, setType] = useState(state?.type || "공지사항");

  // 페이징 처리
  const pageSize = 8;
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  useEffect(() => {
    setSearchValue("");
    setfilterOn(false);
    axios({
      url: `${BASIC_URL}/board?type=${type}`,
      method: "get",
    })
      .then((result) => {
        setBoardArticle(result.data.boards);
        setMaxIndex(pageSize);
        setMinIndex(0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [type]);

  const pageHandlerchange = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  const getBoard = (boardCateogry) => {
    axios({
      method: "get",
      url: `${BASIC_URL}/board?type=${boardCateogry}`,
    }).then((res) => {
      setBoardArticle(res.data.boards);
    });
  };

  // 검색어 입력
  const filterBoard = () => {
    if (searchValue === "") {
      getBoard(type);
      setfilterOn(false);
    } else {
      axios({
        url: `${BASIC_URL}/board?type=${type}&word=${searchValue}`,
        method: "GET",
      })
        .then((res) => {
          if (res.data) {
            setMaxIndex(pageSize);
            setMinIndex(0);
            setCurrent(1);
            setSearchBoardArticle(
              res.data.boards.map((item) => {
                return {
                  ...item,
                  title: item.title.replace(
                    searchValue,
                    `<span>${searchValue}</span>`
                  ),
                };
              })
            );
            setfilterOn(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const enterKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (searchValue === "") {
        getBoard(type);
        setfilterOn(false);
      } else {
        axios({
          url: `${BASIC_URL}/board?type=${type}&word=${searchValue}`,
          method: "GET",
        })
          .then((res) => {
            if (res.data) {
              setMaxIndex(pageSize);
              setMinIndex(0);
              setCurrent(1);
              setSearchBoardArticle(
                res.data.boards.map((item) => {
                  return {
                    ...item,
                    title: item.title.replace(
                      searchValue,
                      `<span>${searchValue}</span>`
                    ),
                  };
                })
              );
              setfilterOn(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const searchBoardContent = searchBoardArticle?.map((board, index) => {
    return (
      index >= minIndex &&
      index < maxIndex && (
        <BorderDiv key={board.id}>
          <PickUpOptionText
            style={{
              width: 818,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            cursor="pointer"
            onClick={() => navigate(`/boardDetail/${board.id}`)}
            dangerouslySetInnerHTML={{ __html: board.title }}
          />
          <BoardDateText>
            {moment(board.createdAt).format("YYYY.MM.DD")}
          </BoardDateText>
        </BorderDiv>
      )
    );
  });

  const boardCategoryHandler = (event) => {
    setType(event.target.outerText.replace(/ /g, ""));
  };

  return (
    <BoardWrapDiv>
      <InquireModal
        setOpenInquireModal={setOpenInquireModal}
        openInquireModal={openInquireModal}
      />
      <Header position={position} boardText={boardText} borderactive={pathname.includes('/board')} />
      <BoardContentDiv margin="100px" align="center">
        <BoardContainer>
          <BoardCategory
            onClick={boardCategoryHandler}
            active={type === "공지사항"}
          >
            공지사항
          </BoardCategory>
          <BoardCategory
            // onClick={getBoard(type)}
            onClick={boardCategoryHandler}
            active={type === "캠핑정보"}
          >
            캠핑 정보
          </BoardCategory>
          <BoardCategory
            onClick={boardCategoryHandler}
            active={type === "자동차정보"}
          >
            자동차 정보
          </BoardCategory>
          <BoardCategory onClick={boardCategoryHandler} active={type === "FQ"}>
            FQ
          </BoardCategory>
        </BoardContainer>
        <BoardContainer>
          <SearchInput
            placeholder="검색어를 입력해주세요."
            autoComplete="off"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyUp={enterKeyEvent}
          />
          <SearchButton onClick={filterBoard}>검색</SearchButton>
        </BoardContainer>
      </BoardContentDiv>

      {type === "FQ" ? (
        <BoardContentDiv margin="41px" style={{ justifyContent: "flex-end" }}>
          <BoardContainer>
            <QuestionText
              onClick={() => {
                if (token) {
                  setOpenInquireModal(true);
                } else {
                  navigate("/login");
                }
              }}
            >
              1:1 문의하기
            </QuestionText>
          </BoardContainer>
        </BoardContentDiv>
      ) : null}
      {/* 게시글 */}
      {searchBoardArticle?.length === 0 && filterOn ? (
        <ErrorDiv>
          <MyPageImg src={myPageImg} alt="img" style={{ marginBottom: 0 }} />
          <ErrorText>해당 검색어에 대한 검색 결과가 없습니다.</ErrorText>
        </ErrorDiv>
      ) : searchBoardArticle?.length > 0 && filterOn ? (
        <>
          <BoardContentDiv column margin="44px">
            <BorderLine />
            {searchBoardContent}
          </BoardContentDiv>
          <Pagination
            style={{ marginTop: 50, marginBottom: 50 }}
            total={searchBoardArticle?.length}
            responsive
            onChange={pageHandlerchange}
            pageSize={pageSize}
            current={current}
          />
        </>
      ) : (
        <>
          <BoardContentDiv column margin="44px">
            <BorderLine />
            {boardArticle?.map((board, index) => {
              return (
                index >= minIndex &&
                index < maxIndex && (
                  <BorderDiv key={board.id}>
                    <PickUpOptionText
                      style={{
                        width: 818,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() =>
                        navigate(`/boardDetail/${board.id}`, {
                          state: board.type,
                        })
                      }
                      cursor="pointer"
                      dangerouslySetInnerHTML={{ __html: board.title }}
                    />
                    <BoardDateText>
                      {moment(board.createdAt).format("YYYY.MM.DD")}
                    </BoardDateText>
                  </BorderDiv>
                )
              );
            })}
          </BoardContentDiv>
          <Pagination
            style={{ marginTop: 50, marginBottom: 50 }}
            total={boardArticle?.length}
            responsive
            onChange={pageHandlerchange}
            pageSize={pageSize}
            current={current}
          />
        </>
      )}
    </BoardWrapDiv>
  );
};

export default Board;
