import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import MyPageDelearInfo from "./MyPageDelearInfo";
import Loading from "react-loading";
import tokenState from "../../states/TokenState";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Naver } from "../../assets/icons/MyPageNaver.svg";
import { ReactComponent as Kakao } from "../../assets/icons/MyPageKakao.svg";
import { Google } from "../login&join/LoginContent";
import { ReactComponent as GoogleLogo } from "../../assets/img/google.svg";
import {
  DeleteAccountModal,
  UpdatePasswordModal,
  UpdatePhoneNumberModal,
} from "./MyPageModal";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageActiveDiv,
  MyPageContentDiv,
  MyPageText,
  MyPageEngText,
} from "./MyPageMenu";
import { useUserType } from "../../hooks/useUserInfo";

export const MyPageLine = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #868e96;
  margin-top: 20px;
  margin-bottom: 24px;

  ${(props) =>
    props.border &&
    css`
      border: 1px solid #ededed;
      margin-top: 50px;
    `};
`;

export const MyPageListLine = styled.div`
  width: 290px;
  height: 1px;
  border: 1px solid #ededed;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: props.column && "column",
  width: props.width && "100%",
  alignItems: props.center && "center",
  marginTop: props.margin && "50px",
}));

export const UpdateInfoLabel = styled.label`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  margin-top: 51px;
  margin-bottom: 18px;
  letter-spacing: -0.72px;
  color: #212529;
  font-weight: 500;

  ${(props) =>
    props.color &&
    css`
      margin-top: 50px;
      cursor: pointer;
      color: #808080;
    `}
`;

export const UpdateInfo = styled.input`
  outline: none;
  width: 708px;
  letter-spacing: -0.72px;
  padding: 20px 0 20px 16px;
  border-radius: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  height: 60px;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  font-size: 18px;
  font-weight: 400;

  ::placeholder {
    color: #212529;
    font-size: 18px;
    font-weight: 400;
    font-family: SpoqaHanSansNeo;
  }

  ${(props) =>
    props.width &&
    css`
      width: 570px;
      background-color: #ffffff;
    `}
`;

const UpdateButton = styled.button`
  border: none;
  outline: none;
  background-color: #212529;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  border-radius: 10px;
  margin-top: ${(props) => (props.marginTop ? 0 : "10px")};
  margin-left: ${(props) => (props.marginLeft ? 0 : "18px")};
  cursor: pointer;
  font-size: 18px;
  width: ${(props) => (props.width ? "120px" : "226px")};
  height: 60px;
  color: #fff;
`;

// 딜러 회원
const UserBasicInfo = styled.button`
  width: 354px;
  height: 70px;
  background-color: #ff6a13;
  font-size: 20px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #fff;
  cursor: pointer;
  border: none;

  ${(props) =>
    props.unactive &&
    css`
      border: 1px solid #ededed;
      background-color: #fff;
      font-weight: 500;
      color: #868e96;
    `};
`;

const MyPageStyle = ({ userInfo }) => {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenState);

  // 회원 유형 구분
  const [userLoginType, setUserLoginType] = useState(true);

  useEffect(() => {
    if (userInfo?.signupType !== "email") {
      setUserLoginType(false);
    } else {
      setUserLoginType(true);
    }
  }, [userLoginType, userInfo]);

  // 비밀번호 변경 모달
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  // 휴대폰 번호 변경 모달
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState(false);

  // 회원 탈퇴 확인 모달
  const [userDeleteModal, setUserDeleteModal] = useState(false);

  const [viewDelarInfo, setViewDelearInfo] = useState(false);
  const [delearInfoButtonActive, setDelearInfoButtonActive] = useState(false);
  const [BasicInfoButtonActive, setBasicInfoButtonActive] = useState(true);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  }, [navigate, token]);

  const userType = useUserType();
  const infoText = useRef(false);

  useEffect(() => {
    infoText.current = true;
    return () => (infoText.current = false);
  }, [infoText]);

  return (
    <MyPageWrapDiv>
      <UpdatePasswordModal
        openPasswordModal={openPasswordModal}
        setOpenPasswordModal={setOpenPasswordModal}
      />
      <UpdatePhoneNumberModal
        openPhoneNumberModal={openPhoneNumberModal}
        setOpenPhoneNumberModal={setOpenPhoneNumberModal}
      />
      <DeleteAccountModal
        setUserDeleteModal={setUserDeleteModal}
        userDeleteModal={userDeleteModal}
      />
      <MyPageContentDiv>
        <MyPageMenu userType={userType} infoText={infoText} />
        <MyPageActiveDiv>
          <MyPageEngText>회원정보 &nbsp;&gt;&nbsp; 회원정보 수정</MyPageEngText>
          {userType && userType === "딜러회원" ? (
            <Container margin>
              <UserBasicInfo
                unactive={delearInfoButtonActive}
                onClick={() => {
                  setViewDelearInfo(false);
                  setDelearInfoButtonActive(false);
                  setBasicInfoButtonActive(true);
                }}
              >
                기본정보
              </UserBasicInfo>

              <UserBasicInfo
                unactive={BasicInfoButtonActive}
                onClick={() => {
                  setDelearInfoButtonActive(true);
                  setBasicInfoButtonActive(false);
                  setViewDelearInfo(true);
                }}
              >
                상사정보
              </UserBasicInfo>
            </Container>
          ) : (
            <MyPageLine />
          )}
          {viewDelarInfo ? (
            <MyPageDelearInfo />
          ) : (
            <>
              <Container margin column>
                <MyPageText>로그인 정보</MyPageText>

                <UpdateInfoLabel htmlFor="email">아이디</UpdateInfoLabel>
                {userInfo.email ? (
                  <UpdateInfo
                    autoComplete="off"
                    id="email"
                    value={userInfo?.email}
                    readOnly
                  />
                ) : (
                  <Loading color="#ff6a13" type="spin" width={40} height={40} />
                )}
                {userInfo?.signupType === "email" ? (
                  <>
                    <UpdateInfoLabel>비밀번호</UpdateInfoLabel>
                    <UpdateButton
                      marginLeft="true"
                      onClick={() => setOpenPasswordModal(true)}
                    >
                      변경
                    </UpdateButton>{" "}
                  </>
                ) : (
                  <>
                    <MyPageLine border />
                    <Container column margin>
                      <MyPageText>연동 SNS</MyPageText>
                      {userInfo?.signupType === "naver" ? (
                        <Naver style={{ marginTop: 30 }} />
                      ) : userInfo?.signupType === "google" ? (
                        <Google style={{ marginTop: 30 }}>
                          <GoogleLogo />
                        </Google>
                      ) : userInfo?.signupType === "kakao" ? (
                        <Kakao style={{ marginTop: 30 }} />
                      ) : null}
                    </Container>
                  </>
                )}
                <MyPageLine border />
              </Container>

              <Container margin column>
                <MyPageText>회원 정보</MyPageText>

                <UpdateInfoLabel htmlFor="name">이름</UpdateInfoLabel>
                <UpdateInfo
                  autoComplete="off"
                  id="name"
                  placeholder={userInfo?.name}
                  readonly
                />
                <UpdateInfoLabel htmlFor="phoneNumber">
                  휴대폰 번호
                </UpdateInfoLabel>
                <Container>
                  <UpdateInfo
                    width="true"
                    id="phoneNumber"
                    placeholder={userInfo?.phoneNumber}
                    autoComplete="off"
                  />
                  <UpdateButton
                    width="true"
                    marginTop="false"
                    onClick={() => setOpenPhoneNumberModal(true)}
                  >
                    변경
                  </UpdateButton>
                </Container>
                <UpdateInfoLabel
                  color="true"
                  onClick={() => setUserDeleteModal(true)}
                >
                  회원탈퇴
                </UpdateInfoLabel>
              </Container>
            </>
          )}
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};

export default MyPageStyle;
