import Header from "../../components/Header";
import FindPasswordStyle from "../../components/login&join/FindPasswordStyle";

const FindPassword = () => {
  const position = false;
  return (
    <>
      <Header position={position} />
      <FindPasswordStyle />
    </>
  );
};

export default FindPassword;
