import Header from "../../components/Header";
import JoinStyle from "../../components/login&join/Joinstyle";

const Join = () => {
  const position = false;
  const margin = false;
  return (
    <>
      <Header position={position} />
      <JoinStyle margin={margin} />
    </>
  );
};
export default Join;
