import styled from "@emotion/styled/macro";
import { css } from "@emotion/react/macro";

export const BoardWrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BoardContentDiv = styled.div`
  margin-top: ${(props) => props.margin};
  width: 1080px;
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
  justify-content: space-between;
  align-items: ${(props) => props.align};
`;

export const BoardContainer = styled.div`
  display: flex;
  height: ${(props) => props.height};
`;

export const BoardContentWrapDiv = styled.div`
  width: 1080px;
  min-height: 711px;
  display: flex;
  flex-direction: column;
`;

export const BorderDiv = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 0 5px;

  ${(props) =>
    props.height &&
    css`
      height: 101px;
    `};
`;

export const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ededed;
`;

export const SearchInput = styled.input`
  width: 300px;
  height: 40px;
  padding-left: 16px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.64px;
  ::placeholder {
    color: #dee2e6;
  }
`;

export const SearchButton = styled.button`
  width: 99px;
  height: 40px;
  outline: none;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #212529;
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #fff;
  margin-left: 10px;
`;

export const BoardDateText = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.64px;
  color: #868e96;
`;

export const BoardCategory = styled.p`
  font-size: 20px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #868e96;
  cursor: pointer;
  margin-right: 54px;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  ${(props) =>
    props.active &&
    css`
      text-decoration: underline;
      text-underline-position: under;
      font-weight: 700;
      color: #ff6a13;
    `};
`;

export const BoardAnswerDiv = styled.div`
  width: 1080px;
  height: 127px;
  background-color: rgb(255, 208, 184, 0.2);
  padding: 20px 20px 32px 20px;
  border: none;
  display: ${(props) => props.display};
`;

export const QuestionText = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.8px;
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  line-height: 28px;
`;

export const ErrorText = styled.p`
  font-size: 20px;
  letter-spacing: -0.8px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  margin-top: 31px;
  color: #868e96;
`;

export const ErrorDiv = styled.div`
  width: 1080px;
  display: flex;
  margin-top: 249px;
  flex-direction: column;
  align-items: center;
  @media (max-height: 760px) {
    margin-top: 180px;
  }
`;

export const BoardDetailContentDiv = styled.div`
  margin-top: 30px;
  width: 100%;
  /* height: 665px; */
  padding: 0 5px;
`;

export const BoardDetailContentText = styled.p`
  & > p {
    font-size: 16px;
    font-weight: 400;
    font-family: SpoqaHanSansNeo;
    letter-spacing: -0.64px;
    color: #212529;
}

& > p > img {
  width : 1080px;
  margin-top : 30px;
}

`;

export const BoardImageDiv = styled.img`
  width: 100%;
  height: 440px;
  object-fit: cover;
  margin-bottom: 30px;
`;
