import styled from "@emotion/styled/macro";
import { ReactComponent as CheckActive } from "../../../../assets/img/pickupStepCheck.svg";
import { ReactComponent as DotLine } from "../../../../assets/img/PickupStepLine.svg";
import { ReactComponent as CheckStep } from "../../../../assets/img/PickupStepCheck2.svg";

import {
  SelfSellCarStepTitle,
  SelfSellCarApplyTextDiv,
  SelfSellCarStepSubText,
  SelfSellCarCheck,
  SelfSellCarStepContainer,
  SelfSellCarInputContainer,
  SelfSellCarStepText,
  SelfSellCarStepBarDiv,
} from "../../../../components/selfSellCar/SelfSellCarStyle";
import Check from "../../../../assets/img/findemail.png";

const WaitingVehicle = () => {
  const StepBarDot = styled(DotLine)`
    margin-top: 10px;
  `;
  return (
    <>
      <SelfSellCarApplyTextDiv>
        <SelfSellCarStepContainer>
          <SelfSellCarCheck alt="check" src={Check} />
          <SelfSellCarStepTitle>
            회원님의 차량은 입고 대기 단계에 있습니다.
          </SelfSellCarStepTitle>
        </SelfSellCarStepContainer>
        <SelfSellCarStepSubText>탁송 진행 중입니다.</SelfSellCarStepSubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarStepBarDiv>
        <SelfSellCarInputContainer align>
          <CheckActive />
          <SelfSellCarStepText active>입고 대기</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정밀 점검</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>상품화</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer
          align
          style={{ marginLeft: 13, marginRight: 13 }}
        >
          <CheckStep />
          <SelfSellCarStepText>경매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>위탁 판매</SelfSellCarStepText>
        </SelfSellCarInputContainer>
        <StepBarDot />
        <SelfSellCarInputContainer align>
          <CheckStep />
          <SelfSellCarStepText>정산 완료</SelfSellCarStepText>
        </SelfSellCarInputContainer>
      </SelfSellCarStepBarDiv>
    </>
  );
};

export default WaitingVehicle;
