import { useLocation } from "react-router";
import Header from "../../components/Header";
import MyPageDeliveryListStyle from "../../components/myPage/MyPageDeliveryListStyle";

const MyPageDeliveryList = () => {
  const position = false;
  const location = useLocation();
  return (
    <>
      <Header position={position} borderactive={location.pathname.includes('/myPage')} />
      <MyPageDeliveryListStyle />
    </>
  );
};

export default MyPageDeliveryList;
