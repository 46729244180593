import { Tabs, DatePicker } from "antd";
import styled from "@emotion/styled/macro";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";
import { BidModal } from "../liveAuction/LiveAuctionModal";
import "moment/locale/ko";
import { useUserType } from "../../hooks/useUserInfo";
import { useEffect, useRef, useState } from "react";
import {
  DeliveryListModal,
  MyPageImg,
  MyPageSubText as EmptyText,
} from "./MyPageDeliveryListStyle";
import {
  MyPageContentDiv,
  MyPageWrapDiv,
  MyPageActiveDiv,
  MyPageContentListDiv,
  MyPageText,
} from "./MyPageMenu";
import { MyPageSubText, Container, TableDiv } from "./MyPageSelfSellCarStyle";
import MyPageMenu from "./MyPageMenu";
import "./myPage.css";
import axios from "axios";
import { BASIC_URL } from "../CommonStyle";
import { useToken } from "../../hooks/useToken";
import myPageImg from "../../assets/img/myPageImg.png";

const AuctionTableDiv = styled(TableDiv)`
  margin-top: ${(props) => props.margin && "50px"};
`;

const MyPageLiveAuction = () => {
  const { TabPane } = Tabs;

  const userType = useUserType();
  const token = useToken();
  const liveAuction = useRef(false);

  const [tabStatus, setTabStatus] = useState("진행중");
  const [userAuctionHistory, setUserAuctionHistory] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [bidModalInfo, setBidModalInfo] = useState();

  const dateHandler = (datesStrings) => {
    setStartDate(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  function disabledDate(current) {
    if (!startDate) {
      return false;
    } else {
      return current && current < moment(startDate);
    }
  }

  // 라이브 옥션 데이터 가져오기
  useEffect(() => {
    if (tabStatus === "진행중") {
      axios({
        url: `${BASIC_URL}/liveauction/me/status?status=${tabStatus}`,
        method: "get",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setUserAuctionHistory(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios({
        url: `${BASIC_URL}/liveauction/me/status?status=${tabStatus}`,
        method: "get",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setUserAuctionHistory(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tabStatus, token]);

  // EndDate가 생기면 API
  useEffect(() => {
    if (startDate && endDate) {
      axios({
        url: `${BASIC_URL}/liveauction/me/status?status="진행완료"&startDate=${startDate}&endDate=${endDate}`,
        method: "get",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setUserAuctionHistory(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token, endDate, startDate]);

  const userLiveAuction = userAuctionHistory?.bidders?.map((auction) => {
    return (
      <>
        <AuctionTableDiv margin border key={auction?.id}>
          <MyPageSubText color="true" font>
            출품번호
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            {auction?.auctionEntryNumber}번
          </MyPageSubText>
        </AuctionTableDiv>
        <AuctionTableDiv>
          <MyPageSubText color="true" font>
            {" "}
            회차
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            1001회 (
            {moment(auction?.startsAt)
              .subtract(9, "hours")
              .format("YYYY년 MM월 DD일")}
            )
          </MyPageSubText>
        </AuctionTableDiv>
        <AuctionTableDiv>
          <MyPageSubText color="true" font>
            {" "}
            차종
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            {auction?.name}
          </MyPageSubText>
        </AuctionTableDiv>
        <AuctionTableDiv>
          <MyPageSubText color="true" font>
            {" "}
            입찰가
          </MyPageSubText>
          <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
            {Number(auction?.price).toLocaleString("en")}원
          </MyPageSubText>
        </AuctionTableDiv>
        <AuctionTableDiv>
          <MyPageSubText color="true" font>
            {" "}
            낙찰가
          </MyPageSubText>
          {tabStatus === "진행중" ? (
            <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
              -
            </MyPageSubText>
          ) : (
            <MyPageSubText style={{ color: "#212529", marginLeft: 20 }}>
              {Number(auction?.highPrice).toLocaleString("en")}원
            </MyPageSubText>
          )}
        </AuctionTableDiv>
        <AuctionTableDiv>
          {tabStatus === "진행중" ? (
            <>
              <MyPageSubText color="true" font>
                {" "}
                부재자입찰
              </MyPageSubText>
              {auction?.editable ? (
                <MyPageSubText
                  style={{
                    marginLeft: 20,
                  }}
                  font2
                  onClick={() => {
                    setBidModalOpen(true);
                    setBidModalInfo({
                      id: auction?.id,
                      name: auction?.userName,
                      price: auction?.price,
                      auctionEntryNumber: auction?.auctionEntryNumber,
                      phoneNumber: auction?.userPhoneNumber,
                      editable: auction?.editable,
                    });
                  }}
                >
                  변경
                </MyPageSubText>
              ) : (
                <MyPageSubText
                  style={{
                    marginLeft: 20,
                    textDecoration: "none",
                  }}
                  font2
                  onClick={() => {
                    setBidModalOpen(true);
                    setBidModalInfo({
                      id: auction?.id,
                      name: auction?.userName,
                      price: auction?.price,
                      auctionEntryNumber: auction?.auctionEntryNumber,
                      phoneNumber: auction?.userPhoneNumber,
                      editable: false,
                    });
                  }}
                >
                  확인
                </MyPageSubText>
              )}
            </>
          ) : (
            <>
              <MyPageSubText color="true" font>
                {" "}
                경매현황
              </MyPageSubText>
              <MyPageSubText
                style={{
                  marginLeft: 20,
                  textDecoration: "none",
                  cursor: "auto",
                }}
                font2
              >
                {auction?.selected ? "낙찰" : "유찰"}
              </MyPageSubText>{" "}
            </>
          )}
        </AuctionTableDiv>
      </>
    );
  });

  useEffect(() => {
    liveAuction.current = true;
    return () => (liveAuction.current = false);
  });

  return (
    <MyPageWrapDiv>
      <BidModal
        bidModalOpen={bidModalOpen}
        setBidModalOpen={setBidModalOpen}
        bidModalInfo={bidModalInfo}
      />
      <MyPageContentDiv>
        <MyPageMenu userType={userType} liveAuction={liveAuction} />
        <MyPageActiveDiv className="myPage_div">
          <MyPageText>나의 이용내역 &nbsp; &gt; &nbsp; 라이브 옥션</MyPageText>

          <Tabs
            defaultActiveKey="1"
            style={{ marginTop: 40, fontSize: 18 }}
            tabBarStyle={{ fontSize: 18 }}
            size="small"
            tabBarGutter={50}
            onChange={(key) => setTabStatus(key)}
          >
            <TabPane key="진행중" tab="진행 중">
              <MyPageContentListDiv style={{ marginTop: 70 }}>
                <Container column width="true">
                  {userAuctionHistory?.bidders?.length === 0 ? (
                    <DeliveryListModal>
                      <MyPageImg src={myPageImg} alt="myPage" />
                      <EmptyText color="true">
                        진행 중인 라이브 옥션이 없습니다.
                      </EmptyText>
                    </DeliveryListModal>
                  ) : (
                    <>
                      <MyPageText>진행 상태 </MyPageText>
                      {userLiveAuction}{" "}
                    </>
                  )}
                </Container>
              </MyPageContentListDiv>
            </TabPane>
            <TabPane key="진행완료" tab="진행 종료">
              <MyPageContentListDiv style={{ marginTop: 70 }}>
                <Container center>
                  <MyPageText>진행 상태 </MyPageText>
                </Container>
                <Container center="true">
                  <DatePicker
                    placeholder="시작일"
                    className="my_page_picker"
                    locale={locale}
                    showToday={false}
                    allowClear={false}
                    onChange={dateHandler}
                  />{" "}
                  <MyPageText style={{ fontWeight: 700, color: "#dee2e6" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;
                  </MyPageText>
                  <DatePicker
                    className="my_page_picker"
                    placeholder="종료일"
                    locale={locale}
                    showToday={false}
                    allowClear={false}
                    disabledDate={disabledDate}
                    onChange={(datesStrings) => {
                      setEndDate(moment(datesStrings._d).format("YYYY-MM-DD"));
                    }}
                  />{" "}
                </Container>
                {userLiveAuction}
              </MyPageContentListDiv>
            </TabPane>
          </Tabs>
        </MyPageActiveDiv>
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};

export default MyPageLiveAuction;
