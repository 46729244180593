import styled from "@emotion/styled/macro";
import axios from "axios";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import tokenState from "../../states/TokenState";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import MyPageAddList from "./MyPageAddList";
import { MyPageLine } from "./MyPageUpdateInfoStyle";
import MyPageMenu, {
  MyPageWrapDiv,
  MyPageContentDiv,
  MyPageText,
  MyPageActiveDiv,
} from "./MyPageMenu";
import { useUserType } from "../../hooks/useUserInfo";
import { BASIC_URL } from "../CommonStyle";
import { ReactComponent as DefaultAddress } from "../../assets/img/defaultDeliveryList.svg";
import myPageImg from "../../assets/img/myPageImg.png";

import "../Common.css";
import "../PickUp.css";

export const MyPageSubText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  color: ${(props) => (props.color ? "#868e96" : "#212529")};
  font-weight: 400;
  letter-spacing: -2px;
  ${(props) =>
    props.font &&
    css`
      color: #212529;
      font-size: 18px;
      font-weight: 500;
      text-decoration: underline;
      text-underline-position: under;
      margin-top: 20px;
      cursor: pointer;
      width: 100%;
      text-align: center;
    `};
`;

export const MyPageImg = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 31px;
`;

export const DeliveryListModal = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 250px;
`;

const UserDeliveryListDiv = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ededed;
  border-radius: 10px;
  height: 130px;
  margin-top: 30px;
`;

const UserDeliveryContentText = styled.p`
  font-size: 22px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  margin-top: 11px;
  color: #212529;
  ${(props) =>
    props.font &&
    css`
      color: #212529;
      font-size: 18px;
      font-weight: 400;
      margin-left: 6px;
    `};
`;

const UserDeliveryListContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserAddressUpdateButton = styled.button`
  width: 120px;
  height: 60px;
  background-color: #333333;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  cursor: pointer;
  margin-top: 35px;
  margin-bottom: 15px;
  font-weight: 500;
`;

const MyPageDeliveryListStyle = () => {
  const navigate = useNavigate();

  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  }, [navigate, token]);

  // 배송지 등록 여부
  const [userAddress, setUserAddress] = useState();
  const [addDeliveryList, setAddDeliveryList] = useState(false);

  useEffect(() => {
    setAddDeliveryList(false);
    axios({
      url: `${BASIC_URL}/user/mypage/shippings`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setUserAddress(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const [deliveryId, setDeliveryId] = useState(0);
  const [findList, setFindList] = useState({});

  useEffect(() => {
    if (deliveryId !== 0) {
      const findAddress = userAddress?.shippings?.find(
        (item) => item.id === deliveryId
      );
      setFindList(findAddress);
    }
  }, [deliveryId, userAddress]);

  // 수정 버튼 눌렀을때 이동 및 필터
  const userDeliveryList = userAddress?.shippings?.map((list) => {
    return (
      <UserDeliveryListDiv key={list.id}>
        <UserDeliveryListContentDiv>
          {list?.default === true ? (
            <DefaultAddress style={{ marginTop: 20 }} />
          ) : null}
          <UserDeliveryContentText>{list.name}</UserDeliveryContentText>
          <UserDeliveryContentText font>
            {list.address} ({list.addressDetail})
          </UserDeliveryContentText>
        </UserDeliveryListContentDiv>
        <UserAddressUpdateButton
          onClick={() => {
            setDeliveryId(list.id);
            setAddDeliveryList(true);
          }}
        >
          수정
        </UserAddressUpdateButton>
      </UserDeliveryListDiv>
    );
  });
  const userType = useUserType();
  const deliveryText = useRef(false);
  useEffect(() => {
    deliveryText.current = true;
    return () => (deliveryText.current = false);
  });
  return (
    <MyPageWrapDiv>
      <MyPageContentDiv>
        <MyPageMenu userType={userType} deliveryText={deliveryText} />
        {addDeliveryList ? (
          <MyPageAddList findList={findList} />
        ) : (
          <MyPageActiveDiv className="myPage_div">
            <MyPageText>회원 정보 &nbsp; &gt; &nbsp; 배송지 관리</MyPageText>
            <MyPageLine />

            {userAddress?.isExist ? (
              <>
                <MyPageSubText style={{ marginTop: 70 }}>배송지</MyPageSubText>
                {userDeliveryList}
                <MyPageSubText
                  font
                  onClick={() => setAddDeliveryList(true)}
                  style={{ marginTop: 70 }}
                >
                  + &nbsp;&nbsp;배송지 추가
                </MyPageSubText>
              </>
            ) : userAddress?.isExist === false ? (
              <>
                <DeliveryListModal>
                  <MyPageImg src={myPageImg} alt="myPage" />
                  <MyPageSubText color="true">
                    입력하신 배송지 정보가 없습니다.
                  </MyPageSubText>
                </DeliveryListModal>
                <MyPageSubText font onClick={() => setAddDeliveryList(true)}>
                  + &nbsp;&nbsp;배송지 추가
                </MyPageSubText>
              </>
            ) : null}
          </MyPageActiveDiv>
        )}
      </MyPageContentDiv>
    </MyPageWrapDiv>
  );
};
export default MyPageDeliveryListStyle;
