import styled from "@emotion/styled/macro";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { useNavigate  } from "react-router";
import { DatePicker, Modal } from "antd";
import DaumPostcode from "react-daum-postcode";
import moment from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { useEffect, useState } from "react";
import { JoinCheckbox } from "../login&join/Joinstyle";
import { ErrorText } from "../login&join/JoinInfoStyle";
import { BASIC_URL } from "../CommonStyle";
import { ReactComponent as Plus } from "../../assets/img/plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/img/removeIcon.svg";
import { DeliveryListModal } from "../login&join/Modals";
import {
  AddressModal,
  ImageModal,
  PrivacyPolicyModal,
} from "../login&join/Modals";
import tokenState from "../../states/TokenState";
import "../Common.css";
import Loading from "react-loading";

const PickUpApplyWrapDiv = styled.div`
  width: 100%;
  min-height: 900px;
`;

const PickUpApplyHeaderDiv = styled.div`
  width: 650px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const PickUpApplyText = styled.p`
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -1.2px;
  color: #212529;
  font-family: SpoqaHanSansNeo;
`;

const PickUpApplySubText = styled.p`
  font-size: 20px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  letter-spacing: -0.8px;
  color: #868e96;
`;

const PickUpButtonDiv = styled.div`
  height: auto;
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

const PickUpApplyButton = styled.button`
  width: 400px;
  height: 100px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? "#E9ECEF" : "#ff6a13")};
  color: #fff;
  font-size: 18px;
  letter-spacing: -0.72px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
`;

const ButtonText = styled.p`
  font-size: 18px;
  letter-spacing: -0.72px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
`;

const PickUpText2 = styled(PickUpApplyText)`
  font-size: 24px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  color: #212529;
`;

const PickUpApplyStepDiv = styled.div`
  width: 1080px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const PickUpApplyLine = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid #dee2e6;
  margin-top: 20px;
`;

const PickUpApplyInputWrapDiv = styled.div`
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
`;

export const PickUpApplyInputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickUpApplyLabel = styled.label`
  font-size: 18px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  letter-spacing: -0.72px;
  color: #212529;
  cursor: pointer;
`;

export const PickUpApplyInput = styled.input`
  outline: none;
  width: 522px;
  height: 60px;
  border-radius: 10px;
  padding: 0 16px;
  border: 1px solid #e9ecef;
  font-size: 18px;
  letter-spacing: -0.72px;
  color: #212529;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  margin-top: 16px;

  &:focus {
    border: 1px solid #333;
    border-color: ${(props) => (props.color ? props.color : props.color)};
  }

  ::placeholder {
    color: #e9ecef;
  }
`;

export const PostButton = styled.button`
  width: 120px;
  height: 60px;
  background-color: #212529;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.72px;
  margin-left: 18px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const PickUpApplyFooterDiv = styled.div`
  margin-top: 140px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ShowMore = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.72px;
  color: #212529;
  display: block;
  margin-top: 2px;
`;

const ApplyFooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PickUpApplyActionButton = styled.button`
  width: 240px;
  height: 70px;
  border-radius: 10px;
  outline: none;
  font-family: SpoqaHanSansNeo;
  background-color: #ff6a13;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  border: none;
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
  letter-spacing: -0.88px;
  :disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const PickUpApplyImgDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin-top: 40px;
`;

const PickupApplyImgWrapDiv = styled.div`
  position: relative;
  margin-left: 20px;
  margin-bottom: 20px;
  &:nth-of-type(5n) {
    margin-left: 0;
  }
`;

const PickUpImg = styled.label`
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 10px;
  background-color: #f7f7f7;
  cursor: pointer;
  display: flex;
  font-family: SpoqaHanSansNeo;
  justify-content: center;
  align-items: center;
`;

const PickUpThumnail = styled.img`
  width: 200px;
  height: 200px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`;

const PickUpTextarea = styled.textarea`
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #e9ecef;
  padding: 16px;
  margin-top: 40px;
  outline: none;
  font-size: 18px;
  resize: none;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -0.72px;

  &:focus {
    border: 1px solid #333;
  }
  &::placeholder {
    color: #e9ecef;
  }
`;

const UpdateAddressText = styled.p`
  font-size: 18px;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  font-weight: 500;
  color: #868e96;
  cursor: pointer;
`;

const UploadImageText = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
  margin-top: 70px;
`;

const PickUpApplyStyle = () => {
  // 토큰
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/login");
    }
  });

  // 주소 , 우편 번호
  const [openPostModal, setOpenPostModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [myAdress, setMyAdress] = useState("");

  const Post = () => {
    const handleComplete = (data) => {
      setZipCode(data.zonecode);
      setMyAdress(data.address);
      setOpenPostModal((prev) => !prev);
    };

    return (
      <Modal
        centered
        visible={openPostModal}
        footer={null}
        width={613}
        closable={false}
        onCancel={() => setOpenPostModal(false)}
        bodyStyle={{ height: "auto" }}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    );
  };

  function disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  const [policyModal, setPolicyModal] = useState(false);

  const [view, setView] = useState(true);

  // 차량 번호 유효성 검사
  const [carNumber, setCarNumber] = useState("");
  const [carNumberText, setCarNumberText] = useState(false);
  const [carNumberInpuBorder, setCarNumberInputBorder] = useState("#333");

  useEffect(() => {
    const carNumberCheck = /\d{2,3}[가-힣]{1}\d{4}/gm.test(carNumber);
    if (carNumber) {
      if (carNumberCheck) {
        setCarNumberText(true);
        setCarNumberInputBorder("#333");
      } else {
        setCarNumberText(false);
        setCarNumberInputBorder("#FF383B");
      }
    } else {
      setCarNumberInputBorder("#333");
      setCarNumberText(true);
    }
  }, [carNumber, carNumberText, carNumberInpuBorder]);

  // 휴대폰 번호 유효성 검사
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberText, setPhoneNumberText] = useState("");
  const [phoneNumberBorder, setPhoneNumberBorder] = useState("#333");

  useEffect(() => {
    const phoneNumberCheck = /^[0-9]{3}[0-9]{4}[0-9]{4}/.test(phoneNumber);
    if (phoneNumber) {
      if (phoneNumberCheck) {
        setPhoneNumberText(true);
        setPhoneNumberBorder("#333");
      } else {
        setPhoneNumberText(false);
        setPhoneNumberBorder("#FF383B");
      }
    } else {
      setPhoneNumberBorder("#333");
      setPhoneNumberText(true);
    }
  }, [phoneNumber, phoneNumberText, phoneNumberBorder]);

  // 신청 타입
  const [type, setType] = useState("");
  useEffect(() => {
    if (view) {
      setType("self");
    } else {
      setType("online");
    }
  }, [type, view]);

  // 상세주소
  const [pickUpAddressDetail, setPickUpAddressDetail] = useState("");

  // 신청인
  const [name, setName] = useState("");

  // 픽업 날짜
  const [pickUpday, setPickUpDay] = useState("");
  const dateHandler = (datesStrings) => {
    setPickUpDay(moment(datesStrings._d).format("YYYY-MM-DD"));
  };

  // 약관 동의
  const [checkedValue, setCheckedValue] = useState(false);
  const checkHanlder = (checkedValue) => {
    setCheckedValue(checkedValue.target.checked);
  };

  // 유선 상담 신청
  const pickupOnlineApply = async () => {
    setApplyLoading(true);

    await axios({
      url: `${BASIC_URL}/pickupmaintenance`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name: name,
        carNumber: carNumber,
        pickUpAddressZipcode: zipCode,
        pickUpAddress: myAdress,
        pickUpAddressDetail: pickUpAddressDetail,
        pickUpday: pickUpday,
        type: type,
      },
    }).then((result) => {
      if (result.data.success) {
        setApplyLoading(false);
        navigate("/pickUpApplySuccess");
      } else {
        alert("오류가 발생했습니다, 관리자에게 문의해주세요.");
        return false;
      }
    });
  };

  // 이미지 첨부
  const [fileList, setFileList] = useState([]);

  // 이미지 설명
  const [imageDescription, setImageDescription] = useState("");

  const [uploadInput, setUploadInput] = useState(false);

  // 이미지 16개 이상 팝업 띄우기
  const [imageModal, setImageModal] = useState(false);

  useEffect(() => {
    if (fileList.length > 15) {
      setUploadInput(true);
    } else {
      setUploadInput(false);
    }
  }, [uploadInput, fileList]);

  const modalOpen = () => {
    if (uploadInput && fileList.length >= 16) {
      setImageModal(true);
    } else {
      setImageModal(false);
    }
  };

  const fileChange = (event) => {
    const nowSelectedImageList = event.target.files;
    const nowFileList = [...fileList];

    for (let i = 0; i < nowSelectedImageList.length; i += 1) {
      nowFileList.push(nowSelectedImageList[i]);
    }

    setFileList(nowFileList);
  };

  // 이미지 삭제
  const removeImage = (src) => {
    setFileList(fileList.filter((item) => item !== src));
  };

  const thumNail = fileList.map((item, index) => {
    return (
      (
        <PickupApplyImgWrapDiv key={index}>
          <RemoveIcon
            onClick={() => {
              removeImage(item);
            }}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
            }}
          />
          <PickUpThumnail src={URL.createObjectURL(item)} alt="thumnail" />
        </PickupApplyImgWrapDiv>
      ) ?? null
    );
  });

  const pickupApply = async () => {
    const images = [];

    setApplyLoading(true);

    await Promise.all(
      fileList.map(async (file) => {
        const formData = new FormData();

        formData.append("file", file);

        const result = await axios({
          url: `${BASIC_URL}/image`,
          method: "POST",
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        });

        if (result.data.success) {
          images.push({ filename: file.name, url: result.data.url });
        }
      })
    );

    await axios({
      url: `${BASIC_URL}/pickupmaintenance`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        name: name,
        carNumber: carNumber,
        pickUpAddressZipcode: zipCode,
        pickUpAddress: myAdress,
        pickUpAddressDetail: pickUpAddressDetail,
        pickUpday: pickUpday,
        images,
        imageDescription: imageDescription,
        type: type,
      },
    }).then((result) => {
      if (result.data.success) {
        setApplyLoading(false);
        navigate("/pickUpApplySuccess");
      } else {
        setApplyLoading(false);
        alert("오류가 발생했습니다, 관리자에게 문의해주세요.");
        return false;
      }
    });
  };

  // 배송지 모달
  const [addressModal, setAddressModal] = useState(false);
  const [userShippingList, setUserShippingList] = useState();
  const deliveryList = userShippingList?.shippings;

  // 회원 마이페이지 배송지 불러오기
  useEffect(() => {
    axios({
      url: `${BASIC_URL}/user/mypage/shippings`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data) {
          setUserShippingList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  // 픽업정비 신청 버튼 활성화
  const [buttonActive, setButtonActive] = useState(false);
  useEffect(() => {
    if (type === "online") {
      if (
        phoneNumberText &&
        carNumberText &&
        phoneNumberText &&
        myAdress &&
        zipCode &&
        checkedValue
      ) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    } else if (type === "self") {
      if (
        phoneNumberText &&
        carNumberText &&
        phoneNumberText &&
        myAdress &&
        zipCode &&
        checkedValue &&
        imageDescription &&
        fileList.length > 0
      ) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    }
  }, [
    phoneNumberText,
    type,
    carNumberText,
    myAdress,
    zipCode,
    checkedValue,
    buttonActive,
    imageDescription,
    fileList,
  ]);
  const [openListModal, setOpenListModal] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);

  return (
    <PickUpApplyWrapDiv>
      <Post />
      <DeliveryListModal
        openListModal={openListModal}
        setOpenListModal={setOpenListModal}
        setZipCode={setZipCode}
        setMyAdress={setMyAdress}
        setPickUpAddressDetail={setPickUpAddressDetail}
        deliveryList={deliveryList}
      />
      <PrivacyPolicyModal
        policyModal={policyModal}
        setPolicyModal={setPolicyModal}
      />
      <PickUpApplyHeaderDiv>
        <PickUpApplyText>픽업정비 신청</PickUpApplyText>
        <PickUpApplySubText
          style={{
            marginTop: 24,
          }}
        >
          상담 방법을 선택해주세요. (유선 상담 선택 시, 상담원이 연락 드릴
          예정입니다.)
        </PickUpApplySubText>
      </PickUpApplyHeaderDiv>
      <PickUpButtonDiv>
        {view ? (
          <>
            <PickUpApplyButton
              onClick={() => setView(true)}
              style={{ fontSize: 24 }}
            >
              직접 신청
              <br />
              <ButtonText style={{ marginTop: 20 }}>
                손상 부위의 설명과 함께 사진을 첨부해주세요.
              </ButtonText>
            </PickUpApplyButton>
            <PickUpApplyButton
              primary
              style={{ marginLeft: 20, fontWeight: 500, fontSize: 24 }}
              onClick={() => setView(false)}
            >
              유선 상담
              <br />
              <ButtonText style={{ marginTop: 20 }}>
                기본정보만 입력해 주세요. 상담원이 연락드립니다.
              </ButtonText>
            </PickUpApplyButton>{" "}
          </>
        ) : (
          <>
            <PickUpApplyButton
              primary
              onClick={() => setView(true)}
              style={{ fontSize: 24 }}
            >
              직접 신청
              <br />
              <ButtonText style={{ marginTop: 20 }}>
                손상 부위의 설명과 함께 사진을 첨부해주세요.
              </ButtonText>
            </PickUpApplyButton>
            <PickUpApplyButton
              style={{ marginLeft: 20, fontSize: 24 }}
              onClick={() => setView(false)}
            >
              유선 상담
              <br />
              <ButtonText style={{ marginTop: 20 }}>
                기본정보만 입력해 주세요. 상담원이 연락드립니다.
              </ButtonText>
            </PickUpApplyButton>{" "}
          </>
        )}
      </PickUpButtonDiv>
      <PickUpApplyStepDiv>
        <PickUpText2>1. 기본정보 입력</PickUpText2>
        <PickUpApplyLine />
        <PickUpApplyInputWrapDiv>
          {/* <PickUpApplyInputDiv>
            <PickUpApplyLabel htmlFor="name">신청인</PickUpApplyLabel>
            <PickUpApplyInput
              placeholder="신청인 입력"
              id="name"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoFocus={true}
              autoComplete="off"
            />
          </PickUpApplyInputDiv> */}
          <PickUpApplyInputDiv>
            <PickUpApplyLabel htmlFor="carNumber">차량 번호</PickUpApplyLabel>
            <PickUpApplyInput
              placeholder="차량번호 입력"
              color={carNumberInpuBorder}
              id="carNumber"
              name="carNumber"
              autocomplete="off"
              type="text"
              value={carNumber}
              onChange={(event) => setCarNumber(event.target.value)}
            />
            {carNumberText ? null : (
              <ErrorText>올바른 차량번호를 입력해주세요.</ErrorText>
            )}
          </PickUpApplyInputDiv>
          <PickUpApplyInputDiv style={{ marginLeft: 36 }}>
            <PickUpApplyLabel htmlFor="phoneNumber">
              휴대폰 번호
            </PickUpApplyLabel>
            <PickUpApplyInput
              autocomplete="off"
              name="phoneNumber"
              placeholder="(-) 없이 숫자만 입력"
              color={phoneNumberBorder}
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
            {phoneNumberText ? null : (
              <ErrorText>올바른 휴대폰번호를 입력해주세요.</ErrorText>
            )}
          </PickUpApplyInputDiv>
        </PickUpApplyInputWrapDiv>
        <PickUpApplyInputWrapDiv>
          <PickUpApplyInputDiv>
            <AddressModal
              addressModal={addressModal}
              setAddressModal={setAddressModal}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <PickUpApplyLabel htmlFor="pickupdate">
                차량 픽업 요청일
              </PickUpApplyLabel>
            </div>
            <DatePicker
              className="pickUpDatePicker"
              disabledDate={disabledDate}
              locale={locale}
              showToday={false}
              allowClear={false}
              onChange={dateHandler}
            />
          </PickUpApplyInputDiv>
          <PickUpApplyInputDiv style={{ display: "inline-block", marginLeft: 36 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <PickUpApplyLabel htmlFor="pickupAddress">
                  차량 픽업 주소
                </PickUpApplyLabel>
                <UpdateAddressText
                  onClick={() => {
                    if (userShippingList?.isExist) {
                      setOpenListModal(true);
                    } else {
                      setAddressModal(true);
                    }
                  }}
                >
                  기존 배송지 선택 &nbsp;&nbsp; &gt;
                </UpdateAddressText>
              </div>
              <PickUpApplyInput
                style={{ width: 384 }}
                placeholder="우편번호 입력"
                id="pickupAddress"
                autocomplete="off"
                readOnly
                value={zipCode}
              />
              <PostButton
                onClick={() => {
                  setOpenPostModal(true);
                  setPickUpAddressDetail("");
                }}
              >
                우편번호
              </PostButton>
            </PickUpApplyInputDiv>
            <div
            style={{
              width: 1080,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <PickUpApplyInputDiv>
              <PickUpApplyInput
                placeholder="주소 입력"
                autocomplete="new-password"
                readOnly
                value={myAdress}
              />
            </PickUpApplyInputDiv>
            <PickUpApplyInputDiv>
              <PickUpApplyInput
                placeholder="상세 주소 입력"
                autocomplete="off"
                value={pickUpAddressDetail}
                onChange={(event) => setPickUpAddressDetail(event.target.value)}
              />
            </PickUpApplyInputDiv>
          </div>
        </PickUpApplyInputWrapDiv>
      </PickUpApplyStepDiv>
      {view ? (
        <>
          <PickUpApplyStepDiv>
            <PickUpText2>2. 이미지 첨부(필수)</PickUpText2>
            <PickUpApplyLine />
            <UploadImageText>
              수리가 필요한 부위의 이미지를 첨부해주세요.(최대 16장까지 가능 )
            </UploadImageText>
            <PickUpApplyImgDiv>
              <PickUpImg htmlFor="Img-upload" onClick={modalOpen}>
                <Plus />
              </PickUpImg>
              <input
                type="file"
                multiple
                id="Img-upload"
                style={{ display: "none" }}
                onChange={fileChange}
                disabled={uploadInput}
              />
              {thumNail}
            </PickUpApplyImgDiv>
          </PickUpApplyStepDiv>
          <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
          <PickUpApplyStepDiv>
            <PickUpText2>3. 사진 상세 설명(필수)</PickUpText2>
            <PickUpApplyLine />
            <UploadImageText>
              첨부된 사진에 대한 설명 및 요청사항을 입력해주세요.
            </UploadImageText>
            <PickUpTextarea
              placeholder="예) 앞범퍼 옆부분에 기스가 나서 수리를 희망합니다."
              maxLength="500"
              onChange={(event) => setImageDescription(event.target.value)}
            />
          </PickUpApplyStepDiv>
        </>
      ) : null}
      <PickUpApplyFooterDiv>
        <ApplyFooterDiv>
          <JoinCheckbox
            unactive={!checkedValue}
            className="selfsellcar_checkbox"
            onChange={checkHanlder}
            style={{ marginRight: 13 }}
          />
          <ShowMore>개인정보 수집 및 이용에 동의합니다.(필수)</ShowMore>
          <ShowMore
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#868E96",
              marginLeft: 16,
            }}
            onClick={() => setPolicyModal(true)}
          >
            더보기
          </ShowMore>
        </ApplyFooterDiv>

        {buttonActive ? (
          <PickUpApplyActionButton
            disabled={applyLoading}
            onClick={type === "self" ? pickupApply : pickupOnlineApply}
          >
            { applyLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                <Loading type="spin" width={30} height={30} />
              </div>
            ) : '픽업 정비 신청' }
          </PickUpApplyActionButton>
        ) : (
          <PickUpApplyActionButton disabled>
            픽업 정비 신청
          </PickUpApplyActionButton>
        )}
      </PickUpApplyFooterDiv>
    </PickUpApplyWrapDiv>
  );
};

export default PickUpApplyStyle;
