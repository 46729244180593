import { atom, DefaultValue } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    if (typeof window !== "undefined") {
      const savedValue = localStorage.getItem(key);

      if (savedValue !== null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };

const snsToken = atom({
  key: "snsToken",
  default: {
    id: "",
    type: "",
  },
  effects_UNSTABLE: [localStorageEffect("snsToken")],
});

export default snsToken;
export { localStorageEffect };
