import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import PickUpMainImg from "../../assets/img/pickupMain.png";
import PickupArrow from "../../assets/img/pickupArrow.png";
import Camera from "../../assets/img/camera.png";
import step2 from "../../assets/img/step2.png";
import step3 from "../../assets/img/step3.png";
import step4 from "../../assets/img/step4.png";
import pickupMainGif from "../../assets/gif/online.gif";
import pickupMainGif2 from "../../assets/gif/untact.gif";
import pickupMainGif3 from "../../assets/gif/pickup.gif";
import pickupMainGif4 from "../../assets/gif/delivery.gif";

const PickUpMainWrapDiv = styled.div`
  width: 100%;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SpoqaHanSansNeo;
`;

const PickUpMainImgDiv = styled.div`
  width: 100%;
  height: 815px;
  background-image: url("${PickUpMainImg}");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
`;

const PickUpMainTextDiv = styled.div`
  width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TextDiv = styled.div`
  width: 700px;
`;

const PickUpMainText = styled.span`
  font-family: SpoqaHanSansNeo;
  display: block;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  letter-spacing: -2px;
  line-height: 60px;
`;

const PickUpMainText1 = styled.span`
  font-family: Poppins;
  display: block;
  font-size: 28px;
  letter-spacing: -1.12px;
  font-weight: 500;
  letter-spacing: -1.12px;
  color: #fff;
`;

const PickUpMainText2 = styled.span`
  font-family: SpoqaHanSansNeo;
  display: block;
  font-size: 20px;

  font-weight: 500;
  letter-spacing: -1px;

  font-weight: 400;
  color: ${(props) => (props.color ? props.color : "#fff")};
  letter-spacing: -1px;
  line-height: 30px;
`;

const PickupNewServiceWrapDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  height: auto;
`;

const NewServiceDiv = styled.div`
  margin-top: 220px;
  height: auto;
`;

const NewServiceText = styled.span`
  font-size: 24px;
  display: block;
  font-weight: 500;
  color: #212529;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.96px;
  line-height: 40px;
`;

const NewServiceContentDiv = styled.div`
  width: 1080px;
  height: 200px;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
`;

const WrapDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1080px;
  justify-content: flex-end;
  margin-top: 120px;
`;

const NewServiceContentTextDiv = styled(NewServiceContentDiv)`
  width: auto;
  height: auto;
  flex-direction: column;
  margin-top: 0;
  flex-direction: column;
  align-items: flex-end;
`;

const NewServiceContent = styled.div`
  width: 180px;
  margin-left: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NewServiceImgDiv = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NewServiceCircle = styled.div`
  width: 36px;
  height: 36px;
  background-color: #212529;
  border-radius: 100px;
`;

const Arrow = styled.img`
  width: 100px;
  height: 10px;
  margin-right: 33px;
  margin-top: 40px;
`;

export const StepDiv = styled.div`
  width: 352px;
  margin-top: 230px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const StepText = styled.span`
  font-size: 30px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.2px;
  line-height: 44px;
  color: #212529;
`;

const StepImgWrapDiv = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StepImgDiv = styled.div`
  width: 708px;
  height: 164px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #868e96;
`;

const StepImg = styled.img`
  width: 40px;
  height: 40px;
`;

export const Circle2 = styled(NewServiceCircle)`
  width: ${(props) => (props.width ? props.width : props.width)};
  height: 50px;
  background: #ff6a13;
  position: absolute;
  left: -20px;
  z-index: -1;
  transition: width 0.3s;
  cursor: pointer;
`;

const PickupAnimationDiv = styled.div`
  width: 20px;
  height: 3px;
  border: 1px solid #ff6a13;
  background: #ff6a13;
  margin-left: 5px;
  transition: width 1s;
  cursor: pointer;
  &:hover {
    width: 382px;
    transition: width 2s;
  }
`;

const PickupAnimationDiv2 = styled(PickupAnimationDiv)`
  height: 3px;
  border: 1px solid #ff6a13;
  background: #ff6a13;
  transition: width 1s;
  cursor: pointer;
  top: 43px;
  left: 223px;
  width: 20px;
  position: absolute;
  &:hover {
    width: 190px;
    transition: width 1s;
  }
`;

const InterRactionImg = styled.img`
  width: 120px;
  height: 120px;
`;

const PickupMainOrageText = styled.p`
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  color: #ff6a13;
  letter-spacing: -0.8px;
`;

const PickupMainOrageText2 = styled.p`
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  color: #ff6a13;
  letter-spacing: -0.96px;
  margin-top: 20px;
`;

const OragnceText3 = styled.p`
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  color: #ff6a13;
  letter-spacing: -0.72px;
`;

const PickUpServiceSubText = styled.p`
  font-size: 18px;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
  margin-top: 12px;
`;

const PickUpStepSubText = styled.p`
  font-size: 18px;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
  font-weight: 400;
  color: #212529;
`;

const PickupStepGuideText = styled.span`
  font-size: 30px;
  font-weight: 600;
  font-family: Poppins;
  letter-spacing: -1.2px;

  margin-left: 12px;
  color: #212529;
`;

const PickupStepGuideText2 = styled.span`
  font-size: 30px;
  font-weight: 700;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -1.2px;
  color: #212529;
`;

const PickUpFooterText2 = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.8px;
  color: #212529;
  margin-top: 12px;
`;

const PickUpApplyText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  letter-spacing: -0.9px;
  color: ${(props) => props.color};
`;

const PickUpMainStyle = () => {
  const navigate = useNavigate();

  const [textWidth, setTextWidth] = useState(20);
  const [textWidth2, setTexdtWidth2] = useState(20);

  const handleScroll = () => {
    const { scrollTop } = document.body;

    if (scrollTop > 930) {
      setTextWidth(300);
      setTexdtWidth2(185);
    } else {
      setTextWidth(20);
      setTexdtWidth2(20);
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
  });

  const [color, setColor] = useState("#212529");
  const [width, setWidth] = useState("48px");
  return (
    <PickUpMainWrapDiv>
      <PickUpMainImgDiv>
        <PickUpMainTextDiv>
          <TextDiv>
            <PickUpMainText1>More than smart -</PickUpMainText1>
            <PickUpMainText style={{ marginTop: 24 }}>
              신청부터 픽업 & 딜리버리 까지 <br />
              편리한 비대면 정비 서비스를 제공합니다.
            </PickUpMainText>
            <PickUpMainText2 style={{ marginTop: 40 }}>
              대면 & 오프라인 방문으로 번거롭고 불편하셨죠 ? <br />
              A-Z 비대면 픽업 딜리버리 정비 서비스를 만나보세요.
            </PickUpMainText2>
          </TextDiv>
        </PickUpMainTextDiv>
      </PickUpMainImgDiv>
      <PickupNewServiceWrapDiv>
        <NewServiceDiv>
          <PickupMainOrageText>New Service</PickupMainOrageText>

          <NewServiceText style={{ marginTop: 16 }}>
            달라진 픽업정비 <br />
            서비스를 만나보세요.
          </NewServiceText>
        </NewServiceDiv>
      </PickupNewServiceWrapDiv>
      <NewServiceContentDiv>
        <NewServiceContent style={{ marginLeft: 35 }}>
          <NewServiceImgDiv>
            <InterRactionImg src={pickupMainGif} alt="gif" />
          </NewServiceImgDiv>

          <PickupMainOrageText2>Online</PickupMainOrageText2>
          <PickUpServiceSubText>간편 3분 온라인 신청</PickUpServiceSubText>
        </NewServiceContent>
        <NewServiceContent style={{ width: 200 }}>
          <NewServiceImgDiv style={{ position: "relative" }}>
            <InterRactionImg src={pickupMainGif2} alt="gif" />
          </NewServiceImgDiv>

          <PickupMainOrageText2>Untact</PickupMainOrageText2>
          <PickUpServiceSubText>비대면 정비 서비스 제공</PickUpServiceSubText>
        </NewServiceContent>

        <NewServiceContent style={{ width: 200 }}>
          <NewServiceImgDiv style={{ position: "relative" }}>
            <InterRactionImg src={pickupMainGif3} alt="gif" />
          </NewServiceImgDiv>

          <PickupMainOrageText2>Pick-up</PickupMainOrageText2>
          <PickUpServiceSubText>자동 픽업 서비스</PickUpServiceSubText>
        </NewServiceContent>

        <NewServiceContent style={{ width: 200 }}>
          <NewServiceImgDiv
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <InterRactionImg src={pickupMainGif4} alt="gif" />
          </NewServiceImgDiv>

          <PickupMainOrageText2>Delivery</PickupMainOrageText2>
          <PickUpServiceSubText>희망 배송지로 딜리버리</PickUpServiceSubText>
        </NewServiceContent>
      </NewServiceContentDiv>
      <WrapDiv>
        <Arrow src={PickupArrow} alt="arrow" />
        <NewServiceContentTextDiv>
          <OragnceText3>As is</OragnceText3>

          <div style={{ position: "relative" }}>
            <StepText>
              오프라인 방문으로 번거로운 서비스에서,
              <br />
              신청부터 픽업 & 딜리버리 까지
              <br />
              편리한 비대면 정비 서비스를 제공합니다.
            </StepText>
            <PickupAnimationDiv2 style={{ width: textWidth2 }} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <PickupAnimationDiv style={{ width: textWidth }} />
            <OragnceText3
              style={{
                marginTop: 10,

                marginLeft: 5,
              }}
            >
              To be
            </OragnceText3>
          </div>
        </NewServiceContentTextDiv>
      </WrapDiv>
      <WrapDiv style={{ justifyContent: "space-between", marginTop: 320 }}>
        <NewServiceDiv style={{ marginTop: 0 }}>
          <PickupMainOrageText>Step Guide</PickupMainOrageText>

          <NewServiceText style={{ marginTop: 16 }}>
            차량 픽업, 정비 딜리버리 까지 <br />
            집에서 편하게 이용해보세요.
          </NewServiceText>
        </NewServiceDiv>
        <StepImgWrapDiv>
          <StepImgDiv>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepImg src={Camera} alt="camera" />
              <PickupStepGuideText>Step.1</PickupStepGuideText>
            </div>
            <PickUpStepSubText>
              차량의 수리가 필요한 부위를 사진과 함께 남겨주세요.
            </PickUpStepSubText>
          </StepImgDiv>
          <StepImgDiv>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepImg src={step2} alt="camera" />
              <PickupStepGuideText>Step.2</PickupStepGuideText>
            </div>
            <PickUpStepSubText>
              상담원과 유선상담 후 차량을 픽업합니다.
            </PickUpStepSubText>
          </StepImgDiv>
          <StepImgDiv>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepImg src={step3} alt="camera" />

              <PickupStepGuideText>Step.3</PickupStepGuideText>
            </div>
            <PickUpStepSubText>
              정밀검진 후 확정된 견적과 정비예정일을 알려드립니다.
            </PickUpStepSubText>
          </StepImgDiv>
          <StepImgDiv style={{ borderBottom: "1px solid #868E96" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepImg src={step4} alt="camera" />

              <PickupStepGuideText>Step.4</PickupStepGuideText>
            </div>
            <PickUpStepSubText>
              수리완료 후 고객님이 원하시는 곳으로 딜리버리 합니다.
            </PickUpStepSubText>
          </StepImgDiv>

          <TextDiv
            style={{
              marginTop: 120,
              width: "auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <PickupStepGuideText2>
                지금 바로 서비스를 이용해보세요.
              </PickupStepGuideText2>
              <PickUpFooterText2>
                가장 편리한 자동차 수리, 탈것에서 시작하세요!
              </PickUpFooterText2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
              }}
              onMouseOver={() => {
                setColor("#fff");
                setWidth("185px");
              }}
              onMouseOut={() => {
                setColor("#212529");
                setWidth("48px");
              }}
            >
              <Circle2 width={width} />
              <PickUpApplyText
                color={color}
                onClick={() => navigate("/pickUpApply")}
              >
                신청 바로가기{" "}
                <PickUpApplyText
                  color={color}
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    display: "inline-block",
                  }}
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&gt;
                </PickUpApplyText>
              </PickUpApplyText>
            </div>
          </TextDiv>
        </StepImgWrapDiv>
      </WrapDiv>
    </PickUpMainWrapDiv>
  );
};

export default PickUpMainStyle;
