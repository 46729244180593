import styled from "@emotion/styled/macro";
import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router";
import { WrapDiv, CommonButton } from "../../components/CommonStyle";
import Header from "../../components/Header";
import { ReactComponent as Check } from "../../assets/icons/PaymentCheck.svg";

const PaymentDiv = styled.div`
  width: 600px;
  margin: 80px auto 0 auto;
  align-items: center;
  justify-content: center;
  font-family: SpoqaHanSansNeo;
`;

const PaymentCompleteTitleDiv = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const SuccessImg = styled(Check)`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`;

const PaymentTitle = styled.span`
  font-size: 30px;
  font-weight: 500;
  font-family: SpoqaHanSansNeo;
  color: #212529;
  letter-spacing: -1.2px;
`;

const PaymentSubTitle = styled.span`
  font-size: 18px;
  color: #808080;
  font-weight: 500;
  letter-spacing: -0.72px;
  font-family: SpoqaHanSansNeo;
`;

const PaymentLine = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PayInfoDiv = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PayInfoText = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #212529;
  letter-spacing: -0.64px;
  font-family: SpoqaHanSansNeo;
`;

const PaymentComplete = () => {
  const position = false;
  const textBold = true;
  const { payMethod } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <Header position={position} textBold={textBold} />
      <WrapDiv>
        <PaymentDiv>
          <PaymentCompleteTitleDiv>
            <SuccessImg />
            <PaymentTitle>정비신청이 완료되었습니다.</PaymentTitle>
          </PaymentCompleteTitleDiv>
          <PaymentCompleteTitleDiv style={{ marginTop: 20 }}>
            <PaymentSubTitle>결제 요청이 완료되었습니다.{'\n'}입금이 완료되면 정비가 시작될 예정입니다.</PaymentSubTitle>
          </PaymentCompleteTitleDiv>
          <PaymentLine style={{ marginTop: 65 }} />
          <div
            style={{
              width: " 100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PayInfoDiv style={{ marginTop: 100 }}>
              <PaymentSubTitle>결제 정보 </PaymentSubTitle>
              {payMethod === "1" ? (
                <PayInfoText>계좌이체 </PayInfoText>
              ) : (
                <PayInfoText>카드결제 </PayInfoText>
              )}
            </PayInfoDiv>

            <PayInfoDiv>
              {payMethod === "1" ? (
                <>
                  <PaymentSubTitle>입금 계좌 </PaymentSubTitle>
                  <PayInfoText>{state.bank} </PayInfoText>
                </>
              ) : null}
            </PayInfoDiv>
            {payMethod === "1" ? (
              <>
                <PayInfoDiv>
                  <PaymentSubTitle>예금주 </PaymentSubTitle>
                  <PayInfoText>{state.depositor}</PayInfoText>
                </PayInfoDiv>
                <PayInfoDiv>
                  <PaymentSubTitle>입금 기간 </PaymentSubTitle>
                  <PayInfoText>2021년 12월 31일 까지</PayInfoText>
                </PayInfoDiv>
              </>
            ) : null}
            <PayInfoDiv>
              <PaymentSubTitle>금액</PaymentSubTitle>
              <PayInfoText>
                {Number(state.totalPrice).toLocaleString("en")} 원
              </PayInfoText>
            </PayInfoDiv>

            <CommonButton
              style={{ marginTop: 335, marginBottom: 136 }}
              onClick={() => navigate(`/pickupDetail`)}
            >
              신청 현황
            </CommonButton>
          </div>
        </PaymentDiv>
      </WrapDiv>
    </>
  );
};

export default PaymentComplete;
