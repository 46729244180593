import styled from "@emotion/styled/macro";

export const WrapDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CommonButton = styled.button`
  border: none;
  width: 240px;
  height: 70px;
  background-color: #ff6a13;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  border-radius: 10px;
  cursor: pointer;
  @media (max-height: 760px) {
    margin-bottom: 50px;
  }
`;

export const BASIC_URL = "https://api.talgut.com";
