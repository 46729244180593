import axios from "axios";
import moment from "moment";
import Header from "../../components/Header";
import { useNavigate } from "react-router";
import Loading from "react-loading";
import { useEffect, useState } from "react";
import { HeaderMenuBar, HeaderMenuText } from "../../components/Header";
import { BASIC_URL } from "../../components/CommonStyle";
import { SelfSellCarStepModal } from "../../components/login&join/Modals";
import { useToken } from "../../hooks/useToken";
import {
  SelfSellCarWrapDiv,
  SelfSellCarApplyTextDiv,
  SelfSellCarApplyText,
  SelfSellCarApplySubText,
  SelfSellCarListDiv,
  SelfSellCarApplyLabel,
  SelfSellCarLine,
} from "../../components/selfSellCar/SelfSellCarStyle";
import {
  CarStorageListDiv,
  CarStorageListHeaderDiv,
  CarStorageStatusDiv,
  CarStorageListText,
  CatStorageStatusLine,
  CarStorageStatusInnerDiv,
  CarStorageStatusText,
} from "../../components/carStorage/CarStorageStyle";
import { ReactComponent as StepIcon } from "../../assets/img/stepicon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/ListArrow.svg";

const SelfSellCarList = () => {
  const navigate = useNavigate();
  const position = false;
  const selfSellCarText = true;
  const hidden = false;
  const bold = true;
  const token = useToken();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!token) {
      navigate("/login");
    } else {
      axios({
        url: `${BASIC_URL}/selfsellcar/mine`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.data) {
          setUserSelfSellCarList(res.data.selfSellcars);
          setIsLoading(false);
        }
      });
    }
    return () => setUserSelfSellCarList();
  }, [navigate, token]);

  const [userSelfSellCarList, setUserSelfSellCarList] = useState();

  const selfSellCarApplyList = userSelfSellCarList?.map((list) => {
    return (
      <CarStorageListDiv
        key={list.id}
        onClick={() => navigate(`/selfSellCarStep/${list.id}`)}
      >
        <CarStorageListHeaderDiv>
          <CarStorageListText>{list.carNumber}</CarStorageListText>
          <Arrow />
        </CarStorageListHeaderDiv>
        <CarStorageStatusDiv>
          <CarStorageStatusInnerDiv>
            <CarStorageStatusText color="#868E96">
              진행상태
            </CarStorageStatusText>
            <CarStorageStatusText color="#212529">
              {list.status}
            </CarStorageStatusText>
          </CarStorageStatusInnerDiv>
          <CarStorageStatusInnerDiv>
            <CarStorageStatusText color="#868E96">신청일</CarStorageStatusText>
            <CarStorageStatusText color="#212529">
              {moment(list.createdAt).format("YYYY.MM.DD")}
            </CarStorageStatusText>
          </CarStorageStatusInnerDiv>
        </CarStorageStatusDiv>
        <CatStorageStatusLine />
        <CarStorageStatusDiv>
          <CarStorageStatusInnerDiv>
            <CarStorageStatusText color="#868E96">
              신청인&nbsp;&nbsp;&nbsp;&nbsp;
            </CarStorageStatusText>
            <CarStorageStatusText color="#212529">
              {list.name}
            </CarStorageStatusText>
          </CarStorageStatusInnerDiv>
        </CarStorageStatusDiv>
      </CarStorageListDiv>
    );
  });

  const [selfSellCarStepModal, setSelfSellCarStepModal] = useState(false);

  return (
    <SelfSellCarWrapDiv>
      <SelfSellCarStepModal
        selfSellCarStepModal={selfSellCarStepModal}
        setSelfSellCarStepModal={setSelfSellCarStepModal}
      />
      <Header position={position} selfSellCarText={selfSellCarText} />
      <HeaderMenuBar hidden={hidden}>
        <HeaderMenuText
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/selfSellCarApply")}
        >
          셀프 셀카 신청
        </HeaderMenuText>
        <HeaderMenuText
          style={{ marginLeft: 28, cursor: "pointer" }}
          onClick={() => navigate("/selfSellCarList")}
          bold={bold}
        >
          나의 신청 현황
        </HeaderMenuText>
      </HeaderMenuBar>
      <SelfSellCarApplyTextDiv style={{ marginTop: 40 }}>
        <SelfSellCarApplyText>셀프 셀카 나의 신청 현황</SelfSellCarApplyText>
        <SelfSellCarApplySubText>
          나의 신청 현황을 확인해보세요.
        </SelfSellCarApplySubText>
      </SelfSellCarApplyTextDiv>
      <SelfSellCarLine style={{ marginTop: 60 }} />
      <SelfSellCarListDiv justify onClick={() => setSelfSellCarStepModal(true)}>
        <SelfSellCarApplyLabel>진행단계</SelfSellCarApplyLabel>
        <StepIcon style={{ marginLeft: 8 }} />
      </SelfSellCarListDiv>

      <SelfSellCarListDiv>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" width="100px" height="100px" color="#ff6a13" />
          </div>
        ) : (
          selfSellCarApplyList
        )}
      </SelfSellCarListDiv>
    </SelfSellCarWrapDiv>
  );
};

export default SelfSellCarList;
