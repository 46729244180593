import axios from "axios";
import { Tabs, Slider } from "antd";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { ReactComponent as Icon } from "../../../assets/icons/inpsection/inpsection_01.svg";
import { ReactComponent as Icon2 } from "../../../assets/icons/inpsection/inspection_02.svg";
import { ReactComponent as Icon3 } from "../../../assets/icons/inpsection/inspection_03.svg";
import { ReactComponent as Icon4 } from "../../../assets/icons/inpsection/inspection_04.svg";
import { ReactComponent as Icon5 } from "../../../assets/icons/inpsection/inspection_05.svg";
import { ReactComponent as Icon6 } from "../../../assets/icons/inpsection/inspection_06.svg";
import { ReactComponent as Icon7 } from "../../../assets/icons/inpsection/inspection_07.svg";
import { ReactComponent as Icon8 } from "../../../assets/icons/inpsection/inspection_08.svg";
import { ReactComponent as Icon9 } from "../../../assets/icons/inpsection/inspection_09.svg";
import { ReactComponent as Icon10 } from "../../../assets/icons/inpsection/inspection_10.svg";
import { JoinCheckbox } from "../../../components/login&join/Joinstyle";
import {
  SelfSellCarDataDiv,
  CarInsuranceDiv,
  CarInsuraceInfoDiv,
  HistorySubText,
  DropShippingDiv,
  CarInsuranceCountDiv,
  SelfSellCarApplySuccessText,
  TextContainer,
  AdvertisingText,
  ButtonContainer,
  UserSelectContainer,
  DropShippingModalOpenText,
  ConfirmText2,
  SelfSellCarStepBarDiv,
  ComfirmLink,
  SelfSellCarInputContainer,
  ReturnPriceTextDiv,
  InspectionText,
  TableMenuDiv,
  ConfirmCarBox,
  CarInsuranceCountTextDiv,
  TableMenu,
  SelfSellCarApplyMenuText,
  SelfSellCarApplyButton,
  TableContent,
  SelfSellCarResult,
  SelfSellCarListDiv,
  AuctionInfoInput,
  AuctionMenuDiv,
  AuctionButton,
  AuctionInfoContainer,
  AuctionInfoDiv,
  SelfSellCarStepSubText,
  TableContentDiv,
  SelfSellCarText2,
  SelfSellCarContainer,
  DropShippingContainer,
  ReviewText,
  DropShippingText,
  ConfirmText,
  AuctionText,
  CloseInspectionContainer,
  ConfirmCarContainer,
  CarInspectionImg,
} from "../../../components/selfSellCar/SelfSellCarStyle";
import {
  CategoryText,
  CheckBoxContainer,
  ContentBox,
  InspecionIconContainer,
  InspectionText as ContentText,
  IconText,
} from "../../../components/InspectionImage";
import { AutoOutletText2 } from "../../../components/autoOutlet/AutoOutletStyle";
import { ReactComponent as Mark } from "../../../assets/img/pickupmark.svg";
import {
  ReviewTextArea,
  ReviewDiv,
} from "../../../components/pick-up/PickUpStyle";
import {
  CarStorageApplyTextDiv,
  CarStorageListWrapDiv,
  CarStorageText4,
} from "../../../components/carStorage/CarStorageStyle";
import {
  AuctionModal,
  DropshippingModal,
} from "../../../components/myPage/MyPageModal";
import Deduction from "../../../assets/img/Deduction.svg";
import {
  DropshippingRequestModal,
  RequestCostModal,
} from "../../../components/selfSellCar/SelfSellCarModals";
import "../../../components/selfSellCar/SelfSellCar.css";
import { BASIC_URL } from "../../../components/CommonStyle";
import { useToken } from "../../../hooks/useToken";
import { SelfSellCarReviewModal } from "../../../components/login&join/Modals";

// 상품화 단게 TabBar
const CarInfoTabBar = ({ selfSellCarData }) => {
  const { TabPane } = Tabs;
  const { id } = useParams();
  const token = useToken();
  const [openAuctionModal, setOpenAuctionModal] = useState(false);
  const [openDropshippingModal, setOpenDropshippingModal] = useState(false);
  const [openDropShippingRequestModal, setOpenDropShippingRequestModal] =
    useState(false);
  const [CostModalOpen, setCostModalOpen] = useState(false);

  // 보험이력 데이터
  const { insuranceHistory } = selfSellCarData;

  const { carInsepction } = selfSellCarData;
  const { sortedData } = carInsepction;

  // 상품화 결과 이미지 전후
  const { productizing } = selfSellCarData || [];
  const { image } = productizing || [];

  // 경매 희망가
  const [hopePrice, setHopePrice] = useState("");

  // 차감액
  const deductionText = `${Number(
    selfSellCarData?.productDetails?.totalPrice
  ).toLocaleString("en")}원`;

  // 즉시 매입가 가져오기
  const [purchasePrice, setPurchasePrice] = useState(0);
  useEffect(() => {
    if (selfSellCarData) {
      if (
        selfSellCarData?.status === "상품화완료" ||
        selfSellCarData?.status === "경매신청"
      ) {
        axios({
          url: `${BASIC_URL}/selfsellcar/${id}/auction/beforedata`,
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            if (res.data) {
              setPurchasePrice(res.data.nowSellPrice);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    return () => setPurchasePrice(0);
  }, [id, token, selfSellCarData]);

  // 정산 예정가
  const [returnPrice, setReturnPrice] = useState(null);
  useEffect(() => {
    if (hopePrice && deductionText) {
      setReturnPrice(
        hopePrice - (selfSellCarData?.productDetails?.totalPrice + 55000)
      );
    }
  }, [hopePrice, deductionText, selfSellCarData]);

  // 경매 신청 버튼 활성화
  const [auctionApplyButton, setAuctionApplyButton] = useState(true);

  useEffect(() => {
    console.log(hopePrice);
    console.log(parseInt(selfSellCarData?.productDetails?.totalPrice) + 55000);

    if (
      hopePrice &&
      parseInt(hopePrice) >
        parseInt(selfSellCarData?.productDetails?.totalPrice) + 55000
    ) {
      setAuctionApplyButton(false);
    } else {
      setAuctionApplyButton(true);
    }
  }, [hopePrice, selfSellCarData]);

  // 경매 요청가가 즉시 매입가 보다 낮을시
  const applyAuctionmodalOpen = () => {
    if (hopePrice < purchasePrice) {
      alert("즉시 매입가 보다 높은 경매 요청가를 입력해주세요.");
    } else {
      setOpenAuctionModal(true);
    }
  };

  // 경매 결과 가져오기
  const [auctionResult, setAuctionResult] = useState("");
  useEffect(() => {
    if (
      selfSellCarData?.status === "경매완료" ||
      (selfSellCarData?.status.includes("위탁판매") &&
        selfSellCarData?.auctionDone &&
        selfSellCarData?.status === "정산완료")
    ) {
      axios({
        url: `${BASIC_URL}/selfsellcar/${id}/auction/result`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setAuctionResult(res.data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, [id, token, selfSellCarData]);

  // 위탁판매 Data 가져오기
  const { advertisement } = selfSellCarData ?? [];
  const { consignmentSales } = selfSellCarData ?? [];

  const [settlementInfo, setSettlementInfo] = useState();

  useEffect(() => {
    if (selfSellCarData?.status === "정산완료") {
      axios({
        url: `${BASIC_URL}/selfsellcar/${id}/information/payment`,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setSettlementInfo(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => setSettlementInfo();
  }, [id, token, selfSellCarData]);

  // 리뷰작성하기
  const [review, setReview] = useState("");
  const [reviewButton, setReviewButton] = useState(true);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  useEffect(() => {
    if (review) {
      setReviewButton(false);
    } else {
      setReviewButton(true);
    }
  }, [review]);

  const selfSellCarReview = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/review`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: {
        review,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert("리뷰 작성 완료");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reApplyAuction = () => {
    axios({
      url: `${BASIC_URL}/selfsellcar/${id}/status/productizing/done`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data) {
        window.location.reload();
      }
    });
  };

  return (
    <SelfSellCarDataDiv>
      <DropshippingModal
        setOpenDropshippingModal={setOpenDropshippingModal}
        openDropshippingModal={openDropshippingModal}
        openDropShippingRequestModal={openDropShippingRequestModal}
        setOpenDropShippingRequestModal={setOpenDropShippingRequestModal}
      />
      <DropshippingRequestModal
        id={id}
        totalPrice={selfSellCarData?.productDetails?.totalPrice}
        openDropShippingRequestModal={openDropShippingRequestModal}
        setOpenDropShippingRequestModal={setOpenDropShippingRequestModal}
      />
      <AuctionModal
        setOpenAuctionModal={setOpenAuctionModal}
        openAuctionModal={openAuctionModal}
        hopePrice={hopePrice}
        id={id}
      />
      <SelfSellCarReviewModal
        reviewModalOpen={reviewModalOpen}
        setReviewModalOpen={setReviewModalOpen}
        id={id}
        selfSellCarReview={selfSellCarReview}
      />

      <Tabs defaultActiveKey="점검 결과" tabBarGutter={30}>
        {/* <TabPane tab="보험 이력" key="보험이력">
          <CarInsuranceDiv>
            <CarInsuraceInfoDiv>
              <HistorySubText insuranceText>자세히 보기</HistorySubText>
            </CarInsuraceInfoDiv>
            <CarInsuranceCountDiv>
              <CarInsuranceCountTextDiv>
                <SelfSellCarApplyMenuText>
                  {insuranceHistory?.damageToMyCar}건
                </SelfSellCarApplyMenuText>
              </CarInsuranceCountTextDiv>
              <CarInsuranceCountTextDiv>
                <SelfSellCarApplyMenuText>
                  {insuranceHistory?.hitCar}건
                </SelfSellCarApplyMenuText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <SelfSellCarApplyMenuText>
                  {insuranceHistory?.repurposed}건
                </SelfSellCarApplyMenuText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <SelfSellCarApplyMenuText>
                  {insuranceHistory?.ownerChange}건
                </SelfSellCarApplyMenuText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <SelfSellCarApplyMenuText>
                  {insuranceHistory?.flooding}건
                </SelfSellCarApplyMenuText>
              </CarInsuranceCountTextDiv>
            </CarInsuranceCountDiv>
            <CarInsuranceCountDiv margin>
              <CarInsuranceCountTextDiv>
                <HistorySubText style={{ marginLeft: 0 }}>
                  내 차 피해
                </HistorySubText>
              </CarInsuranceCountTextDiv>
              <CarInsuranceCountTextDiv>
                <HistorySubText style={{ marginLeft: 0 }}>
                  타 차 가해
                </HistorySubText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <HistorySubText style={{ marginLeft: 0 }}>
                  용도 이력{" "}
                </HistorySubText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <HistorySubText style={{ marginLeft: 0 }}>
                  번호/소유자 변경
                </HistorySubText>
              </CarInsuranceCountTextDiv>{" "}
              <CarInsuranceCountTextDiv>
                <HistorySubText style={{ marginLeft: 0 }}>
                  전손/침수
                </HistorySubText>
              </CarInsuranceCountTextDiv>
            </CarInsuranceCountDiv>
          </CarInsuranceDiv>
        </TabPane> */}

        <TabPane tab="점검 결과" key="점검결과">
          <SelfSellCarContainer>
            <InspectionText>자동차 상태 표기</InspectionText>
            <InspecionIconContainer style={{ width: "auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: 518,
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon /> <IconText>교환</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon2 /> <IconText>판금/용접</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon3 /> <IconText>교환필요</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon4 /> <IconText>판금필요</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Icon5 /> <IconText>조정필요</IconText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 14,
                  width: 518,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon6 /> <IconText>도장</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon7 /> <IconText>도장필요 </IconText>{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 22,
                    flex: 1,
                  }}
                >
                  <Icon8 /> <IconText>꺾임</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 22,
                  }}
                >
                  <Icon9 /> <IconText>부식</IconText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Icon10 /> <IconText>상처</IconText>
                </div>
              </div>
            </InspecionIconContainer>
          </SelfSellCarContainer>
          <CarInspectionImg src={carInsepction?.insepectionImage} alt="" />

          <CloseInspectionContainer>
            <CategoryText>
              • &nbsp;외판부위의 교환, 부식, 판금 및 용접 수리
            </CategoryText>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[0].hood?.check}
                />
                <ContentText active={sortedData[0].hood?.check}>
                  1. 후드
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  unactive={!sortedData[1].frontHenderL?.check}
                  className="selfsellcar_checkbox"
                />
                <ContentText active={sortedData[1].frontHenderL?.check}>
                  2. 프론트 휀더(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[2].frontHenderR?.check}
                />
                <ContentText active={sortedData[2].frontHenderR?.check}>
                  3. 프론트 휀더(우)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[3].frontDoorL?.check}
                />
                <ContentText active={sortedData[3].frontDoorL?.check}>
                  4. 프론트 도어(좌)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[4].frontDoorR?.check}
                />
                <ContentText active={sortedData[4].frontDoorR?.check}>
                  5. 프론트 도어(우)
                </ContentText>
              </ContentBox>

              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[5].rearDoorL?.check}
                />
                <ContentText active={sortedData[5].rearDoorL?.check}>
                  6. 리어 도어(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[6].rearDoorR?.check}
                />
                <ContentText active={sortedData[6].rearDoorR?.check}>
                  7. 리어 도어(우)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[7].trunkRid?.check}
                />
                <ContentText active={sortedData[7].trunkRid?.check}>
                  8. 트렁크리드
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[8].radiatorSupport?.check}
                />
                <ContentText active={sortedData[8].radiatorSupport?.check}>
                  9. 라디에이터 서포트
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[9].loopPanel?.check}
                />
                <ContentText active={sortedData[9].loopPanel?.check}>
                  10. 루프 패널
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[10].quarterPanelL?.check}
                />
                <ContentText active={sortedData[10].quarterPanelL?.check}>
                  11. 쿼터 패널(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[11].quarterPanelR?.check}
                />
                <ContentText active={sortedData[11].quarterPanelR?.check}>
                  12. 쿼터 패널(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[12].sideSealPanelL?.check}
                />
                <ContentText active={sortedData[12].sideSealPanelL?.check}>
                  13. 사이드실 패널(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[13].sideSealPanelR?.check}
                />
                <ContentText active={sortedData[13].sideSealPanelR?.check}>
                  14. 사이드실 패널(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CategoryText style={{ marginTop: 67 }}>
              • &nbsp;주요골격 부위의 교환,부식,판금 및 용접수리
            </CategoryText>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[14].frontPanel?.check}
                />
                <ContentText active={sortedData[14].frontPanel?.check}>
                  15. 프론트 패널
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[15].crossMember?.check}
                />
                <ContentText active={sortedData[15].crossMember?.check}>
                  16. 크로스 멤버
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[16].insidePanelL?.check}
                />
                <ContentText active={sortedData[16].insidePanelL?.check}>
                  17. 인사이드 패널(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[17].insidePanelR?.check}
                />
                <ContentText active={sortedData[17].insidePanelR?.check}>
                  18. 인사이드 패널(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[18].rearPanel?.check}
                />
                <ContentText active={sortedData[18].rearPanel?.check}>
                  19. 리어 패널
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[19].trunkFloor?.check}
                />
                <ContentText active={sortedData[19].trunkFloor?.check}>
                  20. 트렁크 플로어
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[20].frontSideMemberL?.check}
                />
                <ContentText active={sortedData[20].frontSideMemberL?.check}>
                  21. 프론트 사이드 멤버(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[21].frontSideMemberR?.check}
                />
                <ContentText active={sortedData[21].frontSideMemberR?.check}>
                  22. 프론트 사이드 멤버(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[22].rearSideL?.check}
                />
                <ContentText active={sortedData[22].rearSideL?.check}>
                  23. 리어 사이드 멤버(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[23].rearSideR?.check}
                />
                <ContentText active={sortedData[23].rearSideR?.check}>
                  24. 리어 사이드 멤버(우)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[24].frontWheelL?.check}
                />
                <ContentText active={sortedData[24].frontWheelL?.check}>
                  25. 프론트 휠하우스(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[25].frontWheelR?.check}
                />
                <ContentText active={sortedData[25].frontWheelR?.check}>
                  26. 프론트 휠하우스 (우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[26].rearWheelL?.check}
                />
                <ContentText active={sortedData[26].rearWheelL?.check}>
                  27. 리어 휠하우스(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[27].rearWheelR?.check}
                />
                <ContentText active={sortedData[27].rearWheelR?.check}>
                  28. 리어 휠하우스(우)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[28].fillerAL?.check}
                />
                <ContentText active={sortedData[28].fillerAL?.check}>
                  29. 필러 패널A(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[29].fillerAR?.check}
                />
                <ContentText active={sortedData[29].fillerAR?.check}>
                  30. 필러 패널A(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[30].fillerBL?.check}
                />
                <ContentText active={sortedData[30].fillerBL?.check}>
                  31. 필러 패널B(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[31].fillerBR?.check}
                />
                <ContentText active={sortedData[31].fillerBR?.check}>
                  32. 필러 패널B(우)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[32].fillerCL?.check}
                />
                <ContentText active={sortedData[32].fillerCL?.check}>
                  33. 필러 패널C(좌)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[33].fillerCR?.check}
                />
                <ContentText active={sortedData[33].fillerCR?.check}>
                  34. 필러 패널C(우)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>

            <CheckBoxContainer style={{ justifyContent: "flex-start" }}>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[34].packageTray?.check}
                />
                <ContentText active={sortedData[34].packageTray?.check}>
                  35. 패키지트레이
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[35].dashPanel?.check}
                />
                <ContentText active={sortedData[35].dashPanel?.check}>
                  36. 대쉬 패널
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[36].floorPanel?.check}
                />
                <ContentText active={sortedData[36].floorPanel?.check}>
                  37. 플로어 패널(바닥)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[37].frontGlass?.check}
                />
                <ContentText active={sortedData[37].frontGlass?.check}>
                  38. 앞 유리
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[38].backGlass?.check}
                />
                <ContentText active={sortedData[38].backGlass?.check}>
                  39. 뒷 유리
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[39].rearLeftFrontGlass?.check}
                />
                <ContentText active={sortedData[39].rearLeftFrontGlass?.check}>
                  40. 옆 유리 (운전석 앞)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[40].rearLeftBackGlass?.check}
                />
                <ContentText active={sortedData[40].rearLeftBackGlass?.check}>
                  41. 옆 유리 (운전석 뒤)
                </ContentText>
              </ContentBox>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[41].rearRightFrontGlass?.check}
                />
                <ContentText active={sortedData[41].rearRightFrontGlass?.check}>
                  42. 옆 유리 (조수석 앞)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
            <CheckBoxContainer>
              <ContentBox>
                <JoinCheckbox
                  className="selfsellcar_checkbox"
                  unactive={!sortedData[42].rearRightBackGlass?.check}
                />
                <ContentText active={sortedData[42].rearRightBackGlass?.check}>
                  43. 옆 유리 (조수석 뒤)
                </ContentText>
              </ContentBox>
            </CheckBoxContainer>
          </CloseInspectionContainer>

          <SelfSellCarApplyMenuText style={{ marginTop: 120 }}>
            상품 내역
          </SelfSellCarApplyMenuText>
          <TableMenuDiv>
            <TableMenu>
              <InspectionText>옵션</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>상품</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>가격</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>총 예상 소요일</InspectionText>
            </TableMenu>
            <TableMenu>
              <InspectionText>총 금액</InspectionText>
            </TableMenu>
          </TableMenuDiv>
          <TableContentDiv>
            <TableContent>
              <ConfirmText style={{ marginTop: 12, marginBottom: 12 }}>
                {selfSellCarData?.productDetails?.option
                  ? selfSellCarData?.productDetails?.option
                  : "선택형(고객)"}
              </ConfirmText>
            </TableContent>
            <TableContent>
              {selfSellCarData?.productDetails?.inspect &&
                selfSellCarData?.productDetails?.inspect?.map((option) => {
                  return (
                    <ConfirmText style={{ marginTop: 12, marginBottom: 12 }}>
                      {option?.name}
                    </ConfirmText>
                  );
                })}
            </TableContent>
            <TableContent>
              {selfSellCarData?.productDetails?.inspect &&
                selfSellCarData?.productDetails?.inspect?.map((option) => {
                  return (
                    <ConfirmText style={{ marginTop: 12, marginBottom: 12 }}>
                      {Number(option?.totalPrice).toLocaleString("en")}원
                    </ConfirmText>
                  );
                })}
            </TableContent>
            <TableContent>
              <DropShippingText>
                {selfSellCarData?.productDetails?.totalDay
                  ? selfSellCarData?.productDetails?.totalDay
                  : 0}
                일
              </DropShippingText>
            </TableContent>
            <TableContent>
              <DropShippingText>
                {selfSellCarData?.productDetails?.totalPrice
                  ? `${Number(
                      selfSellCarData?.productDetails?.totalPrice
                    ).toLocaleString("en")}원`
                  : "0원"}
              </DropShippingText>
            </TableContent>
          </TableContentDiv>
        </TabPane>

        {selfSellCarData?.status === "상품화완료" ||
        selfSellCarData?.status === "경매신청" ||
        selfSellCarData?.status === "경매완료" ||
        selfSellCarData?.status.includes("위탁판매") ||
        selfSellCarData?.status === "정산완료" ? (
          <TabPane tab="상품화 결과" key="상품화결과">
            <>
              {image?.map((repairResult, index) => {
                return (
                  <>
                    <SelfSellCarContainer key={index}>
                      <SelfSellCarApplyMenuText>
                        {repairResult?.name} (전 - 후)
                      </SelfSellCarApplyMenuText>
                    </SelfSellCarContainer>
                    <CarInsuranceCountDiv margin key={repairResult?.id}>
                      <SelfSellCarResult
                        alt="result"
                        src={repairResult?.imageBefore}
                      />
                      <SelfSellCarResult
                        alt="result"
                        src={repairResult?.imageAfter}
                      />
                    </CarInsuranceCountDiv>
                  </>
                );
              })}
              {selfSellCarData?.status !== "상품화완료" ? null : (
                <SelfSellCarText2>
                  • 상품화가 완료된 차량은 빠른 판매를 위해 경매에 1차 출품
                  되며, 경매에서 고객희망가에 도달하지 못 할 경우, 소매 판매로
                  진행됩니다.
                  <br />
                  경매 단계를 거치지 않고 소매 판매를 진행하실 경우 “
                  <DropShippingModalOpenText
                    onClick={() => setOpenDropshippingModal(true)}
                  >
                    위탁 판매 신청
                  </DropShippingModalOpenText>
                  ”을 클릭해주세요.
                </SelfSellCarText2>
              )}

              {selfSellCarData?.status !== "상품화완료" ? null : (
                <>
                  <SelfSellCarDataDiv margin>
                    <SelfSellCarApplyMenuText>
                      경매 출품 정보
                    </SelfSellCarApplyMenuText>
                    <SelfSellCarText2 color="true" style={{ marginLeft: 20 }}>
                      즉시 매입가 보다 높은 경매 희망가를 입력해 주세요.{" "}
                      <SelfSellCarText2 style={{ marginTop: 0 }}>
                        (현재 즉시 매입가는{" "}
                        {Number(purchasePrice).toLocaleString("en")}원 입니다.)
                      </SelfSellCarText2>
                    </SelfSellCarText2>
                    <AuctionInfoDiv>
                      <AuctionMenuDiv>
                        <SelfSellCarStepSubText auctionMenu>
                          경매 희망가
                        </SelfSellCarStepSubText>
                      </AuctionMenuDiv>

                      <AuctionInfoInput
                        placeholder="경매 희망가 입력"
                        autoComplete="off"
                        value={hopePrice}
                        onChange={(event) => setHopePrice(event.target.value)}
                      />

                      <AuctionMenuDiv>
                        <SelfSellCarStepSubText auctionMenu>
                          경매 결과
                        </SelfSellCarStepSubText>
                      </AuctionMenuDiv>
                      <AuctionInfoInput
                        value="경매 완료 후 확인 가능합니다."
                        autoComplete="off"
                        readOnly
                      />
                    </AuctionInfoDiv>
                    <AuctionInfoDiv style={{ marginTop: 0 }}>
                      <AuctionMenuDiv>
                        <SelfSellCarStepSubText auctionMenu>
                          차감액
                        </SelfSellCarStepSubText>

                        {/* <Mark
                          style={{
                            marginLeft: 7,
                            cursor: "pointer",
                          }}
                        />
                        <img
                          src={Deduction}
                          alt="deduciton"
                          style={{
                            position: "absolute",
                            top: 45,
                            left: -5,
                            width: 270,
                          }}
                        /> */}
                      </AuctionMenuDiv>
                      <AuctionInfoInput
                        value={deductionText}
                        readOnly
                        autoComplete="off"
                      />
                      <AuctionMenuDiv>
                        <SelfSellCarStepSubText auctionMenu>
                          정산 예정가
                        </SelfSellCarStepSubText>
                      </AuctionMenuDiv>
                      {returnPrice && returnPrice > 0 ? (
                        <ReturnPriceTextDiv>
                          <AuctionText>
                            {Number(returnPrice).toLocaleString("en")}원
                          </AuctionText>
                        </ReturnPriceTextDiv>
                      ) : (
                        <AuctionInfoInput
                          value="-"
                          autoComplete="off"
                          readOnly
                        />
                      )}
                    </AuctionInfoDiv>
                  </SelfSellCarDataDiv>
                  <CarStorageApplyTextDiv>
                    <SelfSellCarApplyButton
                      disabled={auctionApplyButton}
                      onClick={applyAuctionmodalOpen}
                    >
                      경매 신청
                    </SelfSellCarApplyButton>
                  </CarStorageApplyTextDiv>
                </>
              )}
            </>
          </TabPane>
        ) : null}

        {selfSellCarData?.status === "경매신청" ||
        selfSellCarData?.status === "경매완료" ||
        (selfSellCarData?.status.includes("위탁판매") &&
          selfSellCarData?.auctionDone) ||
        (selfSellCarData?.status === "정산완료" &&
          selfSellCarData?.auctionDone) ? (
          <TabPane tab="경매 출품 정보" key="경매출품정보">
            <SelfSellCarDataDiv>
              <RequestCostModal
                CostModalOpen={CostModalOpen}
                setCostModalOpen={setCostModalOpen}
                willPrice={selfSellCarData?.auctionData?.willPrice}
                id={id}
              />
              <CarStorageListWrapDiv>
                <TextContainer>
                  <SelfSellCarApplyMenuText>
                    경매 출품 정보
                  </SelfSellCarApplyMenuText>
                  {auctionResult &&
                  auctionResult === "유찰" &&
                  selfSellCarData?.auctionable === true ? (
                    <CarStorageText4 style={{ marginLeft: 12 }}>
                      (재경매는 1번만 가능합니다.)
                    </CarStorageText4>
                  ) : null}
                </TextContainer>
                <CarStorageText4>
                  • 광고기간, 저당, 벌금 등에 따라 추가 정산금은 변경될 수
                  있습니다.
                </CarStorageText4>
              </CarStorageListWrapDiv>
              <AuctionInfoDiv style={{ marginTop: 41 }}>
                <AuctionMenuDiv>
                  <SelfSellCarStepSubText auctionMenu>
                    경매 희망가
                  </SelfSellCarStepSubText>
                </AuctionMenuDiv>
                <AuctionInfoContainer>
                  <CarStorageText4>
                    {Number(
                      selfSellCarData?.auctionData?.hopePrice
                    ).toLocaleString("en")}
                    원
                  </CarStorageText4>
                </AuctionInfoContainer>
                <AuctionMenuDiv>
                  <SelfSellCarStepSubText auctionMenu>
                    경매 결과
                  </SelfSellCarStepSubText>
                </AuctionMenuDiv>
                <AuctionInfoContainer>
                  {auctionResult && auctionResult === "유찰" ? (
                    <AuctionText>{auctionResult}</AuctionText>
                  ) : auctionResult === "낙찰" ? (
                    <AuctionText>{auctionResult}</AuctionText>
                  ) : (
                    <CarStorageText4>
                      경매 완료 후 확인 가능합니다.
                    </CarStorageText4>
                  )}
                </AuctionInfoContainer>
              </AuctionInfoDiv>
              <AuctionInfoDiv style={{ marginTop: 0 }}>
                <AuctionMenuDiv>
                  <SelfSellCarStepSubText auctionMenu>
                    차감액
                  </SelfSellCarStepSubText>

                  {/* <Mark
                    style={{
                      marginLeft: 7,
                      cursor: "pointer",
                    }}
                  />
                  <img
                    src={Deduction}
                    alt="deduciton"
                    style={{
                      position: "absolute",
                      top: 45,
                      left: -5,
                      width: 270,
                    }}
                  /> */}
                </AuctionMenuDiv>
                <AuctionInfoInput
                  value={`${Number(
                    selfSellCarData?.auctionData?.totalPrice
                  ).toLocaleString("en")}원`}
                  readOnly
                  autoComplete="off"
                />
                <AuctionMenuDiv>
                  <SelfSellCarStepSubText auctionMenu>
                    정산 예정가
                  </SelfSellCarStepSubText>
                </AuctionMenuDiv>
                <AuctionInfoContainer>
                  {auctionResult && auctionResult === "유찰" ? (
                    <AutoOutletText2>-</AutoOutletText2>
                  ) : (
                    <AuctionText>
                      {Number(
                        selfSellCarData?.auctionData?.willPrice
                      ).toLocaleString("en")}
                      원
                    </AuctionText>
                  )}
                </AuctionInfoContainer>
              </AuctionInfoDiv>
            </SelfSellCarDataDiv>
            <ButtonContainer>
              {/* 경매 유찰 시 정산 신청 >> 재경매 && 위탁 판매 신청 버튼 추가 */}
              {/* <AuctionButton>위탁 판매 신청</AuctionButton> */}
              {selfSellCarData?.status === "경매완료" &&
              auctionResult === "낙찰" ? (
                <AuctionButton
                  background
                  onClick={() => setCostModalOpen(true)}
                >
                  정산 신청
                </AuctionButton>
              ) : selfSellCarData?.status === "경매완료" &&
                auctionResult === "유찰" ? (
                <>
                  <AuctionButton
                    background
                    onClick={() => setOpenDropShippingRequestModal(true)}
                  >
                    위탁 판매 신청
                  </AuctionButton>
                  {selfSellCarData?.auctionable === false &&
                  auctionResult === "유찰" ? null : (
                    <AuctionButton onClick={reApplyAuction}>
                      재경매
                    </AuctionButton>
                  )}
                </>
              ) : null}
            </ButtonContainer>
          </TabPane>
        ) : null}

        {selfSellCarData?.status.includes("위탁판매") ||
        (selfSellCarData?.status === "정산완료" &&
          selfSellCarData?.auctionDone === false) ? (
          <>
            <TabPane tab="위탁 판매 신청 내역" key="위탁판매신청내역">
              <DropShippingContainer>
                <SelfSellCarApplyMenuText>
                  위탁 판매 신청 내역
                </SelfSellCarApplyMenuText>
                <SelfSellCarStepBarDiv column>
                  <DropShippingText>기본 정보</DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        광고 선택
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv>
                      {consignmentSales?.adList?.map((list) => {
                        return (
                          <CarStorageText4 key={list?.id}>
                            {list?.name}
                            &nbsp;({Number(list?.price).toLocaleString("en")}원)
                            &nbsp;
                          </CarStorageText4>
                        );
                      })}
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                  <AuctionInfoDiv style={{ marginTop: 0 }}>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        필수 비용 선택
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4>
                        {Number(
                          consignmentSales?.essentialPrice
                        ).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        계좌 정보
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4>
                        {consignmentSales?.bank} {consignmentSales?.account}
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>
                <SelfSellCarStepBarDiv column>
                  <SelfSellCarListDiv>
                    <DropShippingText>가격 정보</DropShippingText>
                    <CarStorageText4>
                      • 광고기간, 저당, 벌금 등에 따라 추가정산금은 변경될 수
                      있습니다.
                    </CarStorageText4>
                  </SelfSellCarListDiv>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        판매 희망가
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4>
                        {Number(consignmentSales?.hopePrice).toLocaleString(
                          "en"
                        )}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        선 지급금
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4>
                        {Number(
                          consignmentSales?.advancePayment
                        ).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                  <AuctionInfoDiv style={{ marginTop: 0 }}>
                    <AuctionMenuDiv>
                      {/* <SelfSellCarStepSubText auctionMenu>
                        차감액
                      </SelfSellCarStepSubText>
                      <Mark
                        style={{
                          marginLeft: 7,
                          cursor: "pointer",
                        }}
                      /> */}
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4>
                        {Number(
                          selfSellCarData?.productDetails?.totalPrice
                        ).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText auctionMenu>
                        추가 정산금
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv width="true">
                      <CarStorageText4 style={{ fontWeight: 700 }}>
                        {Number(
                          parseInt(consignmentSales?.hopePrice) -
                            (parseInt(consignmentSales?.essentialPrice) +
                              parseInt(consignmentSales?.advancePayment) +
                              parseInt(
                                selfSellCarData?.productDetails?.totalPrice +
                                  55000
                              ))
                        ).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>
              </DropShippingContainer>
            </TabPane>
            <TabPane tab="내 차 확인하기" key="내차확인하기">
              <DropShippingContainer>
                <ConfirmCarContainer>
                  <SelfSellCarApplyMenuText>
                    내 차 확인하기
                  </SelfSellCarApplyMenuText>
                  <ConfirmText style={{ marginLeft: 22 }}>
                    내 차의 판매 상태를 확인해보세요. (주 단위로 업데이트
                    됩니다.)
                  </ConfirmText>
                </ConfirmCarContainer>
                <ConfirmCarBox>
                  <ConfirmCarContainer>
                    <DropShippingText>1. 광고 확인</DropShippingText>
                    <ConfirmText2 style={{ marginLeft: 20 }}>
                      (클릭 시 해당 사이트로 이동합니다.)
                    </ConfirmText2>
                  </ConfirmCarContainer>
                  <ConfirmCarContainer style={{ marginTop: 30 }}>
                    {advertisement?.adList?.map((list) => {
                      return (
                        <ComfirmLink
                          key={list?.id}
                          href={list?.url}
                          targee="_blank"
                        >
                          {list.name}
                        </ComfirmLink>
                      );
                    })}
                  </ConfirmCarContainer>
                </ConfirmCarBox>
                <ConfirmCarBox style={{ height: "auto" }}>
                  <DropShippingText>2. 광고 효과</DropShippingText>

                  <SelfSellCarInputContainer style={{ marginTop: 40 }}>
                    <ConfirmText>광고 경과일</ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.day}
                    />

                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.day === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.day === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.day === 60
                        ? "보통"
                        : advertisement?.adEffect?.day === 80
                        ? "좋음"
                        : advertisement?.adEffect?.day === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                  <SelfSellCarInputContainer style={{ marginTop: 20 }}>
                    <ConfirmText>인기도 (조회수)</ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.popularity}
                    />
                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.popularity === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.popularity === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.popularity === 60
                        ? "보통"
                        : advertisement?.adEffect?.popularity === 80
                        ? "좋음"
                        : advertisement?.adEffect?.popularity === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                  <SelfSellCarInputContainer style={{ marginTop: 20 }}>
                    <ConfirmText>차량 가격 (시세 대비)</ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.price}
                    />
                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.price === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.price === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.price === 60
                        ? "보통"
                        : advertisement?.adEffect?.price === 80
                        ? "좋음"
                        : advertisement?.adEffect?.price === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                  <SelfSellCarInputContainer style={{ marginTop: 20 }}>
                    <ConfirmText>
                      시장 상황 (검색자 수 대비 등급 매물)
                    </ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.condition}
                    />
                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.condition === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.condition === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.condition === 60
                        ? "보통"
                        : advertisement?.adEffect?.condition === 80
                        ? "좋음"
                        : advertisement?.adEffect?.condition === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                  <SelfSellCarInputContainer style={{ marginTop: 20 }}>
                    <ConfirmText>유선 문의</ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.qustion}
                    />
                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.qustion === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.qustion === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.qustion === 60
                        ? "보통"
                        : advertisement?.adEffect?.qustion === 80
                        ? "좋음"
                        : advertisement?.adEffect?.qustion === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                  <SelfSellCarInputContainer style={{ marginTop: 20 }}>
                    <ConfirmText>매장 방문</ConfirmText>
                    <Slider
                      className="advertising_slider"
                      value={advertisement?.adEffect?.visit}
                    />
                    <AdvertisingText style={{ marginTop: 13, marginLeft: 10 }}>
                      {advertisement?.adEffect?.visit === 20
                        ? "매우 나쁨"
                        : advertisement?.adEffect?.visit === 40
                        ? "나쁨"
                        : advertisement?.adEffect?.visit === 60
                        ? "보통"
                        : advertisement?.adEffect?.visit === 80
                        ? "좋음"
                        : advertisement?.adEffect?.visit === 100
                        ? "매우좋음"
                        : null}
                    </AdvertisingText>
                  </SelfSellCarInputContainer>
                </ConfirmCarBox>
                <ConfirmCarBox style={{ height: 166 }}>
                  <DropShippingText>3. 종합 의견</DropShippingText>

                  <ConfirmCarContainer>
                    {advertisement?.adEffect?.opinion && (
                      <SelfSellCarApplySuccessText>
                        { advertisement.adEffect.opinion }
                      </SelfSellCarApplySuccessText>
                    )}
                  </ConfirmCarContainer>
                </ConfirmCarBox>
              </DropShippingContainer>
            </TabPane>{" "}
          </>
        ) : null}

        {selfSellCarData?.status === "정산완료" && selfSellCarData?.saleDone ? (
          <>
            <TabPane tab="차량 대금 정산" key="차량대금정산">
              <RequestCostModal
                CostModalOpen={CostModalOpen}
                setCostModalOpen={setCostModalOpen}
                willPrice={selfSellCarData?.auctionData?.willPrice}
                id={id}
              />
              <DropShippingContainer>
                <SelfSellCarApplyMenuText>
                  차량 대금 정산
                </SelfSellCarApplyMenuText>
                <SelfSellCarStepBarDiv column>
                  <DropShippingText>1. 판매금액</DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        차량 판매 금액
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv style={{ justifyContent: "flex-end" }}>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        {settlementInfo &&
                          Number(settlementInfo.soldPrice).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>

                <SelfSellCarStepBarDiv column>
                  <DropShippingText>2. 비용</DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        필수 비용
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv
                      style={{ justifyContent: "space-between" }}
                    >
                      <CarStorageText4>
                        성능점검 / 차량보관비 / 사진 촬영 / 클레임 보험
                      </CarStorageText4>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        {settlementInfo &&
                          Number(settlementInfo?.essentialPrice).toLocaleString(
                            "en"
                          )}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                  <AuctionInfoDiv style={{ marginTop: 0 }}>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        광고 비용
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv
                      style={{ justifyContent: "space-between" }}
                    >
                      <CarStorageText4>
                        {settlementInfo &&
                          settlementInfo?.adList?.map((list) => list)}
                      </CarStorageText4>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        {settlementInfo &&
                          Number(settlementInfo?.adPrice).toLocaleString("en")}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                  <AuctionInfoDiv style={{ marginTop: 0 }}>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        상품화 비용
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv
                      style={{ justifyContent: "space-between" }}
                    >
                      <UserSelectContainer>
                        {settlementInfo &&
                          settlementInfo?.inspectionList?.map((list, index) => {
                            return (
                              <CarStorageText4 key={index}>
                                {list}
                                &nbsp;
                                {index ===
                                settlementInfo?.inspectionList?.length - 1
                                  ? null
                                  : "/"}
                                &nbsp;
                              </CarStorageText4>
                            );
                          })}
                      </UserSelectContainer>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        {settlementInfo &&
                          Number(settlementInfo?.totalPrice).toLocaleString(
                            "en"
                          )}
                        원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>

                <SelfSellCarStepBarDiv column>
                  <DropShippingText>3. 고객 입금액 </DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        최종 판매 금액
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv style={{ justifyContent: "flex-end" }}>
                      <AuctionText>12,400,000원</AuctionText>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>

                <SelfSellCarStepBarDiv column>
                  <DropShippingText>4. 최종 정산 금액</DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        {" "}
                        선지급 금액
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv
                      style={{ justifyContent: "space-between" }}
                    >
                      <CarStorageText4>2022.01.01 지급</CarStorageText4>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        10,000,000원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                  <AuctionInfoDiv style={{ marginTop: 0 }}>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        추가 정산 금액
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                    <DropShippingDiv
                      style={{ justifyContent: "space-between" }}
                    >
                      <CarStorageText4>2022.01.21 지급 예정</CarStorageText4>
                      <CarStorageText4 style={{ fontWeight: 500 }}>
                        17,000,000원
                      </CarStorageText4>
                    </DropShippingDiv>
                  </AuctionInfoDiv>
                </SelfSellCarStepBarDiv>
                <SelfSellCarStepBarDiv column>
                  <DropShippingText>5. 기타 </DropShippingText>
                  <AuctionInfoDiv>
                    <AuctionMenuDiv>
                      <SelfSellCarStepSubText style={{ marginTop: 0 }}>
                        기타 금액
                      </SelfSellCarStepSubText>
                    </AuctionMenuDiv>
                  </AuctionInfoDiv>
                  <ButtonContainer>
                    <SelfSellCarApplyButton
                      style={{ marginTop: 0 }}
                      onClick={() => setCostModalOpen(true)}
                    >
                      정산 신청
                    </SelfSellCarApplyButton>
                  </ButtonContainer>
                </SelfSellCarStepBarDiv>
              </DropShippingContainer>
            </TabPane>{" "}
          </>
        ) : null}

        {selfSellCarData?.status === "정산완료" &&
        selfSellCarData?.paymentDone ? (
          <TabPane tab="후기 작성" key="후기작성">
            <ReviewText>서비스 후기 작성</ReviewText>
            {selfSellCarData?.reviewable ? (
              <ReviewTextArea
                maxLength={500}
                placeholder="셀프 셀카 서비스는 어떠셨나요? 후기를 작성해주세요."
                value={review}
                onChange={(event) => setReview(event.target.value)}
              />
            ) : (
              <ReviewDiv
                readOnly
                value={selfSellCarData?.review && selfSellCarData?.review}
              />
            )}
            {selfSellCarData?.reviewable ? (
              <CarStorageApplyTextDiv>
                <SelfSellCarApplyButton
                  margin
                  disabled={reviewButton}
                  onClick={() => {
                    setReviewModalOpen(true);
                  }}
                >
                  후기 작성
                </SelfSellCarApplyButton>
              </CarStorageApplyTextDiv>
            ) : null}
          </TabPane>
        ) : null}
      </Tabs>
    </SelfSellCarDataDiv>
  );
};

export default CarInfoTabBar;
